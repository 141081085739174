import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { FileText, AlertTriangle } from "lucide-react";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white" dir="rtl">
      <SEO 
        title="شرایط استفاده | اینستایار"
        description="شرایط و قوانین استفاده از دستیار هوشمند اینستایار برای تولید محتوای اینستاگرام"
        keywords="شرایط استفاده اینستایار, قوانین اینستایار, دستیار هوشمند اینستاگرام"
      />
      
      {/* Use the standardized marketing header */}
      <MarketingHeader currentPage="terms" />

      {/* Hero Section */}
      <section className="py-16 px-6 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <FileText className="w-16 h-16 text-indigo-600 mx-auto mb-6" />
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">شرایط استفاده از خدمات</h1>
          <p className="text-xl text-gray-600 mb-8 leading-relaxed">
            این شرایط، قوانین استفاده از خدمات اینستایار را تعیین می‌کند. لطفاً آن را با دقت مطالعه کنید.
          </p>
        </div>
      </section>

      {/* Censorship Note */}
      <section className="py-6 px-6">
        <div className="max-w-4xl mx-auto">
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-6 flex gap-4">
            <div className="flex-shrink-0">
              <AlertTriangle className="w-8 h-8 text-yellow-500" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-yellow-800 mb-2">تذکر مهم درباره تولید محتوا</h3>
              <p className="text-yellow-700">
                کاربر گرامی، با توجه به قوانین کشور ایران، استفاده از اینستایار برای تولید محتوای غیراخلاقی، سیاسی یا محتوایی که مغایر با قوانین و ارزش‌های جمهوری اسلامی ایران باشد ممنوع است. در صورت مشاهده، حساب کاربری مسدود خواهد شد و ممکن است طبق قوانین کشور مورد پیگرد قانونی قرار گیرید.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Terms Content */}
      <section className="py-12 px-6 mb-16">
        <div className="max-w-4xl mx-auto">
          <div className="space-y-12">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">مقدمه</h2>
              <p className="text-gray-600 mb-4">
                با استفاده از وب‌سایت و خدمات اینستایار، شما با این شرایط استفاده موافقت می‌کنید. اگر با هر بخشی از این شرایط موافق نیستید، لطفاً از خدمات ما استفاده نکنید.
              </p>
              <p className="text-gray-600">
                ما ممکن است این شرایط را در هر زمانی تغییر دهیم. استفاده مداوم شما از خدمات ما پس از هرگونه تغییر، به معنای پذیرش شرایط جدید است.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">حساب کاربری</h2>
              <p className="text-gray-600 mb-4">
                برای استفاده از برخی از خدمات ما، شما باید یک حساب کاربری ایجاد کنید. شما مسئول حفظ امنیت حساب خود و تمامی فعالیت‌هایی هستید که تحت حساب شما انجام می‌شود.
              </p>
              <p className="text-gray-600 mb-4">
                شما باید اطلاعات دقیق و کامل را هنگام ثبت‌نام ارائه دهید و این اطلاعات را در صورت تغییر، به‌روزرسانی کنید.
              </p>
              <p className="text-gray-600">
                ما حق داریم حساب شما را در صورت نقض هر یک از شرایط استفاده، تعلیق یا حذف کنیم.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">خدمات و پرداخت</h2>
              <p className="text-gray-600 mb-4">
                اینستایار یک دوره آزمایشی رایگان با ۲۰ اعتبار برای کاربران جدید ارائه می‌دهد. پس از آن، برای ادامه استفاده از خدمات، باید اعتبار خریداری کنید.
              </p>
              <p className="text-gray-600 mb-4">
                قیمت‌ها و جزئیات پرداخت در صفحه تعرفه‌ها مشخص شده است. ما حق تغییر قیمت‌ها را در هر زمان حفظ می‌کنیم، اما هرگونه تغییر قیمت به شما اطلاع داده خواهد شد.
              </p>
              <p className="text-gray-600">
                پرداخت‌ها غیرقابل بازگشت هستند، مگر در مواردی که خدمات به دلیل مشکلات فنی از سمت ما قابل ارائه نباشند.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">استفاده مجاز</h2>
              <p className="text-gray-600 mb-4">
                شما نباید از خدمات ما برای مقاصد غیرقانونی یا ممنوعه استفاده کنید. شما نباید:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>محتوای نامناسب، توهین‌آمیز، فریبنده یا گمراه‌کننده تولید کنید</li>
                <li>به حقوق مالکیت معنوی دیگران تجاوز کنید</li>
                <li>از خدمات برای ارسال هرزنامه یا محتوای ناخواسته استفاده کنید</li>
                <li>به سیستم‌های ما دسترسی غیرمجاز داشته باشید یا تلاش کنید</li>
                <li>در عملکرد سایت یا خدمات ما اختلال ایجاد کنید</li>
                <li>اطلاعات شخصی دیگران را بدون اجازه آنها جمع‌آوری کنید</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">محتوای تولید شده</h2>
              <p className="text-gray-600 mb-4">
                محتوایی که با استفاده از خدمات ما تولید می‌کنید، متعلق به شماست. با این حال، شما مسئولیت کامل در قبال استفاده از این محتوا و هرگونه پیامد ناشی از آن را بر عهده دارید.
              </p>
              <p className="text-gray-600">
                ما حق داریم هر محتوایی که قوانین ما را نقض می‌کند، حذف کنیم. ما همچنین ممکن است برای بهبود خدمات خود، از الگوهای مشخص شده در کاربرد خدمات توسط شما استفاده کنیم.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">حقوق مالکیت معنوی</h2>
              <p className="text-gray-600 mb-4">
                تمامی حقوق مالکیت معنوی مربوط به وب‌سایت، خدمات و محتوای ما (به جز محتوای تولید شده توسط شما) متعلق به ما یا ارائه‌دهندگان محتوای ما است. استفاده از خدمات ما هیچ حقی را به شما منتقل نمی‌کند.
              </p>
              <p className="text-gray-600">
                شما نباید هیچ عنصری از وب‌سایت یا خدمات ما را کپی، تغییر، توزیع، اجرا، نمایش، یا به هر شکلی استفاده کنید، مگر اینکه به صراحت توسط ما مجاز شناخته شده باشد.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">محدودیت مسئولیت</h2>
              <p className="text-gray-600 mb-4">
                خدمات ما "همانطور که هست" و "آنطور که در دسترس است" ارائه می‌شود، بدون هیچ نوع ضمانتی، صریح یا ضمنی. ما هیچ تضمینی در مورد دقت، قابلیت اطمینان، کیفیت، یا مناسب بودن خدمات برای نیازهای خاص شما ارائه نمی‌دهیم.
              </p>
              <p className="text-gray-600">
                ما در برابر هیچ خسارت غیرمستقیم، تصادفی، ویژه، تبعی یا تنبیهی ناشی از استفاده یا عدم توانایی استفاده از خدمات ما مسئول نیستیم، حتی اگر از احتمال چنین خساراتی مطلع شده باشیم.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">فسخ</h2>
              <p className="text-gray-600 mb-4">
                شما می‌توانید استفاده از خدمات ما را در هر زمانی با حذف حساب کاربری خود متوقف کنید.
              </p>
              <p className="text-gray-600">
                ما نیز می‌توانیم دسترسی شما به خدمات را در صورت نقض این شرایط یا به هر دلیل دیگری، در هر زمان و بدون اطلاع قبلی محدود یا قطع کنیم.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">قانون حاکم</h2>
              <p className="text-gray-600 mb-4">
                این شرایط تحت قوانین جمهوری اسلامی ایران تفسیر و اجرا می‌شود. هرگونه اختلاف ناشی از یا مربوط به این شرایط باید در دادگاه‌های صالح ایران حل و فصل شود.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">تماس با ما</h2>
              <p className="text-gray-600 mb-4">
                اگر سؤالی درباره این شرایط دارید، لطفاً با ما تماس بگیرید:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>ایمیل: info@instayar.com</li>
                <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
                <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
              </ul>
            </div>
          </div>

          <div className="mt-16 text-center">
            <p className="text-gray-600 mb-6">آخرین به‌روزرسانی: ۱۵ تیر ۱۴۰۳</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-12 px-6">
        <div className="max-w-7xl mx-auto grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">لینک‌های مفید</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">خدمات</h3>
            <ul className="space-y-2">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">تماس با ما</h3>
            <ul className="space-y-2">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Terms;
