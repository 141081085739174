
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { ArrowRight, CheckCircle, Code, Cpu, Globe, Hash, Layers, MessageSquare, Instagram, TrendingUp, Clock, Lightbulb, Zap, ShieldCheck } from "lucide-react";
import SEO from "@/components/SEO";
import { MarketingHeader } from "@/components/MarketingHeader";

const Landing = () => {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const {
        data
      } = await supabase.auth.getSession();
      setSession(data.session);
    };
    checkSession();
  }, []);

  const handleGetStarted = () => {
    if (session) {
      navigate("/app");
    } else {
      navigate("/auth");
    }
  };

  // Define structured data for rich snippets
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "اینستایار",
    "applicationCategory": "BusinessApplication",
    "offers": {
      "@type": "Offer",
      "price": "۰",
      "priceCurrency": "IRR"
    },
    "operatingSystem": "Web",
    "description": "دستیار هوشمند اینستاگرام با هوش مصنوعی برای تولید محتوا، افزایش فالوور و رشد کسب و کار",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "254"
    }
  };

  // Data for the page
  const features = [
    {
      icon: MessageSquare,
      title: "تولید کپشن هوشمند",
      description: "کپشن‌های جذاب و تعاملی برای پست‌های اینستاگرام شما با استفاده از هوش مصنوعی تولید می‌شود تا نرخ تعامل را افزایش دهد."
    },
    {
      icon: Hash,
      title: "تولید هشتگ",
      description: "هشتگ‌های مرتبط و پرطرفدار برای افزایش دیده شدن پست‌های شما، با در نظر گرفتن الگوریتم‌های اینستاگرام."
    },
    {
      icon: Layers,
      title: "طراحی کروسل محتوا",
      description: "ایده‌های خلاقانه برای کروسل‌های جذاب که نرخ تعامل را افزایش می‌دهند و زمان ماندگاری کاربر را بیشتر می‌کنند."
    },
    {
      icon: Cpu,
      title: "مشاور هوشمند رشد",
      description: "راهنمایی هوشمند برای بهبود استراتژی محتوا و افزایش فالوور با تحلیل داده‌های پیج شما."
    },
    {
      icon: Globe,
      title: "ترجمه متن حرفه‌ای",
      description: "ترجمه متن‌های شما به فارسی روان و طبیعی برای مخاطبان ایرانی، با حفظ محتوا و مفهوم اصلی."
    },
    {
      icon: Instagram,
      title: "بهینه‌سازی بیو",
      description: "تحلیل و بهینه‌سازی بیوگرافی پیج شما برای جذب فالوور بیشتر و تبدیل بازدیدکنندگان به مشتری."
    },
    {
      icon: MessageSquare,
      title: "مشاور پاسخ به کامنت",
      description: "پیشنهادات هوشمند برای پاسخگویی به کامنت‌ها به شکلی تعاملی و حرفه‌ای."
    },
    {
      icon: MessageSquare,
      title: "مشاور پاسخ به دایرکت",
      description: "ارائه پاسخ‌های حرفه‌ای و هوشمند برای دایرکت‌های مختلف از مشتریان و فالوورها."
    },
    {
      icon: Layers,
      title: "ایده‌پرداز استوری",
      description: "ایجاد ایده‌های خلاقانه برای استوری‌های جذاب که مخاطبان را به تعامل بیشتر تشویق می‌کند."
    },
    {
      icon: Layers,
      title: "ساخت کوئیز و نظرسنجی",
      description: "طراحی کوئیز و نظرسنجی‌های تعاملی برای افزایش مشارکت فالوورها و شناخت بهتر مخاطبان."
    },
    {
      icon: MessageSquare,
      title: "تولید پیام‌های تعاملی",
      description: "ایجاد پیام‌های تعاملی برای ارتباط بهتر با فالوورها و تقویت رابطه با مخاطبان."
    },
    {
      icon: Layers,
      title: "تقویم محتوایی هوشمند",
      description: "برنامه‌ریزی هوشمند برای انتشار محتوا در بهترین زمان‌ها برای دستیابی به بیشترین تعامل."
    },
    {
      icon: Layers,
      title: "ایده‌پرداز رویدادها",
      description: "طراحی کمپین‌های مختلف برای مناسبت‌های تقویمی و رویدادهای خاص."
    },
    {
      icon: Layers,
      title: "مشاور زیبایی‌شناسی",
      description: "مشاوره برای ایجاد هارمونی بصری و زیبایی‌شناسی در فید اینستاگرام شما."
    },
    {
      icon: MessageSquare,
      title: "ویرایشگر متن و کپشن",
      description: "ویرایش و بهبود متن‌های شما برای تاثیرگذاری بیشتر و جذابیت بالاتر."
    },
    {
      icon: Layers,
      title: "بازنویسی محتوا",
      description: "تبدیل محتوای قدیمی به فرمت‌های جدید برای استفاده مجدد و حداکثر بهره‌وری."
    },
    {
      icon: Layers,
      title: "مشاور تبلیغات",
      description: "ارائه استراتژی‌های هوشمند برای تبلیغات اثربخش در اینستاگرام."
    }
  ];

  const howItWorks = [
    {
      title: "انتخاب ابزار",
      description: "از میان ابزارهای متنوع اینستایار، ابزار مورد نظر خود را انتخاب کنید."
    },
    {
      title: "وارد کردن جزئیات",
      description: "موضوع، سبک و جزئیات محتوای مورد نظر خود را وارد کنید."
    },
    {
      title: "دریافت محتوا",
      description: "محتوای تولید شده توسط هوش مصنوعی را دریافت کرده و در اینستاگرام خود استفاده کنید."
    }
  ];

  const testimonials = [
    {
      name: "سارا محمدی",
      title: "اینفلوئنسر سبک زندگی",
      quote: "اپ شما خیلی باحاله! دیگه وقتم رو واسه کپشن نوشتن هدر نمیدم. الان فالوورام کلی رشد کردن و کلی هم کامنت و لایک می‌گیرم!"
    },
    {
      name: "علی کریمی",
      title: "صاحب فروشگاه آنلاین",
      quote: "از وقتی با اینستایار کار می‌کنم، فروشم ۴۰ درصد بیشتر شده. کپشن‌هاش خیلی خفنه و مشتری‌ها رو جذب می‌کنه. دمش گرم!"
    },
    {
      name: "نیلوفر رضایی",
      title: "مدیر دیجیتال مارکتینگ",
      quote: "اینستایار بهترین ابزاریه که تا حالا دیدیم! کار تیم ما رو خیلی راحت کرده و وقتمون رو آزاد کرده که روی استراتژی تمرکز کنیم. هزینه‌اش هم به صرفه‌ست."
    }
  ];

  const pricingPlans = [
    {
      name: "پکیج مبتدی",
      description: "برای کاربران تازه‌کار",
      price: "۵۰,۰۰۰",
      credits: "۵۰",
      features: ["دسترسی به تمام ابزارها", "پشتیبانی ایمیلی", "بدون محدودیت زمانی", "بهبود مداوم کیفیت هوش مصنوعی"],
      featured: false
    },
    {
      name: "پکیج متوسط",
      description: "برای اینفلوئنسرها و برندها",
      price: "۱۵۰,۰۰۰",
      credits: "۲۰۰",
      features: ["دسترسی به تمام ابزارها", "پشتیبانی ایمیلی", "بدون محدودیت زمانی", "بهبود مداوم کیفیت هوش مصنوعی"],
      featured: true
    },
    {
      name: "پکیج حرفه‌ای",
      description: "برای آژانس‌ها و کسب‌وکارها",
      price: "۲۵۰,۰۰۰",
      credits: "۵۰۰",
      features: ["دسترسی به تمام ابزارها", "پشتیبانی ایمیلی", "بدون محدودیت زمانی", "بهبود مداوم کیفیت هوش مصنوعی"],
      featured: false
    }
  ];

  const faqs = [
    {
      question: "اینستایار چگونه کار می‌کند؟",
      answer: "اینستایار با استفاده از هوش مصنوعی پیشرفته و الگوریتم‌های یادگیری ماشین، محتوای اینستاگرام را تجزیه و تحلیل کرده و محتوای مناسب و جذاب برای پیج شما تولید می‌کند. کافیست موضوع و سبک مورد نظر خود را وارد کنید تا محتوای مناسب دریافت کنید."
    },
    {
      question: "آیا محتوای تولید شده توسط اینستایار منحصر به فرد است؟",
      answer: "بله، تمامی محتوای تولید شده توسط اینستایار کاملاً منحصر به فرد و اختصاصی است. هوش مصنوعی ما از داده‌های متنوع آموزش دیده است تا محتوایی اصیل و خلاقانه تولید کند."
    },
    {
      question: "آیا برای استفاده از اینستایار نیاز به دانش فنی خاصی دارم؟",
      answer: "خیر، استفاده از اینستایار بسیار ساده و بدون نیاز به دانش فنی خاصی است. رابط کاربری بسیار ساده و راهنمای گام به گام برای استفاده از هر ابزار وجود دارد."
    },
    {
      question: "اعتبار خریداری شده چه مدت اعتبار دارد؟",
      answer: "اعتبار خریداری شده در اینستایار محدودیت زمانی ندارد و هر زمان که بخواهید می‌توانید از آن استفاده کنید. اعتبارها پس از خرید به حساب کاربری شما اضافه می‌شوند."
    },
    {
      question: "آیا اینستایار برای همه کسب و کارها مناسب است؟",
      answer: "بله، اینستایار برای تمامی کسب و کارها، اینفلوئنسرها، برندها و افراد حقیقی که قصد رشد در اینستاگرام را دارند مناسب است. ابزارهای هوشمند ما برای صنایع مختلف و سبک‌های گوناگون محتوا طراحی شده‌اند."
    },
    {
      question: "چگونه می‌توانم به پشتیبانی دسترسی داشته باشم؟",
      answer: "پشتیبانی اینستایار از طریق ایمیل و فرم تماس در دسترس است. تیم ما در کمتر از ۲۴ ساعت به درخواست‌های پشتیبانی پاسخ می‌دهد."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 via-purple-50 to-white overflow-x-hidden" dir="rtl">
      <SEO title="اینستایار | دستیار هوشمند اینستاگرام با هوش مصنوعی" description="اینستایار با کمک هوش مصنوعی پیشرفته به شما کمک می‌کند محتوای جذاب و تعاملی برای اینستاگرام تولید کنید و فالوورها را به سرعت افزایش دهید. تولید کپشن، هشتگ و ایده‌های محتوایی با هوش مصنوعی." keywords="اینستاگرام، هوش مصنوعی، افزایش فالوور، تولید محتوا، کپشن اینستاگرام، هشتگ اینستاگرام، بازاریابی اینستاگرام، رشد پیج اینستاگرام، استراتژی اینستاگرام، اینستایار" structuredData={structuredData} />
      
      {/* Hero Section with Responsive NavBar */}
      <MarketingHeader />

      <section className="relative pt-20 pb-32 px-6 max-w-7xl mx-auto">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <div className="absolute left-0 right-0 top-0 -z-10 h-96 bg-gradient-to-b from-indigo-100 to-transparent blur-3xl"></div>
        </div>

        <div className="max-w-3xl mx-auto text-center">
          <motion.h1 className="text-4xl md:text-6xl font-bold text-indigo-900 mb-6" initial={{
          opacity: 0,
          y: 20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          duration: 0.5
        }}>
            دستیار هوشمند اینستاگرام شما
          </motion.h1>
          <motion.p className="text-xl text-gray-700 mb-4 leading-relaxed" initial={{
          opacity: 0,
          y: 20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          duration: 0.5,
          delay: 0.1
        }}>
            اینستایار با کمک هوش مصنوعی پیشرفته، به شما کمک می‌کند تا محتوای جذاب، کاربردی و تعاملی برای اینستاگرام خود تولید کنید و فالوورهای خود را به سرعت افزایش دهید.
          </motion.p>
          <motion.div className="bg-indigo-100 border-2 border-indigo-300 rounded-lg p-4 mb-8 text-indigo-800 font-bold" initial={{
          opacity: 0,
          scale: 0.9
        }} animate={{
          opacity: 1,
          scale: 1
        }} transition={{
          duration: 0.5,
          delay: 0.2
        }}>
            <Zap className="inline-block ml-2 mb-1" />
            با ثبت‌نام، ۲۰ اعتبار رایگان دریافت کنید!
          </motion.div>
          <motion.div initial={{
          opacity: 0,
          y: 20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          duration: 0.5,
          delay: 0.3
        }} className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button onClick={handleGetStarted} size="lg" className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-full px-8 text-lg h-14">
              شروع رایگان
              <ArrowRight className="mr-2" />
            </Button>
          </motion.div>
        </div>

        <motion.div className="mt-16 relative max-w-5xl mx-auto" initial={{
        opacity: 0,
        y: 40
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        duration: 0.7,
        delay: 0.3
      }}>
          <div className="rounded-2xl overflow-hidden shadow-2xl border border-indigo-100">
            <img src="/lovable-uploads/a99ea6bb-5f8f-496b-ba97-5ed9f4a2eea9.png" alt="اینستایار - دستیار هوشمند اینستاگرام با هوش مصنوعی" className="w-full h-auto" />
            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-2xl"></div>
          </div>
        </motion.div>
      </section>

      {/* Safety Notice */}
      <section className="py-8 px-6 bg-white">
        <div className="max-w-5xl mx-auto">
          <div className="flex items-center justify-center bg-green-50 p-6 rounded-xl border border-green-200">
            <ShieldCheck className="text-green-600 w-8 h-8 ml-4 flex-shrink-0" />
            <p className="text-lg text-gray-800">
              <span className="font-bold text-green-700">استفاده ایمن:</span> ابزارهای اینستایار هیچ گونه تداخلی با شرایط استفاده از اینستاگرام ندارند و برخلاف ابزارهای دیگر، باعث مسدود شدن یا گزارش حساب شما نمی‌شوند.
            </p>
          </div>
        </div>
      </section>

      {/* Benefits Banner */}
      <section className="py-12 bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
        <div className="max-w-6xl mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="flex flex-col items-center">
              <Clock className="w-10 h-10 mb-4" />
              <h3 className="text-xl font-semibold mb-2">صرفه‌جویی در زمان</h3>
              <p className="text-indigo-100">تولید محتوا در کمتر از ۱ دقیقه</p>
            </div>
            <div className="flex flex-col items-center">
              <TrendingUp className="w-10 h-10 mb-4" />
              <h3 className="text-xl font-semibold mb-2">افزایش تعامل</h3>
              <p className="text-indigo-100">محتوای جذاب برای افزایش لایک و کامنت</p>
            </div>
            <div className="flex flex-col items-center">
              <Lightbulb className="w-10 h-10 mb-4" />
              <h3 className="text-xl font-semibold mb-2">ایده‌های خلاقانه</h3>
              <p className="text-indigo-100">پایان بن‌بست خلاقیت در تولید محتوا</p>
            </div>
          </div>
        </div>
      </section>

      {/* AI Improvement Section */}
      <section className="py-16 px-6 bg-gradient-to-b from-white to-indigo-50">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-2xl overflow-hidden shadow-xl border border-indigo-100 p-8">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="flex-shrink-0 bg-indigo-600 rounded-full p-6 w-24 h-24 flex items-center justify-center">
                <Cpu className="w-12 h-12 text-white" />
              </div>
              
              <div className="flex-1">
                <h2 className="text-3xl font-bold text-indigo-900 mb-4">هوش مصنوعی دائماً در حال بهبود</h2>
                <p className="text-lg text-gray-700 mb-4">
                  ما به طور مداوم در حال ارتقاء الگوریتم‌های هوش مصنوعی اینستایار هستیم. با هر بروزرسانی، محتوای تولید شده هوشمندتر، جذاب‌تر و مؤثرتر می‌شود.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <CheckCircle className="text-green-500 ml-2 flex-shrink-0" />
                    <span>بروزرسانی‌های منظم الگوریتم‌های تولید محتوا</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle className="text-green-500 ml-2 flex-shrink-0" />
                    <span>اضافه شدن ابزارهای جدید بر اساس نیازهای کاربران</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle className="text-green-500 ml-2 flex-shrink-0" />
                    <span>تطبیق با آخرین تغییرات و الگوریتم‌های اینستاگرام</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 px-6 bg-white" id="features">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 px-4 py-1 rounded-full text-sm font-medium">ویژگی‌های اینستایار</span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mt-4 mb-4">ابزارهای هوشمند برای رشد پیج اینستاگرام</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              اینستایار با مجموعه‌ای از ابزارهای هوشمند، کار تولید محتوا و رشد پیج اینستاگرام را برای شما آسان می‌کند.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div 
                key={index} 
                className="bg-white rounded-xl p-6 border border-gray-100 shadow-sm hover:shadow-md transition-shadow hover:border-indigo-200" 
                initial={{ opacity: 0, y: 20 }} 
                whileInView={{ opacity: 1, y: 0 }} 
                transition={{ duration: 0.5, delay: index * 0.1 }} 
                viewport={{ once: true }}
              >
                <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center text-indigo-600 mb-5">
                  <feature.icon className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section className="py-24 px-6 bg-gray-50" id="how-it-works">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 px-4 py-1 rounded-full text-sm font-medium">نحوه کار</span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mt-4 mb-4">تولید محتوا در سه مرحله ساده</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              تولید محتوا با اینستایار بسیار ساده است و در سه مرحله انجام می‌شود.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {howItWorks.map((step, index) => (
              <motion.div 
                key={index} 
                className="bg-white rounded-xl p-6 border border-gray-100 shadow-sm text-center" 
                initial={{ opacity: 0, y: 20 }} 
                whileInView={{ opacity: 1, y: 0 }} 
                transition={{ duration: 0.5, delay: index * 0.1 }} 
                viewport={{ once: true }}
              >
                <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center text-white mx-auto mb-6">
                  <span className="text-xl font-bold">{index + 1}</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="py-24 px-6 bg-white relative overflow-hidden" id="testimonials">
        <div className="absolute top-0 right-0 w-96 h-96 bg-indigo-100 rounded-full -mr-48 -mt-48 opacity-50"></div>
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-100 rounded-full -ml-48 -mb-48 opacity-50"></div>
        
        <div className="max-w-7xl mx-auto relative z-10">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 px-4 py-1 rounded-full text-sm font-medium">تجربه‌های کاربران</span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mt-4 mb-4">کاربران اینستایار چه می‌گویند</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              اینفلوئنسرها، برندها و کسب‌وکارهای مختلف از اینستایار برای رشد پیج اینستاگرام خود استفاده می‌کنند.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <motion.div 
                key={index} 
                className="bg-white rounded-xl p-6 shadow-sm border border-gray-100" 
                initial={{ opacity: 0, y: 20 }} 
                whileInView={{ opacity: 1, y: 0 }} 
                transition={{ duration: 0.5, delay: index * 0.1 }} 
                viewport={{ once: true }}
              >
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-bold text-xl">
                    {testimonial.name.charAt(0)}
                  </div>
                  <div className="mr-3">
                    <p className="font-semibold text-gray-900">{testimonial.name}</p>
                    <p className="text-sm text-gray-500">{testimonial.title}</p>
                  </div>
                </div>
                <p className="text-gray-600 mb-4 leading-relaxed">"{testimonial.quote}"</p>
                <div className="flex text-yellow-400">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-24 px-6 bg-gradient-to-b from-white to-indigo-50" id="pricing">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 px-4 py-1 rounded-full text-sm font-medium">تعرفه‌ها</span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mt-4 mb-4">قیمت‌گذاری ساده و مقرون به صرفه</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              هر ابزار بر اساس قدرت هوش مصنوعی مصرفی، اعتبار مشخصی نیاز دارد. با خرید اعتبار، به تمامی ابزارهای اینستایار دسترسی خواهید داشت.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {pricingPlans.map((plan, index) => (
              <motion.div
                key={index}
                className={`rounded-xl p-8 border ${
                  plan.featured ? 'bg-white border-indigo-200 shadow-lg relative' : 'bg-white/80 border-gray-100'
                }`}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                {plan.featured && (
                  <div className="absolute top-0 right-8 -translate-y-1/2 bg-indigo-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                    محبوب‌ترین
                  </div>
                )}
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{plan.name}</h3>
                <p className="text-gray-500 mb-6 text-sm">{plan.description}</p>
                <div className="mb-6">
                  <span className="text-4xl font-bold text-gray-900">{plan.price}</span>
                  <span className="text-gray-500"> تومان</span>
                  <p className="text-gray-500 text-sm mt-1">پرداخت یک‌باره</p>
                </div>
                <ul className="space-y-3 mb-8">
                  <li className="flex items-center text-gray-700">
                    <CheckCircle className="w-5 h-5 text-green-500 ml-2 flex-shrink-0" />
                    <span>{plan.credits} اعتبار</span>
                  </li>
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-700">
                      <CheckCircle className="w-5 h-5 text-green-500 ml-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <Button
                  className={`w-full ${
                    plan.featured
                      ? 'bg-indigo-600 hover:bg-indigo-700 text-white'
                      : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
                  }`}
                  onClick={handleGetStarted}
                >
                  انتخاب پلن
                </Button>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-24 px-6 bg-white" id="faq">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-16">
            <span className="bg-indigo-100 text-indigo-800 px-4 py-1 rounded-full text-sm font-medium">سؤالات متداول</span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mt-4 mb-4">به سؤالات شما پاسخ می‌دهیم</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              پاسخ سؤالات رایج درباره اینستایار را اینجا می‌توانید بیابید.
            </p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                className="border border-gray-200 rounded-lg p-6 bg-white"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.05 }}
                viewport={{ once: true }}
              >
                <h3 className="text-lg font-semibold text-gray-900 mb-3">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 px-6 bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">آماده‌اید پیج اینستاگرام خود را متحول کنید؟</h2>
          <p className="text-xl opacity-90 mb-10 max-w-2xl mx-auto">
            با اینستایار، تولید محتوا و رشد در اینستاگرام آسان‌تر از همیشه است. درآمد و شهرت خود را با محتوای حرفه‌ای و جذاب افزایش دهید.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button onClick={handleGetStarted} size="lg" className="bg-white text-indigo-700 hover:bg-gray-100 rounded-full px-8 text-lg h-14">
              شروع رایگان
              <ArrowRight className="mr-2" />
            </Button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="pt-20 pb-8 px-6 bg-gray-900 text-gray-400">
        <div className="max-w-7xl mx-auto grid md:grid-cols-4 gap-8 mb-16">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
            <div className="flex space-x-4">
              <a href="https://instagram.com/instayar.ai" className="text-gray-400 hover:text-white" aria-label="اینستاگرام اینستایار">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.148-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.012-3.584.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z" />
                </svg>
              </a>
            </div>
            
            {/* E-Namad Trust Seal */}
            <div className="mt-6">
              <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=587430&amp;Code=yJLcoctpyjH4fAkFyInNUsgL1EV5fT8y">
                <img referrerPolicy="origin" src="/lovable-uploads/1d262131-51ea-486f-a574-7441fb600b25.png" alt="نماد اعتماد الکترونیکی" data-code="yJLcoctpyjH4fAkFyInNUsgL1EV5fT8y" style={{ cursor: "pointer" }} />
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">امکانات</h3>
            <ul className="space-y-2">
              <li><a href="#features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="#how-it-works" className="hover:text-white">نحوه کار</a></li>
              <li><a href="#pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="#faq" className="hover:text-white">سؤالات متداول</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">شرکت</h3>
            <ul className="space-y-2">
              <li><a href="/about" className="hover:text-white">درباره ما</a></li>
              <li><a href="/blog" className="hover:text-white">وبلاگ</a></li>
              <li><a href="/careers" className="hover:text-white">فرصت‌های شغلی</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">قوانین</h3>
            <ul className="space-y-2">
              <li><a href="/terms" className="hover:text-white">قوانین استفاده</a></li>
              <li><a href="/privacy" className="hover:text-white">حریم خصوصی</a></li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 pt-8 text-center">
          <p className="text-sm">© {new Date().getFullYear()} تمامی حقوق برای اینستایار محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
