
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface SettingsTabProps {
  deleteConfirmText: string;
  setDeleteConfirmText: (text: string) => void;
  onSignOut: () => Promise<void>;
  onDeleteAccount: () => Promise<void>;
  loading: boolean;
}

const SettingsTab = ({
  onSignOut,
  loading
}: SettingsTabProps) => {
  return <Card>
      <CardHeader>
        <CardTitle>تنظیمات حساب کاربری</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h3 className="text-lg font-medium mb-2">تغییر رمز عبور</h3>
          <p className="text-gray-600 mb-4">برای تغییر رمز عبور خود، ابتدا از حساب کاربری خارج شوید و سپس از گزینه "فراموشی رمز عبور" استفاده کنید.</p>
          <Button variant="outline" onClick={onSignOut} className="text-slate-950">
            خروج برای تغییر رمز عبور
          </Button>
        </div>
      </CardContent>
    </Card>;
};

export default SettingsTab;
