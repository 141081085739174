
import { useEffect } from 'react';
import { useAdminAuth } from '@/hooks/useAdminAuth';
import AdminLayout from '@/components/admin/AdminLayout';
import AdminSettings from '@/components/admin/AdminSettings';
import { Skeleton } from '@/components/ui/skeleton';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/lib/auth';
import { securityUtils } from '@/lib/utils';

const AdminSettingsPage = () => {
  const { isAdmin, loading } = useAdminAuth();
  const { session } = useAuth();
  
  useEffect(() => {
    if (session && isAdmin) {
      // تضمین امنیت داده‌ها قبل از ارسال به سرور
      const safeUserID = securityUtils.sanitizeString(session.user.id);
      
      // Log page access for audit trail
      supabase.from('user_events').insert({
        user_id: safeUserID,
        event_type: 'admin_page_view',
        event_data: { 
          page: 'settings',
          timestamp: new Date().toISOString()
        }
      }).then(({ error }) => {
        if (error) console.error('خطا در ثبت رویداد:', error);
      });
    }
  }, [session, isAdmin]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white" dir="rtl">
        <div className="text-center">
          <Skeleton className="h-8 w-32 bg-gray-700 mb-4 mx-auto" />
          <div className="text-lg">در حال بارگذاری...</div>
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <AdminLayout>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6 text-white">تنظیمات مدیریت</h1>
        <AdminSettings />
      </div>
    </AdminLayout>
  );
};

export default AdminSettingsPage;
