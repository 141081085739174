
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Loader2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import HCaptcha from "./HCaptcha";
import { HCAPTCHA_SITE_KEY, CAPTCHA_ERROR_MESSAGES } from "./captchaConfig";

declare global {
  interface Window {
    hcaptcha?: {
      reset: () => void;
    };
  }
}

const loginSchema = z.object({
  email: z.string().email("لطفاً یک ایمیل معتبر وارد کنید"),
  password: z.string().min(6, "رمز عبور باید حداقل ۶ کاراکتر باشد"),
});

export type LoginFormValues = z.infer<typeof loginSchema>;

interface LoginFormProps {
  onModeChange: (mode: "signup" | "forgotPassword") => void;
}

// Key for tracking login attempts in local storage
const LOGIN_ATTEMPTS_KEY = 'login_attempts';
const MAX_ATTEMPTS_BEFORE_CAPTCHA = 3;

const LoginForm = ({ onModeChange }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string | null>(null);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [captchaRequired, setCaptchaRequired] = useState(false);
  const { toast } = useToast();

  // Load login attempts from localStorage on component mount
  useEffect(() => {
    const attempts = parseInt(localStorage.getItem(LOGIN_ATTEMPTS_KEY) || '0', 10);
    setLoginAttempts(attempts);
    setCaptchaRequired(attempts >= MAX_ATTEMPTS_BEFORE_CAPTCHA);
  }, []);

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleCaptchaVerify = (token: string) => {
    console.log("کپچا تأیید شد، طول توکن:", token.length);
    setCaptchaToken(token);
    setCaptchaError(null);
  };

  const handleCaptchaError = (error: any) => {
    console.error("هکپچا خطا داد:", error);
    
    let errorMessage = CAPTCHA_ERROR_MESSAGES.loadingError;
    if (error === "network-error") {
      errorMessage = CAPTCHA_ERROR_MESSAGES.networkError;
    }
    
    setCaptchaError(errorMessage);
    setCaptchaToken(null);
  };

  const handleCaptchaExpire = () => {
    console.log("کپچا منقضی شد");
    setCaptchaToken(null);
    setCaptchaError(CAPTCHA_ERROR_MESSAGES.expiredError);
  };

  const verifyCaptchaToken = async (token: string): Promise<boolean> => {
    try {
      console.log("ارسال درخواست تأیید کپچا با توکن:", token.substring(0, 15) + "...");
      
      const { data: authData } = await supabase.auth.getSession();
      
      const { data, error: functionError } = await supabase.functions.invoke("verify-hcaptcha", {
        body: { token },
      });
      
      console.log("نتیجه تأیید کپچا:", data);
      
      if (functionError) {
        console.error("خطا در تابع edge:", functionError);
        return false;
      }
      
      if (data && data.success === true) {
        console.log("کپچا با موفقیت تأیید شد");
        return true;
      } else {
        console.error("خطا در تأیید کپچا:", data?.error || "خطای نامشخص");
        if (data?.errorCodes) {
          console.error("کدهای خطای هکپچا:", data.errorCodes);
        }
        return false;
      }
    } catch (error) {
      console.error("خطا در ارسال درخواست تأیید کپچا:", error);
      return false;
    }
  };

  const incrementLoginAttempts = () => {
    const newAttempts = loginAttempts + 1;
    setLoginAttempts(newAttempts);
    localStorage.setItem(LOGIN_ATTEMPTS_KEY, newAttempts.toString());
    
    if (newAttempts >= MAX_ATTEMPTS_BEFORE_CAPTCHA) {
      setCaptchaRequired(true);
    }
  };

  const resetLoginAttempts = () => {
    setLoginAttempts(0);
    setCaptchaRequired(false);
    localStorage.setItem(LOGIN_ATTEMPTS_KEY, '0');
  };

  const handleLogin = async (data: LoginFormValues) => {
    setLoading(true);
    setError(null);

    try {
      // Check if captcha is required
      if (captchaRequired && !captchaToken) {
        throw new Error("لطفاً ابتدا کپچا را تکمیل کنید");
      }
      
      // Verify captcha if available
      if (captchaToken) {
        console.log("در حال تأیید کپچا...");
        const isValidCaptcha = await verifyCaptchaToken(captchaToken);
        
        if (!isValidCaptcha) {
          throw new Error(CAPTCHA_ERROR_MESSAGES.verificationError);
        }
        
        console.log("کپچا تأیید شد، تلاش برای ورود...");
      }
      
      // Login
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

      if (signInError) {
        console.error("خطای ورود به سوپابیس:", signInError);
        incrementLoginAttempts();
        throw signInError;
      }
      
      console.log("ورود موفقیت‌آمیز!");
      resetLoginAttempts();
      
    } catch (error: any) {
      console.error("خطا در ورود:", error);
      setError(error.message || "مشکلی در فرآیند ورود پیش آمد");
      
      if (error.message && error.message.includes("Invalid login credentials")) {
        setError("ایمیل یا رمز عبور نادرست است");
        // Only increment attempts for credential errors
        incrementLoginAttempts();
      }
      
      toast({
        title: "خطا",
        description: error.message || "مشکلی در فرآیند ورود پیش آمد",
        variant: "destructive",
      });
      
      if (error.message && error.message.includes("کپچا")) {
        setCaptchaToken(null);
        try {
          if (window.hcaptcha) {
            window.hcaptcha.reset();
          }
        } catch (e) {
          console.error("خطا در بازنشانی کپچا:", e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleLogin)} className="space-y-3">
      <div className="space-y-1">
        <Label htmlFor="email" className="text-white text-sm">ایمیل</Label>
        <Input
          id="email"
          type="email"
          placeholder="name@example.com"
          className="bg-white/10 border-white/20 text-white placeholder:text-gray-400 text-sm"
          {...form.register("email")}
        />
        {form.formState.errors.email && (
          <p className="text-xs text-red-400">{form.formState.errors.email.message}</p>
        )}
      </div>
      
      <div className="space-y-1">
        <div className="flex justify-between items-center">
          <Label htmlFor="password" className="text-white text-sm">رمز عبور</Label>
          <Button 
            type="button" 
            variant="ghost" 
            className="text-xs text-indigo-300 hover:text-indigo-200 p-0 h-auto"
            onClick={() => onModeChange("forgotPassword")}
          >
            فراموشی رمز عبور؟
          </Button>
        </div>
        <Input
          id="password"
          type="password"
          placeholder="رمز عبور خود را وارد کنید"
          className="bg-white/10 border-white/20 text-white placeholder:text-gray-400 text-sm"
          {...form.register("password")}
        />
        {form.formState.errors.password && (
          <p className="text-xs text-red-400">{form.formState.errors.password.message}</p>
        )}
      </div>
      
      {captchaRequired && (
        <>
          <HCaptcha
            sitekey={HCAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerify}
            onError={handleCaptchaError}
            onExpire={handleCaptchaExpire}
          />
          
          {captchaError && (
            <p className="text-xs text-red-400">{captchaError}</p>
          )}
          
          {loginAttempts >= MAX_ATTEMPTS_BEFORE_CAPTCHA && (
            <p className="text-xs text-yellow-400">
              بیش از {MAX_ATTEMPTS_BEFORE_CAPTCHA} بار تلاش ناموفق برای ورود انجام شده است. لطفاً کپچا را تکمیل کنید.
            </p>
          )}
        </>
      )}
      
      <Button 
        type="submit" 
        variant="gradient"
        className="w-full text-sm h-9 mt-3"
        disabled={loading || (captchaRequired && !captchaToken)}
      >
        {loading ? <Loader2 className="h-4 w-4 animate-spin ml-2" /> : null}
        ورود
      </Button>
      
      <div className="text-center text-xs">
        <span className="text-gray-300">حساب کاربری ندارید؟</span>{" "}
        <button 
          type="button"
          onClick={() => onModeChange("signup")}
          className="text-indigo-300 hover:text-indigo-200"
        >
          ثبت‌نام کنید
        </button>
      </div>

      {error && (
        <div className="bg-red-500/20 text-red-200 p-2 rounded-md text-xs border border-red-500/20">
          {error}
        </div>
      )}
    </form>
  );
};

export default LoginForm;
