
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";

interface FormData {
  text: string;
  targetLanguage: string;
}

const languages = [
  { value: "English", label: "انگلیسی" },
  { value: "Arabic", label: "عربی" },
  { value: "French", label: "فرانسوی" },
  { value: "German", label: "آلمانی" },
  { value: "Spanish", label: "اسپانیایی" },
  { value: "Italian", label: "ایتالیایی" },
  { value: "Russian", label: "روسی" },
  { value: "Chinese", label: "چینی" },
  { value: "Japanese", label: "ژاپنی" },
  { value: "Korean", label: "کره‌ای" },
  { value: "Turkish", label: "ترکی" },
];

export const TextTranslator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [translatedText, setTranslatedText] = useState<string>("");
  const { toast } = useToast();
  const form = useForm<FormData>();
  const { profile, refreshProfile } = useAuth();

  const onSubmit = async (data: FormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "translator", creditCost: 2 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    if (data.text.length > 2000) {
      toast({
        title: "خطا",
        description: "متن وارد شده نباید بیشتر از ۲۰۰۰ کاراکتر باشد.",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      // Call the function first to ensure it works before deducting credits
      const response = await supabase.functions.invoke('translate-text', {
        body: data
      });

      if (response.error) throw response.error;
      
      const result = response.data;
      
      // Check if result contains error property
      if (result && result.error) {
        throw new Error(result.error);
      }
      
      // Now deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setTranslatedText(result.translated_text);

      try {
        const { error: saveError } = await supabase.from('translations').insert({
          original_text: data.text,
          target_language: data.targetLanguage,
          translated_text: result.translated_text
        });
        
        if (saveError) {
          console.error("Error saving translation:", saveError);
          // Continue execution even if saving fails
        }
      } catch (saveException) {
        console.error("Exception saving translation:", saveException);
        // Continue execution even if saving fails
      }

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "ترجمه انجام شد",
        description: "متن شما با موفقیت ترجمه شد",
      });

    } catch (error: any) {
      toast({
        title: "خطا",
        description: error.message || "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="text"
            render={({ field }) => (
              <FormItem>
                <FormLabel>متن اصلی</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="متن مورد نظر خود را وارد کنید (حداکثر ۲۰۰۰ کاراکتر)..."
                    className="h-32"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="targetLanguage"
            render={({ field }) => (
              <FormItem>
                <FormLabel>زبان مقصد</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="زبان مورد نظر را انتخاب کنید" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {languages.map((language) => (
                      <SelectItem key={language.value} value={language.value}>
                        {language.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            ترجمه متن
          </Button>
        </form>
      </Form>

      {translatedText ? (
        <Card className="p-4">
          <h3 className="font-bold mb-2">متن ترجمه شده</h3>
          <p className="whitespace-pre-wrap">{translatedText}</p>
        </Card>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>مترجم هوشمند</strong> به شما کمک می‌کند متن‌های خود را به ۱۰ زبان زنده دنیا ترجمه کنید. 
            این ابزار با استفاده از هوش مصنوعی پیشرفته، ترجمه‌های دقیق و روان ارائه می‌دهد که از ترجمه‌های ماشینی معمولی بسیار بهتر هستند. 
            مناسب برای ترجمه پست‌ها، کپشن‌ها، پیام‌ها و متن‌های کوتاه با کمترین هزینه ممکن.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
