import React from "react";
interface InfoBoxProps {
  children: React.ReactNode;
}
export const InfoBox = ({
  children
}: InfoBoxProps) => {
  return <div className="backdrop-blur-sm border border-white/10 p-4 rounded-md mb-4 text-right text-white bg-slate-900/[0.43]">
      {children}
    </div>;
};