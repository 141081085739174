
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { 
  Home, 
  Users, 
  CreditCard, 
  BarChart2, 
  Wrench, 
  LogOut, 
  Settings,
  TicketIcon,
  AlertCircle
} from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from '@/lib/auth';

// Admin session timeout (15 minutes)
const ADMIN_SESSION_TIMEOUT = 15 * 60 * 1000;

const AdminSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { session } = useAuth();
  const [inactivityTime, setInactivityTime] = useState(0);
  const [showWarning, setShowWarning] = useState(false);

  // Setup activity monitors and session timeout
  useEffect(() => {
    if (!session) return;
    
    let inactivityTimer: number;
    let warningShown = false;
    
    // Reset the timer on any user activity
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      setShowWarning(false);
      
      // Check if session has expired based on timestamp
      const roleTimestamp = sessionStorage.getItem('admin_role_timestamp');
      if (roleTimestamp) {
        const timestamp = parseInt(roleTimestamp, 10);
        const elapsed = Date.now() - timestamp;
        
        if (elapsed > ADMIN_SESSION_TIMEOUT) {
          // Session expired, log out the user
          handleLogout();
          toast({
            title: "جلسه منقضی شده",
            description: "جلسه شما به پایان رسیده است. لطفاً دوباره وارد شوید.",
            variant: "destructive"
          });
          return;
        }
        
        // Update remaining time
        setInactivityTime(Math.floor((ADMIN_SESSION_TIMEOUT - elapsed) / 1000));
        
        // Set warning at 2 minutes before timeout
        if (elapsed > ADMIN_SESSION_TIMEOUT - (2 * 60 * 1000) && !warningShown) {
          setShowWarning(true);
          warningShown = true;
          toast({
            title: "هشدار پایان جلسه",
            description: "جلسه شما تا 2 دقیقه دیگر به پایان می‌رسد. برای ادامه کار فعالیتی انجام دهید.",
            // تغییر از "warning" به "destructive" برای سازگاری با انواع مجاز
            variant: "destructive",
            duration: 10000
          });
        }
        
        // Set timer for session expiration
        inactivityTimer = window.setTimeout(() => {
          // Session expired, log out the user
          handleLogout();
          toast({
            title: "جلسه منقضی شده",
            description: "به دلیل عدم فعالیت، جلسه شما به پایان رسیده است.",
            variant: "destructive"
          });
        }, ADMIN_SESSION_TIMEOUT - elapsed);
      }
    };
    
    // Event listeners for user activity
    const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
    activityEvents.forEach(event => {
      document.addEventListener(event, resetTimer);
    });
    
    // Initial timer setup
    resetTimer();
    
    // Set interval to update the timer display
    const timerInterval = setInterval(() => {
      const roleTimestamp = sessionStorage.getItem('admin_role_timestamp');
      if (roleTimestamp) {
        const timestamp = parseInt(roleTimestamp, 10);
        const elapsed = Date.now() - timestamp;
        const remaining = Math.max(0, Math.floor((ADMIN_SESSION_TIMEOUT - elapsed) / 1000));
        setInactivityTime(remaining);
        
        // Show warning when 2 minutes remain
        if (remaining <= 120 && remaining > 0 && !warningShown) {
          setShowWarning(true);
          warningShown = true;
        }
      }
    }, 1000);
    
    // Cleanup
    return () => {
      clearTimeout(inactivityTimer);
      clearInterval(timerInterval);
      activityEvents.forEach(event => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, [session, toast]);

  const handleLogout = async () => {
    try {
      // Log the logout event
      if (session?.user) {
        await supabase.from('user_events').insert({
          user_id: session.user.id,
          event_type: 'admin_logout',
          event_data: { 
            timestamp: new Date().toISOString()
          }
        }).then(({ error }) => {
          if (error) console.error('خطا در ثبت رویداد خروج:', error);
        });
      }
      
      // Clear admin session data
      sessionStorage.removeItem('admin_role');
      sessionStorage.removeItem('admin_role_timestamp');
      
      // Sign out from Supabase
      await supabase.auth.signOut();
      
      toast({
        title: "خروج موفق",
        description: "با موفقیت از سیستم خارج شدید",
      });
      
      navigate('/admin/auth');
    } catch (error) {
      console.error('خطا در خروج از سیستم:', error);
      toast({
        title: "خطا",
        description: "مشکلی در خروج از سیستم پیش آمد",
        variant: "destructive"
      });
    }
  };

  // Format the remaining time
  const formatRemainingTime = () => {
    const minutes = Math.floor(inactivityTime / 60);
    const seconds = inactivityTime % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const menuItems = [
    { title: 'داشبورد', path: '/admin/dashboard', icon: <Home size={18} /> },
    { title: 'کاربران', path: '/admin/users', icon: <Users size={18} /> },
    { title: 'پرداخت‌ها', path: '/admin/payments', icon: <CreditCard size={18} /> },
    { title: 'کوپن‌ها', path: '/admin/coupons', icon: <TicketIcon size={18} /> },
    { title: 'ابزارها', path: '/admin/tools', icon: <Wrench size={18} /> },
    { title: 'گزارش‌ها', path: '/admin/reports', icon: <BarChart2 size={18} /> },
    { title: 'تنظیمات', path: '/admin/settings', icon: <Settings size={18} /> }
  ];

  // Extend the sessionStorage timeout on any navigation
  const handleNavigation = () => {
    if (session) {
      // Update the admin role timestamp to extend session
      sessionStorage.setItem('admin_role_timestamp', Date.now().toString());
      setShowWarning(false);
    }
  };

  return (
    <div className="h-full w-56 bg-gray-800 p-4 flex flex-col" dir="rtl">
      <div className="flex items-center justify-center p-2 mb-6">
        <h1 className="text-xl font-bold text-white">پنل مدیریت</h1>
      </div>
      
      <Separator className="bg-gray-700 mb-4" />
      
      <nav className="flex-1 space-y-1">
        {menuItems.map((item) => (
          <Link
            to={item.path}
            key={item.path}
            className={`flex items-center px-3 py-2 rounded-lg text-sm transition-colors ${
              location.pathname === item.path
                ? 'bg-gray-700 text-white'
                : 'text-gray-300 hover:bg-gray-700/50 hover:text-white'
            }`}
            onClick={handleNavigation}
          >
            <span className="ml-2">{item.icon}</span>
            {item.title}
          </Link>
        ))}
      </nav>
      
      <Separator className="bg-gray-700 my-4" />
      
      {showWarning && (
        <div className="bg-amber-900/30 border border-amber-700/30 rounded-md p-2 mb-3 text-xs flex items-start space-x-2 space-x-reverse text-amber-300">
          <AlertCircle className="h-4 w-4 flex-shrink-0 ml-1" />
          <div>
            <p className="font-semibold">هشدار پایان جلسه</p>
            <p>زمان باقی‌مانده: {formatRemainingTime()}</p>
          </div>
        </div>
      )}
      
      <div className="text-xs text-gray-500 mb-2 text-center">
        زمان باقی‌مانده جلسه: {formatRemainingTime()}
      </div>
      
      <Button
        variant="ghost"
        className="text-red-400 hover:text-red-300 hover:bg-red-900/20 justify-start"
        onClick={handleLogout}
      >
        <LogOut size={18} className="ml-2" />
        خروج از سیستم
      </Button>
    </div>
  );
};

export default AdminSidebar;
