import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2, Copy, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronDown } from "lucide-react";
import type { UseEmblaCarouselType } from "embla-carousel-react";
interface FormData {
  title: string;
  description: string;
  slideCount: number;
}
interface Slide {
  title: string;
  content: string;
  explanation: string;
}
interface CarouselData {
  overview: string;
  overall_explanation: string;
  slides: Slide[];
}
export const CarouselPlanner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [carouselData, setCarouselData] = useState<CarouselData | null>(null);
  const [openCollapsibles, setOpenCollapsibles] = useState<{
    [key: number]: boolean;
  }>({});
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const {
    toast
  } = useToast();
  const form = useForm<FormData>({
    defaultValues: {
      slideCount: 5
    }
  });
  const {
    profile,
    refreshProfile
  } = useAuth();
  const onSubmit = async (data: FormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }
    const toolInfo = {
      id: "carousel",
      creditCost: 4
    };
    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }
    setIsLoading(true);
    try {
      const response = await supabase.functions.invoke('generate-carousel', {
        body: {
          ...data,
          language: "farsi"
        }
      });
      if (response.error) throw response.error;
      const result = response.data;
      const {
        error: updateError
      } = await supabase.from('profiles').update({
        credits: profile.credits - toolInfo.creditCost
      }).eq('id', profile.id);
      if (updateError) throw updateError;
      const {
        error: insertError
      } = await supabase.from('tool_usage').insert({
        user_id: profile.id,
        tool_id: toolInfo.id,
        credit_cost: toolInfo.creditCost
      });
      if (insertError) throw insertError;
      setCarouselData(result);
      setActiveSlideIndex(0);
      await supabase.from('carousel_plans').insert({
        title: data.title,
        description: data.description,
        slide_count: data.slideCount,
        slides: result.slides
      });
      await refreshProfile();
      toast({
        title: "کروسل تولید شد",
        description: "کروسل شما با موفقیت تولید شد"
      });
    } catch (error) {
      toast({
        title: "خطا",
        description: "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };
  const copySlideContent = (index: number) => {
    if (!carouselData) return;
    const slide = carouselData.slides[index];
    const slideText = `${slide.title}\n${slide.content}`;
    navigator.clipboard.writeText(slideText);
    toast({
      title: "کپی شد",
      description: `اسلاید ${index + 1} با موفقیت کپی شد`
    });
  };
  const toggleCollapsible = (index: number) => {
    setOpenCollapsibles(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };
  const handleSlideChange = (index: number) => {
    setActiveSlideIndex(index);
  };
  return <div className="space-y-6" dir="rtl">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField control={form.control} name="title" render={({
          field
        }) => <FormItem>
                <FormLabel>عنوان کروسل</FormLabel>
                <FormControl>
                  <Input placeholder="مثال: راهنمای خرید دوربین" {...field} />
                </FormControl>
              </FormItem>} />
          <FormField control={form.control} name="description" render={({
          field
        }) => <FormItem>
                <FormLabel>توضیحات</FormLabel>
                <FormControl>
                  <Textarea placeholder="توضیحات بیشتر درباره محتوای کروسل..." className="h-24" {...field} />
                </FormControl>
              </FormItem>} />
          <FormField control={form.control} name="slideCount" render={({
          field
        }) => <FormItem>
                <FormLabel>تعداد اسلایدها</FormLabel>
                <FormControl>
                  <Input type="number" min={2} max={10} {...field} onChange={e => field.onChange(parseInt(e.target.value))} />
                </FormControl>
              </FormItem>} />
          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            تولید کروسل
          </Button>
        </form>
      </Form>

      {carouselData ? <div className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>پیشنهاد کروسل</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">{carouselData.overview}</p>
              
              <div className="w-full relative">
                <Carousel className="w-full" opts={{
              startIndex: activeSlideIndex,
              loop: true,
              direction: "rtl",
              slidesToScroll: 1,
              duration: 25
            }} onSelect={api => {
              if (api) {
                const selectedIndex = api.selectedScrollSnap();
                handleSlideChange(selectedIndex);
              }
            }}>
                  <CarouselContent className="transition-all duration-500 ease-in-out">
                    {carouselData.slides.map((slide, index) => <CarouselItem key={index} className="transition-opacity duration-500 ease-in-out">
                        <Card className="bg-muted/50 h-full flex flex-col">
                          <CardHeader>
                            <CardTitle className="text-md">{slide.title}</CardTitle>
                          </CardHeader>
                          <CardContent className="flex-1">
                            <p className="whitespace-pre-wrap">{slide.content}</p>
                          </CardContent>
                          <CardFooter className="flex justify-center pt-2 border-t">
                            <Button variant="ghost" size="sm" onClick={() => copySlideContent(index)}>
                              <Copy className="h-4 w-4" />
                              <span className="mr-2">کپی</span>
                            </Button>
                          </CardFooter>
                        </Card>
                      </CarouselItem>)}
                  </CarouselContent>
                  
                  <div className="flex justify-center items-center gap-4 mt-4">
                    <CarouselPrevious />
                    <CarouselNext />
                  </div>
                </Carousel>
              </div>
            </CardContent>
          </Card>

          <Card className="relative overflow-hidden">
            <div className="absolute top-0 right-0 left-0 h-1 bg-gradient-to-l from-purple-600 to-blue-600"></div>
            <CardHeader>
              <div className="flex items-center justify-between">
                <CardTitle className="flex items-center gap-2">
                  <Sparkles className="h-5 w-5 text-purple-500 animate-pulse" />
                  توضیحات کروسل
                </CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <div className="mb-6 text-justify max-h-[200px] overflow-y-auto p-2 rounded-none bg-gray-50">
                <p>{carouselData.overall_explanation}</p>
              </div>
              
              <div className="space-y-4 mt-6">
                {carouselData.slides.map((slide, index) => <Collapsible key={index} className="border rounded-md p-4" open={openCollapsibles[index]}>
                    <CollapsibleTrigger className="flex justify-between items-center w-full" onClick={() => toggleCollapsible(index)}>
                      <h3 className="text-lg font-semibold">توضیحات اسلاید {index + 1}: {slide.title}</h3>
                      <ChevronDown className={`h-5 w-5 transition-transform ${openCollapsibles[index] ? 'transform rotate-180' : ''}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className="pt-4 text-justify max-h-[200px] overflow-y-auto">
                      <p>{slide.explanation}</p>
                    </CollapsibleContent>
                  </Collapsible>)}
              </div>
            </CardContent>
          </Card>
        </div> : <InfoBox>
          <p className="text-sm">
            <strong>طراح کروسل</strong> با استفاده از هوش مصنوعی، کروسل‌های آموزشی و جذاب برای پیج اینستاگرام شما تولید می‌کند. 
            این ابزار ساختار، عناوین و محتوای هر اسلاید را به صورت حرفه‌ای طراحی می‌کند تا بیشترین تعامل را دریافت کنید. 
            کروسل‌ها یکی از محبوب‌ترین فرمت‌های محتوا در اینستاگرام هستند که می‌توانند ارزش زیادی به مخاطبان شما ارائه دهند.
          </p>
        </InfoBox>}
    </div>;
};