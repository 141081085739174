import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Users, CreditCard, Award, Book } from "lucide-react";
import { BarChart as BarChartIcon } from "lucide-react";
import { Book as RechartsBook } from "lucide-react";
import { 
  BarChart as RechartsBarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, 
  LineChart, Line, CartesianGrid, Legend, PieChart, Pie, Cell
} from 'recharts';
import { supabase } from "@/integrations/supabase/client";

interface ToolUsage {
  tool_id: string;
  count: number;
}

interface ReadData {
  content_type: string;
  count: number;
}

interface PieChartData {
  name: string;
  value: number;
  toolId: string;
}

interface ReadPieChartData {
  name: string;
  value: number;
  contentType: string;
}

type CustomTooltipFormatter = (value: any, name: string, props: any) => [string, string];

const AdminMetrics = () => {
  const [metrics, setMetrics] = useState({
    users: 0,
    activeUsers: 0,
    monthlyRevenue: 0,
    successfulPaymentCount: 0,
    successfulPaymentPercent: 0,
    mostUsedTool: {
      name: '',
      count: 0
    },
    totalReads: 0
  });

  const [monthlyData, setMonthlyData] = useState([]);
  const [toolsData, setToolsData] = useState<PieChartData[]>([]);
  const [readsData, setReadsData] = useState<ReadPieChartData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const { data: usersData } = await supabase
          .from('users')
          .select('id', { count: 'exact' });

        const { data: activeUsersData } = await supabase
          .from('user_metrics')
          .select('user_id')
          .gt('last_seen_at', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString());

        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString();

        const { data: monthlyPaymentsData } = await supabase
          .from('payments')
          .select('amount')
          .eq('status', 'success')
          .gte('created_at', firstDayOfMonth)
          .lte('created_at', lastDayOfMonth);

        const monthlyRevenue = monthlyPaymentsData?.reduce((sum, payment) => sum + (payment.amount || 0), 0) || 0;

        const { data: successfulPaymentUsers } = await supabase
          .from('payments')
          .select('user_id')
          .eq('status', 'success')
          .limit(1)
          .order('created_at', { ascending: false });

        const successfulPaymentUserIds = new Set();
        successfulPaymentUsers?.forEach(payment => {
          successfulPaymentUserIds.add(payment.user_id);
        });

        const successfulPaymentCount = successfulPaymentUserIds.size;
        const totalUsers = usersData?.length || 1;
        const successfulPaymentPercent = Math.round((successfulPaymentCount / totalUsers) * 100);

        const { data: mostUsedToolRaw, error: mostUsedToolError } = await supabase
          .rpc('get_most_used_tool_this_month');

        if (mostUsedToolError) {
          console.error('خطا در دریافت آمار ابزارها:', mostUsedToolError);
        }

        let mostUsedTool = {
          name: 'بدون داده',
          count: 0
        };

        if (mostUsedToolRaw && Array.isArray(mostUsedToolRaw) && mostUsedToolRaw.length > 0) {
          const toolData = mostUsedToolRaw[0] as ToolUsage;
          mostUsedTool = {
            name: getToolName(toolData.tool_id),
            count: toolData.count
          };
        }

        const { data: totalReadsData, error: totalReadsError } = await supabase
          .rpc('execute_sql', { 
            sql_query: 'SELECT COUNT(*) FROM content_reads' 
          });

        if (totalReadsError) {
          console.error('خطا در دریافت تعداد کل خوانده‌ها:', totalReadsError);
        }

        let totalReads = 0;
        if (totalReadsData && Array.isArray(totalReadsData) && totalReadsData.length > 0) {
          const countValue = totalReadsData[0] as Record<string, any>;
          totalReads = parseInt(countValue.count as string);
        }

        setMetrics({
          users: usersData?.length || 0,
          activeUsers: activeUsersData?.length || 0,
          monthlyRevenue,
          successfulPaymentCount,
          successfulPaymentPercent,
          mostUsedTool,
          totalReads
        });

        const getMonthlyData = async () => {
          const months = [];
          const now = new Date();

          for (let i = 5; i >= 0; i--) {
            const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
            const monthEnd = new Date(now.getFullYear(), now.getMonth() - i + 1, 0);

            const monthStart = month.toISOString();
            const monthEndISO = monthEnd.toISOString();

            const { data: monthUsers } = await supabase
              .from('users')
              .select('id', { count: 'exact' })
              .gte('created_at', monthStart)
              .lt('created_at', monthEndISO);

            const { data: monthPayments } = await supabase
              .from('payments')
              .select('amount')
              .eq('status', 'success')
              .gte('created_at', monthStart)
              .lt('created_at', monthEndISO);

            const monthRevenue = monthPayments?.reduce((sum, payment) => sum + (payment.amount || 0), 0) || 0;

            const persianMonths = [
              'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
              'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
            ];
            const persianMonth = persianMonths[month.getMonth()];

            months.push({
              name: persianMonth,
              کاربران: monthUsers?.length || 0,
              درآمد: monthRevenue
            });
          }

          setMonthlyData(months);
        };

        const getToolsUsage = async () => {
          const { data: allToolsUsage, error: allToolsError } = await supabase
            .from('tool_usage')
            .select('tool_id')
            .gt('created_at', new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString())
            .lt('created_at', new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString());

          const { data: toolUsageRaw, error: toolUsageError } = await supabase
            .rpc('get_tool_usage_this_month');

          if (toolUsageError) {
            console.error('خطا در دریافت آمار استفاده از ابزارها:', toolUsageError);
          }

          if (toolUsageRaw && Array.isArray(toolUsageRaw) && toolUsageRaw.length > 0) {
            const allToolIds = new Set();

            if (allToolsUsage && Array.isArray(allToolsUsage)) {
              allToolsUsage.forEach(item => {
                if (item.tool_id) {
                  allToolIds.add(item.tool_id);
                }
              });
            }

            const topTools = new Map<string, number>();
            toolUsageRaw.forEach((tool: ToolUsage) => {
              topTools.set(tool.tool_id, tool.count);
              allToolIds.delete(tool.tool_id);
            });

            const pieData: PieChartData[] = Array.from(topTools.entries()).map(([toolId, count]) => ({
              name: getToolName(toolId),
              value: count,
              toolId
            }));

            if (allToolIds.size > 0) {
              pieData.push({
                name: 'سایر ابزارها',
                value: allToolIds.size,
                toolId: 'other'
              });
            }

            setToolsData(pieData);
          } else {
            setToolsData([
              { name: 'تحلیل پروفایل', value: 0, toolId: 'profile-analysis' },
              { name: 'تولید کپشن', value: 0, toolId: 'caption-generator' },
              { name: 'ایده‌پردازی', value: 0, toolId: 'story-ideas' },
              { name: 'هشتگ‌ساز', value: 0, toolId: 'hashtag-generator' },
              { name: 'سایر', value: 0, toolId: 'other' }
            ]);
          }
        };

        const getReadsData = async () => {
          const sqlQuery = `
            SELECT 
              content_type, 
              COUNT(*) as count
            FROM 
              content_reads
            WHERE 
              created_at >= '${new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()}'
              AND created_at < '${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString()}'
            GROUP BY 
              content_type
            ORDER BY 
              count DESC
            LIMIT 5
          `;
          
          const { data: readsDataRaw, error: readsError } = await supabase
            .rpc('execute_sql', { sql_query: sqlQuery });

          if (readsError) {
            console.error('خطا در دریافت آمار خوانده‌ها:', readsError);
          }

          if (readsDataRaw && Array.isArray(readsDataRaw) && readsDataRaw.length > 0) {
            const pieData: ReadPieChartData[] = readsDataRaw.map((item) => {
              const typedItem = item as Record<string, any>;
              return {
                name: getContentTypeName(typedItem.content_type as string),
                value: Number(typedItem.count),
                contentType: typedItem.content_type as string
              };
            });

            setReadsData(pieData);
          } else {
            setReadsData([
              { name: 'مقالات', value: 0, contentType: 'article' },
              { name: 'راهنماها', value: 0, contentType: 'guide' },
              { name: 'آموزش‌ها', value: 0, contentType: 'tutorial' },
              { name: 'سوالات متداول', value: 0, contentType: 'faq' },
              { name: 'سایر', value: 0, contentType: 'other' }
            ]);
          }
        };

        await Promise.all([getMonthlyData(), getToolsUsage(), getReadsData()]);
        setLoading(false);
      } catch (error) {
        console.error('خطا در دریافت داده‌های داشبورد:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();

    const intervalId = setInterval(() => {
      fetchDashboardData();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getToolName = (toolId) => {
    const toolNames = {
      'profile-analysis': 'تحلیل پروفایل',
      'caption-generator': 'تولید کپشن',
      'hashtag-generator': 'هشتگ‌ساز',
      'story-ideas': 'ایده‌پردازی',
      'content-calendar': 'تقویم محتوا',
      'dm-consultant': 'مشاور دایرکت',
      'growth-mentor': 'مشاور رشد',
      'aesthetic-advisor': 'مشاور زیبایی‌شناسی',
      'carousel-planner': 'طراح کاروسل',
      'quiz-generator': 'سازنده کوئیز',
      'text-translator': 'مترجم متن',
      'repurpose-content': 'بازسازی محتوا',
      'outreach-generator': 'تولید پیام تبلیغاتی',
      'promotion-advisor': 'مشاور تبلیغات',
      'holiday-campaign': 'کمپین مناسبتی',
      'caption-editor': 'ویرایگر کپشن',
      'bio-analysis': 'تحلیل بیو'
    };

    return toolNames[toolId] || toolId;
  };

  const getContentTypeName = (contentType) => {
    const contentTypes = {
      'article': 'مقالات',
      'blog': 'بلاگ',
      'guide': 'راهنماها',
      'tutorial': 'آموزش‌ها',
      'faq': 'سوالات متداول',
      'help': 'راهنما',
      'tool-guide': 'راهنمای ابزار',
      'feature': 'ویژگی‌ها',
      'news': 'اخبار',
      'update': 'به‌روزرسانی‌ها'
    };

    return contentTypes[contentType] || contentType;
  };

  const COLORS = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658', '#ff8042', '#ff6361'];

  const customPieTooltip: CustomTooltipFormatter = (value, name, entry) => {
    return [`${value} بار استفاده`, `${name}`];
  };

  const customReadTooltip: CustomTooltipFormatter = (value, name, entry) => {
    return [`${value} بار خوانده شده`, `${name}`];
  };

  if (loading) {
    return (
      <div className="text-center p-10 text-white">
        در حال بارگذاری آمار...
      </div>
    );
  }

  return (
    <div className="space-y-6" dir="rtl">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card className="bg-gray-800 border-gray-700">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-gray-200">کاربران</CardTitle>
            <Users className="h-4 w-4 text-indigo-400" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-white">{metrics.users.toLocaleString('fa-IR')}</div>
            <p className="text-xs text-muted-foreground">
              {metrics.activeUsers.toLocaleString('fa-IR')} کاربر فعال
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gray-800 border-gray-700">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-gray-200">درآمد ماهانه</CardTitle>
            <CreditCard className="h-4 w-4 text-indigo-400" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-white">{(metrics.monthlyRevenue/10000).toLocaleString('fa-IR')}</div>
            <p className="text-xs text-muted-foreground">
              تومان در ماه جاری
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gray-800 border-gray-700">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-gray-200">کاربران با پرداخت موفق</CardTitle>
            <Award className="h-4 w-4 text-indigo-400" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-white">{metrics.successfulPaymentCount.toLocaleString('fa-IR')}</div>
            <p className="text-xs text-muted-foreground">
              {metrics.successfulPaymentPercent}٪ از کل کاربران
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gray-800 border-gray-700">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-gray-200">محتوای خوانده شده</CardTitle>
            <Book className="h-4 w-4 text-indigo-400" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-white">{metrics.totalReads.toLocaleString('fa-IR')}</div>
            <p className="text-xs text-muted-foreground">
              بار خوانده شده در مجموع
            </p>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card className="bg-gray-800 border-gray-700">
          <CardHeader>
            <CardTitle className="text-lg font-medium text-white">روند کاربران و درآمد</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="name" stroke="#888" fontSize={12} />
                  <YAxis stroke="#888" fontSize={12} />
                  <Tooltip 
                    contentStyle={{ backgroundColor: '#333', border: 'none', borderRadius: '8px' }}
                    labelStyle={{ color: '#fff', fontWeight: 'bold', marginBottom: '5px' }}
                    itemStyle={{ padding: '2px 0' }}
                  />
                  <Legend />
                  <Line type="monotone" dataKey="کاربران" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="درآمد" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-gray-800 border-gray-700">
          <CardHeader>
            <CardTitle className="text-lg font-medium text-white">استفاده از ابزارها</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80 flex items-center justify-center">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={toolsData}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                    label={({name, percent}) => `${name} ${(percent * 100).toFixed(0)}%`}
                    paddingAngle={2}
                  >
                    {toolsData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={customPieTooltip}
                    contentStyle={{ 
                      backgroundColor: '#222', 
                      border: 'none', 
                      borderRadius: '8px',
                      padding: '10px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                    }}
                    labelStyle={{ color: '#fff', fontWeight: 'bold', marginBottom: '5px' }}
                    itemStyle={{ color: '#ddd' }}
                  />
                  <Legend 
                    layout="horizontal" 
                    verticalAlign="bottom" 
                    align="center"
                    formatter={(value) => <span style={{ color: '#eee' }}>{value}</span>}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card className="bg-gray-800 border-gray-700">
        <CardHeader>
          <CardTitle className="text-lg font-medium text-white">آمار محتوای خوانده شده</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={readsData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  nameKey="name"
                  label={({name, percent}) => `${name} ${(percent * 100).toFixed(0)}%`}
                  paddingAngle={2}
                >
                  {readsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip 
                  formatter={customReadTooltip}
                  contentStyle={{ 
                    backgroundColor: '#222', 
                    border: 'none', 
                    borderRadius: '8px',
                    padding: '10px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                  }}
                  labelStyle={{ color: '#fff', fontWeight: 'bold', marginBottom: '5px' }}
                  itemStyle={{ color: '#ddd' }}
                />
                <Legend 
                  layout="horizontal" 
                  verticalAlign="bottom" 
                  align="center"
                  formatter={(value) => <span style={{ color: '#eee' }}>{value}</span>}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <Card className="bg-gray-800 border-gray-700">
        <CardHeader>
          <CardTitle className="text-lg font-medium text-white">آمار ماهانه</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <RechartsBarChart data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                <XAxis dataKey="name" stroke="#888" fontSize={12} />
                <YAxis stroke="#888" fontSize={12} />
                <Tooltip 
                  contentStyle={{ backgroundColor: '#333', border: 'none', borderRadius: '8px' }}
                  labelStyle={{ color: '#fff', fontWeight: 'bold', marginBottom: '5px' }}
                  itemStyle={{ padding: '2px 0' }}
                />
                <Legend 
                  formatter={(value) => <span style={{ color: '#eee' }}>{value}</span>}
                />
                <Bar dataKey="کاربران" fill="#8884d8" />
                <Bar dataKey="درآمد" fill="#82ca9d" />
              </RechartsBarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminMetrics;
