
import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { ToolBar } from "@/components/ToolBar";
import { ToolWindows } from "@/components/ToolWindow";
import { MobileAppGrid } from "@/components/MobileAppGrid";
import { MobileToolWindow } from "@/components/MobileToolWindow";
import { TopBar } from "@/components/TopBar";
import { MobileTopBar } from "@/components/MobileTopBar";
import { tools } from "@/lib/tools";
import { useMobile } from "@/hooks/use-mobile";
import type { Tool } from "@/types/tool";

interface ActiveTool extends Tool {
  instanceId: string;
  zIndex: number;
}

const Index = () => {
  const [activeTools, setActiveTools] = useState<ActiveTool[]>([]);
  const [maxZIndex, setMaxZIndex] = useState(10);
  const [activeMobileTool, setActiveMobileTool] = useState<Tool | null>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [useVideoBackground, setUseVideoBackground] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { toast } = useToast();
  const { profile, session } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();

  useEffect(() => {
    const checkAuth = async () => {
      console.log("Checking auth on Index page");
      const { data: { session } } = await supabase.auth.getSession();
      
      // Redirect to /auth if not authenticated
      if (!session) {
        console.log("User not authenticated, redirecting to /auth");
        navigate('/auth');
      } else {
        console.log("User is authenticated, staying on page");
      }
    };
    
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const video = videoRef.current;
    if (video && useVideoBackground) {
      const handleCanPlay = () => {
        setVideoLoaded(true);
        video.play().catch(err => console.error("Error playing video:", err));
      };
      
      video.addEventListener('canplaythrough', handleCanPlay);
      
      // Start loading the video
      video.load();
      
      return () => {
        video.removeEventListener('canplaythrough', handleCanPlay);
      };
    } else if (!useVideoBackground) {
      setVideoLoaded(false);
    }
  }, [useVideoBackground]);

  const handleToolClick = useCallback(async (toolId: string) => {
    const tool = tools.find(t => t.id === toolId);
    if (!tool || !profile || !session) {
      if (!session) {
        navigate('/auth');
        return;
      }
      return;
    }

    if (isMobile) {
      // For mobile, set the active tool
      setActiveMobileTool(tool);
    } else {
      // For desktop, add to active tools
      const newZIndex = maxZIndex + 1;
      setMaxZIndex(newZIndex);
      
      const instanceId = `${tool.id}-${Date.now()}`;
      setActiveTools(prev => [...prev, {
        ...tool,
        instanceId,
        zIndex: newZIndex
      }]);
    }
  }, [maxZIndex, profile, session, navigate, toast, isMobile]);

  const handleCloseTool = useCallback((instanceId: string) => {
    setActiveTools(prev => prev.filter(tool => tool.instanceId !== instanceId));
  }, []);

  const handleCloseMobileTool = useCallback(() => {
    setActiveMobileTool(null);
  }, []);

  const handleWindowClick = useCallback((instanceId: string) => {
    setActiveTools(prev => {
      const newZIndex = maxZIndex + 1;
      setMaxZIndex(newZIndex);
      return prev.map(tool => 
        tool.instanceId === instanceId 
          ? { ...tool, zIndex: newZIndex }
          : tool
      );
    });
  }, [maxZIndex]);

  const toggleBackground = useCallback(() => {
    setUseVideoBackground(prev => !prev);
  }, []);

  if (!session) return null;

  return (
    <div className="fixed inset-0 w-screen h-screen overflow-hidden no-scroll-page" dir="rtl">
      <div 
        className={`absolute inset-0 -z-10 ${(!useVideoBackground || !videoLoaded) ? 'opacity-100' : 'opacity-0 transition-opacity duration-1000'}`}
        style={{
          background: `url("/lovable-uploads/9b847d69-0e42-4191-b92a-492215e504bb.png") no-repeat center center`,
          backgroundSize: 'cover',
        }}
      />

      <div className={`absolute inset-0 -z-10 ${(useVideoBackground && videoLoaded) ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000`}>
        <video
          ref={videoRef}
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/resources//Sky.webm" type="video/webm" />
        </video>
      </div>

      {(!videoLoaded || !useVideoBackground || isMobile) && (
        <div className="absolute inset-0 -z-10 bg-black/20" />
      )}

      {isMobile ? (
        <>
          <MobileTopBar useVideoBackground={useVideoBackground} onToggleBackground={toggleBackground} />
          <div className="pt-16 pb-4 h-full">
            <MobileAppGrid tools={tools} onToolClick={handleToolClick} />
            <AnimatePresence>
              {activeMobileTool && (
                <MobileToolWindow 
                  tool={activeMobileTool} 
                  onClose={handleCloseMobileTool} 
                />
              )}
            </AnimatePresence>
          </div>
        </>
      ) : (
        <>
          <TopBar useVideoBackground={useVideoBackground} onToggleBackground={toggleBackground} />
          <ToolWindows
            activeTools={activeTools}
            onClose={handleCloseTool}
            onWindowClick={handleWindowClick}
          />
          <ToolBar tools={tools} onToolClick={handleToolClick} />
        </>
      )}
    </div>
  );
};

export default Index;
