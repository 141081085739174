
import { supabase } from "@/integrations/supabase/client";
import type { QuizData } from "@/types/quiz";
import { Json } from "@/integrations/supabase/types";

export const generateQuiz = async (subject: string): Promise<QuizData> => {
  const response = await supabase.functions.invoke('generate-quiz', {
    body: {
      type: "quiz",
      subject,
      questionCount: 3,
      includeAnswers: true // Explicitly request answers
    }
  });

  if (response.error) {
    throw response.error;
  }

  // Make sure we have a valid response with both questions and answers
  const quizData = response.data as QuizData;
  
  // Ensure each question has a correct answer if not already provided
  if (!quizData.answers || quizData.answers.length === 0) {
    // Generate default answers if missing
    quizData.answers = quizData.questions.map(() => ({ correct_option: 0 }));
  }

  return quizData;
};

export const saveQuiz = async (subject: string, quizData: QuizData): Promise<void> => {
  await supabase.from('generated_quizzes').insert({
    subject,
    questions: quizData.questions as unknown as Json,
    answers: quizData.answers as unknown as Json,
    is_general: !subject,
    type: "quiz"
  });
};
