
import { MacWindow } from "@/components/MacWindow";
import { motion, AnimatePresence } from "framer-motion";
import type { Tool } from "@/types/tool";
import { useState } from "react";
import { HelpOverlay } from "@/components/HelpOverlay";

interface ActiveTool extends Tool {
  instanceId: string;
  zIndex: number;
}

interface ToolWindowsProps {
  activeTools: ActiveTool[];
  onClose: (instanceId: string) => void;
  onWindowClick: (instanceId: string) => void;
}

// Help data for each tool
const toolHelpData: Record<string, { title: string, description: string, steps: string[], tips: string[] }> = {
  bio: {
    title: "راهنمای تحلیل و بهینه‌سازی بیو",
    description: "این ابزار به شما کمک می‌کند تا بیوگرافی اینستاگرام خود را تحلیل کرده و پیشنهادهای بهینه‌سازی دریافت کنید.",
    steps: [
      "بیوگرافی فعلی خود را در قسمت مربوطه کپی کنید",
      "زمینه فعالیت خود را انتخاب کنید",
      "دکمه «تحلیل بیو» را بزنید",
      "نتایج تحلیل و پیشنهادهای بهبود را مشاهده کنید",
      "در صورت تمایل، بیوگرافی بهینه‌شده را کپی کنید"
    ],
    tips: [
      "از هشتگ‌های مرتبط با کسب و کار خود در بیو استفاده کنید",
      "اطلاعات تماس خود را در بیو قرار دهید",
      "از ایموجی‌های مرتبط برای جذابیت بیشتر استفاده کنید",
      "لینک وبسایت یا صفحات دیگر خود را در بیو قرار دهید"
    ]
  },
  caption: {
    title: "راهنمای تولید کپشن هوشمند",
    description: "این ابزار به شما کمک می‌کند تا کپشن‌های جذاب و تاثیرگذار برای پست‌های اینستاگرام خود تولید کنید.",
    steps: [
      "موضوع پست خود را وارد کنید",
      "نوع محتوا را مشخص کنید (عکس، ویدیو، کاروسل)",
      "لحن مورد نظر خود را انتخاب کنید",
      "دکمه «تولید کپشن» را بزنید",
      "از میان کپشن‌های تولید شده، موارد دلخواه را انتخاب کنید"
    ],
    tips: [
      "از کلمات کلیدی مرتبط با موضوع پست استفاده کنید",
      "کپشن‌ها را شخصی‌سازی کنید تا با برند شما همخوانی داشته باشد",
      "از سوالات برای تعامل بیشتر با مخاطبان استفاده کنید",
      "طول کپشن را متناسب با نوع محتوا تنظیم کنید"
    ]
  },
  hashtag: {
    title: "راهنمای تولید هشتگ هوشمند",
    description: "این ابزار به شما کمک می‌کند تا هشتگ‌های مرتبط و موثر برای افزایش دید پست‌های خود تولید کنید.",
    steps: [
      "توضیحات پست خود را وارد کنید",
      "تعداد هشتگ‌های مورد نیاز را مشخص کنید",
      "دکمه «تولید هشتگ» را بزنید",
      "هشتگ‌های تولید شده را مشاهده و استفاده کنید"
    ],
    tips: [
      "ترکیبی از هشتگ‌های پرطرفدار و اختصاصی استفاده کنید",
      "هشتگ‌های محلی را فراموش نکنید",
      "از هشتگ‌های مرتبط با صنعت خود استفاده کنید",
      "تعداد مناسب هشتگ بین 5 تا 15 عدد است"
    ]
  },
  carousel: {
    title: "راهنمای طراحی کروسل محتوا",
    description: "این ابزار به شما کمک می‌کند تا ایده‌های کاروسل جذاب برای مخاطبان خود طراحی کنید.",
    steps: [
      "موضوع کاروسل خود را وارد کنید",
      "تعداد اسلایدها را مشخص کنید",
      "هدف خود از انتشار کاروسل را انتخاب کنید",
      "دکمه «طراحی کاروسل» را بزنید",
      "طرح کاروسل تولید شده را مشاهده و استفاده کنید"
    ],
    tips: [
      "در اسلاید اول از تصویر و عنوان جذاب استفاده کنید",
      "هر اسلاید باید یک نکته کلیدی را پوشش دهد",
      "از اسلاید آخر برای دعوت به اقدام (CTA) استفاده کنید",
      "تم بصری یکسان در تمام اسلایدها داشته باشید"
    ]
  },
  quiz: {
    title: "راهنمای تولید کوئیز",
    description: "با این ابزار می‌توانید کوئیزهای تعاملی برای استوری و پست‌های خود ایجاد کنید.",
    steps: [
      "موضوع کوئیز خود را وارد کنید",
      "دکمه «تولید کوئیز» را بزنید",
      "سوالات و گزینه‌ها را مشاهده کنید",
      "در صورت نیاز، سوالات را ویرایش کنید",
      "کوئیز را در استوری یا پست خود استفاده کنید"
    ],
    tips: [
      "کوئیزها را متناسب با علایق مخاطبان خود طراحی کنید",
      "از سوالات چالشی و سرگرم‌کننده استفاده کنید",
      "جواب کوئیز را در استوری بعدی یا کامنت‌ها به اشتراک بگذارید",
      "از نتایج کوئیز برای شناخت بهتر مخاطبان استفاده کنید"
    ]
  },
  dm: {
    title: "راهنمای مشاور پاسخ به پیام‌ها",
    description: "این ابزار به شما کمک می‌کند تا پاسخ‌های حرفه‌ای و مناسب برای پیام‌های دایرکت مشتریان و مخاطبان خود تهیه کنید.",
    steps: [
      "موقعیت یا زمینه پیام را وارد کنید",
      "پیام دریافتی را وارد کنید",
      "دکمه «تولید پاسخ» را بزنید",
      "از میان گزینه‌های پیشنهادی، پاسخ مناسب را انتخاب کنید"
    ],
    tips: [
      "همیشه با لحنی دوستانه و حرفه‌ای پاسخ دهید",
      "به سوالات مشتریان به طور دقیق پاسخ دهید",
      "زمان پاسخگویی را کوتاه نگه دارید",
      "پیام‌ها را شخصی‌سازی کنید تا صمیمیت بیشتری داشته باشند"
    ]
  },
  outreach: {
    title: "راهنمای پیام نویس",
    description: "این ابزار به شما کمک می‌کند تا پیام‌های تاثیرگذار برای برقراری ارتباط با افراد، برندها یا اینفلوئنسرها بنویسید.",
    steps: [
      "مخاطب هدف خود را مشخص کنید",
      "هدف از ارسال پیام را وارد کنید",
      "دکمه «تولید پیام» را بزنید",
      "پیام‌های پیشنهادی را مشاهده و انتخاب کنید"
    ],
    tips: [
      "پیام را با اشاره به موضوع مشترک آغاز کنید",
      "کوتاه و مختصر بنویسید",
      "ارزش پیشنهادی خود را به وضوح بیان کنید",
      "یک دعوت به اقدام مشخص در انتهای پیام قرار دهید"
    ]
  },
  story: {
    title: "راهنمای ایده‌پرداز استوری",
    description: "این ابزار به شما کمک می‌کند تا ایده‌های خلاقانه برای استوری‌های اینستاگرام خود پیدا کنید.",
    steps: [
      "زمینه فعالیت خود را وارد کنید",
      "موضوع یا تم مورد نظر را مشخص کنید (اختیاری)",
      "نوع استوری را انتخاب کنید (تکی یا سریالی)",
      "دکمه «تولید ایده» را بزنید",
      "ایده‌های تولید شده را مشاهده و استفاده کنید"
    ],
    tips: [
      "از عناصر تعاملی مانند نظرسنجی و سوال استفاده کنید",
      "استوری‌های سریالی را برای روایت داستان استفاده کنید",
      "پشت صحنه کار یا زندگی روزمره را به اشتراک بگذارید",
      "از هایلایت‌ها برای دسته‌بندی استوری‌های مرتبط استفاده کنید"
    ]
  },
  calendar: {
    title: "راهنمای تقویم محتوایی هوشمند",
    description: "این ابزار به شما کمک می‌کند تا یک برنامه محتوایی منظم و موثر برای اینستاگرام خود تدوین کنید.",
    steps: [
      "نوع کسب و کار خود را مشخص کنید",
      "تعداد پست‌های هفتگی را وارد کنید",
      "موضوعات مورد علاقه را انتخاب کنید",
      "دکمه «تولید تقویم» را بزنید",
      "تقویم محتوایی تولید شده را مشاهده و دانلود کنید"
    ],
    tips: [
      "تنوع در انواع محتوا (پست، استوری، ریلز) داشته باشید",
      "مناسبت‌ها و رویدادهای مهم را در تقویم خود لحاظ کنید",
      "برای هر روز هفته یک تم خاص در نظر بگیرید",
      "تقویم را به صورت منظم بروزرسانی کنید"
    ]
  },
  holiday: {
    title: "راهنمای ایده‌پرداز رویدادها",
    description: "این ابزار به شما کمک می‌کند تا ایده‌های محتوایی خلاقانه برای مناسبت‌ها و رویدادهای مختلف تولید کنید.",
    steps: [
      "مناسبت یا رویداد مورد نظر را انتخاب کنید",
      "زمینه فعالیت خود را وارد کنید",
      "دکمه «تولید ایده» را بزنید",
      "ایده‌های پیشنهادی را مشاهده و انتخاب کنید"
    ],
    tips: [
      "از مناسبت‌ها برای برگزاری مسابقه و افزایش تعامل استفاده کنید",
      "محتوا را از قبل آماده کنید و در زمان مناسب منتشر کنید",
      "از هشتگ‌های مرتبط با مناسبت استفاده کنید",
      "تخفیف‌ها و پیشنهادات ویژه را در مناسبت‌ها ارائه دهید"
    ]
  },
  aesthetic: {
    title: "راهنمای مشاور زیبایی‌شناسی",
    description: "این ابزار به شما کمک می‌کند تا سبک بصری منسجم و جذاب برای صفحه اینستاگرام خود ایجاد کنید.",
    steps: [
      "سبک فعلی صفحه خود را توصیف کنید",
      "زمینه فعالیت و مخاطبان هدف را مشخص کنید",
      "رنگ‌های مورد علاقه خود را انتخاب کنید",
      "دکمه «دریافت مشاوره» را بزنید",
      "پیشنهادات سبک بصری را مشاهده کنید"
    ],
    tips: [
      "از یک پالت رنگی محدود و هماهنگ استفاده کنید",
      "فیلترهای یکسان برای حفظ انسجام بصری به کار ببرید",
      "چیدمان گرید صفحه را با دقت برنامه‌ریزی کنید",
      "فضای خالی کافی در تصاویر برای خوانایی متن داشته باشید"
    ]
  },
  editor: {
    title: "راهنمای ویرایشگر متن و کپشن",
    description: "این ابزار به شما کمک می‌کند تا متن‌ها و کپشن‌های خود را ویرایش کرده و بهبود دهید.",
    steps: [
      "متن فعلی خود را وارد کنید",
      "نوع ویرایش مورد نظر را انتخاب کنید",
      "لحن دلخواه را مشخص کنید",
      "دکمه «ویرایش متن» را بزنید",
      "متن ویرایش شده را مشاهده و استفاده کنید"
    ],
    tips: [
      "از جملات کوتاه و قابل فهم استفاده کنید",
      "غلط‌های املایی و دستوری را اصلاح کنید",
      "از کلمات کلیدی مرتبط با موضوع استفاده کنید",
      "لحن متن را متناسب با مخاطبان خود تنظیم کنید"
    ]
  },
  translator: {
    title: "راهنمای مترجم متن",
    description: "این ابزار به شما کمک می‌کند تا متن‌های خود را به زبان‌های مختلف ترجمه کنید.",
    steps: [
      "متن مورد نظر خود را وارد کنید",
      "زبان مبدا و مقصد را انتخاب کنید",
      "دکمه «ترجمه» را بزنید",
      "ترجمه را مشاهده و استفاده کنید"
    ],
    tips: [
      "متن‌های ساده و بدون اصطلاحات پیچیده بهتر ترجمه می‌شوند",
      "ترجمه را برای اطمینان از صحت آن بررسی کنید",
      "از ترجمه برای جذب مخاطبان بین‌المللی استفاده کنید",
      "در صورت نیاز، ترجمه را برای تطابق فرهنگی تنظیم کنید"
    ]
  },
  growth: {
    title: "راهنمای مشاور رشد صفحه",
    description: "این ابزار پیشنهادهای شخصی‌سازی شده برای رشد صفحه اینستاگرام شما ارائه می‌دهد.",
    steps: [
      "اطلاعات پایه صفحه خود را وارد کنید",
      "زمینه فعالیت و مخاطبان هدف را مشخص کنید",
      "چالش‌های فعلی خود را بیان کنید",
      "دکمه «دریافت مشاوره» را بزنید",
      "استراتژی‌های پیشنهادی را مشاهده کنید"
    ],
    tips: [
      "بر کیفیت محتوا به جای کمیت تمرکز کنید",
      "تعامل با مخاطبان را در اولویت قرار دهید",
      "از تحلیل‌ها برای بهبود استراتژی خود استفاده کنید",
      "همکاری با سایر صفحات مرتبط را فراموش نکنید"
    ]
  },
  repurpose: {
    title: "راهنمای بازنویسی محتوا",
    description: "این ابزار به شما کمک می‌کند تا محتوای موجود خود را برای استفاده در قالب‌های دیگر بازنویسی کنید.",
    steps: [
      "محتوای اصلی خود را وارد کنید",
      "قالب مقصد را انتخاب کنید (پست، استوری، ریلز)",
      "دکمه «بازنویسی محتوا» را بزنید",
      "محتوای بازنویسی شده را مشاهده و استفاده کنید"
    ],
    tips: [
      "یک محتوای خوب می‌تواند برای چندین قالب مختلف بازنویسی شود",
      "نکات کلیدی محتوای اصلی را حفظ کنید",
      "محتوا را متناسب با ویژگی‌های هر قالب تنظیم کنید",
      "عناصر بصری مکمل برای هر قالب در نظر بگیرید"
    ]
  },
  promotion: {
    title: "راهنمای مشاور تبلیغات",
    description: "این ابزار به شما کمک می‌کند تا استراتژی‌های تبلیغاتی موثر برای اینستاگرام خود طراحی کنید.",
    steps: [
      "هدف تبلیغات خود را مشخص کنید",
      "بودجه و محدودیت‌های زمانی را وارد کنید",
      "مخاطبان هدف را تعریف کنید",
      "دکمه «دریافت مشاوره» را بزنید",
      "استراتژی‌های پیشنهادی را مشاهده کنید"
    ],
    tips: [
      "تبلیغات را برای مخاطبان خاص هدف‌گذاری کنید",
      "از عناصر بصری جذاب و متن قانع‌کننده استفاده کنید",
      "نتایج تبلیغات را بررسی و تحلیل کنید",
      "تبلیغات موفق را بهینه‌سازی کرده و مجدداً اجرا کنید"
    ]
  }
};

export const ToolWindows = ({ activeTools, onClose, onWindowClick }: ToolWindowsProps) => {
  const [toolWithHelpOpen, setToolWithHelpOpen] = useState<string | null>(null);

  const toggleHelp = (instanceId: string) => {
    setToolWithHelpOpen(toolWithHelpOpen === instanceId ? null : instanceId);
  };

  return (
    <AnimatePresence mode="sync">
      {activeTools.map((tool) => {
        const ToolComponent = tool.component;
        const helpData = toolHelpData[tool.id] || {
          title: `راهنمای ${tool.title}`,
          description: "این ابزار به شما کمک می‌کند تا محتوای با کیفیت برای اینستاگرام خود تولید کنید.",
          steps: ["وارد کردن اطلاعات لازم", "انتخاب گزینه‌های مناسب", "دریافت نتایج"],
          tips: ["از نتایج به عنوان راهنما استفاده کنید", "محتوا را با سبک خود تطبیق دهید"]
        };
        
        return (
          <div key={tool.instanceId}>
            <MacWindow
              title={`${tool.title} (${tool.creditCost} اعتبار)`}
              isOpen={true}
              onClose={() => onClose(tool.instanceId)}
              zIndex={tool.zIndex}
              onClick={() => onWindowClick(tool.instanceId)}
              showHelpButton={true}
              onHelpClick={() => toggleHelp(tool.instanceId)}
            >
              <div className="max-w-3xl mx-auto">
                <ToolComponent initialShowHelp={toolWithHelpOpen === tool.instanceId} />
              </div>
            </MacWindow>
            
            <HelpOverlay
              isVisible={toolWithHelpOpen === tool.instanceId}
              onClose={() => setToolWithHelpOpen(null)}
              title={helpData.title}
              description={helpData.description}
              steps={helpData.steps}
              tips={helpData.tips}
            />
          </div>
        );
      })}
    </AnimatePresence>
  );
};
