
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useMobile } from "@/hooks/use-mobile";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMobile();

  useEffect(() => {
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname,
      "with search params:",
      location.search
    );
  }, [location.pathname, location.search]);

  const isPaymentCallback = location.pathname === "/payment/callback";

  return (
    <div className={`${!isMobile ? 'min-h-screen' : 'mobile-page-container'} flex items-center justify-center bg-gray-100 p-4`} dir="rtl">
      <div className="text-center bg-white p-6 md:p-8 rounded-xl shadow-sm max-w-md w-full">
        <h1 className="text-3xl md:text-4xl font-bold mb-3 md:mb-4 text-indigo-600">404</h1>
        <p className="text-lg md:text-xl text-gray-600 mb-4 md:mb-6">صفحه مورد نظر یافت نشد</p>
        
        {isPaymentCallback && (
          <div className="mb-4 text-right bg-gray-100 p-3 rounded">
            <p className="font-bold mb-2">اطلاعات درخواست پرداخت:</p>
            <p>مسیر: {location.pathname}</p>
            <p>پارامترها: {location.search}</p>
          </div>
        )}
        
        <Button 
          className="bg-indigo-600 hover:bg-indigo-700 text-white"
          onClick={() => navigate("/")}
        >
          بازگشت به صفحه اصلی
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
