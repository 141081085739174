
import { Button } from "@/components/ui/button";

interface HelpOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  description: string;
  steps: string[];
  tips: string[];
}

export const HelpOverlay = ({ 
  isVisible, 
  onClose, 
  title, 
  description, 
  steps, 
  tips 
}: HelpOverlayProps) => {
  if (!isVisible) return null;
  
  return (
    <div 
      className="fixed inset-0 z-50 glass-overlay flex items-center justify-center p-4 animate-in fade-in-0 duration-200"
      onClick={onClose}
    >
      <div 
        className="glass-effect p-6 rounded-lg max-w-xl w-full text-right space-y-4 animate-in slide-in-from-bottom-3 duration-300 max-h-[80vh] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-300" 
        onClick={e => e.stopPropagation()}
        style={{
          scrollbarWidth: 'thin',
          scrollbarColor: '#D1D5DB transparent'
        }}
      >
        <h3 className="text-2xl font-bold text-gray-900 mb-3">{title}</h3>
        <p className="text-gray-700 mb-5 text-base leading-relaxed">{description}</p>
        
        <div className="space-y-4">
          <h4 className="font-semibold text-gray-800 text-lg">مراحل استفاده:</h4>
          <ol className="list-decimal list-inside space-y-2.5 pr-4">
            {steps.map((step, index) => (
              <li key={index} className="text-gray-700">{step}</li>
            ))}
          </ol>
        </div>
        
        <div className="space-y-4 mt-5">
          <h4 className="font-semibold text-gray-800 text-lg">نکات کلیدی:</h4>
          <ul className="list-disc list-inside space-y-2.5 pr-4">
            {tips.map((tip, index) => (
              <li key={index} className="text-gray-700">{tip}</li>
            ))}
          </ul>
        </div>
        
        <div className="mt-8 text-center">
          <Button onClick={onClose}>بستن راهنما</Button>
        </div>
      </div>
    </div>
  );
};
