import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { CheckCircle, Clock, Lightbulb } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import SEO from "@/components/SEO";
import { MarketingHeader } from "@/components/MarketingHeader";

const Roadmap = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const completedFeatures = [
    {
      title: "تولید کپشن هوشمند",
      description: "ایجاد کپشن‌های جذاب و تعاملی با هوش مصنوعی",
      date: "فروردین ۱۴۰۳"
    },
    {
      title: "تولید هشتگ",
      description: "پیشنهاد هشتگ‌های مرتبط و پرطرفدار",
      date: "اردیبهشت ۱۴۰۳"
    },
    {
      title: "مشاور هوشمند رشد",
      description: "تحلیل و ارائه راهکارهای رشد پیج",
      date: "خرداد ۱۴۰۳"
    }
  ];

  const inProgressFeatures = [
    {
      title: "تحلیل رقبا",
      description: "بررسی و تحلیل پیج‌های رقیب در حوزه شما",
      date: "تیر ۱۴۰۴"
    },
    {
      title: "اتوماسیون محتوا",
      description: "زمانبندی خودکار انتشار پست‌ها",
      date: "مرداد ۱۴۰۴"
    }
  ];

  const plannedFeatures = [
    {
      title: "ویرایش خودکار تصاویر",
      description: "بهینه‌سازی تصاویر برای اینستاگرام",
      date: "شهریور ۱۴۰۴"
    },
    {
      title: "تحلیل احساسات کامنت‌ها",
      description: "درک و تحلیل بازخورد مخاطبان",
      date: "مهر ۱۴۰۴"
    },
    {
      title: "پیش‌بینی بهترین زمان پست",
      description: "تعیین زمان مناسب انتشار محتوا",
      date: "آبان ۱۴۰۴"
    }
  ];

  return (
    <div className="bg-white w-full max-w-full overflow-x-hidden" dir="rtl">
      <SEO 
        title="نقشه راه توسعه اینستایار | ویژگی‌های آینده دستیار هوشمند اینستاگرام"
        description="با نقشه راه توسعه اینستایار آشنا شوید. ویژگی‌های فعلی، در حال توسعه و برنامه‌های آینده هوش مصنوعی اینستایار برای کمک به رشد اینستاگرام شما"
        keywords="نقشه راه اینستایار, ویژگی‌های آینده اینستاگرام, هوش مصنوعی اینستاگرام, رشد اینستاگرام, اینستایار"
      />
      
      {/* Use the standardized marketing header */}
      <MarketingHeader currentPage="roadmap" />

      {/* Hero Section */}
      <section className="py-8 md:py-16 px-4 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4 md:mb-6">نقشه راه اینستایار</h1>
          <p className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8 leading-relaxed">
            ما به طور مداوم در حال بهبود و توسعه اینستایار هستیم. در اینجا می‌توانید ببینید که چه ویژگی‌هایی را توسعه داده‌ایم و چه برنامه‌هایی برای آینده داریم.
          </p>
        </div>
      </section>

      {/* Roadmap Content */}
      <section className="py-8 md:py-16 px-4">
        <div className="max-w-5xl mx-auto">
          {/* Completed Features */}
          <div className="mb-12 md:mb-16">
            <div className="flex items-center mb-6 md:mb-8">
              <CheckCircle className="w-6 h-6 md:w-8 md:h-8 text-green-500 ml-2 md:ml-3" />
              <h2 className="text-xl md:text-2xl font-bold text-gray-900">ویژگی‌های تکمیل شده</h2>
            </div>
            <div className="grid md:grid-cols-3 gap-4 md:gap-6">
              {completedFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="bg-white rounded-xl p-6 border border-gray-200"
                >
                  <span className="text-sm text-green-600 font-medium">{feature.date}</span>
                  <h3 className="text-lg font-semibold text-gray-900 mt-2 mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>

          {/* In Progress Features */}
          <div className="mb-12 md:mb-16">
            <div className="flex items-center mb-6 md:mb-8">
              <Clock className="w-6 h-6 md:w-8 md:h-8 text-indigo-500 ml-2 md:ml-3" />
              <h2 className="text-xl md:text-2xl font-bold text-gray-900">در حال توسعه</h2>
            </div>
            <div className="grid md:grid-cols-2 gap-4 md:gap-6">
              {inProgressFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="bg-indigo-50 rounded-xl p-6 border border-indigo-100"
                >
                  <span className="text-sm text-indigo-600 font-medium">{feature.date}</span>
                  <h3 className="text-lg font-semibold text-gray-900 mt-2 mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Planned Features */}
          <div>
            <div className="flex items-center mb-6 md:mb-8">
              <Lightbulb className="w-6 h-6 md:w-8 md:h-8 text-purple-500 ml-2 md:ml-3" />
              <h2 className="text-xl md:text-2xl font-bold text-gray-900">برنامه‌های آینده</h2>
            </div>
            <div className="grid md:grid-cols-3 gap-4 md:gap-6">
              {plannedFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="bg-purple-50 rounded-xl p-6 border border-purple-100"
                >
                  <span className="text-sm text-purple-600 font-medium">{feature.date}</span>
                  <h3 className="text-lg font-semibold text-gray-900 mt-2 mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-8 md:py-12 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">اینستایار</h3>
            <p className="mb-3 md:mb-4 text-sm md:text-base">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">لینک‌های مفید</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">خدمات</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">تماس با ما</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-8 md:mt-12 pt-6 md:pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Roadmap;
