import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useMobile } from "@/hooks/use-mobile";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";

const FAQ = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const faqCategories = [
    {
      id: "general",
      title: "سؤالات عمومی",
      items: [
        {
          question: "اینستایار چیست؟",
          answer: "اینستایار یک دستیار هوشمند مبتنی بر هوش مصنوعی است که به کاربران کمک می‌کند تا محتوای جذاب و تعاملی برای اینستاگرام خود تولید کنند. با استفاده از ابزارهای متنوع اینستایار، می‌توانید کپشن، هشتگ، ایده‌های کروسل و محتوای استوری تولید کنید و استراتژی رشد پیج خود را بهبود بخشید."
        },
        {
          question: "آیا اینستایار فقط برای کسب‌وکارها مناسب است؟",
          answer: "خیر، اینستایار برای همه کاربران اینستاگرام، از اینفلوئنسرها گرفته تا کسب‌وکارهای کوچک و بزرگ و حتی کاربران شخصی مفید است. هر کسی که می‌خواهد محتوای بهتر و جذاب‌تری در اینستاگرام منتشر کند، می‌تواند از اینستایار استفاده کند."
        },
        {
          question: "آیا نیاز به دانش فنی برای استفاده از اینستایار دارم؟",
          answer: "خیر، اینستایار با رابط کاربری ساده و کاربرپسند طراحی شده است و استفاده از آن نیاز به دانش فنی خاصی ندارد. کافیست وارد حساب کاربری خود شوید، ابزار مورد نظر را انتخاب کنید و طبق راهنمایی‌های ساده، جزئیات مورد نیاز را وارد کنید."
        },
      ]
    },
    {
      id: "account",
      title: "حساب کاربری",
      items: [
        {
          question: "چگونه در اینستایار ثبت‌نام کنم؟",
          answer: "برای ثبت‌نام در اینستایار، کافیست به صفحه ورود/ثبت‌نام مراجعه کنید و با استفاده از ایمیل یا شماره موبایل خود ثبت‌نام کنید. پس از تأیید حساب کاربری، می‌توانید از تمامی ابزارهای اینستایار استفاده کنید."
        },
        {
          question: "آیا می‌توانم حساب کاربری خود را حذف کنم؟",
          answer: "بله، شما می‌توانید از طریق پنل تنظیمات حساب کاربری، درخواست حذف حساب خود را ارسال کنید. پس از تأیید درخواست، تمامی اطلاعات شما از سیستم حذف خواهد شد. توجه داشته باشید که این عملیات غیرقابل بازگشت است."
        },
        {
          question: "اگر رمز عبور خود را فراموش کردم، چه کنم؟",
          answer: "در صفحه ورود، بر روی گزینه «فراموشی رمز عبور» کلیک کنید و ایمیل یا شماره موبایل خود را وارد کنید. ما لینک بازیابی رمز عبور را برای شما ارسال خواهیم کرد."
        },
      ]
    },
    {
      id: "pricing",
      title: "قیمت‌گذاری و پرداخت",
      items: [
        {
          question: "آیا استفاده از اینستایار رایگان است؟",
          answer: "اینستایار یک دوره آزمایشی رایگان با ۲۰ ا��تبار برای کاربران جدید ارائه می���دهد. پس از آن، می‌توانید با خرید اعتبار، به استفاده از اینستایار ادامه دهید."
        },
        {
          question: "هزینه استفاده از اینستایار چقدر است؟",
          answer: "اینستایار بر اساس اعتبار کار می‌کند. شما می‌توانید بسته‌های اعتباری مختلف را خریداری کنید و از آنها برای استفاده از ابزارهای مختلف استفاده کنید. هر ابزار، بسته به پیچیدگی، تعدادی اعتبار مصرف می‌کند. برای مشاهده بسته‌های اعتباری، به صفحه تعرفه‌ها مراجعه کنید."
        },
        {
          question: "اعتبارها چگونه کار می‌کنند؟",
          answer: "هر بار که از یکی از ابزارهای اینستایار استفاده می‌کنید، تعدادی اعتبار از حساب شما کم می‌شود. تعداد اعتبار مصرفی بسته به نوع ابزار و پیچیدگی درخواست متفاوت است. به عنوان مثال، تولید یک کپشن ۱ اعتبار و طراحی یک کروسل ۳ اعتبار مصرف می‌کند."
        },
        {
          question: "روش‌های پرداخت شما چیست؟",
          answer: "در حال حاضر پرداخت فقط از طریق درگاه‌های شتاب امکان‌پذیر است."
        },
        {
          question: "آیا اعتبارها منقضی می‌شوند؟",
          answer: "خیر، اعتبارهای خریداری شده هیچ محدودیت زمانی ندارند و تا زمانی که استفاده نشوند در حساب شما باقی می‌مانند."
        }
      ]
    },
    {
      id: "tools",
      title: "ابزارها و امکانات",
      items: [
        {
          question: "آیا محتوای تولید شده توسط اینستایار اختصاصی و یکتاست؟",
          answer: "بله، محتوای تولید شده توسط اینستایار برای هر کاربر منحصر به فرد است و با توجه به اطلاعات و خواسته‌های شما تولید می‌شود."
        },
        {
          question: "آیا می‌توانم محتوایی که توسط اینستایار تولید شده را ویرایش کنم؟",
          answer: "بله، شما می‌توانید محتوای تولید شده را قبل از استفاده ویرایش کنید تا کاملاً با سبک و نیازهای شما مطابقت داشته باشد."
        },
        {
          question: "تفاوت بین ابزارهای مختلف اینستایار چیست؟",
          answer: "هر ابزار اینستایار برای هدف خاصی طراحی شده است. برخی برای تولید محتوا مانند کپشن و هشتگ، برخی برای تحلیل و بهبود استراتژی، و برخی دیگر برای افزایش تعامل با مخاطبان. شما می‌توانید از ترکیب این ابزارها برای بهبود عملکرد کلی پیج اینستاگرام خود استفاده کنید."
        }
      ]
    },
    {
      id: "security",
      title: "امنیت و حریم خصوصی",
      items: [
        {
          question: "آیا اطلاعات من در اینستایار امن است؟",
          answer: "بله، امنیت اطلاعات کاربران برای ما بسیار مهم است. ما از پروتکل‌های امنیتی پیشرفته برای محافظت از داده‌ها استفاده می‌کنیم و هرگز اطلاعات شخصی شما را بدون اجازه با شخص ثالثی به اشتراک نمی‌گذاریم."
        },
        {
          question: "آیا اینستایار به اکانت اینستاگرام من دسترسی دارد؟",
          answer: "خیر، اینستایار نیازی به دسترسی مستقیم به اکانت اینستاگرام شما ندارد. کافیست اطلاعات مورد نیاز برای تولید محتوا را فراهم کنید."
        },
        {
          question: "آیا استفاده از اینستایار برای پیج اینستاگرام من امن است؟",
          answer: "بله، اینستایار کاملاً با قوانین و شرایط استفاده از اینستاگرام مطابقت دارد و استفاده از آن هیچ خطری برای پیج شما ندارد."
        }
      ]
    }
  ];

  return (
    <div className="bg-white w-full max-w-full overflow-x-hidden" dir="rtl">
      <SEO 
        title="سؤالات متداول | اینستایار"
        description="پاسخ به سؤالات رایج درباره دستیار هوشمند اینستایار برای تولید محتوای اینستاگرام"
        keywords="سوالات متداول اینستایار, FAQ اینستایار, راهنمای اینستایار"
      />
      
      <MarketingHeader currentPage="faq" />

      <section className="py-16 px-4 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">سؤالات متداول</h1>
          <p className="text-xl text-gray-600 mb-8 leading-relaxed">
            پاسخ سؤالات رایج شما درباره اینستایار را اینجا پیدا کنید.
          </p>
        </div>
      </section>

      <section className="py-12 px-4 mb-16">
        <div className="max-w-5xl mx-auto">
          <Tabs defaultValue="general" className="w-full">
            <TabsList className="w-full flex flex-wrap justify-center mb-8">
              {faqCategories.map((category) => (
                <TabsTrigger 
                  key={category.id} 
                  value={category.id}
                  className="px-6 py-2 m-1"
                >
                  {category.title}
                </TabsTrigger>
              ))}
            </TabsList>
            
            {faqCategories.map((category) => (
              <TabsContent key={category.id} value={category.id} className="space-y-6">
                {category.items.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                    className="bg-white rounded-xl p-6 border border-gray-200"
                  >
                    <h3 className="text-lg font-semibold text-gray-900 mb-3">{item.question}</h3>
                    <p className="text-gray-600">{item.answer}</p>
                  </motion.div>
                ))}
              </TabsContent>
            ))}
          </Tabs>

          <div className="mt-16 text-center">
            <p className="text-gray-600 mb-6">هنوز سؤال دیگری دارید؟</p>
            <Button 
              onClick={() => navigate("/contact")} 
              className="bg-indigo-600 hover:bg-indigo-700 text-white"
            >
              تماس با ما
            </Button>
          </div>
        </div>
      </section>

      <footer className="bg-gray-900 text-gray-400 py-12 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">لینک‌های مفید</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">خدمات</h3>
            <ul className="space-y-2">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">تماس با ما</h3>
            <ul className="space-y-2">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default FAQ;
