
import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const ReportsManagement = () => {
  const [activeTab, setActiveTab] = useState("users");
  
  return (
    <div className="p-6" dir="rtl">
      <h1 className="text-2xl font-bold mb-6 text-white">گزارش‌ها</h1>
      
      <Tabs defaultValue="users" onValueChange={setActiveTab}>
        <TabsList className="mb-6">
          <TabsTrigger value="users">کاربران</TabsTrigger>
          <TabsTrigger value="payments">پرداخت‌ها</TabsTrigger>
          <TabsTrigger value="usage">استفاده از ابزارها</TabsTrigger>
        </TabsList>
        
        <TabsContent value="users">
          <Card className="bg-gray-800 border-gray-700">
            <CardHeader>
              <CardTitle className="text-white">گزارش کاربران</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-center py-12 text-gray-400">
                اطلاعات نمودار در حال حاضر موجود نیست.
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="payments">
          <Card className="bg-gray-800 border-gray-700">
            <CardHeader>
              <CardTitle className="text-white">گزارش پرداخت‌ها</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-center py-12 text-gray-400">
                اطلاعات نمودار در حال حاضر موجود نیست.
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="usage">
          <Card className="bg-gray-800 border-gray-700">
            <CardHeader>
              <CardTitle className="text-white">آمار استفاده از ابزارها</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-center py-12 text-gray-400">
                اطلاعات نمودار در حال حاضر موجود نیست.
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ReportsManagement;
