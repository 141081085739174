
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent } from "@/components/ui/card";
import { Loader2, Copy, Lightbulb, Sparkles } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

export const PromotionAdvisor = () => {
  const [businessType, setBusinessType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any>(null);
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const { profile, refreshProfile } = useAuth();

  const handleGetAdvice = async () => {
    if (!businessType) {
      toast({
        title: "خطا",
        description: "لطفاً نوع کسب و کار را وارد کنید",
        variant: "destructive",
      });
      return;
    }

    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "promotion", creditCost: 8 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      // Call the function first to ensure it works before deducting credits
      const { data, error } = await supabase.functions.invoke("suggest-promotions", {
        body: { businessType },
      });

      if (error) throw error;
      
      // Now check if data contains error property
      if (data && data.error) {
        throw new Error(data.error);
      }

      // Now deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setSuggestions({
        targetNiches: data.targetNiches,
        promotionIdeas: data.promotionIdeas
      });
      
      // Set explanation if available
      if (data.explanation) {
        setExplanation(data.explanation);
      }

      try {
        const { error: saveError } = await supabase
          .from("promotion_suggestions")
          .insert([{
            business_type: businessType,
            target_niches: data.targetNiches,
            promotion_ideas: data.promotionIdeas,
            explanation: data.explanation || ""
          }]);

        if (saveError) {
          console.error("Error saving promotion suggestions:", saveError);
          // Continue execution even if saving fails
        }
      } catch (saveException) {
        console.error("Exception saving promotion suggestions:", saveException);
        // Continue execution even if saving fails
      }

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "پیشنهادات آماده شد",
        description: "راهکارهای تبلیغاتی برای کسب و کار شما آماده است",
      });
    } catch (error) {
      console.error("Error in promotion suggestions:", error);
      toast({
        title: "خطا",
        description: "مشکلی در دریافت پیشنهادات پیش آمد",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4" dir="rtl">
      <Input
        placeholder="نوع کسب و کار خود را وارد کنید..."
        value={businessType}
        onChange={(e) => setBusinessType(e.target.value)}
      />
      <Button 
        onClick={handleGetAdvice}
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            در حال دریافت پیشنهادات...
          </>
        ) : (
          "دریافت پیشنهادات تبلیغاتی"
        )}
      </Button>

      {suggestions ? (
        <div className="space-y-4">
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <div>
                  <h3 className="font-semibold mb-2">بازار هدف پیشنهادی:</h3>
                  <ul className="list-disc list-inside space-y-1">
                    {suggestions.targetNiches.map((niche: string, index: number) => (
                      <li key={index} className="text-gray-600">{niche}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">ایده‌های تبلیغاتی:</h3>
                  <ul className="list-disc list-inside space-y-1">
                    {suggestions.promotionIdeas.map((idea: string, index: number) => (
                      <li key={index} className="text-gray-600">{idea}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی تبلیغاتی</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>مشاور تبلیغات</strong> راهکارهای هوشمند و اختصاصی برای بازاریابی کسب و کار شما ارائه می‌دهد. 
            با استفاده از این ابزار، بازار هدف مناسب برای محصولات و خدمات خود را شناسایی کنید و استراتژی‌های تبلیغاتی موثر برای رشد کسب و کار خود دریافت کنید. 
            این ابزار به شما کمک می‌کند از بودجه تبلیغاتی خود بهترین استفاده را داشته باشید.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
