
import React, { useState, useEffect } from "react";
import { User, Clock, Home, Video, Image, Sun, Moon, Star } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CreditDisplay } from "@/components/CreditDisplay";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "@/components/ui/button";

interface MobileTopBarProps {
  useVideoBackground: boolean;
  onToggleBackground: () => void;
}

export const MobileTopBar = ({ useVideoBackground, onToggleBackground }: MobileTopBarProps) => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState<string>("");
  const [isDaytime, setIsDaytime] = useState<boolean>(true);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" }));
      
      const hours = now.getHours();
      setIsDaytime(hours >= 6 && hours < 18);
    };

    updateTime();
    const interval = setInterval(updateTime, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 h-16 bg-black/50 backdrop-blur-md px-4 flex items-center justify-between text-white z-50 overflow-visible" dir="rtl">
      <div className="flex items-center gap-2">
        <button
          className="w-9 h-9 flex items-center justify-center rounded-full bg-white/10"
          onClick={() => navigate('/dashboard')}
          aria-label="داشبورد"
        >
          <User className="w-4 h-4 text-white" />
        </button>
        <button
          className="w-9 h-9 flex items-center justify-center rounded-full bg-white/10"
          onClick={() => navigate('/app')}
          aria-label="خانه"
        >
          <Home className="w-4 h-4 text-white" />
        </button>
        <button
          className="w-9 h-9 flex items-center justify-center rounded-full bg-white/10"
          onClick={onToggleBackground}
          aria-label="تغییر پس‌زمینه"
        >
          <AnimatePresence mode="wait">
            {useVideoBackground ? (
              <motion.div
                key="video-icon"
                initial={{ rotateY: 90, opacity: 0 }}
                animate={{ rotateY: 0, opacity: 1 }}
                exit={{ rotateY: -90, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="flex items-center justify-center"
              >
                <Video className="w-4 h-4 text-white" />
              </motion.div>
            ) : (
              <motion.div
                key="image-icon"
                initial={{ rotateY: 90, opacity: 0 }}
                animate={{ rotateY: 0, opacity: 1 }}
                exit={{ rotateY: -90, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="flex items-center justify-center"
              >
                <Image className="w-4 h-4 text-white" />
              </motion.div>
            )}
          </AnimatePresence>
        </button>
        <div className="w-auto h-9 flex items-center justify-center rounded-full bg-white/10 min-w-32 pl-2 pr-3 relative overflow-hidden">
          <div className="flex items-center w-full">
            <div className="w-1/3 flex items-center justify-center relative">
              <AnimatePresence mode="wait">
                {isDaytime ? (
                  <motion.div 
                    key="daytime"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="absolute inset-0 bg-gradient-to-b from-blue-400/20 to-blue-600/10 rounded-l-full" />
                    <motion.div 
                      animate={{ 
                        y: [0, -3, 0, 3, 0],
                        scale: [1, 1.05, 1, 0.95, 1]
                      }}
                      transition={{ 
                        repeat: Infinity,
                        repeatType: "mirror",
                        duration: 5
                      }}
                      className="relative z-10"
                    >
                      <Sun className="w-6 h-6 text-yellow-300" />
                    </motion.div>
                  </motion.div>
                ) : (
                  <motion.div 
                    key="nighttime"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="absolute inset-0 bg-gradient-to-b from-indigo-900/20 to-indigo-800/10 rounded-l-full" />
                    <motion.div 
                      animate={{ 
                        y: [0, -2, 0, 2, 0],
                        rotate: [0, 5, 0, -5, 0]
                      }}
                      transition={{ 
                        repeat: Infinity,
                        repeatType: "mirror",
                        duration: 6
                      }}
                      className="relative z-10"
                    >
                      <Moon className="w-6 h-6 text-amber-100" />
                    </motion.div>
                    
                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ 
                        opacity: [0, 0.8, 0.2, 0.9, 0],
                        scale: [0.8, 1.2, 0.9, 1.1, 0.8]
                      }}
                      transition={{ 
                        repeat: Infinity,
                        duration: 3.5,
                        times: [0, 0.2, 0.4, 0.7, 1]
                      }}
                      className="absolute top-1 right-1 z-10"
                    >
                      <Star className="w-1.5 h-1.5 text-white" />
                    </motion.div>
                    
                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ 
                        opacity: [0, 0.7, 0.3, 1, 0],
                        scale: [0.7, 1.1, 0.9, 1.3, 0.7]
                      }}
                      transition={{ 
                        repeat: Infinity,
                        duration: 4.2,
                        delay: 0.7,
                        times: [0, 0.3, 0.5, 0.8, 1]
                      }}
                      className="absolute top-3 right-3 z-10"
                    >
                      <Star className="w-1 h-1 text-white" />
                    </motion.div>
                    
                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ 
                        opacity: [0, 0.9, 0.4, 0.8, 0],
                        scale: [0.9, 1.2, 1, 1.3, 0.9]
                      }}
                      transition={{ 
                        repeat: Infinity,
                        duration: 5,
                        delay: 1.5,
                        times: [0, 0.2, 0.6, 0.8, 1]
                      }}
                      className="absolute top-1 left-2 z-10"
                    >
                      <Star className="w-1.5 h-1.5 text-white" />
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="w-2/3 flex items-center justify-end z-10">
              <Clock className="w-4 h-4 text-white/80 ml-1 z-10" />
              <span className="text-sm font-medium z-10">{currentTime}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex justify-end">
        <CreditDisplay />
      </div>
    </div>
  );
};
