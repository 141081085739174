
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { useMobile } from "@/hooks/use-mobile";
import { LogOut } from "lucide-react";

interface ProfileTabProps {
  email: string;
  createdAt: string;
  userId: string;
}

const ProfileTab = ({ email, createdAt, userId }: ProfileTabProps) => {
  const { toast } = useToast();
  const { profile, refreshProfile, signOut } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    city: "",
    instagramPage: ""
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const isMobile = useMobile();

  // Check if profile is already completed
  useEffect(() => {
    if (profile?.has_claimed_profile_reward) {
      setIsFormSubmitted(true);
    }
    
    if (profile?.full_name) {
      setFormData(prev => ({
        ...prev,
        fullName: profile.full_name || "",
        phone: profile.phone_number || "",
        city: profile.city || "",
        instagramPage: profile.instagram_page || ""
      }));
    }
  }, [profile]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Update profile with new data
      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          full_name: formData.fullName,
          phone_number: formData.phone,
          city: formData.city,
          instagram_page: formData.instagramPage,
          has_claimed_profile_reward: true
        })
        .eq('id', userId);

      if (updateError) throw updateError;

      // Refresh the profile
      await refreshProfile();

      setIsFormSubmitted(true);
      toast({
        title: "پروفایل با موفقیت تکمیل شد",
        description: "۲۰ اعتبار رایگان به حساب شما افزوده شد!",
      });
    } catch (error) {
      console.error("Error submitting profile:", error);
      toast({
        title: "خطا",
        description: "مشکلی در ثبت اطلاعات پیش آمد. لطفاً دوباره تلاش کنید.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      toast({
        title: "خروج موفق",
        description: "شما با موفقیت از حساب کاربری خارج شدید.",
      });
    } catch (error) {
      console.error("Error signing out:", error);
      toast({
        title: "خطا",
        description: "مشکلی در خروج از حساب پیش آمد. لطفاً دوباره تلاش کنید.",
        variant: "destructive",
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>اطلاعات پروفایل</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="space-y-4">
            <div>
              <Label htmlFor="email" className="text-gray-800">ایمیل</Label>
              <Input 
                id="email" 
                value={email} 
                readOnly 
                className="bg-gray-50"
              />
            </div>
            <div>
              <Label htmlFor="created-at" className="text-gray-800">تاریخ عضویت</Label>
              <Input 
                id="created-at" 
                value={createdAt} 
                readOnly 
                className="bg-gray-50"
              />
            </div>
            <div>
              <Label htmlFor="user-id" className="text-gray-800">شناسه کاربری</Label>
              <Input 
                id="user-id" 
                value={userId} 
                readOnly 
                className="bg-gray-50"
              />
            </div>
          </div>

          {!isFormSubmitted && (
            <form onSubmit={handleSubmit} className="space-y-4 border-t pt-6">
              <div>
                <Label htmlFor="fullName" className="text-gray-800">نام و نام خانوادگی</Label>
                <Input
                  id="fullName"
                  value={formData.fullName}
                  onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
                  required
                />
              </div>
              <div>
                <Label htmlFor="phone" className="text-gray-800">شماره تلفن</Label>
                <Input
                  id="phone"
                  value={formData.phone}
                  onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                  required
                />
              </div>
              <div>
                <Label htmlFor="city" className="text-gray-800">شهر</Label>
                <Input
                  id="city"
                  value={formData.city}
                  onChange={(e) => setFormData(prev => ({ ...prev, city: e.target.value }))}
                  required
                />
              </div>
              <div>
                <Label htmlFor="instagramPage" className="text-gray-800">صفحه اینستاگرام</Label>
                <Input
                  id="instagramPage"
                  value={formData.instagramPage}
                  onChange={(e) => setFormData(prev => ({ ...prev, instagramPage: e.target.value }))}
                  required
                  placeholder="مثال: @instayar"
                />
              </div>
              <Button 
                type="submit" 
                className="w-full bg-indigo-600 hover:bg-indigo-700"
                disabled={loading}
              >
                {loading ? "در حال ثبت..." : "تکمیل پروفایل و دریافت ۲۰ اعتبار رایگان"}
              </Button>
            </form>
          )}
          
          {/* Logout button only for mobile */}
          {isMobile && (
            <div className="pt-6 border-t">
              <Button 
                onClick={handleSignOut}
                variant="outline" 
                className="w-full border-red-600 text-red-500 hover:bg-red-600/10 hover:text-red-500"
              >
                <LogOut className="ml-2 h-4 w-4" />
                خروج از حساب کاربری
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProfileTab;
