import { useState } from "react";
import { useAuth } from "@/lib/auth";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Card, CardContent } from "@/components/ui/card";
import { toast } from "@/hooks/use-toast";
import { Image, Download, Loader2, Sparkles } from "lucide-react";
import { AIToolCard } from "./AIToolCard";
import { Alert, AlertDescription } from "./ui/alert";
export const ImageGenerator = () => {
  const {
    session
  } = useAuth();
  const [prompt, setPrompt] = useState("");
  const [quality, setQuality] = useState<"standard" | "high">("standard");
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = useState<string | null>(null);
  const [revisedPrompt, setRevisedPrompt] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const handleGenerateImage = async () => {
    if (!prompt || prompt.trim().length === 0) {
      toast({
        title: "لطفاً یک درخواست وارد کنید",
        variant: "destructive"
      });
      return;
    }
    if (!session?.user?.id) {
      toast({
        title: "برای استفاده از این ابزار باید وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }
    try {
      setIsGenerating(true);
      setError(null);
      setRevisedPrompt(null);

      // Calculate credit cost based on quality
      const creditCost = quality === "high" ? 20 : 15;

      // Notify user that image generation may take time
      toast({
        title: "در حال ساخت تصویر",
        description: "لطفا صبر کنید. ساخت تصویر ممکن است چند لحظه طول بکشد."
      });
      console.log("Calling generate-image function with:", {
        prompt,
        quality
      });

      // Call Supabase Edge Function to generate image
      const {
        data,
        error
      } = await supabase.functions.invoke("generate-image", {
        body: {
          prompt,
          quality
        }
      });
      console.log("Edge function response:", data, error);
      if (error) {
        throw new Error(error.message);
      }
      if (data.error) {
        throw new Error(data.error);
      }
      if (!data.imageUrl) {
        throw new Error("خطا در دریافت تصویر");
      }

      // Set the generated image URL and revised prompt
      setGeneratedImage(data.imageUrl);
      setRevisedPrompt(data.revisedPrompt);

      // Save image data to the database
      const {
        error: saveError
      } = await supabase.from('generated_images').insert({
        prompt,
        revised_prompt: data.revisedPrompt,
        image_url: data.imageUrl,
        quality,
        user_id: session.user.id
      });
      if (saveError) {
        console.error("Error saving image to database:", saveError);
        // Continue execution despite the error to show the image to the user
      }

      // Deduct credits based on quality
      const {
        error: creditError
      } = await supabase.rpc('deduct_credits', {
        amount: creditCost
      });
      if (creditError) {
        console.error("Error deducting credits:", creditError);
        throw new Error(creditError.message);
      }

      // Log tool usage
      const {
        error: usageError
      } = await supabase.from('tool_usage').insert({
        tool_id: 'image',
        user_id: session.user.id,
        credit_cost: creditCost
      });
      if (usageError) {
        console.error("Error logging tool usage:", usageError);
      }
      toast({
        title: "تصویر با موفقیت ساخته شد"
      });
    } catch (error) {
      console.error("Error generating image:", error);
      setError(error instanceof Error ? error.message : "خطای ناشناخته");
      toast({
        title: "خطا در ساخت تصویر",
        description: error instanceof Error ? error.message : "خطای ناشناخته",
        variant: "destructive"
      });
    } finally {
      setIsGenerating(false);
    }
  };
  const handleDownload = async () => {
    if (!generatedImage) return;
    try {
      // Show loading toast
      toast({
        title: "در حال دانلود تصویر",
        description: "لطفا منتظر بمانید..."
      });

      // Create a temporary anchor element for download
      const link = document.createElement('a');
      link.href = generatedImage;
      link.download = `instayar-image-${Date.now()}.png`;

      // Append to document, click to trigger download, then remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast({
        title: "تصویر با موفقیت دانلود شد"
      });
    } catch (error) {
      console.error("Error downloading image:", error);
      toast({
        title: "خطا در دانلود تصویر",
        variant: "destructive"
      });
    }
  };
  return <AIToolCard title="تولید تصویر با هوش مصنوعی" description="با استفاده از DALL-E 3 تصاویر با کیفیت بالا بسازید" icon={Image}>
      <div className="space-y-4">
        
        
        <Alert className="bg-blue-500/10 border border-blue-400/30 text-blue-600">
          <AlertDescription className="text-sm text-right">
            ساخت تصویر ممکن است چند لحظه طول بکشد، لطفا صبور باشید.
          </AlertDescription>
        </Alert>
        
        <div>
          <Label htmlFor="prompt" className="text-gray-900">درخواست شما</Label>
          <Textarea id="prompt" placeholder="توضیحات تصویر مورد نظر خود را به صورت دقیق وارد کنید..." value={prompt} onChange={e => setPrompt(e.target.value)} rows={4} dir="rtl" className="mt-1 backdrop-blur-sm bg-transparent border border-white/30 text-gray-900 rounded-md" />
        </div>
        
        <div>
          <Label className="block mb-2 text-gray-900">کیفیت تصویر</Label>
          <RadioGroup value={quality} onValueChange={val => setQuality(val as "standard" | "high")} className="flex space-x-4 space-x-reverse">
            <div className="flex items-center space-x-2 space-x-reverse">
              <RadioGroupItem value="standard" id="standard" />
              <Label htmlFor="standard" className="text-gray-800 px-[14px]">استاندارد (15 اعتبار)</Label>
            </div>
            <div className="flex items-center space-x-2 space-x-reverse">
              <RadioGroupItem value="high" id="high" />
              <Label htmlFor="high" className="text-gray-800 px-[13px]">کیفیت بالا (20 اعتبار)</Label>
            </div>
          </RadioGroup>
        </div>
        
        <Button onClick={handleGenerateImage} disabled={isGenerating || !prompt || !session?.user} className="w-full">
          {isGenerating ? <>
              <Loader2 className="w-4 h-4 ml-2 animate-spin" />
              در حال ساخت تصویر...
            </> : "تولید تصویر"}
        </Button>
        
        {error && <div className="p-3 text-sm text-white bg-red-500/70 rounded-md">
            {error}
          </div>}
        
        {generatedImage && <Card className="mt-4 overflow-hidden backdrop-blur-sm bg-transparent border border-white/30">
            <CardContent className="p-0">
              {revisedPrompt && <div className="p-3 bg-purple-500/10 border-b border-purple-400/30 text-purple-700">
                  <div className="flex items-center gap-2 mb-1 text-sm font-medium">
                    <Sparkles className="w-4 h-4" />
                    <span>درخواست بهبود یافته:</span>
                  </div>
                  <p className="text-sm text-right">{revisedPrompt}</p>
                </div>}
              <div className="relative">
                <img src={generatedImage} alt={revisedPrompt || prompt} className="w-full h-auto" />
                <Button variant="outline" size="sm" className="absolute bottom-4 right-4 bg-black/70 hover:bg-black/90" onClick={handleDownload}>
                  <Download className="w-4 h-4 ml-2" />
                  دانلود تصویر
                </Button>
              </div>
            </CardContent>
          </Card>}
      </div>
    </AIToolCard>;
};