import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useMobile } from "@/hooks/use-mobile";
import { Menu } from "lucide-react";
import { useState } from "react";
interface MarketingHeaderProps {
  currentPage?: string;
}
export const MarketingHeader = ({
  currentPage
}: MarketingHeaderProps) => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return <header className="bg-white border-b border-gray-200 mobile-header-fixed">
      <div className="max-w-7xl mx-auto py-4 md:py-6 px-4 md:px-6 marketing-page-header">
        <div className={`flex justify-between items-center ${isMobile ? 'marketing-top-menu' : ''}`}>
          <h1 className="text-2xl font-bold bg-gradient-to-l from-indigo-600 to-purple-600 text-transparent bg-clip-text cursor-pointer" onClick={() => navigate("/")}>
            اینستایار
          </h1>
          
          {isMobile ? <>
              <Button variant="ghost" onClick={toggleMobileMenu} aria-label="منو" className="p-2 text-card-foreground">
                <Menu size={24} />
              </Button>
              
              {mobileMenuOpen && <div className="absolute top-full left-0 right-0 bg-white border-b border-gray-200 shadow-md z-50 py-3 px-4">
                  <div className="flex flex-col gap-2">
                    <Button variant={currentPage === "blog" ? "default" : "ghost"} className={`w-full justify-start ${currentPage === "blog" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"}`} onClick={() => {
                navigate("/blog");
                setMobileMenuOpen(false);
              }}>
                      آموزش اینستاگرام
                    </Button>
                    
                    <Button variant={currentPage === "pricing" ? "default" : "ghost"} className={`w-full justify-start ${currentPage === "pricing" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"}`} onClick={() => {
                navigate("/pricing");
                setMobileMenuOpen(false);
              }}>
                      تعرفه‌ها
                    </Button>
                    
                    <Button variant={currentPage === "about" ? "default" : "ghost"} className={`w-full justify-start ${currentPage === "about" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"}`} onClick={() => {
                navigate("/about");
                setMobileMenuOpen(false);
              }}>
                      درباره ما
                    </Button>
                    
                    <Button variant={currentPage === "contact" ? "default" : "ghost"} className={`w-full justify-start ${currentPage === "contact" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"}`} onClick={() => {
                navigate("/contact");
                setMobileMenuOpen(false);
              }}>
                      تماس با ما
                    </Button>
                    
                    <Button className="bg-indigo-600 hover:bg-indigo-700 text-white w-full" onClick={() => {
                navigate("/auth");
                setMobileMenuOpen(false);
              }}>
                      ورود / ثبت‌نام
                    </Button>
                  </div>
                </div>}
            </> : <div className="flex items-center gap-2 md:gap-4">
              <Button variant={currentPage === "blog" ? "default" : "ghost"} className={currentPage === "blog" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"} size="default" onClick={() => navigate("/blog")}>
                آموزش اینستاگرام
              </Button>
              
              <Button variant={currentPage === "pricing" ? "default" : "ghost"} className={currentPage === "pricing" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"} size="default" onClick={() => navigate("/pricing")}>
                تعرفه‌ها
              </Button>
              
              <Button variant={currentPage === "about" ? "default" : "ghost"} className={currentPage === "about" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"} size="default" onClick={() => navigate("/about")}>
                درباره ما
              </Button>
              
              <Button variant={currentPage === "contact" ? "default" : "ghost"} className={currentPage === "contact" ? "bg-indigo-600 text-white" : "text-gray-700 hover:text-indigo-600 hover:bg-gray-100"} size="default" onClick={() => navigate("/contact")}>
                تماس با ما
              </Button>
              
              <Button className="bg-indigo-600 hover:bg-indigo-700 text-white" size="default" onClick={() => navigate("/auth")}>
                ورود / ثبت‌نام
              </Button>
            </div>}
        </div>
      </div>
    </header>;
};