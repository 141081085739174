import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";
import { Clock, Search, Instagram, ArrowRight } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import { getInstagramImages } from "@/integrations/supabase/client";

// Types
interface BlogPost {
  id: string;
  title: string;
  summary: string;
  publishedDate: string;
  readTime: string;
  imageUrl: string;
  category: string;
  featured?: boolean;
}

const Blog = () => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCategory, setActiveCategory] = useState<string>("all");
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [instagramImages, setInstagramImages] = useState<string[]>([]);

  // Get query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    if (category) {
      setActiveCategory(category);
    }
  }, [location]);

  // Placeholder data - in a real app, this would come from an API
  useEffect(() => {
    const fetchData = () => {
      // دریافت تصاویر اینستاگرام
      const images = getInstagramImages();
      setInstagramImages(images);
      
      // Simulate delay
      setTimeout(() => {
        const getRandomImage = () => {
          if (images.length === 0) {
            // فالبک به تصاویر Unsplash در صورت عدم وجود تصاویر اینستاگرام
            const fallbackImages = [
              "https://images.unsplash.com/photo-1611162616475-46b635cb6868",
              "https://images.unsplash.com/photo-1573152958734-1922c188fba3"
            ];
            return fallbackImages[Math.floor(Math.random() * fallbackImages.length)];
          }
          return images[Math.floor(Math.random() * images.length)];
        };

        const blogPosts: BlogPost[] = [
          {
            id: "instagram-advertising-strategies",
            title: "استراتژی‌های تبلیغات اینستاگرام: راهنمای جامع برای کسب و کارهای ایرانی",
            summary: "استراتژی‌های پیشرفته و کاربردی برای موفقیت در تبلیغات اینستاگرام و انواع فرمت‌های تبلیغاتی، اصول طراحی، و روش‌های هدف‌گذاری مخاطبان.",
            publishedDate: "۳۰ مهر ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "تبلیغات",
            featured: true
          },
          {
            id: "instagram-reels-editing-tools",
            title: "بهترین ابزارهای ویرایش ریلز اینستاگرام در سال ۱۴۰۳",
            summary: "معرفی و بررسی بهترین اپلیکیشن‌ها و نرم‌افزارهای ویرایش ریلز اینستاگرام که می‌توانند به شما در تولید محتوای جذاب‌تر کمک کنند.",
            publishedDate: "۱۰ مهر ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "ریلز",
            featured: false
          },
          {
            id: "instagram-reels-tutorial-complete-2024",
            title: "آموزش کامل ساخت ریلز اینستاگرام در سال ۱۴۰۳",
            summary: "راهنمای گام به گام برای ساخت ریلزهای حرفه‌ای و جذاب در اینستاگرام به همراه نکات و ترفندهای کاربردی برای افزایش بازدید و تعامل.",
            publishedDate: "۱۵ مهر ۱۴۰۳",
            readTime: "۱۰ دقیقه",
            imageUrl: getRandomImage(),
            category: "ریلز",
            featured: false
          },
          {
            id: "10-hidden-tricks-instagram-growth",
            title: "۱۰ ترفند مخفی برای افزایش فالوور اینستاگرام",
            summary: "در این مقاله، ۱۰ ترفند کمتر شناخته شده اما قدرتمند برای افزایش فالوور در اینستاگرام را معرفی می‌کنیم که می‌تواند به رشد سریع پیج شما کمک کند.",
            publishedDate: "۱۵ اردیبهشت ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "رشد فالوور",
            featured: false
          },
          {
            id: "leverage-new-instagram-algorithm",
            title: "چگونه از الگوریتم جدید اینستاگرام به نفع خود استفاده کنیم",
            summary: "الگوریتم اینستاگرام مدام در حال تغییر است. در این مقاله نحوه استفاده از آخرین تغییرات الگوریتم اینستاگرام را بررسی می‌کنیم.",
            publishedDate: "۵ خرداد ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "الگوریتم"
          },
          {
            id: "complete-guide-instagram-reels",
            title: "راهنمای کامل تولید محتوا برای ریلز اینستاگرام",
            summary: "ریلز یکی از محبوب‌ترین قابلیت‌های اینستاگرام است. این راهنمای کامل به شما کمک می‌کند تا ریلزهای جذاب و پربازدید تولید کنید.",
            publishedDate: "۲۰ اردیبهشت ۱۴۰۳",
            readTime: "۱۰ دقیقه",
            imageUrl: getRandomImage(),
            category: "ریلز"
          },
          {
            id: "instagram-story-strategies-for-sales",
            title: "استراتژی‌های استوری برای افزایش فروش",
            summary: "استوری اینستاگرام ابزاری قدرتمند برای فروش است. در این مقاله، استراتژی‌های موثر برای استفاده از استوری جهت افزایش فروش را بررسی می‌کنیم.",
            publishedDate: "۳ خرداد ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "فروش"
          },
          {
            id: "best-posting-times-instagram-iran",
            title: "بهترین ساعات انتشار پست در اینستاگرام",
            summary: "زمان انتشار پست تاثیر زیادی در میزان دیده شدن آن دارد. در این مقاله بهترین ساعات برای پست گذاشتن در اینستاگرام برای مخاطبان ایرانی را بررسی می‌کنیم.",
            publishedDate: "۱۰ خرداد ۱۴۰۳",
            readTime: "۵ دقیقه",
            imageUrl: getRandomImage(),
            category: "زمان‌بندی"
          },
          {
            id: "hashtag-strategy-content-visibility",
            title: "استراتژی هشتگ‌ها برای افزایش دیده شدن محتوا",
            summary: "هشتگ‌ها نقش مهمی در دیده شدن محتوای شما دارند. در این مقاله، نحوه استفاده موثر از هشتگ‌ها برای افزایش دیده شدن محتوا را می‌آموزید.",
            publishedDate: "۲۵ اردیبهشت ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "الگوریتم"
          },
          {
            id: "attracting-real-active-instagram-followers",
            title: "ترفندهای جذب مخاطب فعال و واقعی",
            summary: "چگونه می‌توان فالوورهای واقعی و فعال جذب کرد؟ در این مقاله راهکارهای عملی برای جذب مخاطبان واقعی که با محتوای شما تعامل می‌کنند را بررسی می‌کنیم.",
            publishedDate: "۱۲ خرداد ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "رشد فالوور"
          },
          {
            id: "how-to-sell-products-on-instagram",
            title: "چگونه با اینستاگرام محصولات خود را بفروشیم",
            summary: "فروش محصول در اینستاگرام نیازمند استراتژی خاصی ����ت. در این مقاله، روش‌های موثر فروش محصولات از طریق اینستاگرام را معرفی می‌کنیم.",
            publishedDate: "۷ خرداد ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "فروش"
          },
          {
            id: "professional-photography-instagram",
            title: "اصول عکاسی حرفه‌ای برای اینستاگرام",
            summary: "کیفیت عکس‌ها یکی از عوامل مهم در جذب مخاطب است. در این مقاله، اصول اولیه عکاسی حرفه‌ای برای اینستاگرام را می��آموزید.",
            publishedDate: "۲ خرداد ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "عکاسی"
          },
          {
            id: "successful-instagram-bio",
            title: "ویژگی‌های یک بیوی موفق در اینستاگرام",
            summary: "بیوگرافی اینستاگرام اولین برخورد مخاطب با پیج شماست. در این مقاله، ویژگی‌های یک بیوی موفق و جذاب را بررسی می‌کنیم.",
            publishedDate: "۱۸ اردیبهشت ۱۴۰۳",
            readTime: "۵ دقیقه",
            imageUrl: getRandomImage(),
            category: "پروفایل"
          },
          {
            id: "ai-instagram-content-creation",
            title: "استفاده از هوش مصنوعی برای تولید محتوا",
            summary: "هوش مصنوعی می‌تواند به تولید محتوای جذاب برای اینستاگرام کمک کند. در این مقاله، نحوه استفاده از ابزارهای هوش مصنوعی برای تولید محتوا را بررسی می‌کنیم.",
            publishedDate: "۵ خرداد ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "هوش مصنوعی"
          },
          {
            id: "designing-attractive-carousel-posts",
            title: "نحوه طراحی پست‌های اسلایدی جذاب",
            summary: "پست‌های اسلایدی یا کروسل یکی از انواع محبوب محتوا در اینستاگرام هستند. در این مقاله، اصول طراحی پست‌های اسلایدی جذاب را می‌آموزید.",
            publishedDate: "۲۲ اردیبهشت ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "محتوا"
          },
          {
            id: "create-instagram-content-calendar",
            title: "چگونه تقویم محتوایی اینستاگرام ایجاد کنیم",
            summary: "تقویم محتوایی به شما کمک می‌کند تا برنامه منظمی برای انتشار محتوا داشته باشید. در این مقاله، نحوه ایجاد یک تقویم محتوایی موثر را می‌آموزید.",
            publishedDate: "۱۵ خرداد ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "محتوا"
          },
          {
            id: "instagram-analytics-key-performance-indicators",
            title: "آنالیز عملکرد پیج اینستاگرام",
            summary: "آنالیز عملکرد پیج برای بهبود استراتژی محتوا ضروری است. در این مقاله، شاخص‌های کلیدی عملکرد و نحوه تحلیل آنها را بررسی می‌کنیم.",
            publishedDate: "۸ خرداد ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "تحلیل"
          },
          {
            id: "instagram-influencer-collaboration-guide",
            title: "راهنمای همکاری با اینفلوئنسرها",
            summary: "همکاری با اینفلوئنسرها یکی از روش‌های موثر برای افزایش دیده شدن برند شماست. در این مقاله، نحوه همکاری موفق با اینفلوئنسرها را می‌آموزید.",
            publishedDate: "۲۰ خرداد ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "همکاری"
          },
          {
            id: "instagram-personal-branding",
            title: "استفاده از اینست��گرام برای برندسازی شخصی",
            summary: "اینستاگرام ابزاری قدرتمند برای برندسازی شخصی است. در این مقاله، نحوه استفاده از اینستاگرام برای تقویت برند شخصی را بررسی می‌کنیم.",
            publishedDate: "۱۷ اردیبهشت ���۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "برندسازی"
          },
          {
            id: "psychology-tips-instagram-engagement",
            title: "نکات روانشناسی برای افزایش تعامل",
            summary: "روانشناسی نقش مهمی در جذب مخاطب دارد. در این مقاله، نکات روانشناسی برای افزایش تعامل در اینستاگرام را بررسی می‌کنیم.",
            publishedDate: "۳۰ اردیبهشت ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "تعامل"
          },
          {
            id: "essential-tools-professional-instagram-management",
            title: "ابزارهای ضروری برای مدیریت حرفه‌ای",
            summary: "مدیریت حرفه‌ای پیج اینستاگرام نیازمند ابزارهای مناسب است. در این مقاله، ابزارهای ضروری برای مدیریت حرفه‌ای پیج اینستاگرام را معرفی می‌کنیم.",
            publishedDate: "۱۳ خرداد ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "ابزارها"
          },
          {
            id: "instagram-monetization-methods-1403",
            title: "روش‌های کسب درآمد از اینستاگرام",
            summary: "اینستاگرام فرصت‌های متعددی برای کسب درآمد ارائه می‌دهد. در این مقاله، روش‌های مختلف کسب درآمد از اینستاگرام در سال ۱۴۰۳ را بررسی می‌کنیم.",
            publishedDate: "۲۵ خرداد ۱۴۰۳",
            readTime: "۱۰ دقیقه",
            imageUrl: getRandomImage(),
            category: "درآمدزایی"
          },
          {
            id: "instagram-vs-other-social-media-platforms",
            title: "مقایسه اینستاگرام با سایر پلتفرم‌ها",
            summary: "هر پلتفرم شبکه اجتماعی ویژگی‌های خاص خود را دارد. در این مقاله، اینستاگرام را با سایر پلتفرم‌های محبوب مقایسه می‌کنیم.",
            publishedDate: "۱۸ خرداد ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "مقایسه"
          },
          {
            id: "comprehensive-guide-instagram-engagement",
            title: "راهنمای جامع افزایش نرخ تعامل",
            summary: "نرخ تعامل یکی از مهم‌ترین شاخص‌های موفقیت در اینستاگرام است. در این مقاله، راهکارهای جامع برای افزایش نرخ تعامل را بررسی می‌کنیم.",
            publishedDate: "۲۷ اردیبهشت ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "تعامل"
          },
          {
            id: "instagram-key-statistics-1403",
            title: "آمار و ارقام کلیدی اینستاگرام",
            summary: "آشنایی با آمار و ارقام اینستاگرام می‌تواند به بهبود استراتژی کمک کند. در این مقاله، آمار و ارقام کلیدی اینستاگرام در سال ۱۴۰۳ را بررسی می‌کنیم.",
            publishedDate: "۱ خرداد ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "تحلیل"
          },
          {
            id: "complete-instagram-ads-guide-iranian-businesses",
            title: "راهنمای کامل تبلیغات در اینستاگرام",
            summary: "تبلیغات در اینستاگرام می���تواند به افزایش فروش و آگاهی از برند کمک کند. در این مقاله، راهنمای کامل تبلیغات در اینستاگرام برای کسب‌وکارهای ایرانی ارائه می‌شود.",
            publishedDate: "۲۳ خرداد ۱۴۰۳",
            readTime: "۱۱ دقیقه",
            imageUrl: getRandomImage(),
            category: "تبلیغات"
          },
          {
            id: "setup-instagram-shop-with-new-features",
            title: "نحوه راه‌اندازی فروشگاه در اینستاگرام",
            summary: "ویژگی‌های فروشگاهی اینستاگرام فرصتی عالی برای فروش آنلاین ارائه می‌دهند. در این مقاله، نحوه راه‌اندازی یک فروشگاه موفق در اینستاگرام را می‌آموزید.",
            publishedDate: "۲۹ اردیبهشت ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "فروش"
          },
          {
            id: "instagram-live-comprehensive-guide",
            title: "راهنمای جامع لایو اینستاگرام",
            summary: "لایو اینستاگرام ابزاری موثر برای تعامل با مخاطبان است. در این مقاله، راهنمای جامع برگزاری لایوهای جذاب و تعاملی را ارائه می‌دهیم.",
            publishedDate: "۶ خرداد ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "لایو"
          },
          {
            id: "how-to-use-instagram-guides",
            title: "نحوه استفاده از راهنماها در اینستاگرام",
            summary: "راهنماها در اینستاگرام امکان ارائه محتوای مرجع را فراهم می‌کنند. در این مقاله، نحوه استفاده موثر از راهنماها را می‌آموزید.",
            publishedDate: "۱۶ خرداد ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "راهنماها"
          },
          {
            id: "instagram-collab-content-creators",
            title: "همکاری با سازندگان محتوا در اینستاگرام",
            summary: "همکاری با سایر سازندگان محتوا می‌تواند به افزایش دسترسی کمک کند. در این مقاله، نحوه همکاری موثر با سازندگان محتوا را بررسی می‌کنیم.",
            publishedDate: "۱۰ اردیبهشت ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "همکاری"
          },
          {
            id: "all-about-threads-meta-new-platform",
            title: "همه چیز درباره تردز: پلتفرم جدید متا",
            summary: "تردز پلتفرم جدید متا برای رقابت با توییتر است. در این مقاله، همه چیز درباره تردز و تفاوت‌های آن با اینستاگرام را می‌آموزید.",
            publishedDate: "۱۹ خرداد ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "مقایسه"
          },
          {
            id: "best-photo-editing-apps-instagram-1403",
            title: "بهترین نرم‌افزارهای ویرایش عکس",
            summary: "ویرایش عکس نقش مهمی در جذابیت محتوای بصری دارد. در این مقاله، بهترین نرم‌افزارهای ویرایش عکس برای اینستاگرام در سال ۱۴۰۳ را معرفی می‌کنیم.",
            publishedDate: "۱۴ اردیبهشت ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "عکاسی"
          },
          {
            id: "how-to-use-music-instagram-content",
            title: "نحوه استفاده از موسیقی در محتوا",
            summary: "موسیقی می‌تواند به جذابیت محتوای ویدیویی بیفزاید. در این مقاله، نحوه استفاده موثر از موسیقی در استوری‌ها، ریلز و IGTV را می‌آموزید.",
            publishedDate: "۲۸ خرداد ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "موسیقی"
          },
          {
            id: "instagram-content-strategy-b2b",
            title: "استراتژی محتوای اینستاگرام برای B2B",
            summary: "کسب‌وکارهای B2B نیازمند استراتژی متفاوتی برای اینستاگرام هستند. در این مقاله، استراتژی‌های موثر محتوا برای کسب‌وکارهای B2B را بررسی می‌کنیم.",
            publishedDate: "۵ تیر ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "استراتژی"
          },
          {
            id: "instagram-content-pillars",
            title: "ستون‌های محتوایی برای اینستاگرام موفق",
            summary: "ستون‌های محتوایی مشخص می‌کنند که درباره چه موضوعاتی پست بگذارید. در این مقاله، نحوه ایجاد ستون‌های محتوایی قدرتمند را می‌آموزید.",
            publishedDate: "۸ تیر ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "محتوا"
          },
          {
            id: "instagram-content-batching",
            title: "تولید محتوای دسته‌ای برای صرفه‌جویی در زمان",
            summary: "تولید محتوای دسته‌ای می‌تواند به صرفه‌جویی در زمان و بهبود کیفیت کمک کند. در این مقاله، روش‌های تولید محتوای دسته‌ای را بررسی می‌کنیم.",
            publishedDate: "۱۱ تیر ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "محتوا"
          },
          {
            id: "instagram-niche-marketing",
            title: "بازاریابی تخصصی در اینستاگرام",
            summary: "تمرکز بر یک حوزه تخصصی می‌تواند به موفقیت در اینستاگرام کمک کند. در این مقاله، اصول ب��زاریابی تخصصی در اینستاگرام را می‌آموزید.",
            publishedDate: "۱۵ تیر ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "بازاریابی"
          },
          {
            id: "instagram-community-building",
            title: "ایجاد جامعه قدرتمند در اینستاگرام",
            summary: "جامعه‌سازی یکی از مهم‌ترین جنبه‌های موفقیت در اینستاگرام است. در این مقاله، روش‌های ایجاد و مدیریت یک جامعه فعال را بررسی می‌کنیم.",
            publishedDate: "۱۸ تیر ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "تعامل"
          },
          {
            id: "instagram-account-security",
            title: "امنیت حساب کاربری اینستاگرام",
            summary: "حفظ امنیت حساب اینستاگرام بسیار مهم است. در این مقاله، روش‌های افزایش امنیت حساب کاربری و جلوگیری از هک شدن را بررسی می‌کنیم.",
            publishedDate: "۲۲ تیر ۱۴۰۳",
            readTime: "۶ دقیقه",
            imageUrl: getRandomImage(),
            category: "امنیت"
          },
          {
            id: "instagram-content-repurposing",
            title: "بازآفرینی محتوا برای افزایش بهره‌وری",
            summary: "بازآفرینی محتوا می‌تواند به کاهش بار تولید محتوا کمک کند. در این مقاله، روش‌های بازآفرینی محتوا بین پلتفرم‌های مختلف را می‌آموزید.",
            publishedDate: "۲۵ تیر ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "محتوا"
          },
          {
            id: "instagram-audience-segmentation",
            title: "بخش‌بندی مخاطبان اینستاگرام",
            summary: "بخش‌بندی مخاطبان می‌تواند به هدفمندتر کردن محتوا کمک کند. در این مقاله، روش‌های شناسایی و بخش‌بندی مخاطبان را بررسی می‌کنیم.",
            publishedDate: "۲۹ تیر ۱۴۰۳",
            readTime: "۸ دقیقه",
            imageUrl: getRandomImage(),
            category: "بازاریابی"
          },
          {
            id: "instagram-crisis-management",
            title: "مدیریت بحران در اینستاگرام",
            summary: "بحران‌های اینستاگرامی می‌توانند به سرعت گسترش یابند. در این مقاله، نحوه آمادگی و پاسخ به بحران‌ها در اینستاگرام را می‌آموزید.",
            publishedDate: "۲ مرداد ۱۴۰۳",
            readTime: "۹ دقیقه",
            imageUrl: getRandomImage(),
            category: "مدیریت"
          },
          {
            id: "instagram-analytics-tools",
            title: "ابزارهای آنالیز اینستاگرام",
            summary: "استفاده از ابزارهای آنالیز می‌تواند به بهبود عملکرد کمک کند. در این مقاله، بهترین ابزارهای آنالیز اینستاگرام را معرفی می‌کنیم.",
            publishedDate: "۶ مرداد ۱۴۰۳",
            readTime: "۷ دقیقه",
            imageUrl: getRandomImage(),
            category: "تحلیل"
          }
        ];

        setPosts(blogPosts);
        setFilteredPosts(blogPosts);
        setLoading(false);
      }, 500);
    };

    fetchData();
  }, []);

  // Filter posts based on search term and active category
  useEffect(() => {
    if (posts.length === 0) return;

    const filtered = posts.filter(post => {
      const matchesSearch = post.title.includes(searchTerm) || 
                           post.summary.includes(searchTerm) ||
                           post.category.includes(searchTerm);
      
      const matchesCategory = activeCategory === "all" || post.category === activeCategory;
      
      return matchesSearch && matchesCategory;
    });
    
    setFilteredPosts(filtered);
  }, [searchTerm, activeCategory, posts]);

  // Get all categories from posts
  const categories = ["all", ...Array.from(new Set(posts.map(post => post.category)))].sort();

  const featuredPost = posts.find(post => post.featured);

  return (
    <div className={`bg-white ${!isMobile ? 'min-h-screen' : 'mobile-page-container'}`} dir="rtl">
      <SEO 
        title="وبلاگ اینستایار | آموزش اینستاگرام و روش‌های افزایش فالوور"
        description="در وبلاگ اینستایار، آخرین روش‌های افزایش فالوور، تولید محتوای جذاب، استراتژی‌های الگوریتم اینستاگرام و ترفندهای بازاریابی در اینستاگرام را بیاموزید."
        keywords="آموزش اینستاگرام، افزایش فالوور اینستاگرام، استراتژی محتوا اینستاگرام، الگوریتم اینستاگرام، تولید محتوا اینستاگرام، ترفندهای اینستاگرام"
        type="website"
      />

      <MarketingHeader currentPage="blog" />

      <main className="max-w-7xl mx-auto py-12 px-4 md:px-6">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">وبلاگ اینستایار</h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">آخرین مقالات، آموزش‌ها و ترفندهای افزایش فالوور و بهینه‌سازی محتوا در اینستاگرام</p>
        </div>
        
        {/* Search Only - Removed Filter Tabs */}
        <div className="mb-12">
          <div className="w-full max-w-4xl mx-auto">
            <div className="relative">
              <Search className="absolute right-3 top-3 h-5 w-5 text-gray-400" />
              <Input
                placeholder="جستجو در مقالات..."
                className="pr-10 text-right"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="text-center py-20">
            <div className="w-16 h-16 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
            <p className="text-gray-600">در حال بارگذاری مقالات...</p>
          </div>
        ) : (
          <>
            {/* Featured Post */}
            {featuredPost && activeCategory === "all" && !searchTerm && (
              <div className="mb-16">
                <h2 className="text-2xl font-bold mb-6 border-b pb-2">مقاله ویژه</h2>
                <div className="bg-white rounded-lg overflow-hidden shadow-lg border border-gray-100">
                  <div className="md:flex">
                    <div className="md:w-1/2">
                      <img 
                        src={featuredPost.imageUrl} 
                        alt={featuredPost.title}
                        className="h-64 md:h-full w-full object-cover" 
                      />
                    </div>
                    <div className="md:w-1/2 p-6 md:p-8 flex flex-col justify-between">
                      <div>
                        <Badge className="mb-3 bg-indigo-100 text-indigo-800 hover:bg-indigo-200">{featuredPost.category}</Badge>
                        <h3 className="text-2xl font-bold mb-3 text-gray-900">{featuredPost.title}</h3>
                        <p className="text-gray-600 mb-6 line-clamp-3">{featuredPost.summary}</p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center text-gray-500 text-sm">
                          <Clock className="w-4 h-4 ml-1" />
                          <span>{featuredPost.readTime}</span>
                        </div>
                        <Link to={`/blog/${featuredPost.id}`}>
                          <Button>
                            ادامه مطلب
                            <ArrowRight className="mr-2 h-4 w-4" />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Posts Grid */}
            {filteredPosts.length === 0 ? (
              <div className="text-center py-20">
                <h3 className="text-xl font-semibold mb-2">نتیجه‌ای یافت نشد</h3>
                <p className="text-gray-600">متأسفانه مقاله‌ای با این عنوان یا دسته‌بندی یافت نشد. لطفاً جستجوی خود را تغییر دهید.</p>
              </div>
            ) : (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredPosts.map((post) => (
                  <motion.div 
                    key={post.id}
                    className="bg-white rounded-lg overflow-hidden shadow-sm border border-gray-100 hover:shadow-md transition-all"
                    whileHover={{ y: -5 }}
                  >
                    <Link to={`/blog/${post.id}`}>
                      <div className="h-52 overflow-hidden">
                        <img 
                          src={post.imageUrl} 
                          alt={post.title}
                          className="w-full h-full object-cover transition-transform hover:scale-105 duration-300" 
                        />
                      </div>
                    </Link>
                    <div className="p-6">
                      <div className="flex justify-between items-center mb-3">
                        <Badge className="bg-indigo-100 text-indigo-800 hover:bg-indigo-200">{post.category}</Badge>
                        <div className="flex items-center text-gray-500 text-sm">
                          <Clock className="w-4 h-4 ml-1" />
                          <span>{post.readTime}</span>
                        </div>
                      </div>
                      <Link to={`/blog/${post.id}`}>
                        <h3 className="text-xl font-bold mb-3 text-gray-900 hover:text-indigo-600 transition-colors line-clamp-2">{post.title}</h3>
                      </Link>
                      <p className="text-gray-600 mb-4 line-clamp-3">{post.summary}</p>
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">{post.publishedDate}</span>
                        <Link to={`/blog/${post.id}`}>
                          <Button variant="link" className="text-indigo-600 p-0 h-auto font-medium">
                            ادامه مطلب
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
          </>
        )}

        {/* Subscription CTA */}
        <div className="my-20 bg-indigo-50 rounded-xl p-8 md:p-12">
          <div className="md:flex items-center justify-between">
            <div className="md:w-2/3 mb-6 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-3">به خبرنامه اینستایار بپیوندید</h2>
              <p className="text-gray-600">آخرین مقالات، ترفندها و آموزش‌های اینستاگرام را مستقیماً در ایمیل خود دریافت کنید.</p>
            </div>
            <div className="flex">
              <Input
                placeholder="ایمیل خود را وارد کنید"
                className="rounded-l-none text-right"
              />
              <Button className="bg-indigo-600 hover:bg-indigo-700 rounded-r-none">
                عضویت
              </Button>
            </div>
          </div>
        </div>

        {/* Instagram CTA */}
        <div className="text-center mb-12">
          <h3 className="text-xl font-bold mb-4">ما را در اینستاگرام دنبال کنید</h3>
          <p className="text-gray-600 mb-6">آخرین اخبار، ترفندها و آموزش‌های اینستاگرام را در پیج اینستاگرام ما دنبال کنید.</p>
          <a
            href="https://instagram.com/instayar.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full hover:from-purple-600 hover:to-pink-600 transition-colors"
          >
            <Instagram className="mr-2 h-5 w-5" />
            صفحه اینستاگرام اینستایار
          </a>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-8 px-4 md:px-6 mt-12">
        <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">اینستایار</h3>
            <p className="mb-3 md:mb-4 text-sm md:text-base">دستیار هوشمند اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">لینک‌های مفید</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><Link to="/" className="hover:text-white">صفحه اصلی</Link></li>
              <li><Link to="/blog" className="hover:text-white">آموزش اینستاگرام</Link></li>
              <li><Link to="/pricing" className="hover:text-white">تعرفه‌ها</Link></li>
              <li><Link to="/contact" className="hover:text-white">تماس با ما</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">خدمات</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><Link to="/features" className="hover:text-white">ویژگی‌ها</Link></li>
              <li><Link to="/pricing" className="hover:text-white">تعرفه‌ها</Link></li>
              <li><Link to="/faq" className="hover:text-white">سؤالات متداول</Link></li>
              <li><Link to="/help" className="hover:text-white">مرکز راهنما</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">تماس با ما</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-8 pt-6 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Blog;
