
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { QuizResults } from "@/components/quiz/QuizResults";
import { generateQuiz, saveQuiz } from "@/services/quizService";
import type { QuizData, QuizFormData } from "@/types/quiz";
import { InfoBox } from "@/components/InfoBox";

export const QuizGenerator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quizData, setQuizData] = useState<QuizData | null>(null);
  const { toast } = useToast();
  const form = useForm<QuizFormData>();
  const { profile, refreshProfile } = useAuth();

  const onSubmit = async (data: QuizFormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "quiz", creditCost: 4 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      // Generate quiz data
      const result = await generateQuiz(data.subject);
      
      // Deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setQuizData(result);

      // Save quiz to database
      await saveQuiz(data.subject, result);

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "کوئیز تولید شد",
        description: "کوئیز شما با موفقیت تولید شد",
      });

    } catch (error) {
      toast({
        title: "خطا",
        description: "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6" dir="rtl">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="subject"
            render={({ field }) => (
              <FormItem>
                <FormLabel>موضوع</FormLabel>
                <FormControl>
                  <Input placeholder="موضوع یا حوزه فعالیت شما" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            تولید کوئیز
          </Button>
        </form>
      </Form>

      {quizData ? (
        <QuizResults quizData={quizData} />
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>سازنده کوئیز هوشمند</strong> به شما امکان می‌دهد تا در کمترین زمان ممکن، کوئیز‌های حرفه‌ای و تعاملی برای پیج اینستاگرام خود ایجاد کنید. 
            با استفاده از این ابزار، میزان تعامل فالوورهای خود را به شکل چشمگیری افزایش دهید و وفاداری آن‌ها را تقویت کنید. فقط موضوع مورد نظر خود را وارد کنید!
          </p>
        </InfoBox>
      )}
    </div>
  );
};
