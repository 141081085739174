
import {
  Hash,
  List,
  MessageSquare,
  Layers,
  HelpCircle,
  MessageCircle,
  Globe,
  Edit,
  Calendar,
  LineChart,
  Repeat,
  Megaphone,
  Image,
} from "lucide-react";
import { BioAnalysis } from "@/components/BioAnalysis";
import { CaptionGenerator } from "@/components/CaptionGenerator";
import { HashtagGenerator } from "@/components/HashtagGenerator";
import { CarouselPlanner } from "@/components/CarouselPlanner";
import { QuizGenerator } from "@/components/QuizGenerator";
import { DMConsultant } from "@/components/DMConsultant";
import { OutreachGenerator } from "@/components/OutreachGenerator";
import { StoryIdeaGenerator } from "@/components/StoryIdeaGenerator";
import { ContentCalendar } from "@/components/ContentCalendar";
import { HolidayCampaignGenerator } from "@/components/HolidayCampaignGenerator";
import { CaptionEditor } from "@/components/CaptionEditor";
import { TextTranslator } from "@/components/TextTranslator";
import { GrowthMentor } from "@/components/GrowthMentor";
import { ContentRepurposer } from "@/components/ContentRepurposer";
import { PromotionAdvisor } from "@/components/PromotionAdvisor";
import { ImageGenerator } from "@/components/ImageGenerator";
import type { Tool } from "@/types/tool";

export const tools: Tool[] = [
  { id: "bio", title: "تحلیل و بهینه‌سازی بیو", shortTitle: "بیو", icon: List, component: BioAnalysis, creditCost: 6 },
  { id: "caption", title: "تولید کپشن هوشمند", shortTitle: "کپشن", icon: MessageSquare, component: CaptionGenerator, creditCost: 4 },
  { id: "hashtag", title: "تولید هشتگ هوشمند", shortTitle: "هشتگ", icon: Hash, component: HashtagGenerator, creditCost: 2 },
  { id: "carousel", title: "طراحی کروسل محتوا", shortTitle: "کروسل", icon: Layers, component: CarouselPlanner, creditCost: 5 },
  { id: "quiz", title: "تولید کوئیز", shortTitle: "کوئیز", icon: HelpCircle, component: QuizGenerator, creditCost: 4 },
  { id: "dm", title: "مشاور پاسخ به پیام‌ها", shortTitle: "پیام", icon: MessageCircle, component: DMConsultant, creditCost: 3 },
  { id: "outreach", title: "پیام نویس", shortTitle: "پیام نویس", icon: MessageCircle, component: OutreachGenerator, creditCost: 4 },
  { id: "story", title: "ایده‌پرداز استوری", shortTitle: "استوری", icon: Layers, component: StoryIdeaGenerator, creditCost: 3 },
  { id: "calendar", title: "تقویم محتوایی هوشمند", shortTitle: "تقویم", icon: Calendar, component: ContentCalendar, creditCost: 6 },
  { id: "holiday", title: "ایده‌پرداز رویدادها", shortTitle: "رویداد", icon: Calendar, component: HolidayCampaignGenerator, creditCost: 4 },
  { id: "editor", title: "ویرایشگر متن و کپشن", shortTitle: "ویرایش", icon: Edit, component: CaptionEditor, creditCost: 2 },
  { id: "translator", title: "مترجم متن", shortTitle: "ترجمه", icon: Globe, component: TextTranslator, creditCost: 2 },
  { id: "growth", title: "مشاور رشد صفحه", shortTitle: "رشد", icon: LineChart, component: GrowthMentor, creditCost: 11 },
  { id: "repurpose", title: "بازنویسی محتوا", shortTitle: "بازنویسی", icon: Repeat, component: ContentRepurposer, creditCost: 5 },
  { id: "promotion", title: "مشاور تبلیغات", shortTitle: "تبلیغ", icon: Megaphone, component: PromotionAdvisor, creditCost: 8 },
  { id: "image", title: "تولید تصویر هوشمند", shortTitle: "تصویر", icon: Image, component: ImageGenerator, creditCost: 15 }
];
