
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { HelpOverlay } from "@/components/HelpOverlay";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Search, UserPlus, UserMinus, RefreshCw } from "lucide-react";

interface AdminUser {
  id: string;
  user_id: string;
  role: string;
  created_at: string;
  email?: string;
  full_name?: string;
}

interface SiteSettings {
  payments_enabled: boolean;
  registration_enabled: boolean;
  site_online: boolean;
}

const AdminSettings = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [isAddAdminDialogOpen, setIsAddAdminDialogOpen] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [settings, setSettings] = useState<SiteSettings>({
    payments_enabled: true,
    registration_enabled: true,
    site_online: true
  });
  const [loadingSettings, setLoadingSettings] = useState(true);
  
  const fetchAdminUsers = async () => {
    setLoading(true);
    try {
      const { data: adminData, error: adminError } = await supabase
        .from("admin_users")
        .select("*")
        .order("created_at", { ascending: false });
        
      if (adminError) {
        console.error("خطای دریافت ادمین‌ها:", adminError);
        throw adminError;
      }
      
      if (!adminData || adminData.length === 0) {
        setAdminUsers([]);
        setLoading(false);
        return;
      }
      
      const userIds = adminData.map(admin => admin.user_id);
      
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("id, email");
        
      if (userError) {
        console.error("خطای دریافت اطلاعات کاربران:", userError);
        throw userError;
      }
      
      const { data: profileData, error: profileError } = await supabase
        .from("profiles")
        .select("id, full_name")
        .in("id", userIds);
        
      if (profileError) {
        console.error("خطای دریافت پروفایل‌ها:", profileError);
        throw profileError;
      }
      
      const emailMap: Record<string, string> = {};
      const nameMap: Record<string, string> = {};
      
      userData?.forEach(user => {
        emailMap[user.id] = user.email;
      });
      
      profileData?.forEach(profile => {
        nameMap[profile.id] = profile.full_name || "";
      });
      
      const formattedAdmins = adminData.map(admin => ({
        ...admin,
        email: emailMap[admin.user_id] || "ایمیل نامشخص",
        full_name: nameMap[admin.user_id] || ""
      }));
      
      setAdminUsers(formattedAdmins);
    } catch (error) {
      console.error("خطا در دریافت لیست ادمین‌ها:", error);
      toast.error("خطا در بارگذاری لیست ادمین‌ها");
    } finally {
      setLoading(false);
    }
  };
  
  const fetchSettings = async () => {
    setLoadingSettings(true);
    try {
      const { data: result, error } = await supabase
        .rpc('execute_sql', {
          sql_query: "SELECT payments_enabled, registration_enabled, site_online FROM site_settings WHERE id = 1"
        });
        
      if (error) {
        console.error("خطا در دریافت تنظیمات:", error);
        // تنظیمات پیش‌فرض اگر هیچی یافت نشد
        setSettings({
          payments_enabled: true,
          registration_enabled: true,
          site_online: true
        });
      } else if (result && Array.isArray(result) && result.length > 0) {
        // ابتدا مطمئن شویم که یک شی نتیجه داریم، سپس به طور ایمن مقادیر را استخراج کنیم
        const rawSettings = result[0];
        
        // استفاده از عملگر as برای تبدیل به Record
        const settingsObj = rawSettings as Record<string, unknown>;
        
        // ایجاد صریح یک شی تنظیمات با انواع صحیح
        const settingsData: SiteSettings = {
          payments_enabled: typeof settingsObj.payments_enabled === 'boolean' ? settingsObj.payments_enabled : true,
          registration_enabled: typeof settingsObj.registration_enabled === 'boolean' ? settingsObj.registration_enabled : true,
          site_online: typeof settingsObj.site_online === 'boolean' ? settingsObj.site_online : true
        };
        
        setSettings(settingsData);
      }
    } catch (error) {
      console.error("خطا در دریافت تنظیمات:", error);
      toast.error("خطا در بارگذاری تنظیمات");
    } finally {
      setLoadingSettings(false);
    }
  };
  
  const updateSetting = async (setting: keyof SiteSettings, value: boolean) => {
    try {
      const { error } = await supabase
        .rpc('execute_sql', {
          sql_query: `UPDATE site_settings SET ${setting} = ${value} WHERE id = 1`
        });
        
      if (error) {
        console.error("خطای به‌روزرسانی تنظیمات:", error);
        throw error;
      }
      
      setSettings(prev => ({ ...prev, [setting]: value }));
      toast.success("تنظیمات با موفقیت به‌روزرسانی شد");
    } catch (error) {
      console.error("خطا در به‌روزرسانی تنظیمات:", error);
      toast.error("خطا در به‌روزرسانی تنظیمات");
    }
  };
  
  const openAddAdminDialog = () => {
    setAdminEmail("");
    setIsAddAdminDialogOpen(true);
  };
  
  const addAdmin = async () => {
    if (!adminEmail) {
      toast.error("لطفا ایمیل کاربر را وارد کنید");
      return;
    }
    
    try {
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("id")
        .eq("email", adminEmail)
        .single();
        
      if (userError) {
        console.error("خطای جستجوی کاربر:", userError);
        if (userError.code === "PGRST116") {
          toast.error("کاربری با این ایمیل یافت نشد");
        } else {
          throw userError;
        }
        return;
      }
      
      const { data: existingAdmin, error: checkError } = await supabase
        .from("admin_users")
        .select("id")
        .eq("user_id", userData.id)
        .maybeSingle();
        
      if (checkError && checkError.code !== "PGRST116") {
        console.error("خطای بررسی وجود ادمین:", checkError);
        throw checkError;
      }
      
      if (existingAdmin) {
        toast.error("این کاربر در حال حاضر ادمین است");
        return;
      }
      
      const { error } = await supabase
        .from("admin_users")
        .insert({
          user_id: userData.id,
          role: "admin"
        });
        
      if (error) {
        console.error("خطای افزودن ادمین:", error);
        throw error;
      }
      
      toast.success("کاربر با موفقیت به عنوان ادمین اضافه شد");
      setIsAddAdminDialogOpen(false);
      setAdminEmail("");
      fetchAdminUsers();
    } catch (error) {
      console.error("خطا در افزودن ادمین:", error);
      toast.error("خطا در افزودن ادمین");
    }
  };
  
  const removeAdmin = async (userId: string) => {
    try {
      const { error } = await supabase
        .from("admin_users")
        .delete()
        .eq("user_id", userId);
        
      if (error) {
        console.error("خطای حذف ادمین:", error);
        throw error;
      }
      
      toast.success("ادمین با موفقیت حذف شد");
      fetchAdminUsers();
    } catch (error) {
      console.error("خطا در حذف ادمین:", error);
      toast.error("خطا در حذف ادمین");
    }
  };
  
  useEffect(() => {
    fetchAdminUsers();
    fetchSettings();
  }, []);
  
  const filteredAdmins = adminUsers.filter(
    admin => 
      admin.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (admin.full_name && admin.full_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("fa-IR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };
  
  return (
    <div className="space-y-6" dir="rtl">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-white">تنظیمات مدیریت</h2>
        <Button variant="outline" onClick={() => setShowHelp(true)}>
          راهنما
        </Button>
      </div>
      
      <Card className="bg-gray-800 border-gray-700">
        <CardHeader>
          <CardTitle className="text-white">تنظیمات سایت</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {loadingSettings ? (
            <div className="space-y-4">
              <Skeleton className="h-8 w-full bg-gray-700" />
              <Skeleton className="h-8 w-full bg-gray-700" />
              <Skeleton className="h-8 w-full bg-gray-700" />
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-white">فعال بودن پرداخت‌ها</p>
                  <p className="text-sm text-gray-400">امکان پرداخت در سایت را فعال یا غیرفعال کنید</p>
                </div>
                <Switch 
                  checked={settings.payments_enabled} 
                  onCheckedChange={(checked) => updateSetting("payments_enabled", checked)}
                />
              </div>
              
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-white">فعال بودن ثبت‌نام</p>
                  <p className="text-sm text-gray-400">امکان ثبت‌نام کاربران جدید را فعال یا غیرفعال کنید</p>
                </div>
                <Switch 
                  checked={settings.registration_enabled} 
                  onCheckedChange={(checked) => updateSetting("registration_enabled", checked)}
                />
              </div>
              
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-white">سایت آنلاین</p>
                  <p className="text-sm text-gray-400">سایت را در حالت آنلاین یا حالت تعمیر و نگهداری قرار دهید</p>
                </div>
                <Switch 
                  checked={settings.site_online} 
                  onCheckedChange={(checked) => updateSetting("site_online", checked)}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      
      <Card className="bg-gray-800 border-gray-700">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="text-white">مدیریت ادمین‌ها</CardTitle>
          <Button 
            onClick={openAddAdminDialog}
            className="flex items-center gap-2"
          >
            <UserPlus className="h-4 w-4" />
            <span>افزودن ادمین</span>
          </Button>
        </CardHeader>
        <CardContent>
          <div className="flex items-center gap-2 mb-4">
            <div className="relative flex-1">
              <Search className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="جستجوی ادمین..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-4 pr-10"
              />
            </div>
            <Button 
              variant="outline" 
              size="icon" 
              onClick={fetchAdminUsers} 
              disabled={loading}
            >
              <RefreshCw className={`h-4 w-4 ${loading ? "animate-spin" : ""}`} />
            </Button>
          </div>
          
          <div className="space-y-3">
            {loading ? (
              Array(3).fill(0).map((_, i) => (
                <div key={i} className="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-40 bg-gray-600" />
                    <Skeleton className="h-3 w-32 bg-gray-600" />
                  </div>
                  <Skeleton className="h-9 w-9 rounded-md bg-gray-600" />
                </div>
              ))
            ) : filteredAdmins.length === 0 ? (
              <div className="text-center py-8 text-gray-400">
                هیچ ادمینی یافت نشد.
              </div>
            ) : (
              filteredAdmins.map((admin) => (
                <div key={admin.id} className="bg-gray-700 p-4 rounded-lg flex justify-between items-center">
                  <div>
                    <div className="font-medium text-white">{admin.email}</div>
                    <div className="text-sm text-gray-400">
                      {admin.full_name && <span className="ml-2">{admin.full_name}</span>}
                      <span>افزوده شده در {formatDate(admin.created_at)}</span>
                    </div>
                  </div>
                  <Button 
                    variant="ghost" 
                    size="icon"
                    onClick={() => removeAdmin(admin.user_id)}
                    title="حذف ادمین"
                    className="text-red-400 hover:text-red-300 hover:bg-red-900/20"
                  >
                    <UserMinus className="h-5 w-5" />
                  </Button>
                </div>
              ))
            )}
          </div>
        </CardContent>
      </Card>
      
      <Dialog open={isAddAdminDialogOpen} onOpenChange={setIsAddAdminDialogOpen}>
        <DialogContent className="bg-gray-800 text-white border-gray-700 sm:max-w-md" dir="rtl">
          <DialogHeader>
            <DialogTitle>افزودن ادمین جدید</DialogTitle>
          </DialogHeader>
          
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">ایمیل کاربر</label>
              <Input
                value={adminEmail}
                onChange={(e) => setAdminEmail(e.target.value)}
                placeholder="ایمیل کاربر موردنظر را وارد کنید"
                className="bg-gray-700 border-gray-600"
              />
            </div>
            
            <div className="flex justify-end gap-2 pt-2">
              <Button type="button" variant="outline" onClick={() => setIsAddAdminDialogOpen(false)}>
                انصراف
              </Button>
              <Button 
                onClick={addAdmin}
                disabled={!adminEmail}
              >
                افزودن ادمین
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      
      <HelpOverlay
        isVisible={showHelp}
        onClose={() => setShowHelp(false)}
        title="راهنمای تنظیمات مدیریت"
        description="در این بخش می‌توانید ادمین‌های سایت را مدیریت کنید."
        steps={[
          "برای افزودن ادمین جدید، روی دکمه «افزودن ادمین» کلیک کنید و کاربر مورد نظر را انتخاب کنید.",
          "برای حذف یک ادمین، روی آیکون حذف در سمت راست نام آن کلیک کنید.",
          "می‌توانید از بخش جستجو برای پیدا کردن ادمین‌ها استفاده کنید."
        ]}
        tips={[
          "افزودن ادمین به معنای دادن دسترسی کامل به تمام بخش‌های مدیریتی است.",
          "با دقت ادمین‌ها را انتخاب کنید و فقط به افراد مورد اعتماد دسترسی ادمین بدهید."
        ]}
      />
    </div>
  );
};

export default AdminSettings;
