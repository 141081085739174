
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/lib/auth";
import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

type AuthMode = "login" | "signup" | "forgotPassword";

const AuthContainer = () => {
  const [mode, setMode] = useState<AuthMode>("login");
  const navigate = useNavigate();
  const { session } = useAuth();

  useEffect(() => {
    if (session) {
      navigate("/app");
    }
    
    document.body.classList.add('no-scroll-page');
    
    return () => {
      document.body.classList.remove('no-scroll-page');
    };
  }, [session, navigate]);

  const toggleMode = (newMode: AuthMode) => {
    setMode(newMode);
  };

  return (
    <div 
      className="fixed inset-0 w-screen h-screen flex flex-col items-center justify-center overflow-auto py-12 px-4"
      dir="rtl" 
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/lovable-uploads/9b847d69-0e42-4191-b92a-492215e504bb.png")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div 
        className="w-full max-w-md mx-auto p-6 rounded-xl relative mb-6 md:mb-8"
        style={{
          backgroundColor: 'rgba(17, 17, 17, 0.6)',
          backdropFilter: 'blur(20px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
        }}
      >
        <div className="text-center mb-6">
          <h1 
            className="text-xl font-bold bg-gradient-to-l from-indigo-400 to-purple-400 text-transparent bg-clip-text cursor-pointer mb-2" 
            onClick={() => navigate("/")}
          >
            اینستایار
          </h1>
          <h2 className="text-xl font-bold text-white mb-1">
            {mode === "login" ? "ورود به اینستایار" : 
             mode === "signup" ? "ثبت‌نام در اینستایار" : 
             "بازیابی رمز عبور"}
          </h2>
          <p className="text-gray-300 text-sm">
            {mode === "login" ? "برای استفاده از ابزارهای هوشمند اینستایار، وارد شوید" : 
             mode === "signup" ? "حساب کاربری جدید ایجاد کنید" : 
             "ایمیل خود را برای بازیابی رمز عبور وارد کنید"}
          </p>
        </div>

        <div className="space-y-4">
          {mode === "login" && (
            <LoginForm onModeChange={toggleMode} />
          )}
          
          {mode === "signup" && (
            <SignupForm onModeChange={toggleMode} />
          )}
          
          {mode === "forgotPassword" && (
            <ForgotPasswordForm onModeChange={toggleMode} />
          )}
        </div>

        <div className="mt-6 pt-4 border-t border-white/10 text-center text-gray-400 text-xs">
          <p>با ورود به اینستایار، شما <a href="/terms" className="text-indigo-300 hover:text-indigo-200">شرایط استفاده</a> و <a href="/privacy" className="text-indigo-300 hover:text-indigo-200">سیاست حریم خصوصی</a> ما را می‌پذیرید.</p>
        </div>
      </div>

      <div className="mt-2 text-center">
        <Button 
          variant="ghost" 
          className="text-white hover:bg-white/10 text-sm h-9" 
          onClick={() => navigate("/")}
        >
          بازگشت به صفحه اصلی
          <ArrowRight className="mr-2 h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default AuthContainer;
