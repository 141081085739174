
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";

export const GrowthMentor = () => {
  const [concern, setConcern] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [analysis, setAnalysis] = useState<any>(null);
  const { toast } = useToast();
  const { profile, refreshProfile } = useAuth();

  const handleAnalysis = async () => {
    if (!concern) {
      toast({
        title: "خطا",
        description: "لطفاً مشکل یا نگرانی خود را وارد کنید",
        variant: "destructive",
      });
      return;
    }

    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "growth", creditCost: 11 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      console.log('Calling analyze-growth function with concern:', concern);
      
      // Call the function first to ensure it works before deducting credits
      const { data, error } = await supabase.functions.invoke("analyze-growth", {
        body: { concern },
      });

      if (error) {
        console.error('Supabase function error:', error);
        throw new Error(error.message || 'خطا در ارتباط با سرور');
      }

      console.log('Received data from analyze-growth:', data);
      
      // Check if data contains error property
      if (data && data.error) {
        throw new Error(data.error);
      }
      
      if (!data || !data.problems || !data.plans) {
        throw new Error('پاسخ دریافتی از سرور معتبر نیست');
      }

      // Now deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setAnalysis(data);
      
      try {
        const { error: saveError } = await supabase
          .from("growth_consultations")
          .insert({
            page_url: concern, // Using concern as page_url since it may contain URL or general concern
            problems: data.problems,
            improvement_plans: data.plans,
          });

        if (saveError) {
          console.error("Error saving growth consultation:", saveError);
          // Continue execution even if saving fails
        }
      } catch (saveException) {
        console.error("Exception saving growth consultation:", saveException);
        // Continue execution even if saving fails
      }

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "تحلیل انجام شد",
        description: "پیشنهادات رشد پیج شما آماده است",
      });
    } catch (error: any) {
      console.error("Error in growth analysis:", error);
      toast({
        title: "خطا",
        description: error.message || "مشکلی در تحلیل پیش آمد",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4" dir="rtl">
      <Textarea
        placeholder="هر سوالی در مورد رشد صفحه خود دارید بپرسید و مشاوره بگیرید. این موضوع شامل نگرانی ها، ایده پردازی و چیدن برنامه رشد و مسائل دیگر میشود..."
        value={concern}
        onChange={(e) => setConcern(e.target.value)}
        className="min-h-[100px]"
      />
      <Button 
        onClick={handleAnalysis} 
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            در حال تحلیل...
          </>
        ) : (
          "دریافت مشاوره رشد"
        )}
      </Button>

      {analysis ? (
        <div className="space-y-4">
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <div>
                  <h3 className="font-semibold mb-2">مشکلات شناسایی شده:</h3>
                  <ul className="list-disc list-inside space-y-1">
                    {analysis.problems.map((problem: string, index: number) => (
                      <li key={index} className="text-gray-600">{problem}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">راهکارهای پیشنهادی:</h3>
                  <ul className="list-disc list-inside space-y-1">
                    {analysis.plans.map((plan: string, index: number) => (
                      <li key={index} className="text-gray-600">{plan}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>مشاور رشد هوشمند</strong> مانند یک مشاور شخصی حرفه‌ای به سوالات و چالش‌های شما در زمینه رشد و توسعه پیج اینستاگرام پاسخ می‌دهد. 
            با استفاده از این ابزار، مشکلات پیج خود را شناسایی کنید و راهکارهای عملی برای افزایش فالوور، تعامل و فروش دریافت کنید. 
            این ابزار قدرتمند با هزینه‌ای بسیار کمتر از یک مشاوره حضوری، راهنمایی‌های تخصصی ارائه می‌دهد.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
