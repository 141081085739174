
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CreditCard, Zap, Award, Sparkles, AlertCircle, CheckCircle, Loader2, TicketIcon } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";

interface CreditsTabProps {
  credits: number;
  onPurchaseCredits: () => void;
}

const CreditsTab = ({
  credits
}: CreditsTabProps) => {
  const {
    session, refreshProfile
  } = useAuth();
  const {
    toast
  } = useToast();
  const [loading, setLoading] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [couponCode, setCouponCode] = useState("");
  const [redeemingCoupon, setRedeemingCoupon] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);

  const handlePlanPurchase = async (planId: string) => {
    if (!session?.user?.id) {
      setError("برای خرید اعتبار باید وارد حساب کاربری خود شوید.");
      toast({
        title: "خطا",
        description: "برای خرید اعتبار باید وارد حساب کاربری خود شوید.",
        variant: "destructive"
      });
      return;
    }
    try {
      setLoading(planId);
      setError(null);
      let amount = 0;
      switch (planId) {
        case "basic":
          amount = 500000; // ۵۰,۰۰۰ تومان به ریال
          break;
        case "admin":
          amount = 1500000; // ۱۵۰,۰۰۰ تومان به ریال
          break;
        case "pro":
          amount = 2500000; // ۲۵۰,۰۰۰ تومان به ریال
          break;
        default:
          throw new Error("بسته نامعتبر است");
      }

      // درخواست پرداخت از طریق Edge Function
      const {
        data,
        error
      } = await supabase.functions.invoke("zibal-request", {
        body: {
          packageName: planId,
          amount: amount,
          userId: session.user.id
        }
      });
      if (error) {
        throw new Error(error.message || "خطا در برقراری ارتباط با سرور پرداخت");
      }
      if (!data.success) {
        throw new Error(data.message || "خطا در فرآیند پرداخت");
      }

      // هدایت به صفحه پرداخت زیبال
      window.location.href = data.paymentUrl;
    } catch (err: any) {
      const errorMessage = err.message || "خطا در فرآیند پرداخت. لطفا دوباره تلاش کنید.";
      setError(errorMessage);
      toast({
        title: "خطا در پرداخت",
        description: errorMessage,
        variant: "destructive"
      });
      console.error("خطا در پرداخت:", err);
    } finally {
      setLoading(null);
    }
  };

  const handleRedeemCoupon = async () => {
    if (!couponCode.trim()) {
      toast({
        title: "خطا",
        description: "لطفاً کد کوپن را وارد کنید",
        variant: "destructive"
      });
      return;
    }

    if (!session?.user?.id) {
      toast({
        title: "خطا",
        description: "برای استفاده از کوپن باید وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }

    try {
      setRedeemingCoupon(true);
      setCouponSuccess(false);
      setError(null);

      const { data, error } = await supabase.rpc('redeem_coupon', {
        p_coupon_code: couponCode.trim(),
        p_user_id: session.user.id
      });

      if (error) throw error;

      // تبدیل data به نوع مناسب برای بررسی وضعیت موفقیت
      const result = data as { success: boolean; message: string; credits_awarded?: number };

      if (!result.success) {
        setError(result.message);
        toast({
          title: "عدم موفقیت",
          description: result.message,
          variant: "destructive"
        });
        return;
      }

      // کوپن با موفقیت اعمال شد
      setCouponSuccess(true);
      setCouponCode("");
      toast({
        title: "موفقیت‌آمیز",
        description: `${result.message} - ${result.credits_awarded} اعتبار به حساب شما اضافه شد`,
        variant: "default"
      });

      // بروزرسانی اطلاعات پروفایل کاربر
      await refreshProfile();

    } catch (err: any) {
      console.error("خطا در بازخرید کوپن:", err);
      setError(err.message || "خطا در بازخرید کوپن. لطفا دوباره تلاش کنید.");
      toast({
        title: "خطا",
        description: err.message || "خطا در بازخرید کوپن. لطفا دوباره تلاش کنید.",
        variant: "destructive"
      });
    } finally {
      setRedeemingCoupon(false);
    }
  };

  return <Card>
      <CardHeader>
        <CardTitle>اعتبار شما</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="bg-indigo-50 border border-indigo-100 rounded-lg p-6 mb-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-600 mb-1">اعتبار باقیمانده</p>
              <p className="text-3xl font-bold text-indigo-700">{credits || 0} اعتبار</p>
            </div>
            <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center border border-indigo-100">
              <CreditCard className="h-8 w-8 text-indigo-600" />
            </div>
          </div>
        </div>

        {/* بخش بازخرید کوپن */}
        <div className="bg-gray-50 border border-gray-100 rounded-lg p-6 mb-6">
          <h3 className="text-lg font-bold mb-4 flex items-center">
            <TicketIcon className="h-5 w-5 text-indigo-600 ml-2" />
            استفاده از کد تخفیف
          </h3>
          
          <div className="flex flex-col md:flex-row gap-3">
            <Input 
              placeholder="کد تخفیف خود را وارد کنید"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className="flex-1"
              dir="ltr" // کد‌ها معمولاً LTR هستند
            />
            <Button 
              onClick={handleRedeemCoupon} 
              disabled={redeemingCoupon || !couponCode.trim()}
              className="bg-indigo-600 hover:bg-indigo-700"
            >
              {redeemingCoupon ? (
                <>
                  <Loader2 className="h-4 w-4 ml-2 animate-spin" />
                  در حال اعمال...
                </>
              ) : (
                'اعمال کد'
              )}
            </Button>
          </div>

          {couponSuccess && (
            <div className="mt-4 bg-green-50 border border-green-100 rounded-lg p-3 flex items-start gap-3">
              <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
              <p className="text-green-600 text-sm">کد تخفیف با موفقیت اعمال شد و اعتبار به حساب شما افزوده شد.</p>
            </div>
          )}

          {error && (
            <div className="mt-4 bg-red-50 border border-red-100 rounded-lg p-3 flex items-start gap-3">
              <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 flex-shrink-0" />
              <p className="text-red-600 text-sm">{error}</p>
            </div>
          )}
        </div>

        {error && <div className="mb-6 bg-red-50 border border-red-100 rounded-lg p-4 flex items-start gap-3">
            <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 flex-shrink-0" />
            <p className="text-red-600 text-sm">{error}</p>
          </div>}

        <Separator className="mb-6" />

        <div className="space-y-6">
          <div>
            <p className="text-gray-600 mb-4">با اعتبار بیشتر، می‌توانید از ابزارهای بیشتری استفاده کنید. یکی از بسته‌های زیر را انتخاب کنید(فیلترشکن خود را خاموش نمایید)</p>
            
            <div className="space-y-4">
              {/* Basic Plan */}
              <Card className="border-2 border-gray-200 hover:border-indigo-300 transition-all duration-200">
                <CardContent className="pt-6">
                  <div className="flex justify-between items-center mb-4">
                    <div>
                      <h3 className="text-lg font-bold flex items-center">
                        <Zap className="h-5 w-5 text-blue-500 ml-2" />
                        بسته پایه
                      </h3>
                      <p className="text-gray-500">۵۰ اعتبار</p>
                    </div>
                    <p className="text-xl font-bold">۵۰,۰۰۰ تومان</p>
                  </div>
                  <Button onClick={() => handlePlanPurchase('basic')} className="w-full bg-blue-500 hover:bg-blue-600" disabled={loading !== null}>
                    {loading === 'basic' ? <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        در حال پردازش...
                      </> : 'خرید بسته پایه'}
                  </Button>
                </CardContent>
              </Card>
              
              {/* Admin Plan */}
              <Card className="border-2 border-indigo-300 hover:border-indigo-500 transition-all duration-200 relative">
                <div className="absolute -top-3 right-4 bg-indigo-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                  محبوب
                </div>
                <CardContent className="pt-6">
                  <div className="flex justify-between items-center mb-4">
                    <div>
                      <h3 className="text-lg font-bold flex items-center">
                        <Award className="h-5 w-5 text-indigo-500 ml-2" />
                        بسته مدیریتی
                      </h3>
                      <p className="text-gray-500">۲۰۰ اعتبار</p>
                    </div>
                    <p className="text-xl font-bold">۱۵۰,۰۰۰ تومان</p>
                  </div>
                  <Button onClick={() => handlePlanPurchase('admin')} className="w-full bg-indigo-600 hover:bg-indigo-700" disabled={loading !== null}>
                    {loading === 'admin' ? <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        در حال پردازش...
                      </> : 'خرید بسته مدیریتی'}
                  </Button>
                </CardContent>
              </Card>
              
              {/* Pro Plan */}
              <Card className="border-2 border-amber-200 hover:border-amber-400 transition-all duration-200 relative">
                <div className="absolute -top-3 right-4 bg-green-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                  بیشترین تخفیف
                </div>
                <CardContent className="pt-6">
                  <div className="flex justify-between items-center mb-4">
                    <div>
                      <h3 className="text-lg font-bold flex items-center">
                        <Sparkles className="h-5 w-5 text-amber-500 ml-2" />
                        بسته حرفه‌ای
                      </h3>
                      <p className="text-gray-500">۵۰۰ اعتبار</p>
                    </div>
                    <p className="text-xl font-bold">۲۵۰,۰۰۰ تومان</p>
                  </div>
                  <Button onClick={() => handlePlanPurchase('pro')} className="w-full bg-amber-500 hover:bg-amber-600" disabled={loading !== null}>
                    {loading === 'pro' ? <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        در حال پردازش...
                      </> : 'خرید بسته حرفه‌ای'}
                  </Button>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>;
};
export default CreditsTab;
