
import * as React from "react"
import { securityUtils } from "@/lib/utils"
import { Textarea } from "@/components/ui/textarea"

interface SecureTextareaProps extends Omit<React.ComponentProps<"textarea">, "ref" | "onChange"> {
  onSanitizedChange?: (value: string) => void;
  allowHTML?: boolean;
}

const SecureTextarea = React.forwardRef<HTMLTextAreaElement, SecureTextareaProps>(
  ({ className, maxLength = 3000, onSanitizedChange, allowHTML = false, ...props }, ref) => {
    const [sanitizedValue, setSanitizedValue] = React.useState<string>('');
    
    // مدیریت تغییرات ورودی و پاکسازی
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const rawValue = e.target.value;
      
      // انتخاب روش پاکسازی بر اساس اینکه آیا HTML مجاز است یا خیر
      const sanitized = allowHTML 
        ? securityUtils.sanitizeHTML(rawValue)
        : securityUtils.sanitizeString(rawValue);
      
      setSanitizedValue(sanitized);
      
      // فراخوانی callback با مقدار پاکسازی شده
      if (onSanitizedChange) {
        onSanitizedChange(sanitized);
      }
    };
    
    return (
      <Textarea
        maxLength={maxLength}
        onChange={handleChange}
        value={sanitizedValue}
        className={className}
        ref={ref}
        {...props}
      />
    );
  }
);

SecureTextarea.displayName = "SecureTextarea";

export { SecureTextarea };
