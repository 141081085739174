
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/lib/auth';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader2, Plus, RefreshCw, Search, Check, X, Ticket, Edit, Trash2, ToggleRight, ToggleLeft } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useToast } from '@/hooks/use-toast';

// تعریف تایپ برای کوپن
interface Coupon {
  id: string;
  code: string;
  credit_amount: number;
  is_active: boolean;
  created_at: string;
  created_by: string;
  usage_limit: number;
  used_count: number;
}

// تایپ برای استفاده از کوپن
interface CouponRedemption {
  id: string;
  coupon_id: string;
  user_id: string;
  redeemed_at: string;
  credits_awarded: number;
  coupon_code?: string;
  user_email?: string;
}

const AdminCoupons = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [filteredCoupons, setFilteredCoupons] = useState<Coupon[]>([]);
  const [redemptions, setRedemptions] = useState<CouponRedemption[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreatingCoupon, setIsCreatingCoupon] = useState(false);
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentTab, setCurrentTab] = useState('active');
  
  // فرم ایجاد کوپن جدید
  const [newCoupon, setNewCoupon] = useState({
    code: '',
    creditAmount: 10,
    usageLimit: 1
  });
  
  const { session } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  // بررسی وضعیت دسترسی ادمین
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!session) {
        navigate('/admin/auth');
        return;
      }
      
      try {
        const { data, error } = await supabase.rpc('is_admin', {
          user_uuid: session.user.id
        });
          
        if (error || !data) {
          navigate('/');
          return;
        }
        
        setIsAdmin(true);
        fetchCoupons();
        fetchRedemptions();
      } catch (error) {
        console.error('خطا در بررسی وضعیت ادمین:', error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    
    checkAdminStatus();
  }, [session, navigate]);

  // دریافت لیست کوپن‌ها
  const fetchCoupons = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('coupons')
        .select('*')
        .order('created_at', { ascending: false });
        
      if (error) throw error;
      
      setCoupons(data || []);
      filterCoupons(data || [], currentTab, searchTerm);
    } catch (error) {
      console.error('خطا در دریافت کوپن‌ها:', error);
      toast({
        title: 'خطا',
        description: 'مشکلی در دریافت لیست کوپن‌ها به وجود آمد',
        variant: 'destructive'
      });
    } finally {
      setLoading(false);
    }
  };

  // دریافت لیست استفاده از کوپن‌ها - اصلاح شده
  const fetchRedemptions = async () => {
    try {
      // کوئری اصلاح شده که فقط از جدول coupon_redemptions استفاده می‌کند
      const { data: redemptionsData, error: redemptionsError } = await supabase
        .from('coupon_redemptions')
        .select('*')
        .order('redeemed_at', { ascending: false });
        
      if (redemptionsError) throw redemptionsError;
      
      // دریافت کد کوپن‌ها برای هر کوپن_آیدی
      const couponIds = redemptionsData.map((item: any) => item.coupon_id);
      
      if (couponIds.length > 0) {
        const { data: couponsData, error: couponsError } = await supabase
          .from('coupons')
          .select('id, code')
          .in('id', couponIds);
          
        if (couponsError) throw couponsError;
        
        // تبدیل آرایه به یک Map برای جستجوی سریع
        const couponMap = new Map();
        couponsData.forEach((coupon: any) => {
          couponMap.set(coupon.id, coupon.code);
        });
        
        // دریافت ایمیل کاربران
        const userIds = redemptionsData.map((item: any) => item.user_id);
        
        if (userIds.length > 0) {
          const { data: authData, error: authError } = await supabase.auth.admin.listUsers();
          
          if (!authError && authData) {
            // تبدیل داده‌های کاربران به یک Map
            const userMap = new Map();
            authData.users.forEach((user: any) => {
              userMap.set(user.id, user.email);
            });
            
            // ترکیب داده‌ها
            const formattedRedemptions = redemptionsData.map((item: any) => ({
              id: item.id,
              coupon_id: item.coupon_id,
              user_id: item.user_id,
              redeemed_at: item.redeemed_at,
              credits_awarded: item.credits_awarded,
              coupon_code: couponMap.get(item.coupon_id) || 'نامشخص',
              user_email: userMap.get(item.user_id) || 'نامشخص'
            }));
            
            setRedemptions(formattedRedemptions);
            return;
          }
        }
      }
      
      // اگر داده‌ای یافت نشد یا خطایی رخ داد، فقط داده‌های اصلی را نمایش بده
      const basicRedemptions = redemptionsData.map((item: any) => ({
        ...item,
        coupon_code: 'در حال بارگذاری...',
        user_email: 'در حال بارگذاری...'
      }));
      
      setRedemptions(basicRedemptions);
      
      // سعی دوم برای دریافت ایمیل‌های کاربران با استفاده از روش جایگزین
      if (redemptionsData.length > 0) {
        try {
          const usersPromises = redemptionsData.map(async (redemption: any) => {
            const { data: userData } = await supabase
              .from('users')
              .select('email')
              .eq('id', redemption.user_id)
              .single();
              
            return {
              redemptionId: redemption.id,
              email: userData?.email || 'نامشخص'
            };
          });
          
          const results = await Promise.all(usersPromises);
          
          // به‌روزرسانی استفاده‌های کوپن با ایمیل‌های دریافتی
          setRedemptions(prev => prev.map(redemption => {
            const userResult = results.find(r => r.redemptionId === redemption.id);
            return {
              ...redemption,
              user_email: userResult?.email || redemption.user_email
            };
          }));
        } catch (e) {
          console.error('خطا در دریافت ایمیل‌های کاربران:', e);
        }
      }
      
    } catch (error) {
      console.error('خطا در دریافت لیست استفاده از کوپن‌ها:', error);
    }
  };

  // فیلتر کردن کوپن‌ها بر اساس وضعیت و جستجو
  const filterCoupons = (coupons: Coupon[], tab: string, search: string) => {
    let filtered = [...coupons];
    
    // فیلتر بر اساس وضعیت فعال بودن
    if (tab === 'active') {
      filtered = filtered.filter(coupon => coupon.is_active);
    } else if (tab === 'inactive') {
      filtered = filtered.filter(coupon => !coupon.is_active);
    }
    
    // فیلتر بر اساس متن جستجو
    if (search.trim()) {
      const searchLower = search.toLowerCase().trim();
      filtered = filtered.filter(coupon => 
        coupon.code.toLowerCase().includes(searchLower)
      );
    }
    
    setFilteredCoupons(filtered);
  };

  // هندلر تغییر تب
  const handleTabChange = (value: string) => {
    setCurrentTab(value);
    filterCoupons(coupons, value, searchTerm);
  };

  // هندلر جستجو
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterCoupons(coupons, currentTab, value);
  };

  // ایجاد کوپن جدید
  const handleCreateCoupon = async () => {
    if (!newCoupon.code.trim()) {
      toast({
        title: 'خطا',
        description: 'کد کوپن نمی‌تواند خالی باشد',
        variant: 'destructive'
      });
      return;
    }
    
    if (newCoupon.creditAmount <= 0) {
      toast({
        title: 'خطا',
        description: 'مقدار اعتبار باید بیشتر از صفر باشد',
        variant: 'destructive'
      });
      return;
    }
    
    if (newCoupon.usageLimit <= 0) {
      toast({
        title: 'خطا',
        description: 'محدودیت استفاده باید بیشتر از صفر باشد',
        variant: 'destructive'
      });
      return;
    }
    
    try {
      setIsCreatingCoupon(true);
      
      const { data, error } = await supabase
        .from('coupons')
        .insert({
          code: newCoupon.code.trim(),
          credit_amount: newCoupon.creditAmount,
          usage_limit: newCoupon.usageLimit,
          created_by: session?.user?.id
        })
        .select();
        
      if (error) {
        if (error.code === '23505') {
          toast({
            title: 'خطا',
            description: 'این کد کوپن قبلاً استفاده شده است',
            variant: 'destructive'
          });
        } else {
          throw error;
        }
        return;
      }
      
      toast({
        title: 'موفق',
        description: 'کوپن جدید با موفقیت ایجاد شد',
      });
      
      // بازنشانی فرم
      setNewCoupon({
        code: '',
        creditAmount: 10,
        usageLimit: 1
      });
      
      // بستن دیالوگ و بارگذاری مجدد کوپن‌ها
      setIsOpenCreateDialog(false);
      fetchCoupons();
      
    } catch (error) {
      console.error('خطا در ایجاد کوپن:', error);
      toast({
        title: 'خطا',
        description: 'مشکلی در ایجاد کوپن جدید به وجود آمد',
        variant: 'destructive'
      });
    } finally {
      setIsCreatingCoupon(false);
    }
  };

  // غیرفعال/فعال کردن کوپن
  const handleToggleCouponStatus = async (coupon: Coupon) => {
    try {
      setIsProcessing(true);
      
      const { error } = await supabase
        .from('coupons')
        .update({ is_active: !coupon.is_active })
        .eq('id', coupon.id);
        
      if (error) throw error;
      
      toast({
        title: 'موفق',
        description: `کوپن ${coupon.code} با موفقیت ${coupon.is_active ? 'غیرفعال' : 'فعال'} شد`,
      });
      
      // بارگذاری مجدد کوپن‌ها
      fetchCoupons();
      
    } catch (error) {
      console.error('خطا در تغییر وضعیت کوپن:', error);
      toast({
        title: 'خطا',
        description: 'مشکلی در تغییر وضعیت کوپن به وجود آمد',
        variant: 'destructive'
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // حذف کوپن
  const handleDeleteCoupon = async (couponId: string) => {
    try {
      setIsProcessing(true);
      
      const { error } = await supabase
        .from('coupons')
        .delete()
        .eq('id', couponId);
        
      if (error) throw error;
      
      toast({
        title: 'موفق',
        description: 'کوپن با موفقیت حذف شد',
      });
      
      // بارگذاری مجدد کوپن‌ها
      fetchCoupons();
      
    } catch (error) {
      console.error('خطا در حذف کوپن:', error);
      toast({
        title: 'خطا',
        description: 'مشکلی در حذف کوپن به وجود آمد',
        variant: 'destructive'
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // فرمت کردن تاریخ
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('fa-IR') + ' - ' + date.toLocaleTimeString('fa-IR');
    } catch (e) {
      return dateString;
    }
  };

  if (loading && !isAdmin) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <Loader2 className="h-8 w-8 animate-spin mr-2" />
        در حال بارگذاری...
      </div>
    );
  }

  if (!isAdmin) {
    return null; // کاربر مجاز نیست، در حال هدایت به صفحه دیگر
  }

  return (
    <AdminLayout>
      <div className="p-6">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-6 gap-4">
          <div>
            <h1 className="text-2xl font-bold text-white">مدیریت کوپن‌ها</h1>
            <p className="text-gray-400 mt-1">ایجاد و مدیریت کوپن‌های تخفیف برای کاربران</p>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-3 w-full lg:w-auto">
            <div className="relative flex-1 sm:w-64">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 h-4 w-4" />
              <Input
                placeholder="جستجوی کد کوپن..."
                className="pl-9 bg-gray-800 text-white border-gray-700 w-full"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            
            <Dialog open={isOpenCreateDialog} onOpenChange={setIsOpenCreateDialog}>
              <DialogTrigger asChild>
                <Button className="bg-indigo-600 hover:bg-indigo-700">
                  <Plus className="h-4 w-4 mr-2" />
                  کوپن جدید
                </Button>
              </DialogTrigger>
              <DialogContent className="bg-gray-800 text-white border-gray-700">
                <DialogHeader>
                  <DialogTitle>ایجاد کوپن جدید</DialogTitle>
                  <DialogDescription className="text-gray-400">
                    با استفاده از این فرم می‌توانید کوپن تخفیف جدید ایجاد کنید.
                  </DialogDescription>
                </DialogHeader>
                
                <div className="space-y-4 py-4">
                  <div className="space-y-2">
                    <Label htmlFor="coupon-code">کد کوپن</Label>
                    <Input
                      id="coupon-code"
                      placeholder="مثال: WELCOME10"
                      className="bg-gray-700 border-gray-600"
                      value={newCoupon.code}
                      onChange={(e) => setNewCoupon({...newCoupon, code: e.target.value})}
                    />
                  </div>
                  
                  <div className="space-y-2">
                    <Label htmlFor="credit-amount">میزان اعتبار</Label>
                    <Input
                      id="credit-amount"
                      type="number"
                      min="1"
                      className="bg-gray-700 border-gray-600"
                      value={newCoupon.creditAmount}
                      onChange={(e) => setNewCoupon({...newCoupon, creditAmount: parseInt(e.target.value) || 0})}
                    />
                  </div>
                  
                  <div className="space-y-2">
                    <Label htmlFor="usage-limit">محدودیت استفاده</Label>
                    <Input
                      id="usage-limit"
                      type="number"
                      min="1"
                      className="bg-gray-700 border-gray-600"
                      value={newCoupon.usageLimit}
                      onChange={(e) => setNewCoupon({...newCoupon, usageLimit: parseInt(e.target.value) || 0})}
                    />
                    <p className="text-xs text-gray-400">تعداد کل دفعاتی که این کوپن می‌تواند استفاده شود.</p>
                  </div>
                </div>
                
                <DialogFooter>
                  <Button 
                    variant="outline" 
                    onClick={() => setIsOpenCreateDialog(false)}
                    className="text-white border-gray-600 hover:bg-gray-700"
                  >
                    انصراف
                  </Button>
                  <Button
                    onClick={handleCreateCoupon}
                    className="bg-indigo-600 hover:bg-indigo-700"
                    disabled={isCreatingCoupon}
                  >
                    {isCreatingCoupon ? (
                      <>
                        <Loader2 className="h-4 w-4 ml-2 animate-spin" />
                        در حال ایجاد...
                      </>
                    ) : (
                      'ایجاد کوپن'
                    )}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            
            <Button 
              variant="outline" 
              onClick={fetchCoupons}
              className="text-white border-gray-700 hover:bg-gray-700 mt-0"
            >
              <RefreshCw className="h-4 w-4 ml-2" />
              بارگذاری مجدد
            </Button>
          </div>
        </div>
        
        <Tabs defaultValue="all" value={currentTab} onValueChange={handleTabChange}>
          <TabsList className="bg-gray-800 border-gray-700 mb-4">
            <TabsTrigger value="all" className="data-[state=active]:bg-gray-700">
              همه کوپن‌ها
            </TabsTrigger>
            <TabsTrigger value="active" className="data-[state=active]:bg-gray-700">
              کوپن‌های فعال
            </TabsTrigger>
            <TabsTrigger value="inactive" className="data-[state=active]:bg-gray-700">
              کوپن‌های غیرفعال
            </TabsTrigger>
            <TabsTrigger value="redemptions" className="data-[state=active]:bg-gray-700">
              استفاده‌های کوپن
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="all" className="mt-0">
            <Card className="bg-gray-800 border-gray-700">
              <CardContent className="p-0">
                <Table className="border-collapse border-spacing-0">
                  <TableHeader>
                    <TableRow className="hover:bg-gray-700/50 border-b border-gray-700">
                      <TableHead className="text-gray-400 font-medium">کد کوپن</TableHead>
                      <TableHead className="text-gray-400 font-medium">میزان اعتبار</TableHead>
                      <TableHead className="text-gray-400 font-medium">استفاده شده</TableHead>
                      <TableHead className="text-gray-400 font-medium">محدودیت</TableHead>
                      <TableHead className="text-gray-400 font-medium">تاریخ ایجاد</TableHead>
                      <TableHead className="text-gray-400 font-medium">وضعیت</TableHead>
                      <TableHead className="text-gray-400 font-medium">عملیات</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center py-8 text-gray-400">
                          <div className="flex items-center justify-center">
                            <Loader2 className="h-6 w-6 animate-spin ml-2" />
                            در حال بارگذاری...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : filteredCoupons.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center py-8 text-gray-400">
                          <div className="flex flex-col items-center justify-center">
                            <Ticket className="h-10 w-10 text-gray-500 mb-2" />
                            <p>هیچ کوپنی یافت نشد</p>
                            {searchTerm && (
                              <p className="text-sm mt-1">جستجو برای: {searchTerm}</p>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredCoupons.map((coupon) => (
                        <TableRow key={coupon.id} className="hover:bg-gray-700/50 border-b border-gray-700">
                          <TableCell className="font-medium text-gray-200">
                            {coupon.code}
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.credit_amount} اعتبار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.used_count} بار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.usage_limit} بار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {formatDate(coupon.created_at)}
                          </TableCell>
                          <TableCell>
                            <Badge variant={coupon.is_active ? "default" : "secondary"}>
                              {coupon.is_active ? 'فعال' : 'غیرفعال'}
                            </Badge>
                          </TableCell>
                          <TableCell>
                            <div className="flex gap-2">
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleToggleCouponStatus(coupon)}
                                disabled={isProcessing}
                                className="hover:bg-gray-700 text-gray-300"
                              >
                                {coupon.is_active ? (
                                  <ToggleRight className="h-4 w-4 text-green-500" />
                                ) : (
                                  <ToggleLeft className="h-4 w-4 text-gray-500" />
                                )}
                              </Button>
                              
                              <AlertDialog>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="hover:bg-gray-700 text-gray-300"
                                  >
                                    <Trash2 className="h-4 w-4 text-red-500" />
                                  </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent className="bg-gray-800 text-white border-gray-700">
                                  <AlertDialogHeader>
                                    <AlertDialogTitle>آیا از حذف کوپن اطمینان دارید؟</AlertDialogTitle>
                                    <AlertDialogDescription className="text-gray-400">
                                      این عمل غیرقابل برگشت است. حذف این کوپن تأثیری بر استفاده‌های قبلی آن نخواهد داشت.
                                    </AlertDialogDescription>
                                  </AlertDialogHeader>
                                  <AlertDialogFooter className="flex gap-2 justify-end">
                                    <AlertDialogCancel className="text-white border-gray-600 hover:bg-gray-700">انصراف</AlertDialogCancel>
                                    <AlertDialogAction
                                      className="bg-red-600 hover:bg-red-700"
                                      onClick={() => handleDeleteCoupon(coupon.id)}
                                    >
                                      حذف کوپن
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialog>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="active" className="mt-0">
            <Card className="bg-gray-800 border-gray-700">
              <CardContent className="p-0">
                <Table className="border-collapse border-spacing-0">
                  <TableHeader>
                    <TableRow className="hover:bg-gray-700/50 border-b border-gray-700">
                      <TableHead className="text-gray-400 font-medium">کد کوپن</TableHead>
                      <TableHead className="text-gray-400 font-medium">میزان اعتبار</TableHead>
                      <TableHead className="text-gray-400 font-medium">استفاده شده</TableHead>
                      <TableHead className="text-gray-400 font-medium">محدودیت</TableHead>
                      <TableHead className="text-gray-400 font-medium">تاریخ ایجاد</TableHead>
                      <TableHead className="text-gray-400 font-medium">عملیات</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={6} className="text-center py-8 text-gray-400">
                          <div className="flex items-center justify-center">
                            <Loader2 className="h-6 w-6 animate-spin ml-2" />
                            در حال بارگذاری...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : filteredCoupons.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} className="text-center py-8 text-gray-400">
                          <div className="flex flex-col items-center justify-center">
                            <Ticket className="h-10 w-10 text-gray-500 mb-2" />
                            <p>هیچ کوپن فعالی یافت نشد</p>
                            {searchTerm && (
                              <p className="text-sm mt-1">جستجو برای: {searchTerm}</p>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredCoupons.map((coupon) => (
                        <TableRow key={coupon.id} className="hover:bg-gray-700/50 border-b border-gray-700">
                          <TableCell className="font-medium text-gray-200">
                            {coupon.code}
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.credit_amount} اعتبار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.used_count} بار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.usage_limit} بار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {formatDate(coupon.created_at)}
                          </TableCell>
                          <TableCell>
                            <div className="flex gap-2">
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleToggleCouponStatus(coupon)}
                                disabled={isProcessing}
                                className="hover:bg-gray-700 text-gray-300"
                              >
                                <ToggleRight className="h-4 w-4 text-green-500" />
                              </Button>
                              
                              <AlertDialog>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="hover:bg-gray-700 text-gray-300"
                                  >
                                    <Trash2 className="h-4 w-4 text-red-500" />
                                  </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent className="bg-gray-800 text-white border-gray-700">
                                  <AlertDialogHeader>
                                    <AlertDialogTitle>آیا از حذف کوپن اطمینان دارید؟</AlertDialogTitle>
                                    <AlertDialogDescription className="text-gray-400">
                                      این عمل غیرقابل برگشت است. حذف این کوپن تأثیری بر استفاده‌های قبلی آن نخواهد داشت.
                                    </AlertDialogDescription>
                                  </AlertDialogHeader>
                                  <AlertDialogFooter className="flex gap-2 justify-end">
                                    <AlertDialogCancel className="text-white border-gray-600 hover:bg-gray-700">انصراف</AlertDialogCancel>
                                    <AlertDialogAction
                                      className="bg-red-600 hover:bg-red-700"
                                      onClick={() => handleDeleteCoupon(coupon.id)}
                                    >
                                      حذف کوپن
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialog>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="inactive" className="mt-0">
            <Card className="bg-gray-800 border-gray-700">
              <CardContent className="p-0">
                <Table className="border-collapse border-spacing-0">
                  <TableHeader>
                    <TableRow className="hover:bg-gray-700/50 border-b border-gray-700">
                      <TableHead className="text-gray-400 font-medium">کد کوپن</TableHead>
                      <TableHead className="text-gray-400 font-medium">میزان اعتبار</TableHead>
                      <TableHead className="text-gray-400 font-medium">استفاده شده</TableHead>
                      <TableHead className="text-gray-400 font-medium">محدودیت</TableHead>
                      <TableHead className="text-gray-400 font-medium">تاریخ ایجاد</TableHead>
                      <TableHead className="text-gray-400 font-medium">عملیات</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={6} className="text-center py-8 text-gray-400">
                          <div className="flex items-center justify-center">
                            <Loader2 className="h-6 w-6 animate-spin ml-2" />
                            در حال بارگذاری...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : filteredCoupons.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} className="text-center py-8 text-gray-400">
                          <div className="flex flex-col items-center justify-center">
                            <Ticket className="h-10 w-10 text-gray-500 mb-2" />
                            <p>هیچ کوپن غیرفعالی یافت نشد</p>
                            {searchTerm && (
                              <p className="text-sm mt-1">جستجو برای: {searchTerm}</p>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredCoupons.map((coupon) => (
                        <TableRow key={coupon.id} className="hover:bg-gray-700/50 border-b border-gray-700">
                          <TableCell className="font-medium text-gray-200">
                            {coupon.code}
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.credit_amount} اعتبار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.used_count} بار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {coupon.usage_limit} بار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {formatDate(coupon.created_at)}
                          </TableCell>
                          <TableCell>
                            <div className="flex gap-2">
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleToggleCouponStatus(coupon)}
                                disabled={isProcessing}
                                className="hover:bg-gray-700 text-gray-300"
                              >
                                <ToggleLeft className="h-4 w-4 text-gray-500" />
                              </Button>
                              
                              <AlertDialog>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="hover:bg-gray-700 text-gray-300"
                                  >
                                    <Trash2 className="h-4 w-4 text-red-500" />
                                  </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent className="bg-gray-800 text-white border-gray-700">
                                  <AlertDialogHeader>
                                    <AlertDialogTitle>آیا از حذف کوپن اطمینان دارید؟</AlertDialogTitle>
                                    <AlertDialogDescription className="text-gray-400">
                                      این عمل غیرقابل برگشت است. حذف این کوپن تأثیری بر استفاده‌های قبلی آن نخواهد داشت.
                                    </AlertDialogDescription>
                                  </AlertDialogHeader>
                                  <AlertDialogFooter className="flex gap-2 justify-end">
                                    <AlertDialogCancel className="text-white border-gray-600 hover:bg-gray-700">انصراف</AlertDialogCancel>
                                    <AlertDialogAction
                                      className="bg-red-600 hover:bg-red-700"
                                      onClick={() => handleDeleteCoupon(coupon.id)}
                                    >
                                      حذف کوپن
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialog>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="redemptions" className="mt-0">
            <Card className="bg-gray-800 border-gray-700">
              <CardContent className="p-0">
                <Table className="border-collapse border-spacing-0">
                  <TableHeader>
                    <TableRow className="hover:bg-gray-700/50 border-b border-gray-700">
                      <TableHead className="text-gray-400 font-medium">کد کوپن</TableHead>
                      <TableHead className="text-gray-400 font-medium">کاربر</TableHead>
                      <TableHead className="text-gray-400 font-medium">اعتبار دریافتی</TableHead>
                      <TableHead className="text-gray-400 font-medium">تاریخ استفاده</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={4} className="text-center py-8 text-gray-400">
                          <div className="flex items-center justify-center">
                            <Loader2 className="h-6 w-6 animate-spin ml-2" />
                            در حال بارگذاری...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : redemptions.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} className="text-center py-8 text-gray-400">
                          <div className="flex flex-col items-center justify-center">
                            <Ticket className="h-10 w-10 text-gray-500 mb-2" />
                            <p>هیچ استفاده‌ای از کوپن یافت نشد</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      redemptions.map((redemption) => (
                        <TableRow key={redemption.id} className="hover:bg-gray-700/50 border-b border-gray-700">
                          <TableCell className="font-medium text-gray-200">
                            {redemption.coupon_code}
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {redemption.user_email}
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {redemption.credits_awarded} اعتبار
                          </TableCell>
                          <TableCell className="text-gray-300">
                            {formatDate(redemption.redeemed_at)}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </AdminLayout>
  );
};

export default AdminCoupons;
