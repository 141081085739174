import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { LucideIcon } from "lucide-react";
interface AIToolCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  children: React.ReactNode;
}
export const AIToolCard = ({
  title,
  description,
  icon: Icon,
  children
}: AIToolCardProps) => {
  return <Card className="w-full">
      <CardHeader>
        <div className="flex items-center gap-2">
          <Icon className="w-6 h-6 text-primary" />
          <CardTitle className="text-xl">{title}</CardTitle>
        </div>
        
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>;
};