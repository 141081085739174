
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Loader2, Lightbulb, Sparkles } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

export const StoryIdeaGenerator = () => {
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [ideas, setIdeas] = useState<any>(null);
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const { profile, refreshProfile } = useAuth();

  const generateIdeas = async () => {
    if (!subject.trim()) {
      toast({
        title: "خطا",
        description: "لطفاً موضوع استوری را وارد کنید",
        variant: "destructive",
      });
      return;
    }

    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "story", creditCost: 3 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setLoading(true);
    try {
      // Call the function first to ensure it works before deducting credits
      const { data, error } = await supabase.functions.invoke("generate-story-ideas", {
        body: { 
          profession: subject, // Using the existing backend parameter names
          theme: details.trim() || undefined,
          storyType: "single",
          storyCount: 1
        },
      });

      if (error) throw error;
      
      // Check if data contains error property
      if (data && data.error) {
        throw new Error(data.error);
      }
      
      // Now deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;
      
      setIdeas(data.ideas);
      setExplanation(data.explanation || "");

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "موفقیت",
        description: "ایده استوری با موفقیت تولید شد",
      });
    } catch (error: any) {
      console.error("Error:", error);
      toast({
        title: "خطا",
        description: error.message || "مشکلی در تولید ایده پیش آمد",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4" dir="rtl">
      <Input
        placeholder="موضوع استوری را وارد کنید..."
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />

      <Textarea
        placeholder="توضیحات بیشتر را بنویسید (اختیاری)..."
        value={details}
        onChange={(e) => setDetails(e.target.value)}
        className="min-h-[100px]"
      />

      <Button
        onClick={generateIdeas}
        disabled={loading}
        className="w-full"
      >
        {loading ? (
          <>
            <Loader2 className="ml-2 h-4 w-4 animate-spin" />
            در حال تولید ایده استوری...
          </>
        ) : (
          "دریافت ایده استوری"
        )}
      </Button>

      {ideas ? (
        <div className="space-y-4" dir="rtl">
          {ideas.map((idea: any, index: number) => (
            <Card key={index} className="text-right">
              <CardHeader>
                <CardTitle>{idea.title}</CardTitle>
                <CardDescription>{idea.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  <p className="font-semibold">بخش‌ها:</p>
                  <ul className="list-disc list-inside space-y-1 pr-4">
                    {idea.segments.map((segment: string, idx: number) => (
                      <li key={idx}>{segment}</li>
                    ))}
                  </ul>
                  <p className="mt-4 text-sm font-medium text-muted-foreground">
                    اقدام نهایی: {idea.cta}
                  </p>
                </div>
              </CardContent>
            </Card>
          ))}
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی استوری: تحلیل ایده</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>ایده‌ساز استوری</strong> به شما کمک می‌کند استوری‌های جذاب و تعاملی برای کسب و کار خود بسازید. 
            با استفاده از این ابزار، دیگر برای تولید محتوای روزانه خود نگران نخواهید بود. 
            فقط موضوع کسب و کار خود را وارد کنید تا ایده‌های خلاقانه و حرفه‌ای دریافت کنید!
          </p>
        </InfoBox>
      )}
    </div>
  );
};
