
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Loader2, Info, Mail } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import HCaptcha from "./HCaptcha";
import { HCAPTCHA_SITE_KEY, CAPTCHA_ERROR_MESSAGES } from "./captchaConfig";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

declare global {
  interface Window {
    hcaptcha?: {
      reset: () => void;
    };
  }
}

const signupSchema = z.object({
  email: z.string().email("لطفاً یک ایمیل معتبر وارد کنید"),
  password: z.string().min(6, "رمز عبور باید حداقل ۶ کاراکتر باشد"),
  phone: z.string().optional(),
});

export type SignupFormValues = z.infer<typeof signupSchema>;

interface SignupFormProps {
  onModeChange: (mode: "login") => void;
}

const SignupForm = ({ onModeChange }: SignupFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string | null>(null);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const { toast } = useToast();

  const form = useForm<SignupFormValues>({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      email: "",
      password: "",
      phone: "",
    },
  });

  const handleCaptchaVerify = (token: string) => {
    console.log("کپچا تأیید شد!");
    setCaptchaToken(token);
    setCaptchaError(null);
  };

  const handleCaptchaError = (error: any) => {
    console.error("هکپچا خطا داد:", error);
    
    let errorMessage = CAPTCHA_ERROR_MESSAGES.loadingError;
    if (error === "network-error") {
      errorMessage = CAPTCHA_ERROR_MESSAGES.networkError;
    }
    
    setCaptchaError(errorMessage);
    setCaptchaToken(null);
  };

  const handleCaptchaExpire = () => {
    setCaptchaToken(null);
    setCaptchaError(CAPTCHA_ERROR_MESSAGES.expiredError);
  };

  const verifyCaptchaToken = async (token: string): Promise<boolean> => {
    try {
      // Use Supabase client to call the edge function (this will add the authorization header automatically)
      const { data, error: functionError } = await supabase.functions.invoke("verify-hcaptcha", {
        body: { token },
      });
      
      console.log("نتیجه تأیید کپچا:", data);
      
      if (functionError) {
        console.error("خطا در تابع edge:", functionError);
        return false;
      }
      
      if (data && data.success === true) {
        return true;
      } else {
        console.error("خطا در تأیید کپچا:", data?.error || "نامشخص");
        return false;
      }
    } catch (error) {
      console.error("خطا در ارسال درخواست تأیید کپچا:", error);
      return false;
    }
  };

  const handleSignup = async (data: SignupFormValues) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    setShowEmailVerification(false);

    try {
      console.log("شروع فرآیند ثبت‌نام برای ایمیل:", data.email);
      setUserEmail(data.email);
      
      if (!captchaToken) {
        throw new Error("لطفاً ابتدا کپچا را تکمیل کنید");
      }
      
      console.log("در حال تأیید کپچا...");
      const isValidCaptcha = await verifyCaptchaToken(captchaToken);
      
      if (!isValidCaptcha) {
        throw new Error(CAPTCHA_ERROR_MESSAGES.verificationError);
      }
      
      console.log("کپچا تأیید شد، تلاش برای ثبت‌نام...");
      
      // Get device fingerprint
      const deviceId = localStorage.getItem('instayar_device_id') || 
        Math.random().toString(36).substring(2, 15) + 
        Math.random().toString(36).substring(2, 15) + 
        Date.now().toString(36);
        
      localStorage.setItem('instayar_device_id', deviceId);
      
      // Check if this device has registered too many accounts
      const { data: deviceData, error: deviceError } = await supabase
        .rpc('check_device_registrations', { 
          device_fingerprint: deviceId 
        });
        
      if (deviceError) {
        console.error("خطای بررسی دستگاه:", deviceError);
      } else if (deviceData && deviceData > 2) {
        throw new Error("شما به حداکثر تعداد حساب‌های مجاز رسیده‌اید. لطفاً با پشتیبانی تماس بگیرید.");
      }
      
      // For debugging purposes, check which URL is being used for email redirects
      const origin = window.location.origin;
      console.log("آدرس ریدایرکت ایمیل:", origin);
      
      // Now proceed with the actual signup without captchaToken
      const { data: signupData, error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          emailRedirectTo: `${origin}/app`,
          data: {
            phone: data.phone || null,
            device_id: deviceId
          }
        },
      });

      if (error) {
        console.error("خطای ثبت‌نام سوپابیس:", error);
        throw error;
      }
      
      console.log("پاسخ ثبت‌نام:", signupData);
      
      // Check if email confirmation was sent successfully
      if (!signupData?.user?.email_confirmed_at) {
        console.log("وضعیت تأیید ایمیل در انتظار. کاربر نیاز به تأیید ایمیل دارد.");
        setShowEmailVerification(true);
      } else {
        console.log("ایمیل قبلاً تأیید شده یا تأیید نیاز نیست.");
      }
      
      setSuccess("ثبت‌نام با موفقیت انجام شد. لطفاً ایمیل خود را برای تایید حساب بررسی کنید.");
      
      // Show prominent toast with clear email verification instructions
      toast({
        title: "ثبت‌نام موفقیت‌آمیز",
        description: "لطفاً ایمیل خود را چک کنید و روی لینک تأیید کلیک کنید تا ثبت‌نام شما تکمیل شود.",
        variant: "default",
        className: "toast-glossy",
      });
      
      // For testing purposes, we'll allow users to go directly to login
      if (!showEmailVerification) {
        onModeChange("login");
      }
    } catch (error: any) {
      console.error("خطا در ثبت‌نام:", error);
      
      // Add more detailed error information
      let errorMessage = error.message || "مشکلی در فرآیند ثبت‌نام پیش آمد";
      
      // Check for specific error types
      if (error.message.includes("User already registered")) {
        errorMessage = "این ایمیل قبلاً ثبت شده است. لطفاً وارد شوید یا از ایمیل دیگری استفاده کنید.";
      } else if (error.message.includes("mail") || error.message.includes("email")) {
        errorMessage = "خطا در ارسال ایمیل تأیید. لطفاً با پشتیبانی تماس بگیرید یا دوباره تلاش کنید.";
        console.error("جزئیات خطای ارسال ایمیل:", error);
      }
      
      setError(errorMessage);
      toast({
        title: "خطا",
        description: errorMessage,
        variant: "destructive",
        className: "toast-glossy",
      });
      
      if (error.message.includes("کپچا")) {
        setCaptchaToken(null);
        if (window.hcaptcha) {
          try {
            window.hcaptcha.reset();
          } catch (e) {
            console.error("خطا در بازنشانی کپچا:", e);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleSignup)} className="space-y-3" dir="rtl">
      {showEmailVerification ? (
        <div className="bg-indigo-900/50 backdrop-blur-xl border border-indigo-500/30 rounded-lg p-4 text-center space-y-3">
          <div className="flex justify-center">
            <div className="w-16 h-16 bg-indigo-500/20 rounded-full flex items-center justify-center">
              <Mail className="h-8 w-8 text-indigo-200" />
            </div>
          </div>
          <h3 className="text-lg font-medium text-white">ایمیل تأیید ارسال شد</h3>
          <p className="text-gray-300 text-sm">
            یک ایمیل تأیید به <span className="text-white font-medium">{userEmail}</span> ارسال شده است.
          </p>
          <p className="text-gray-300 text-sm">
            لطفاً ایمیل خود را بررسی کرده و روی لینک تأیید کلیک کنید تا فرآیند ثبت‌نام تکمیل شود.
          </p>
          <div className="pt-2">
            <Button
              type="button"
              variant="outline"
              className="border-indigo-500/50 text-white hover:bg-indigo-800/50 w-full"
              onClick={() => onModeChange("login")}
            >
              بازگشت به صفحه ورود
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="space-y-1">
            <Label htmlFor="signup-email" className="text-white text-sm">ایمیل</Label>
            <Input
              id="signup-email"
              type="email"
              placeholder="ایمیل خود را وارد کنید..."
              className="bg-white/10 border-white/20 text-white placeholder:text-gray-400 text-sm"
              {...form.register("email")}
            />
            {form.formState.errors.email && (
              <p className="text-xs text-red-400">{form.formState.errors.email.message}</p>
            )}
          </div>
          
          <div className="space-y-1">
            <div className="flex items-center gap-1">
              <Label htmlFor="phone" className="text-white text-sm">شماره تلفن (اختیاری)</Label>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Info className="h-3.5 w-3.5 text-gray-400 cursor-help" />
                  </TooltipTrigger>
                  <TooltipContent side="top" align="center" className="bg-gray-800 text-white border-gray-700 text-xs max-w-[250px]">
                    با ثبت شماره تلفن در قرعه‌کشی ماهانه اعتبار رایگان شرکت داده می‌شوید.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <Input
              id="phone"
              type="tel"
              placeholder="شماره تلفن خود را وارد کنید..."
              className="bg-white/10 border-white/20 text-white placeholder:text-gray-400 text-sm"
              {...form.register("phone")}
            />
          </div>
          
          <div className="space-y-1">
            <Label htmlFor="signup-password" className="text-white text-sm">رمز عبور</Label>
            <Input
              id="signup-password"
              type="password"
              placeholder="رمز عبور خود را وارد کنید..."
              className="bg-white/10 border-white/20 text-white placeholder:text-gray-400 text-sm"
              {...form.register("password")}
            />
            {form.formState.errors.password && (
              <p className="text-xs text-red-400">{form.formState.errors.password.message}</p>
            )}
          </div>
          
          <HCaptcha
            sitekey={HCAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerify}
            onError={handleCaptchaError}
            onExpire={handleCaptchaExpire}
          />
          
          {captchaError && (
            <p className="text-xs text-red-400">{captchaError}</p>
          )}
          
          <Button 
            type="submit" 
            variant="gradient"
            className="w-full text-sm h-9 mt-3"
            disabled={loading || !captchaToken}
          >
            {loading ? <Loader2 className="h-4 w-4 animate-spin ml-2" /> : null}
            ثبت‌نام
          </Button>
          
          <div className="text-center text-xs">
            <span className="text-gray-300">قبلاً ثبت‌نام کرده‌اید؟</span>{" "}
            <button 
              type="button"
              onClick={() => onModeChange("login")}
              className="text-indigo-300 hover:text-indigo-200"
            >
              وارد شوید
            </button>
          </div>

          {error && (
            <div className="bg-red-500/20 text-red-200 p-2 rounded-md text-xs border border-red-500/20">
              {error}
            </div>
          )}
          
          {success && (
            <div className="bg-green-500/20 text-green-200 p-2 rounded-md text-xs border border-green-500/20">
              {success}
            </div>
          )}
        </>
      )}
    </form>
  );
};

export default SignupForm;
