
// This is the site key from your hCaptcha dashboard
// Make sure this matches the site key you've registered in hCaptcha and Supabase
export const HCAPTCHA_SITE_KEY = "55d62f26-eb32-46c0-976a-9734d450c118"; // Production site key

// Common error messages
export const CAPTCHA_ERROR_MESSAGES = {
  networkError: "خطای شبکه در بارگذاری کپچا. لطفاً اتصال اینترنت خود را بررسی کنید.",
  loadingError: "مشکلی در بارگذاری کپچا به وجود آمد. لطفاً صفحه را بارگذاری مجدد کنید.",
  expiredError: "اعتبار کپچا منقضی شده است. لطفاً دوباره تلاش کنید.",
  verificationError: "خطا در تأیید کپچا. لطفاً دوباره تلاش کنید.",
  invalidInputSecret: "خطا در تأیید کپچا (invalid-input-secret). لطفاً با پشتیبانی تماس بگیرید.",
  missingSecret: "خطا در پیکربندی کپچا (missing-secret). لطفاً با پشتیبانی تماس بگیرید.",
  invalidSiteSecret: "خطا در پیکربندی کپچا (invalid-site-secret). لطفاً با پشتیبانی تماس بگیرید.",
};
