import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Instagram, Users, Award, Building, ArrowRight } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";

const About = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <div className="bg-white w-full max-w-full overflow-x-hidden" dir="rtl">
      <SEO 
        title="درباره اینستایار | دستیار هوشمند تولید محتوای اینستاگرام"
        description="با تیم اینستایار آشنا شوید. ما با استفاده از هوش مصنوعی به رشد پیج اینستاگرام شما کمک می‌کنیم."
        keywords="درباره اینستایار, تیم اینستایار, هوش مصنوعی اینستاگرام, دستیار اینستاگرام"
      />
      
      {/* Use the standardized marketing header */}
      <MarketingHeader currentPage="about" />

      {/* Hero Section */}
      <section className="py-16 px-4 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">درباره اینستایار</h1>
          <p className="text-xl text-gray-600 mb-8 leading-relaxed">
            ما در اینستایار ماموریت داریم تا با استفاده از هوش مصنوعی، فرآیند تولید محتوا و رشد در اینستاگرام را برای همه آسان کنیم.
          </p>
        </div>
      </section>

      {/* Our Story */}
      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold text-gray-900 mb-6">داستان ما</h2>
              <p className="text-gray-600 mb-4 leading-relaxed">
                اینستایار در سال ۱۴۰۳ با یک ایده ساده آغاز شد: کمک به افراد برای تولید محتوای با کیفیت در اینستاگرام، بدون نیاز به مهارت‌های تخصصی طراحی و محتوانویسی.
              </p>
              <p className="text-gray-600 mb-4 leading-relaxed">
                بنیانگذاران ما، که خود با چالش‌های مدیریت پیج‌های اینستاگرام دست و پنجه نرم می‌کردند، تصمیم گرفتند راه حلی هوشمند برای این مشکل ارائه دهند. آنها با ترکیب دانش خود در زمینه هوش مصنوعی و شبکه‌های اجتماعی، اینستایار را به عنوان یک دستیار هوشمند برای تولید محتوای اینستاگرام توسعه دادند.
              </p>
              <p className="text-gray-600 leading-relaxed">
                امروز، اینستایار به هزاران کاربر در سراسر ایران کمک می‌کند تا محتوای جذاب و تعاملی برای اینستاگرام خود تولید کنند و فالوورهای خود را افزایش دهند.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="rounded-xl overflow-hidden shadow-lg"
            >
              <img 
                src="https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80" 
                alt="تیم اینستایار" 
                className="w-full h-auto"
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Our Mission */}
      <section className="py-16 px-4 bg-indigo-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">ماموریت ما</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              ماموریت ما در اینستایار، دموکراتیزه کردن تولید محتوای با کیفیت برای اینستاگرام است.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="bg-white rounded-xl p-6 shadow-sm"
            >
              <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mb-6">
                <Users className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">دسترسی برای همه</h3>
              <p className="text-gray-600">
                ما معتقدیم که هر کسی، بدون توجه به مهارت‌های فنی یا بودجه، باید بتواند محتوای با کیفیت برای اینستاگرام خود تولید کند.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="bg-white rounded-xl p-6 shadow-sm"
            >
              <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mb-6">
                <Award className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">کیفیت بالا</h3>
              <p className="text-gray-600">
                ما به ارائه ابزارهایی با کیفیت بالا متعهد هستیم که به کاربران ما کمک می‌کند تا محتوای اصیل و جذاب تولید کنند.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
              className="bg-white rounded-xl p-6 shadow-sm"
            >
              <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mb-6">
                <Building className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">نوآوری مداوم</h3>
              <p className="text-gray-600">
                ما به طور مداوم در حال بهبود و توسعه ابزارهای خود هستیم تا با تغییرات الگوریتم‌های اینستاگرام و نیازهای کاربران همگام باشیم.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">به ما بپیوندید</h2>
          <p className="text-xl opacity-90 mb-8 max-w-2xl mx-auto">
            با اینستایار، رشد پیج اینستاگرام خود را تسریع کنید و به هزاران کاربر خوشحال ما بپیوندید.
          </p>
          <Button 
            onClick={() => navigate("/auth")} 
            size="lg" 
            className="bg-white text-indigo-700 hover:bg-gray-100 rounded-full px-8 text-lg h-14"
          >
            شروع رایگان
            <ArrowRight className="mr-2" />
          </Button>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-12 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
            <div className="flex space-x-4">
              <a href="https://instagram.com/instayar.ai" className="text-gray-400 hover:text-white">
                <Instagram className="w-5 h-5" />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">لینک‌های مفید</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">خدمات</h3>
            <ul className="space-y-2">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">تماس با ما</h3>
            <ul className="space-y-2">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default About;
