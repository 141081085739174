
import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Loader2, CheckCircle, AlertCircle, Home } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { useToast } from "@/components/ui/use-toast";

const PaymentCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { session, refreshProfile } = useAuth();
  const [status, setStatus] = useState<"loading" | "success" | "error">("loading");
  const [message, setMessage] = useState<string>("در حال پردازش نتیجه پرداخت...");
  const [errorDetails, setErrorDetails] = useState<string | null>(null);
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifyPayment = async () => {
      // اگر قبلاً تلاش کرده‌ایم، از اجرای مجدد جلوگیری کنیم
      if (verificationAttempted.current) {
        return;
      }
      
      // علامت‌گذاری که تلاش انجام شده است
      verificationAttempted.current = true;
      
      try {
        // دریافت پارامترهای بازگشتی از زیبال
        const trackId = searchParams.get("trackId");
        const success = searchParams.get("success") === "1";
        const status = searchParams.get("status");

        console.log("Payment callback parameters:", { trackId, success, status });

        if (!trackId) {
          throw new Error("اطلاعات پرداخت یافت نشد.");
        }

        if (!session?.user) {
          console.log("No active session found");
          // ذخیره پارامترهای پرداخت برای وریفای بعدی
          localStorage.setItem("pendingPayment", JSON.stringify({ trackId, success, status }));
          setStatus("error");
          setMessage("لطفاً ابتدا وارد حساب کاربری خود شوید و سپس به داشبورد مراجعه کنید.");
          
          // فقط یکبار نمایش پیام خطا
          toast({
            title: "نیاز به ورود",
            description: "لطفاً وارد حساب کاربری خود شوید.",
            variant: "destructive",
          });
          return;
        }

        console.log("Calling zibal-verify with:", { trackId, success, status, userId: session.user.id });

        // فراخوانی edge function برای تأیید پرداخت
        const { data, error } = await supabase.functions.invoke("zibal-verify", {
          body: {
            trackId,
            success,
            status,
            userId: session.user.id
          }
        });

        console.log("Zibal verify response:", data, error);

        if (error) {
          setErrorDetails(JSON.stringify(error, null, 2));
          throw new Error(error.message || "خطا در ارتباط با سرور");
        }

        if (!data.success) {
          setStatus("error");
          setMessage(data.message || "پرداخت ناموفق بود. لطفاً دوباره تلاش کنید.");
          
          // فقط یکبار نمایش پیام خطا
          toast({
            title: "پرداخت ناموفق",
            description: data.message || "پرداخت ناموفق بود. لطفاً دوباره تلاش کنید.",
            variant: "destructive",
          });
        } else {
          setStatus("success");
          setMessage(data.message || "پرداخت با موفقیت انجام شد.");
          
          // فقط یکبار نمایش پیام موفقیت
          toast({
            title: "پرداخت موفق",
            description: data.message || "پرداخت با موفقیت انجام شد.",
          });
          
          // بروزرسانی پروفایل برای نمایش اعتبار جدید
          if (refreshProfile) {
            await refreshProfile();
          }
        }
      } catch (err: any) {
        console.error("خطا در تأیید پرداخت:", err);
        setStatus("error");
        setMessage(err.message || "خطا در پردازش نتیجه پرداخت. لطفاً با پشتیبانی تماس بگیرید.");
        if (!errorDetails) {
          setErrorDetails(err.toString());
        }
        
        // فقط یکبار نمایش پیام خطا
        toast({
          title: "خطا",
          description: err.message || "خطا در پردازش نتیجه پرداخت. لطفاً با پشتیبانی تماس بگیرید.",
          variant: "destructive",
        });
      }
    };

    verifyPayment();
  }, [searchParams, session, toast, refreshProfile]);

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4" dir="rtl">
      <div 
        className="fixed inset-0 -z-10 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("/lovable-uploads/9b847d69-0e42-4191-b92a-492215e504bb.png")`,
        }}
      />
      <div className="fixed inset-0 -z-10 bg-black/60" />
      
      <Card className="max-w-md w-full">
        <CardHeader>
          <CardTitle className="text-center text-xl">نتیجه پرداخت</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center p-6 text-center">
            {status === "loading" && (
              <>
                <Loader2 className="h-12 w-12 text-indigo-500 mb-4 animate-spin" />
                <p className="text-gray-600 mb-4">{message}</p>
              </>
            )}
            
            {status === "success" && (
              <>
                <div className="bg-green-100 p-3 rounded-full mb-4">
                  <CheckCircle className="h-12 w-12 text-green-500" />
                </div>
                <h3 className="text-lg font-bold text-green-600 mb-2">پرداخت موفق</h3>
                <p className="text-gray-600 mb-6">{message}</p>
                <Button onClick={goToDashboard} className="flex items-center">
                  <Home className="h-4 w-4 ml-2" />
                  بازگشت به داشبورد
                </Button>
              </>
            )}
            
            {status === "error" && (
              <>
                <div className="bg-red-100 p-3 rounded-full mb-4">
                  <AlertCircle className="h-12 w-12 text-red-500" />
                </div>
                <h3 className="text-lg font-bold text-red-600 mb-2">خطا در پرداخت</h3>
                <p className="text-gray-700 mb-6 font-medium">{message}</p>
                {errorDetails && (
                  <div className="w-full mb-6 overflow-auto">
                    <pre className="text-xs bg-gray-100 p-3 rounded text-left" dir="ltr">
                      {errorDetails}
                    </pre>
                  </div>
                )}
                <div className="flex gap-4">
                  <Button onClick={goToDashboard} className="flex items-center">
                    <Home className="h-4 w-4 ml-2" />
                    بازگشت به داشبورد
                  </Button>
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentCallback;
