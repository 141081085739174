
import * as React from "react"
import { securityUtils } from "@/lib/utils"
import { Input } from "@/components/ui/input"

interface SecureInputProps extends Omit<React.ComponentProps<"input">, "ref" | "onChange"> {
  onSanitizedChange?: (value: string) => void;
  sanitizeAs?: 'text' | 'email' | 'number' | 'url' | 'phone' | 'instagram' | 'nationalCode';
}

const SecureInput = React.forwardRef<HTMLInputElement, SecureInputProps>(
  ({ className, type, maxLength = 3000, sanitizeAs = 'text', onSanitizedChange, ...props }, ref) => {
    const [sanitizedValue, setSanitizedValue] = React.useState<string>('');
    
    // مدیریت تغییرات ورودی و پاکسازی
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value;
      let sanitized: string = '';
      
      // انتخاب روش پاکسازی بر اساس نوع داده
      switch (sanitizeAs) {
        case 'email':
          sanitized = securityUtils.sanitizeEmail(rawValue);
          break;
        case 'number':
          sanitized = securityUtils.sanitizeNumber(rawValue).toString();
          break;
        case 'url':
          sanitized = securityUtils.sanitizeUrl(rawValue);
          break;
        case 'phone':
          sanitized = securityUtils.sanitizePhoneNumber(rawValue);
          break;
        case 'instagram':
          sanitized = securityUtils.sanitizeInstagramUsername(rawValue);
          break;
        case 'nationalCode':
          sanitized = securityUtils.sanitizeNationalCode(rawValue);
          break;
        default:
          sanitized = securityUtils.sanitizeString(rawValue);
      }
      
      setSanitizedValue(sanitized);
      
      // فراخوانی callback با مقدار پاکسازی شده
      if (onSanitizedChange) {
        onSanitizedChange(sanitized);
      }
    };
    
    return (
      <Input
        type={type}
        maxLength={maxLength}
        onChange={handleChange}
        value={sanitizedValue}
        className={className}
        ref={ref}
        {...props}
      />
    );
  }
);

SecureInput.displayName = "SecureInput";

export { SecureInput };
