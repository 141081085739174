import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Loader2, Copy, Lightbulb, Sparkles } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

export const OutreachGenerator = () => {
  const [target_audience, setTargetAudience] = useState("");
  const [message_intent, setMessageIntent] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<any>(null);
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const { profile, refreshProfile } = useAuth();

  const generateMessage = async () => {
    if (!target_audience.trim() || !message_intent.trim()) {
      toast({
        title: "خطا",
        description: "لطفاً تمام فیلدها را پر کنید",
        variant: "destructive",
      });
      return;
    }

    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "outreach", creditCost: 4 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke("generate-outreach", {
        body: { target_audience, message_intent },
      });

      if (error) throw error;
      
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;
      
      setMessages(data);
      setExplanation(data.explanation || "");

      await supabase.from("outreach_messages").insert({
        target_audience,
        message_intent,
        generated_content: JSON.stringify(data),
      });

      await refreshProfile();

      toast({
        title: "موفقیت",
        description: "پیام‌های پیشنهادی با موفقیت تولید شدند",
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "خطا",
        description: "مشکلی در تولید پیام پیش آمد",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast({
      title: "کپی شد",
      description: "پیام در کلیپ‌بورد کپی شد",
    });
  };

  return (
    <div className="space-y-4" dir="rtl">
      <div className="space-y-2">
        <Textarea
          placeholder="نام گیرنده پیام (شامل اسم فرد, گروه, سازمان یا هر مخاطب دیگر)"
          value={target_audience}
          onChange={(e) => setTargetAudience(e.target.value)}
          className="min-h-[100px]"
        />
      </div>

      <div className="space-y-2">
        <Textarea
          placeholder="موضوع و جزییات پیام را بنویسید."
          value={message_intent}
          onChange={(e) => setMessageIntent(e.target.value)}
          className="min-h-[100px]"
        />
      </div>

      <Button
        onClick={generateMessage}
        disabled={loading}
        className="w-full"
      >
        {loading ? (
          <>
            <Loader2 className="ml-2 h-4 w-4 animate-spin" />
            در حال تولید پیام...
          </>
        ) : (
          "دریافت پیشنهاد پیام"
        )}
      </Button>

      {messages ? (
        <div className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle className="text-right">پیام رسمی</CardTitle>
            </CardHeader>
            <CardContent className="relative">
              <p className="text-base leading-7 text-right">{messages.message}</p>
              <Button
                variant="ghost"
                size="icon"
                className="absolute left-2 top-2"
                onClick={() => copyToClipboard(messages.message)}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="text-right">پیام دوستانه</CardTitle>
            </CardHeader>
            <CardContent className="relative">
              <p className="text-base leading-7 text-right">{messages.alternative}</p>
              <Button
                variant="ghost"
                size="icon"
                className="absolute left-2 top-2"
                onClick={() => copyToClipboard(messages.alternative)}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </CardContent>
          </Card>

          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }} 
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{ rotate: [0, 15, 0, 15, 0], scale: [1, 1.2, 1, 1.2, 1] }}
                    transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }} 
                      animate={{ scale: 1 }} 
                      transition={{ delay: 0.5, type: "spring" }} 
                      className="flex items-center justify-center ml-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی پیام‌نویسی</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }} 
                    animate={{ opacity: 1 }} 
                    transition={{ delay: 0.7, duration: 0.5 }} 
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed text-right">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>سازنده پیام دایرکت</strong> به شما کمک می‌کند پیام‌های تأثیرگذار و متقاعدکننده برای مخاطبان هدف خود بنویسید. 
            این ابزار پیام‌های شخصی‌سازی شده با لحن مناسب برای هر مخاطب تولید می‌کند تا نرخ پاسخگویی را افزایش دهد. 
            مناسب برای همکاری با اینفلوئنسرها، جذب مشتری، خدمات مشتریان و بازاریابی مستقیم.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
