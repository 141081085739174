
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast"
import { useToast } from "@/hooks/use-toast"
import { useMobile } from "@/hooks/use-mobile"
import { motion, AnimatePresence } from "framer-motion"

interface ToasterProps {
  position?: "top-right" | "top-left" | "bottom-right" | "bottom-left" | "bottom-center";
  toastOptions?: Record<string, any>;
}

export function Toaster({ position = "bottom-center", toastOptions = {} }: ToasterProps) {
  const { toasts } = useToast()
  const isMobile = useMobile()
  
  // Define position classes
  const positionClasses = {
    "top-right": "top-0 right-0",
    "top-left": "top-0 left-0",
    "bottom-right": "bottom-0 right-0",
    "bottom-left": "bottom-0 left-0",
    "bottom-center": "bottom-0 left-1/2 -translate-x-1/2"
  };

  return (
    <ToastProvider duration={5000}>
      <AnimatePresence>
        {toasts.map(function ({ id, title, description, action, ...props }) {
          return (
            <motion.div
              key={id}
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              transition={{ 
                type: "spring", 
                stiffness: 500, 
                damping: 30,
                duration: 0.4
              }}
              style={{ position: "relative" }}
            >
              <Toast 
                {...props} 
                {...toastOptions} 
                className={`backdrop-blur-xl border shadow-2xl 
                  ${props.variant === 'destructive' 
                    ? 'bg-red-500/20 border-red-400/30 text-white' 
                    : 'bg-black/40 border-white/20 text-white'
                  } rounded-2xl overflow-hidden`}
              >
                <div className="grid gap-2">
                  {title && (
                    <ToastTitle className="text-white text-lg font-bold">
                      {title}
                    </ToastTitle>
                  )}
                  {description && (
                    <ToastDescription className="text-white/90 text-sm">
                      {description}
                    </ToastDescription>
                  )}
                </div>
                {action}
                <ToastClose className="absolute top-2 left-2 rounded-full p-1 text-white/80 hover:text-white hover:bg-white/10 transition-colors" />
              </Toast>
            </motion.div>
          )
        })}
      </AnimatePresence>
      <ToastViewport dir="rtl" className={`fixed z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:flex-col ${isMobile ? 'max-w-[90%]' : 'md:max-w-[420px]'} ${positionClasses[position]}`} />
    </ToastProvider>
  )
}
