import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Pagination, PaginationContent, PaginationItem, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { supabase } from "@/integrations/supabase/client";
import { Search, Download } from "lucide-react";
import { format } from "date-fns";

interface Payment {
  id: string;
  created_at: string;
  user_id: string;
  amount: number;
  status: string;
  package_name: string;
  track_id?: string;
  payment_date?: string;
  email?: string;
  full_name?: string;
}

const ITEMS_PER_PAGE = 10;

const PaymentManagement = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  useEffect(() => {
    fetchPayments();
  }, []);
  
  useEffect(() => {
    filterPayments();
  }, [searchTerm, statusFilter, payments]);
  
  const fetchPayments = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from("payments")
        .select("*")
        .order("created_at", { ascending: false });
        
      if (error) throw error;
      
      if (!data || data.length === 0) {
        setPayments([]);
        setFilteredPayments([]);
        setLoading(false);
        return;
      }
      
      const userIds = data.map(payment => payment.user_id);
      
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("id, email")
        .in("id", userIds);
        
      if (userError) throw userError;
      
      const { data: profileData, error: profileError } = await supabase
        .from("profiles")
        .select("id, full_name")
        .in("id", userIds);
        
      if (profileError) throw profileError;
      
      const emailMap: { [key: string]: string } = {};
      const nameMap: { [key: string]: string } = {};
      
      userData?.forEach(user => {
        emailMap[user.id] = user.email;
      });
      
      profileData?.forEach(profile => {
        nameMap[profile.id] = profile.full_name || "";
      });
      
      const formattedPayments = data.map(payment => ({
        ...payment,
        email: emailMap[payment.user_id] || "ایمیل نامشخص",
        full_name: nameMap[payment.user_id] || ""
      }));
      
      setPayments(formattedPayments);
      setFilteredPayments(formattedPayments);
      setTotalPages(Math.ceil(formattedPayments.length / ITEMS_PER_PAGE));
    } catch (error) {
      console.error("خطا در بارگذاری پرداخت‌ها:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const filterPayments = () => {
    let result = [...payments];
    
    if (searchTerm) {
      result = result.filter(
        payment => 
          payment.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          payment.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          payment.track_id?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    if (statusFilter) {
      if (statusFilter === "success") {
        result = result.filter(payment => payment.status === "success");
      } else if (statusFilter === "pending") {
        result = result.filter(payment => payment.status === "pending");
      } else if (statusFilter === "failed") {
        result = result.filter(payment => payment.status === "failed");
      }
    }
    
    setFilteredPayments(result);
    setTotalPages(Math.ceil(result.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
  };
  
  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredPayments.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const getStatusColor = (status: string) => {
    switch (status) {
      case "success":
        return "bg-green-500/20 text-green-400 border-green-500";
      case "pending":
        return "bg-yellow-500/20 text-yellow-400 border-yellow-500";
      case "failed":
        return "bg-red-500/20 text-red-400 border-red-500";
      default:
        return "bg-gray-500/20 text-gray-400 border-gray-500";
    }
  };
  
  const getStatusText = (status: string) => {
    switch (status) {
      case "success":
        return "پرداخت شده";
      case "pending":
        return "در انتظار";
      case "failed":
        return "ناموفق";
      default:
        return status;
    }
  };
  
  const formatDate = (dateString?: string) => {
    if (!dateString) return "نامشخص";
    
    try {
      const date = new Date(dateString);
      const persianDate = format(date, "yyyy/MM/dd - HH:mm");
      return persianDate;
    } catch (error) {
      console.error("خطا در تبدیل تاریخ:", error);
      return "نامشخص";
    }
  };
  
  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat("fa-IR").format(amount);
  };
  
  const exportToCSV = () => {
    const headers = "ID,Email,Name,Amount,Status,Package,TrackID,Date\n";
    const rows = filteredPayments.map(payment => {
      let statusText = "Unknown";
      switch (payment.status) {
        case "success": statusText = "Success"; break;
        case "pending": statusText = "Pending"; break;
        case "failed": statusText = "Failed"; break;
      }
      
      let paymentDate = payment.payment_date || payment.created_at;
      let formattedDate = "Unknown";
      if (paymentDate) {
        try {
          formattedDate = format(new Date(paymentDate), "yyyy/MM/dd - HH:mm");
        } catch (e) {
          formattedDate = "Invalid Date";
        }
      }
      
      return `${payment.id},"${payment.email}","${payment.full_name}",${payment.amount},"${statusText}","${payment.package_name}","${payment.track_id || ''}","${formattedDate}"`;
    }).join("\n");
    
    const csvContent = "data:text/csv;charset=utf-8," + headers + rows;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `payment_report_${new Date().toISOString().slice(0, 10)}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div dir="rtl" className="space-y-6">
      <Card className="bg-gray-800 border-gray-700">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="text-white">مدیریت پرداخت‌ها</CardTitle>
            <Button 
              variant="outline" 
              className="flex items-center gap-2" 
              onClick={exportToCSV}
            >
              <Download className="h-4 w-4" />
              <span>خروجی CSV</span>
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col sm:flex-row gap-4 mb-6">
            <div className="relative flex-1">
              <Search className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="جستجو بر اساس ایمیل، نام یا شناسه تراکنش..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-4 pr-10"
              />
            </div>
            <Select value={statusFilter} onValueChange={setStatusFilter}>
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue placeholder="همه وضعیت‌ها" />
              </SelectTrigger>
              <SelectContent className="bg-gray-800 border-gray-700 text-white">
                <SelectGroup>
                  <SelectItem value="all">همه وضعیت‌ها</SelectItem>
                  <SelectItem value="success">پرداخت شده</SelectItem>
                  <SelectItem value="pending">در انتظار</SelectItem>
                  <SelectItem value="failed">ناموفق</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <Button onClick={fetchPayments}>بارگذاری مجدد</Button>
          </div>
          
          <div className="rounded-md border border-gray-700 overflow-hidden">
            <Table>
              <TableHeader className="bg-gray-900">
                <TableRow>
                  <TableHead className="text-white">کاربر</TableHead>
                  <TableHead className="text-white text-left">مبلغ</TableHead>
                  <TableHead className="text-white text-left">وضعیت</TableHead>
                  <TableHead className="text-white text-left">بسته</TableHead>
                  <TableHead className="text-white text-left">شناسه تراکنش</TableHead>
                  <TableHead className="text-white text-left">تاریخ</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  Array(5).fill(0).map((_, i) => (
                    <TableRow key={i} className="border-gray-700">
                      <TableCell>
                        <div className="space-y-1">
                          <Skeleton className="h-4 w-32 bg-gray-700" />
                          <Skeleton className="h-3 w-24 bg-gray-700" />
                        </div>
                      </TableCell>
                      <TableCell><Skeleton className="h-4 w-16 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-20 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-24 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-28 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-28 bg-gray-700" /></TableCell>
                    </TableRow>
                  ))
                ) : filteredPayments.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center py-6 text-gray-400">
                      هیچ پرداختی یافت نشد.
                    </TableCell>
                  </TableRow>
                ) : (
                  getCurrentPageItems().map((payment) => (
                    <TableRow key={payment.id} className="border-gray-700">
                      <TableCell>
                        <div>
                          <div className="font-medium text-white">{payment.email}</div>
                          {payment.full_name && (
                            <div className="text-sm text-gray-400">{payment.full_name}</div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="font-medium text-white">
                        {formatAmount(payment.amount)} ریال
                      </TableCell>
                      <TableCell>
                        <Badge className={`${getStatusColor(payment.status)} border`}>
                          {getStatusText(payment.status)}
                        </Badge>
                      </TableCell>
                      <TableCell className="text-white">{payment.package_name}</TableCell>
                      <TableCell className="text-gray-400">
                        {payment.track_id || "نامشخص"}
                      </TableCell>
                      <TableCell className="text-gray-400">
                        {formatDate(payment.payment_date || payment.created_at)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
          
          {filteredPayments.length > 0 && totalPages > 1 && (
            <div className="mt-4 flex justify-center">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={handlePrevPage}
                      className={currentPage === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
                    />
                  </PaginationItem>
                  <PaginationItem className="text-white">
                    صفحه {currentPage} از {totalPages}
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationNext
                      onClick={handleNextPage}
                      className={currentPage === totalPages ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentManagement;
