
import { useEffect, useState } from "react";
import { User, Home as HomeIcon, Video, Image } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { CreditDisplay } from "@/components/CreditDisplay";
import { useMobile } from "@/hooks/use-mobile";
import { motion, AnimatePresence } from "framer-motion";

interface TopBarProps {
  useVideoBackground: boolean;
  onToggleBackground: () => void;
}

export const TopBar = ({ useVideoBackground, onToggleBackground }: TopBarProps) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const isMobile = useMobile();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="fixed top-0 right-0 left-0 h-12 bg-black/30 backdrop-blur-md px-4 flex items-center justify-between text-white z-50" dir="rtl">
      {!isMobile && (
        <div className="flex items-center gap-4">
          <div className="flex items-center ml-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={onToggleBackground}
              className="w-8 h-8 p-1 rounded-full hover:bg-white/10 transition-colors"
            >
              <AnimatePresence mode="wait">
                {useVideoBackground ? (
                  <motion.div
                    key="video-icon"
                    initial={{ rotateY: 90, opacity: 0 }}
                    animate={{ rotateY: 0, opacity: 1 }}
                    exit={{ rotateY: -90, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center"
                  >
                    <Video className="w-4 h-4 text-white" />
                  </motion.div>
                ) : (
                  <motion.div
                    key="image-icon"
                    initial={{ rotateY: 90, opacity: 0 }}
                    animate={{ rotateY: 0, opacity: 1 }}
                    exit={{ rotateY: -90, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center"
                  >
                    <Image className="w-4 h-4 text-white" />
                  </motion.div>
                )}
              </AnimatePresence>
            </Button>
          </div>
        </div>
      )}
      <div className={`${isMobile ? 'mx-auto' : 'absolute left-1/2 -translate-x-1/2'}`}>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigate('/app')}
          className="w-12 h-12 text-white hover:text-white/80 hover:scale-110 transition-transform"
        >
          <HomeIcon className="w-6 h-6" />
        </Button>
      </div>
      <div className="flex items-center gap-2 md:gap-4">
        <span className="hidden md:inline">{currentTime.toLocaleTimeString('fa-IR')}</span>
        <CreditDisplay />
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigate('/dashboard')}
          className="text-white hover:text-white/80"
        >
          <User className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
}
