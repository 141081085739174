import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./lib/auth";
import { AppRoutes } from "./routes";
import { Toaster } from "@/components/ui/toaster";
import MaintenancePage from "./components/MaintenancePage";
import { supabase } from "@/integrations/supabase/client";

import "./App.css";

const queryClient = new QueryClient();

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const checkSiteStatus = async () => {
      try {
        const { data: result, error } = await supabase
          .rpc('execute_sql', {
            sql_query: "SELECT site_online FROM site_settings WHERE id = 1"
          });
          
        if (error) {
          console.error("خطا در بررسی وضعیت سایت:", error);
          setIsMaintenance(false);
        } else {
          if (result && Array.isArray(result) && result.length > 0) {
            const rawData = result[0];
            const siteData = rawData as Record<string, unknown>;
            const siteOnline = typeof siteData.site_online === 'boolean' ? siteData.site_online : true;
            setIsMaintenance(!siteOnline);
          } else {
            setIsMaintenance(false);
          }
        }
      } catch (error) {
        console.error("خطا:", error);
        setIsMaintenance(false);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkSiteStatus();
  }, []);
  
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }
  
  if (isMaintenance && !window.location.pathname.startsWith('/admin')) {
    return <MaintenancePage />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <AppRoutes />
          <Toaster position="bottom-center" />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
