import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { format } from "date-fns-jalali";
import { supabase } from "@/integrations/supabase/client";
import { useState, useEffect, useCallback } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { 
  Pagination, 
  PaginationContent, 
  PaginationEllipsis, 
  PaginationItem, 
  PaginationLink, 
  PaginationNext, 
  PaginationPrevious 
} from "@/components/ui/pagination";

interface HistoryItem {
  id: string;
  tool_id: string;
  credit_cost: number;
  created_at: string;
  generated_content?: string;
  user_id: string;
}

interface HistoryTabProps {
  usageHistory: HistoryItem[];
}

const HistoryTab = ({ usageHistory }: HistoryTabProps) => {
  const [toolNames, setToolNames] = useState<Record<string, string>>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [displayHistory, setDisplayHistory] = useState<HistoryItem[]>([]);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [loadingItems, setLoadingItems] = useState<Record<string, boolean>>({});
  const [contentCache, setContentCache] = useState<Record<string, string>>({});
  const itemsPerPage = 5;
  
  const totalPages = Math.ceil(usageHistory.length / itemsPerPage);
  
  const getToolNames = useCallback(() => {
    const toolNameMap: Record<string, string> = {
      "caption-generator": "تولید کپشن",
      "hashtag-generator": "تولید هشتگ",
      "bio-analysis": "تحلیل بیو",
      "bio": "تحلیل بیو",
      "aesthetic-advisor": "مشاور زیبایی",
      "growth-mentor": "مشاور رشد",
      "growth": "مشاور رشد",
      "dm-consultant": "مشاور پیام‌های مستقیم",
      "dm": "مشاور پیام‌های مستقیم",
      "story-idea-generator": "تولید ایده استوری",
      "story": "تولید ایده استوری",
      "content-calendar": "تقویم محتوا",
      "carousel-planner": "برنامه‌ریز کروسل",
      "outreach-generator": "تولید پیام دسترسی",
      "content-repurposer": "بازتولید محتوا",
      "holiday-campaign-generator": "تولید کمپین تعطیلات",
      "holiday": "تولید کمپین تعطیلات",
      "promotion-advisor": "مشاور تبلیغات",
      "promotion": "مشاور تبلیغات",
      "quiz-generator": "تولید کوئیز",
      "quiz": "تولید کوئیز",
      "text-translator": "مترجم متن",
      "translator": "مترجم متن",
      "caption-editor": "ویرایشگر کپشن"
    };
    
    return toolNameMap;
  }, []);
  
  useEffect(() => {
    setToolNames(getToolNames());
  }, [getToolNames]);

  useEffect(() => {
    if (currentPage > 1 && currentPage > totalPages) {
      setCurrentPage(1);
      return;
    }
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
    const currentItems = usageHistory.slice(indexOfFirstItem, indexOfLastItem);
    
    const newItems = currentItems.map(item => ({...item}));
    
    setDisplayHistory(newItems);
    
    const newLoadingItems: Record<string, boolean> = {};
    newItems.forEach(item => {
      if (!contentCache[item.id]) {
        newLoadingItems[item.id] = false;
      } else {
        item.generated_content = contentCache[item.id];
      }
    });
    
    setLoadingItems(newLoadingItems);
  }, [currentPage, usageHistory, totalPages, contentCache]);
  
  useEffect(() => {
    const itemsNeedingContent = displayHistory.filter(item => 
      !item.generated_content && loadingItems[item.id]
    );
    
    if (itemsNeedingContent.length === 0) return;
    
    const fetchGeneratedContent = async () => {
      try {
        const enhancedItems = await Promise.all(
          itemsNeedingContent.map(async (item) => {
            const toolId = item.tool_id;
            let data = null;
            
            try {
              if (toolId === 'caption-generator' || toolId === 'caption') {
                const { data: captionData, error } = await supabase
                  .from('generated_captions')
                  .select('caption, hashtags')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                  
                if (error) throw error;
                if (captionData?.[0]) {
                  data = captionData[0].caption;
                }
              } else if (toolId === 'hashtag-generator' || toolId === 'hashtag') {
                const { data: hashtagData, error } = await supabase
                  .from('hashtag_generations')
                  .select('hashtags')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                  
                if (error) throw error;
                if (hashtagData?.[0]) {
                  try {
                    let hashtagsStr = "";
                    const hashtags = hashtagData[0].hashtags;
                    
                    if (Array.isArray(hashtags)) {
                      hashtagsStr = hashtags.join(' ');
                    } else if (typeof hashtags === 'object' && hashtags !== null) {
                      hashtagsStr = JSON.stringify(hashtags);
                    } else {
                      hashtagsStr = String(hashtags);
                    }
                    
                    data = hashtagsStr;
                  } catch (parseError) {
                    console.error("Error parsing hashtags:", parseError);
                    data = String(hashtagData[0].hashtags);
                  }
                }
              } else if (toolId === 'bio-analysis' || toolId === 'bio') {
                const { data: bioData, error } = await supabase
                  .from('bio_analyses')
                  .select('analysis')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                  
                if (error) throw error;
                if (bioData?.[0]) {
                  data = bioData[0].analysis;
                }
              } else if (toolId === 'promotion' || toolId === 'promotion-advisor') {
                const { data: promotionData, error } = await supabase
                  .from('promotion_suggestions')
                  .select('*')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                
                if (error) throw error;
                if (promotionData?.[0]) {
                  try {
                    let ideasContent = "";
                    let nichesContent = "";
                    
                    if (Array.isArray(promotionData[0].target_niches)) {
                      nichesContent = promotionData[0].target_niches.join('\n- ');
                    } else if (typeof promotionData[0].target_niches === 'object' && promotionData[0].target_niches !== null) {
                      nichesContent = JSON.stringify(promotionData[0].target_niches, null, 2);
                    } else {
                      nichesContent = String(promotionData[0].target_niches || "");
                    }
                    
                    if (Array.isArray(promotionData[0].promotion_ideas)) {
                      ideasContent = promotionData[0].promotion_ideas.join('\n- ');
                    } else if (typeof promotionData[0].promotion_ideas === 'object' && promotionData[0].promotion_ideas !== null) {
                      ideasContent = JSON.stringify(promotionData[0].promotion_ideas, null, 2);
                    } else {
                      ideasContent = String(promotionData[0].promotion_ideas || "");
                    }
                    
                    data = `بازار هدف:\n- ${nichesContent}\n\nایده‌های تبلیغاتی:\n- ${ideasContent}`;
                    
                    const explanationContent = (promotionData[0] as any).explanation || "";
                    
                    if (explanationContent) {
                      data += `\n\nتوضیحات استراتژی:\n${explanationContent}`;
                    }
                  } catch (error) {
                    console.error("Error processing promotion data:", error);
                    data = JSON.stringify(promotionData[0], null, 2);
                  }
                }
              } else if (toolId === 'content-calendar') {
                const { data: calendarData, error } = await supabase
                  .from('content_calendars')
                  .select('content_plan')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                  
                if (error) throw error;
                if (calendarData?.[0]) {
                  data = JSON.stringify(calendarData[0].content_plan, null, 2);
                }
              } else if (toolId === 'quiz' || toolId === 'quiz-generator') {
                const { data: quizData, error } = await supabase
                  .from('generated_quizzes')
                  .select('questions')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                
                if (error) throw error;
                if (quizData?.[0]) {
                  try {
                    if (typeof quizData[0].questions === 'string') {
                      const parsedQuestions = JSON.parse(quizData[0].questions);
                      data = JSON.stringify(parsedQuestions, null, 2);
                    } else {
                      data = JSON.stringify(quizData[0].questions, null, 2);
                    }
                  } catch (error) {
                    console.error("Error processing quiz data:", error);
                    data = String(quizData[0].questions);
                  }
                }
              } else if (toolId === 'story' || toolId === 'story-idea-generator') {
                const { data: storyData, error } = await supabase
                  .from('story_ideas')
                  .select('generated_ideas, intention')
                  .order('created_at', { ascending: false })
                  .lt('created_at', new Date(new Date(item.created_at).getTime() + 5000).toISOString())
                  .gte('created_at', new Date(new Date(item.created_at).getTime() - 60000).toISOString())
                  .limit(1);
                
                if (error) throw error;
                if (storyData?.[0]) {
                  let ideasContent = "";
                  
                  try {
                    const generatedIdeas = storyData[0].generated_ideas;
                    
                    if (typeof generatedIdeas === 'object' && generatedIdeas !== null) {
                      if ('ideas' in generatedIdeas && Array.isArray(generatedIdeas.ideas)) {
                        ideasContent = generatedIdeas.ideas.map((idea: any, index: number) => {
                          const title = idea.title || '';
                          const description = idea.description || '';
                          
                          let segments = '';
                          if (idea.segments && Array.isArray(idea.segments)) {
                            segments = `سگمنت‌ها:\n- ${idea.segments.join('\n- ')}`;
                          }
                          
                          const cta = idea.cta ? `\nاقدام نهایی: ${idea.cta}` : '';
                          
                          return `ایده ${index + 1}:\n${title}\n\n${description}\n\n${segments}${cta}`;
                        }).join('\n\n-----------------\n\n');
                        
                        if (typeof generatedIdeas.explanation === 'string') {
                          ideasContent += `\n\n-----------------\n\nتوضیحات استراتژی:\n${generatedIdeas.explanation}`;
                        }
                      } else if (Array.isArray(generatedIdeas)) {
                        ideasContent = generatedIdeas.map((idea: any, index: number) => {
                          if (typeof idea === 'string') {
                            return `ایده ${index + 1}:\n${idea}`;
                          } else if (typeof idea === 'object') {
                            return `ایده ${index + 1}:\n${JSON.stringify(idea, null, 2)}`;
                          }
                          return `ایده ${index + 1}: ${String(idea)}`;
                        }).join('\n\n-----------------\n\n');
                      } else {
                        ideasContent = JSON.stringify(generatedIdeas, null, 2);
                      }
                    } else if (Array.isArray(generatedIdeas)) {
                      ideasContent = generatedIdeas.join('\n\n- ');
                    } else {
                      ideasContent = String(generatedIdeas);
                    }
                    
                    data = ideasContent;
                    
                    if (storyData[0].intention) {
                      data = `هدف: ${storyData[0].intention}\n\n${data}`;
                    }
                  } catch (error) {
                    console.error("Error parsing story ideas:", error);
                    data = JSON.stringify(storyData[0].generated_ideas, null, 2);
                  }
                }
              }
            } catch (error) {
              console.error(`Error fetching data for tool ${toolId}:`, error);
            }
            
            if (data) {
              setContentCache(prev => ({
                ...prev,
                [item.id]: data
              }));
            }
            
            setLoadingItems(prev => {
              const updated = {...prev};
              delete updated[item.id];
              return updated;
            });
            
            return { 
              ...item, 
              generated_content: data || "محتوایی برای نمایش موجود نیست." 
            };
          })
        );
        
        setDisplayHistory(prevItems => {
          const updatedItems = [...prevItems];
          
          enhancedItems.forEach(enhancedItem => {
            const index = updatedItems.findIndex(item => item.id === enhancedItem.id);
            if (index !== -1) {
              updatedItems[index] = enhancedItem;
            }
          });
          
          return updatedItems;
        });
      } catch (error) {
        console.error("Error fetching generated content:", error);
      }
    };
    
    fetchGeneratedContent();
  }, [displayHistory, loadingItems]);
  
  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages || pageNumber === currentPage) {
      return;
    }
    console.log(`Changing to page ${pageNumber}`);
    setCurrentPage(pageNumber);
  };
  
  const getPageNumbers = () => {
    const pageNumbers: (number | string)[] = [];
    
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }
    
    return pageNumbers;
  };
  
  return (
    <Card className="bg-black/30 backdrop-blur-xl border border-white/10 shadow-xl text-white">
      <CardHeader>
        <CardTitle className="text-white">تاریخچه استفاده</CardTitle>
      </CardHeader>
      <CardContent>
        {usageHistory.length === 0 ? (
          <div className="text-center py-10 text-gray-300">
            هنوز از هیچ ابزاری استفاده نکرده‌اید.
          </div>
        ) : (
          <>
            <div className="space-y-4">
              {displayHistory.map((item) => (
                <div key={item.id} className="border border-white/10 rounded-lg p-4 bg-white/5 backdrop-blur-md">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h3 className="font-medium text-white">{toolNames[item.tool_id] || item.tool_id}</h3>
                      <p className="text-sm text-gray-300">
                        {format(new Date(item.created_at), 'yyyy/MM/dd - HH:mm')}
                      </p>
                    </div>
                    <div className="bg-indigo-500/20 text-indigo-300 text-xs px-2 py-1 rounded border border-indigo-500/30">
                      {item.credit_cost} اعتبار
                    </div>
                  </div>
                  
                  {/* Content display is temporarily disabled
                  {loadingItems[item.id] ? (
                    <div className="mt-3 pt-3 border-t border-white/10">
                      <Skeleton className="h-20 bg-white/10 rounded-md" />
                    </div>
                  ) : item.generated_content && (
                    <div className="mt-3 pt-3 border-t border-white/10">
                      <p className="text-xs text-indigo-200 mb-1">محتوای تولید شده:</p>
                      <div className="bg-white/10 p-3 rounded-md text-gray-300 text-sm whitespace-pre-wrap max-h-40 overflow-y-auto">
                        {item.generated_content}
                      </div>
                    </div>
                  )} */}
                </div>
              ))}
            </div>
            
            {totalPages > 1 && (
              <Pagination dir="rtl" className="mt-6">
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious 
                      onClick={() => handlePageChange(currentPage - 1)}
                      className={currentPage === 1 ? "opacity-50 cursor-not-allowed text-white" : "text-white hover:bg-white/10"}
                    />
                  </PaginationItem>
                  
                  {getPageNumbers().map((number, i) => (
                    <PaginationItem key={i}>
                      {number === '...' ? (
                        <PaginationEllipsis className="text-white" />
                      ) : (
                        <PaginationLink
                          isActive={currentPage === number}
                          onClick={() => typeof number === 'number' && handlePageChange(number)}
                          className={`text-white ${currentPage === number ? 'bg-indigo-600 hover:bg-indigo-700' : 'hover:bg-white/10'}`}
                        >
                          {number}
                        </PaginationLink>
                      )}
                    </PaginationItem>
                  ))}
                  
                  <PaginationItem>
                    <PaginationNext 
                      onClick={() => handlePageChange(currentPage + 1)}
                      className={currentPage === totalPages ? "opacity-50 cursor-not-allowed text-white" : "text-white hover:bg-white/10"}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default HistoryTab;
