import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { InfoBox } from "@/components/InfoBox";

interface BioAnalysisFormData {
  bio: string;
  isCommercial: string;
}

export const BioAnalysis = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [analysis, setAnalysis] = useState<string | null>(null);
  const { toast } = useToast();
  const form = useForm<BioAnalysisFormData>({
    defaultValues: {
      bio: "",
      isCommercial: "false"
    }
  });
  const { profile, refreshProfile } = useAuth();

  const formatAnalysis = (rawAnalysis: string): string => {
    return rawAnalysis
      .replace(/^# (.*$)/gim, '<h2 class="text-xl font-bold mt-4 mb-2">$1</h2>')
      .replace(/^## (.*$)/gim, '<h3 class="text-lg font-bold mt-3 mb-2">$1</h3>')
      .replace(/^### (.*$)/gim, '<h4 class="text-md font-bold mt-2 mb-1">$1</h4>')
      .replace(/\n/g, '<br />')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  const onSubmit = async (data: BioAnalysisFormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }

    const toolInfo = { id: "bio", creditCost: 6 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      const { data: result, error } = await supabase.functions.invoke("analyze-bio", {
        body: {
          bio: data.bio,
          language: "farsi",
          isCommercial: data.isCommercial === "true"
        }
      });

      if (error) throw error;
      
      if (result && result.error) {
        throw new Error(result.error);
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      try {
        await supabase.from("bio_analyses").insert({
          bio_text: data.bio,
          analysis: result.analysis
        });
      } catch (saveException) {
        console.error("Exception saving bio analysis:", saveException);
      }

      const formattedAnalysis = formatAnalysis(result.analysis);
      setAnalysis(formattedAnalysis);

      await refreshProfile();

      toast({
        title: "تحلیل انجام شد",
        description: "بیوی شما با موفقیت تحلیل شد"
      });

    } catch (error: any) {
      toast({
        title: "خطا",
        description: error.message || "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6" dir="rtl">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="bio"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-white">بیو فعلی</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="بیوی اینستاگرام خود را وارد کنید..."
                    className="h-32 text-gray-800"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="isCommercial"
            render={({ field }) => (
              <FormItem className="border border-gray-200 rounded-md p-4">
                <FormLabel className="text-white text-base">نوع پروفایل</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-col space-y-1"
                  >
                    <div className="flex items-center space-x-2 space-x-reverse">
                      <RadioGroupItem value="false" id="personal" />
                      <Label
                        htmlFor="personal"
                        className="cursor-pointer text-white mx-[20px] px-0 rounded-none"
                      >
                        شخصی
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2 space-x-reverse">
                      <RadioGroupItem value="true" id="commercial" />
                      <Label
                        htmlFor="commercial"
                        className="cursor-pointer text-white px-0 mx-[20px]"
                      >
                        تجاری
                      </Label>
                    </div>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />

          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            تحلیل بیو
          </Button>
        </form>
      </Form>

      {analysis ? (
        <Card className="p-4">
          <h3 className="font-bold mb-2 text-lg">تحلیل بیو</h3>
          <div
            className="text-foreground prose-sm"
            dangerouslySetInnerHTML={{ __html: analysis }}
          />
        </Card>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>آنالیز و بهینه‌سازی بیو</strong> بیوی فعلی پروفایل شما را بررسی کرده و نقاط قوت و ضعف آن را شناسایی می‌کند. 
            سپس پیشنهادات اختصاصی ��رای بهبود آن ارائه می‌دهد تا بیوی شما جذاب‌تر، حرفه‌ای‌تر و تأثیرگذارتر شود. 
            با یک بیوی بهینه‌شده، نرخ تبدیل بازدیدکننده به فالوور را افزایش دهید و هویت برند خود را بهتر نمایش دهید.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
