
import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import type { Database } from '@/integrations/supabase/types';
import { toast } from 'sonner';

type Profile = Database['public']['Tables']['profiles']['Row'];

interface AuthContextType {
  session: any;
  profile: Profile | null;
  refreshProfile: () => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Key for the device ID in localStorage
const DEVICE_ID_KEY = 'instayar_device_id';
// Key for auth state
const AUTH_STATE_KEY = 'instayar_auth_state';
// Session health check interval (5 minutes)
const SESSION_HEALTH_CHECK = 5 * 60 * 1000;

// Generate a unique device ID with more entropy
const generateDeviceId = () => {
  const cryptoValues = new Uint32Array(4);
  if (window.crypto && window.crypto.getRandomValues) {
    window.crypto.getRandomValues(cryptoValues);
  }
  
  return Array.from(cryptoValues)
    .map(num => num.toString(16))
    .join('-') + 
    '-' + 
    Date.now().toString(36);
};

// Get or create a device ID
const getDeviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (!deviceId) {
    deviceId = generateDeviceId();
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<any>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastActivity, setLastActivity] = useState<number>(Date.now());

  // Track user activity
  useEffect(() => {
    const updateActivity = () => {
      setLastActivity(Date.now());
    };
    
    // Add event listeners for common user interactions
    const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, updateActivity);
    });
    
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, updateActivity);
      });
    };
  }, []);

  // Regular session health check
  useEffect(() => {
    let healthCheckInterval: number;
    
    if (session) {
      healthCheckInterval = window.setInterval(async () => {
        try {
          // Check if session is still valid
          const { data } = await supabase.auth.getSession();
          if (!data.session) {
            // Session is no longer valid, sign out
            await signOut();
            toast.error('جلسه شما منقضی شده است. لطفاً دوباره وارد شوید.', {
              id: 'session-expired',
            });
          }
        } catch (error) {
          console.error('خطا در بررسی وضعیت جلسه:', error);
        }
      }, SESSION_HEALTH_CHECK);
    }
    
    return () => {
      if (healthCheckInterval) {
        clearInterval(healthCheckInterval);
      }
    };
  }, [session]);

  const fetchProfile = async (userId: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select()
      .eq('id', userId)
      .single();
    
    if (error) {
      console.error('خطا در دریافت پروفایل:', error);
      return;
    }
    
    if (data) {
      setProfile(data);
      // Cache profile data with timestamp for faster loads
      localStorage.setItem('cached_profile', JSON.stringify({
        data: data,
        timestamp: Date.now()
      }));
    }
  };

  const refreshProfile = async () => {
    if (session?.user) {
      await fetchProfile(session.user.id);
    }
  };

  const signOut = async () => {
    try {
      if (session?.user) {
        // Log the sign out event
        try {
          await supabase.from('user_events').insert({
            user_id: session.user.id,
            event_type: 'user_sign_out',
            event_data: {
              timestamp: new Date().toISOString()
            }
          });
        } catch (error) {
          console.error('خطا در ثبت رویداد خروج:', error);
        }
      }
      
      // Clear local auth state and cache
      localStorage.removeItem(AUTH_STATE_KEY);
      localStorage.removeItem('cached_profile');
      sessionStorage.removeItem('admin_role');
      sessionStorage.removeItem('admin_role_timestamp');
      
      // Sign out from Supabase
      await supabase.auth.signOut();
      
      // Reset state
      setSession(null);
      setProfile(null);
    } catch (error) {
      console.error('خطا در خروج از حساب:', error);
      toast.error('خطا در خروج از حساب کاربری');
    }
  };

  useEffect(() => {
    // Try to load cached profile first for faster UI rendering
    const cachedProfileJSON = localStorage.getItem('cached_profile');
    if (cachedProfileJSON) {
      try {
        const cached = JSON.parse(cachedProfileJSON);
        // Use cache if it's less than 30 minutes old
        if (Date.now() - cached.timestamp < 30 * 60 * 1000) {
          setProfile(cached.data);
        }
      } catch (e) {
        console.error('خطا در بازیابی پروفایل از حافظه موقت:', e);
      }
    }

    supabase.auth.getSession().then(({ data: { session: initialSession } }) => {
      setSession(initialSession);
      if (initialSession?.user) {
        fetchProfile(initialSession.user.id);
      }
      setIsLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(session);
      
      if (session?.user) {
        // If user just signed in, record their device ID
        if (_event === 'SIGNED_IN') {
          try {
            const deviceId = getDeviceId();
            const { error } = await supabase.from('user_devices').insert({
              user_id: session.user.id,
              device_id: deviceId,
              device_info: {
                userAgent: navigator.userAgent,
                language: navigator.language,
                platform: navigator.platform,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                timestamp: new Date().toISOString()
              }
            });
            
            if (error && error.code !== '23505') { // Ignore duplicate entries
              console.error('Error recording device ID:', error);
            }
            
            // Track login event
            await supabase.from('user_events').insert({
              user_id: session.user.id,
              event_type: 'user_sign_in',
              event_data: {
                timestamp: new Date().toISOString()
              }
            });
            
          } catch (err) {
            console.error('Failed to record device ID:', err);
          }
          
          // Store auth state for faster page loads
          localStorage.setItem(AUTH_STATE_KEY, JSON.stringify({
            isAuthenticated: true,
            timestamp: Date.now()
          }));
        }
        
        fetchProfile(session.user.id);
      } else {
        setProfile(null);
        // Clear auth state
        localStorage.removeItem(AUTH_STATE_KEY);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ 
      session, 
      profile, 
      refreshProfile, 
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
