
import { useState, useEffect } from 'react';

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      // Use multiple criteria for better mobile detection:
      // 1. Screen width breakpoint (standard for responsive design)
      const isSmallScreen = window.innerWidth <= 768;
      
      // 2. Portrait orientation check (height > width)
      const isPortrait = window.innerHeight > window.innerWidth;
      
      // 3. Touch capability check (most mobile devices are touch-enabled)
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      
      // Consider a device mobile if it has a small screen OR 
      // (is in portrait mode AND has touch capabilities)
      setIsMobile(isSmallScreen || (isPortrait && isTouchDevice));
    };

    // Check on mount
    checkMobile();

    // Check on resize
    window.addEventListener('resize', checkMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
};
