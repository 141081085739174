import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { MapPin, Phone, Mail, Instagram } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import SEO from "@/components/SEO";
import { useState, useEffect } from "react";
import { MarketingHeader } from "@/components/MarketingHeader";

const Contact = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [isEmailHovered, setIsEmailHovered] = useState(false);

  return (
    <div className={`bg-white ${!isMobile ? 'min-h-screen' : 'mobile-page-container'}`} dir="rtl">
      <SEO 
        title="تماس با اینستایار | پشتیبانی و خدمات مشتریان"
        description="با تیم پشتیبانی اینستایار در تماس باشید. ما آماده پاسخگویی به سوالات و راهنمایی شما در استفاده از دستیار هوشمند اینستاگرام هستیم."
        keywords="تماس با اینستایار, پشتیبانی اینستایار, خدمات مشتریان اینستاگرام, تماس با ما"
      />
      
      {/* Use the standardized marketing header */}
      <MarketingHeader currentPage="contact" />

      {/* Hero Section */}
      <section className="py-8 md:py-16 px-4 md:px-6 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4 md:mb-6">تماس با ما</h1>
          <p className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8 leading-relaxed">
            ما همیشه آماده کمک و پاسخگویی به سؤالات شما هستیم. از هر طریقی که راحت‌تر هستید، با ما در تماس باشید.
          </p>
        </div>
      </section>

      {/* Contact Content */}
      <section className="py-12 px-4 md:px-6">
        <div className="max-w-6xl mx-auto grid md:grid-cols-5 gap-8 md:gap-12">
          {/* Contact Information */}
          <div className="md:col-span-2">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">اطلاعات تماس</h2>
            
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center rounded-lg bg-indigo-100 text-indigo-600">
                  <MapPin className="h-6 w-6" />
                </div>
                <div className="mr-4">
                  <h3 className="text-lg font-medium text-gray-900">آدرس دفتر</h3>
                  <address className="mt-1 text-gray-600 not-italic">
                    تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117
                  </address>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center rounded-lg bg-indigo-100 text-indigo-600">
                  <Phone className="h-6 w-6" />
                </div>
                <div className="mr-4">
                  <h3 className="text-lg font-medium text-gray-900">شماره تماس</h3>
                  <p className="mt-1 text-gray-600">
                    <a href="tel:02177942281" className="hover:text-indigo-600">۰۲۱-۷۷۹۴۲۲۸۱</a>
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center rounded-lg bg-indigo-100 text-indigo-600">
                  <Mail className="h-6 w-6" />
                </div>
                <div className="mr-4">
                  <h3 className="text-lg font-medium text-gray-900">ایمیل</h3>
                  <p className="mt-1 text-gray-600">
                    <a href="mailto:info@instayar.com" className="hover:text-indigo-600">info@instayar.com</a>
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center rounded-lg bg-indigo-100 text-indigo-600">
                  <Instagram className="h-6 w-6" />
                </div>
                <div className="mr-4">
                  <h3 className="text-lg font-medium text-gray-900">اینستاگرام</h3>
                  <p className="mt-1 text-gray-600">
                    <a href="https://instagram.com/instayar.ai" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-600">@instayar.ai</a>
                  </p>
                </div>
              </div>
            </div>
            
            <div className="mt-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">ساعات کاری</h3>
              <ul className="space-y-2 text-gray-600">
                <li>شنبه تا چهارشنبه: ۹ صبح تا ۶ عصر</li>
                <li>پنجشنبه: ۹ صبح تا ۱ بعد از ظهر</li>
                <li>جمعه: تعطیل</li>
              </ul>
            </div>
          </div>
          
          {/* Animated Email Container (replacing the Contact Form) */}
          <div className="md:col-span-3">
            <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-xl p-8 md:p-12 shadow-lg relative overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full bg-white opacity-10 transform -skew-x-12"></div>
              
              <div 
                className="relative z-10 text-center transition-all duration-500 transform"
                onMouseEnter={() => setIsEmailHovered(true)}
                onMouseLeave={() => setIsEmailHovered(false)}
                style={{
                  transform: isEmailHovered ? 'scale(1.05)' : 'scale(1)',
                }}
              >
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-6">ارتباط با ما</h2>
                <div className="w-20 h-20 bg-white/20 rounded-full flex items-center justify-center mx-auto mb-6">
                  <Mail className="h-10 w-10 text-white" />
                </div>
                <p className="text-white text-lg mb-4">برای ارتباط با ما می‌توانید به ایمیل زیر پیام دهید:</p>
                <a 
                  href="mailto:info@instayar.com" 
                  className="text-xl md:text-2xl font-bold text-white block hover:underline transition-all duration-300"
                  style={{
                    textShadow: isEmailHovered ? '0 0 10px rgba(255, 255, 255, 0.8)' : 'none',
                  }}
                >
                  info@instayar.com
                </a>
                <p className="text-white/80 mt-6">ما در اسرع وقت به پیام شما پاسخ خواهیم داد</p>
              </div>
              
              {/* Animated circles in background */}
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-white/10 rounded-full"></div>
              <div className="absolute -top-20 -left-20 w-60 h-60 bg-white/5 rounded-full"></div>
              <div className="absolute top-1/2 left-1/4 w-16 h-16 bg-white/10 rounded-full transform -translate-x-1/2 -translate-y-1/2"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Google Map */}
      <div className="py-8 px-4 md:px-6">
        <div className="max-w-6xl mx-auto">
          <div className="w-full h-96 rounded-xl overflow-hidden shadow-md">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.4776867413337!2d51.475290815614355!3d35.73275808018335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e00a61f30034b%3A0xe59cad2972c94831!2sTehranpars%2C%20Tehran%20Province%2C%20Iran!5e0!3m2!1sen!2s!4v1626897654861!5m2!1sen!2s"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              title="Google Maps Location"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-8 md:py-12 px-4 md:px-6 mt-12">
        <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">اینستایار</h3>
            <p className="mb-3 md:mb-4 text-sm md:text-base">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">لینک‌های مفید</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">خدمات</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">تماس با ما</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-8 md:mt-12 pt-6 md:pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
