
import React from "react";
import { motion } from "framer-motion";
import type { Tool } from "@/types/tool";

interface MobileAppGridProps {
  tools: Tool[];
  onToolClick: (toolId: string) => void;
}

export const MobileAppGrid = ({ tools, onToolClick }: MobileAppGridProps) => {
  // Group tools into chunks of 8 tools per page
  const itemsPerPage = 8;
  const pages = [];
  
  for (let i = 0; i < tools.length; i += itemsPerPage) {
    pages.push(tools.slice(i, i + itemsPerPage));
  }

  return (
    <div className="mobile-app-grid">
      {tools.map((tool) => (
        <motion.div
          key={tool.id}
          className="mobile-app-item"
          whileTap={{ scale: 0.95 }}
        >
          <div 
            className="mobile-app-icon"
            onClick={() => onToolClick(tool.id)}
          >
            <tool.icon className="w-8 h-8 text-white" />
          </div>
          <span className="mobile-app-label">{tool.shortTitle}</span>
        </motion.div>
      ))}
    </div>
  );
};
