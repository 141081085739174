import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2, Copy, Check, Info, Lightbulb, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { Switch } from "@/components/ui/switch";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

interface FormData {
  description: string;
  tone: string;
  length: string;
  includeCTA: boolean;
}

export const CaptionGenerator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [generatedCaption, setGeneratedCaption] = useState<string | null>(null);
  const [generatedHashtags, setGeneratedHashtags] = useState<string[] | null>(null);
  const [explanation, setExplanation] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const [showExplanation, setShowExplanation] = useState(true);
  const {
    toast
  } = useToast();
  const form = useForm<FormData>({
    defaultValues: {
      tone: "informal",
      length: "medium",
      includeCTA: false
    }
  });
  const {
    profile,
    refreshProfile
  } = useAuth();

  const onSubmit = async (data: FormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }
    const toolInfo = {
      id: "caption",
      creditCost: 3
    };
    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }
    setIsLoading(true);
    try {
      console.log("Submitting request with data:", data);
      const response = await supabase.functions.invoke('generate-caption', {
        body: {
          description: data.description,
          tone: data.tone,
          length: data.length,
          includeCTA: data.includeCTA
        }
      });
      if (response.error) {
        console.error("Error from edge function:", response.error);
        throw response.error;
      }
      console.log("Response from edge function:", response.data);
      const result = response.data;
      if (!result || typeof result !== 'object') {
        throw new Error('پاسخ نامعتبر از سرور دریافت شد');
      }
      const {
        error: updateError
      } = await supabase.from('profiles').update({
        credits: profile.credits - toolInfo.creditCost
      }).eq('id', profile.id);
      if (updateError) throw updateError;
      const {
        error: insertError
      } = await supabase.from('tool_usage').insert({
        user_id: profile.id,
        tool_id: toolInfo.id,
        credit_cost: toolInfo.creditCost
      });
      if (insertError) throw insertError;
      setGeneratedCaption(result.caption);
      setGeneratedHashtags(result.hashtags);

      // Handle explanation
      if (result.explanation) {
        console.log("Setting explanation:", result.explanation);
        setExplanation(result.explanation);
      } else {
        console.log("No explanation found in the response");
        setExplanation(null);
      }
      await refreshProfile();
      toast({
        title: "کپشن تولید شد",
        description: "کپشن شما با موفقیت تولید شد"
      });
    } catch (error) {
      console.error("Error generating caption:", error);
      toast({
        title: "خطا",
        description: "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    if (!generatedCaption) return;
    let textToCopy = generatedCaption;
    if (generatedHashtags && generatedHashtags.length > 0) {
      textToCopy += '\n\n' + generatedHashtags.map(tag => `#${tag}`).join(' ');
    }
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      toast({
        title: "کپی شد",
        description: "متن به کلیپ‌بورد کپی شد"
      });
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch(err => {
      toast({
        title: "خطا",
        description: "متأسفانه کپی با مشکل مواجه شد",
        variant: "destructive"
      });
    });
  };

  return (
    <div className="space-y-6" dir="rtl">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField control={form.control} name="description" render={({
          field
        }) => <FormItem>
                <FormLabel>توضیحات پست</FormLabel>
                <FormControl>
                  <Textarea placeholder="درباره پست خود توضیح دهید..." className="h-32" {...field} />
                </FormControl>
              </FormItem>} />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormField control={form.control} name="tone" render={({
            field
          }) => <FormItem>
                  <FormLabel>لحن</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="text-black">
                        <SelectValue placeholder="انتخاب لحن" className="text-black" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="text-black">
                      <SelectItem value="formal" className="text-black">رسمی</SelectItem>
                      <SelectItem value="informal" className="text-black">غیررسمی</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>} />
            
            <FormField control={form.control} name="length" render={({
            field
          }) => <FormItem>
                  <FormLabel>طول کپشن</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="text-black">
                        <SelectValue placeholder="انتخاب طول" className="text-black" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="text-black">
                      <SelectItem value="short" className="text-black">کوتاه</SelectItem>
                      <SelectItem value="medium" className="text-black">متوسط</SelectItem>
                      <SelectItem value="long" className="text-black">بلند</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>} />
          </div>
          
          <FormField control={form.control} name="includeCTA" render={({
          field
        }) => <FormItem className="flex flex-row-reverse items-center justify-between rounded-lg border p-3">
                <FormControl>
                  <Switch checked={field.value} onCheckedChange={field.onChange} className="px-0 mx-[9px] text-center" />
                </FormControl>
                <div className="space-y-0.5 text-right">
                  <FormLabel>افزودن دعوت به اقدام</FormLabel>
                  <p className="text-sm text-white/70 mx-0 px-0 py-0 text-right">
                    در انتهای کپشن، از مخاطب بخواهد تا کال تو اکشن CTA انجام دهد
                  </p>
                </div>
              </FormItem>} />
          
          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            تولید کپشن
          </Button>
        </form>
      </Form>

      {generatedCaption ? (
        <div className="space-y-4">
          <Card className="p-4 relative">
            <div className="flex justify-between items-start mb-2">
              <h3 className="font-bold">کپشن</h3>
              <Button variant="ghost" size="sm" className="text-gray-500 hover:text-gray-700" onClick={copyToClipboard}>
                {isCopied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
                <span className="mr-1 text-xs">{isCopied ? "کپی شد" : "کپی"}</span>
              </Button>
            </div>
            
            <p className="whitespace-pre-wrap">{generatedCaption}</p>
            
            {generatedHashtags && generatedHashtags.length > 0 && (
              <div className="mt-4">
                <h3 className="font-bold mb-2">هشتگ‌ها</h3>
                <div className="flex flex-wrap gap-2">
                  {generatedHashtags.map((tag, i) => (
                    <span key={i} className="bg-primary/10 text-primary px-2 py-1 rounded-full text-sm font-medium">
                      #{tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </Card>
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">هوش کپشن: روش نگارش کپشن</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>تولید کپشن هوشمند</strong> به شما کمک می‌کند تا کپشن‌های جذاب و تاثیرگذار برای پست‌های اینستاگرامی خود ایجاد کنید. 
            فقط توضیحی کوتاه درباره محتوای پست خود وارد کنید و لحن و طول مناسب را انتخاب کنید. 
            این ابزار هوشمند به صورت خودکار کپشن‌های حرفه‌ای همراه با هشتگ‌های مرتبط تولید می‌کند که میزان تعامل و دید پست‌های شما را افزایش می‌دهد.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
