import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight, CheckCircle, Code, Rocket, ShieldCheck, TrendingUp, MessageSquare, Hash, Cpu, Globe, Instagram, Layers } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";

const Features = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const featuresList = [
    {
      title: "تولید کپشن هوشمند",
      description: "با استفاده از هوش مصنوعی، کپشن‌های جذاب و مرتبط با محتوای خود تولید کنید.",
      icon: TrendingUp
    },
    {
      title: "تولید هشتگ",
      description: "هشتگ‌های مرتبط و پرطرفدار را به صورت خودکار پیدا کنید.",
      icon: Code
    },
    {
      title: "مشاور هوشمند رشد",
      description: "با تحلیل داده‌ها، بهترین استراتژی‌ها برای رشد پیج خود را بشناسید.",
      icon: Rocket
    },
    {
      title: "امنیت و حریم خصوصی",
      description: "اطلاعات شما نزد ما محفوظ است و با بالاترین استانداردهای امنیتی محافظت می‌شود.",
      icon: ShieldCheck
    },
    {
      title: "پشتیبانی ۲۴/۷",
      description: "تیم پشتیبانی ما همیشه آماده پاسخگویی به سؤالات شماست.",
      icon: CheckCircle
    }
  ];

  const toolsList = [
    {
      title: "تولید کپشن هوشمند",
      description: "کپشن‌های جذاب و تعاملی برای پست‌های اینستاگرام شما با استفاده از هوش مصنوعی تولید می‌شود تا نرخ تعامل را افزایش دهد.",
      icon: MessageSquare
    },
    {
      title: "تولید هشتگ",
      description: "هشتگ‌های مرتبط و پرطرفدار برای افزایش دیده شدن پست‌های شما، با در نظر گرفتن الگوریتم‌های اینستاگرام.",
      icon: Hash
    },
    {
      title: "طراحی کروسل محتوا",
      description: "ایده‌های خلاقانه برای کروسل‌های جذاب که نرخ تعامل را افزایش می‌دهند و زمان ماندگاری کاربر را بیشتر می‌کنند.",
      icon: Layers
    },
    {
      title: "مشاور هوشمند رشد",
      description: "راهنمایی هوشمند برای بهبود استراتژی محتوا و افزایش فالوور با تحلیل داده‌های پیج شما.",
      icon: Cpu
    },
    {
      title: "ترجمه متن حرفه‌ای",
      description: "ترجمه متن‌های شما به فارسی روان و طبیعی برای مخاطبان ایرانی، با حفظ محتوا و مفهوم اصلی.",
      icon: Globe
    },
    {
      title: "بهینه‌سازی بیو",
      description: "تحلیل و بهینه‌سازی بیوگرافی پیج شما برای جذب فالوور بیشتر و تبدیل بازدیدکنندگان به مشتری.",
      icon: Instagram
    }
  ];

  return (
    <div className="bg-white w-full max-w-full overflow-x-hidden" dir="rtl">
      <SEO 
        title="ویژگی‌های اینستایار | دستیار هوشمند اینستاگرام"
        description="با ویژگی‌های دستیار هوشمند اینستایار آشنا شوید. تولید محتوای جذاب و رشد پیج اینستاگرام با قدرت هوش مصنوعی."
        keywords="ویژگی‌های اینستایار, دستیار هوشمند اینستاگرام, تولید محتوا, رشد اینستاگرام"
      />
      
      <MarketingHeader currentPage="features" />

      <section className="py-8 md:py-16 px-4 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4 md:mb-6">ویژگی‌های اینستایار</h1>
          <p className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8 leading-relaxed">
            با اینستایار، تولید محتوای جذاب و افزایش تعامل در اینستاگرام آسان‌تر از همیشه است.
          </p>
        </div>
      </section>

      <section className="py-8 md:py-12 px-4">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {featuresList.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-white rounded-xl p-6 border border-gray-200"
            >
              <div className="w-10 h-10 md:w-12 md:h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-3 md:mb-4">
                <feature.icon className="w-5 h-5 md:w-6 md:h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">{feature.title}</h3>
              <p className="text-gray-600 text-sm md:text-base">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="py-8 md:py-16 px-4 bg-indigo-50">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-3 md:mb-4">ابزارهای هوشمند اینستایار</h2>
            <p className="text-base md:text-lg text-gray-600 max-w-3xl mx-auto">
              اینستایار با مجموعه‌ای از ابزارهای هوشمند، کار تولید محتوا و رشد پیج اینستاگرام را برای شما آسان می‌کند.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
            {toolsList.map((tool, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow border border-gray-100"
              >
                <div className="w-10 h-10 md:w-12 md:h-12 bg-indigo-100 rounded-lg flex items-center justify-center text-indigo-600 mb-4 md:mb-5">
                  <tool.icon className="w-5 h-5 md:w-6 md:h-6" />
                </div>
                <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-2 md:mb-3">{tool.title}</h3>
                <p className="text-gray-600 text-sm md:text-base leading-relaxed">{tool.description}</p>
              </motion.div>
            ))}
          </div>

          <div className="text-center mt-8 md:mt-12">
            <Button 
              onClick={() => navigate("/auth")} 
              size={isMobile ? "default" : "lg"} 
              className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-full px-6 md:px-8 text-base md:text-lg h-12 md:h-14"
            >
              شروع رایگان
              <ArrowRight className="mr-2" />
            </Button>
          </div>
        </div>
      </section>

      <section className="py-8 md:py-16 px-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">با اینستایار، رشد پیج خود را آغاز کنید</h2>
          <p className="text-base md:text-xl opacity-90 mb-6 md:mb-8 max-w-2xl mx-auto">
            به هزاران کاربر خوشحال ما بپیوندید و از ابزارهای هوشمند برای رشد پیج خود استفاده کنید.
          </p>
          <Button 
            onClick={() => navigate("/auth")} 
            size={isMobile ? "default" : "lg"} 
            className="bg-white text-indigo-700 hover:bg-gray-100 rounded-full px-6 md:px-8 text-base md:text-lg h-12 md:h-14"
          >
            شروع رایگان
            <ArrowRight className="mr-2" />
          </Button>
        </div>
      </section>

      <footer className="bg-gray-900 text-gray-400 py-8 md:py-12 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">اینستایار</h3>
            <p className="mb-3 md:mb-4 text-sm md:text-base">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">لینک‌های مفید</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">خدمات</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">تماس با ما</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-8 md:mt-12 pt-6 md:pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Features;
