
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { QuizQuestion } from "@/components/quiz/QuizQuestion";
import type { QuizData } from "@/types/quiz";

interface QuizResultsProps {
  quizData: QuizData;
}

export const QuizResults = ({ quizData }: QuizResultsProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>کوئیز پیشنهادی</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {quizData.questions.map((question, index) => (
            <QuizQuestion 
              key={index} 
              question={question} 
              answer={quizData.answers?.[index]} 
              index={index} 
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
