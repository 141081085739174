
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/lib/auth';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { securityUtils } from '@/lib/utils';

interface AdminAuthState {
  isAdmin: boolean;
  loading: boolean;
}

// Session timeout for admin users (15 minutes)
const ADMIN_SESSION_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds

export const useAdminAuth = (): AdminAuthState => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const { session } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      // If no session, redirect to admin login
      if (!session) {
        navigate('/admin/auth');
        return;
      }
      
      try {
        // Check for a cached admin role that hasn't expired
        const cachedRole = sessionStorage.getItem('admin_role');
        const roleTimestamp = sessionStorage.getItem('admin_role_timestamp');
        
        if (cachedRole === 'true' && roleTimestamp) {
          const timestamp = parseInt(roleTimestamp, 10);
          // Check if the cached role is still valid (hasn't expired)
          if (Date.now() - timestamp < ADMIN_SESSION_TIMEOUT) {
            setIsAdmin(true);
            setLoading(false);
            // Refresh the timestamp to extend the session
            sessionStorage.setItem('admin_role_timestamp', Date.now().toString());
            return;
          } else {
            // Role has expired, clear it from sessionStorage
            sessionStorage.removeItem('admin_role');
            sessionStorage.removeItem('admin_role_timestamp');
          }
        }
        
        // پاکسازی شناسه کاربر قبل از ارسال به سرور
        const safeUserID = securityUtils.sanitizeString(session.user.id);
        
        // Using the is_admin RPC function which is securely implemented on the backend
        const { data, error } = await supabase.rpc('is_admin', {
          user_uuid: safeUserID
        });
          
        if (error) {
          console.error('خطا در بررسی وضعیت ادمین:', error);
          // Log security event
          await supabase.from('user_events').insert({
            user_id: safeUserID,
            event_type: 'admin_check_failed',
            event_data: { 
              error: error.message,
              method: 'useAdminAuth',
              timestamp: new Date().toISOString()
            }
          }).then(({ error: eventError }) => {
            if (eventError) console.error('خطا در ثبت رویداد امنیتی:', eventError);
          });
          
          toast.error('خطا در بررسی وضعیت ادمین');
          navigate('/');
          return;
        }
        
        if (!data) {
          // پاکسازی داده‌ها قبل از ذخیره کردن
          const safeEmail = securityUtils.sanitizeEmail(session.user.email || '');
          
          // Log unauthorized access attempt
          await supabase.from('user_events').insert({
            user_id: safeUserID,
            event_type: 'unauthorized_admin_access',
            event_data: { 
              email: safeEmail,
              timestamp: new Date().toISOString()
            }
          }).then(({ error: eventError }) => {
            if (eventError) console.error('خطا در ثبت رویداد امنیتی:', eventError);
          });
          
          toast.error('شما دسترسی ادمین ندارید');
          navigate('/');
          return;
        }
        
        // User is admin, update cache
        sessionStorage.setItem('admin_role', 'true');
        sessionStorage.setItem('admin_role_timestamp', Date.now().toString());
        setIsAdmin(true);
        
        // Log successful admin access
        await supabase.from('user_events').insert({
          user_id: safeUserID,
          event_type: 'admin_session_start',
          event_data: { 
            timestamp: new Date().toISOString()
          }
        }).then(({ error: eventError }) => {
          if (eventError) console.error('خطا در ثبت رویداد امنیتی:', eventError);
        });
        
      } catch (error) {
        console.error('خطا در بررسی وضعیت ادمین:', error);
        toast.error('خطایی رخ داد');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    
    checkAdminStatus();
    
    // Set up an interval to periodically verify admin status
    const intervalId = setInterval(() => {
      if (session && isAdmin) {
        // Check if the admin session has expired
        const roleTimestamp = sessionStorage.getItem('admin_role_timestamp');
        if (roleTimestamp) {
          const timestamp = parseInt(roleTimestamp, 10);
          if (Date.now() - timestamp > ADMIN_SESSION_TIMEOUT) {
            // Session expired, redirect to login
            sessionStorage.removeItem('admin_role');
            sessionStorage.removeItem('admin_role_timestamp');
            toast.warning('جلسه شما به پایان رسیده است. لطفاً دوباره وارد شوید');
            navigate('/admin/auth');
          }
        }
      }
    }, 60000); // Check every minute
    
    return () => clearInterval(intervalId);
  }, [session, navigate, isAdmin]);

  return { isAdmin, loading };
};
