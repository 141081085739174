
import { useState } from 'react';
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useAuth } from "@/lib/auth";
import { MobileTopBar } from "@/components/MobileTopBar";
import ProfileTab from "@/components/dashboard/ProfileTab";
import CreditsTab from "@/components/dashboard/CreditsTab";
import HistoryTab from "@/components/dashboard/HistoryTab";
import SettingsTab from "@/components/dashboard/SettingsTab";
import TicketsTab from "@/components/dashboard/TicketsTab";
import { Home, User, Clock, CreditCard, Settings, MessageSquare } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "sonner";

export interface MobileDashboardProps {
  credits: number;
  usageHistory: any[];
  onPurchaseCredits: () => void;
  onSignOut: () => Promise<void>;
  loading: boolean;
  // These props are required by TypeScript but won't be used for account deletion
  deleteConfirmText: string;
  setDeleteConfirmText: (text: string) => void;
  onDeleteAccount: () => Promise<void>;
}

const MobileDashboard = ({
  credits,
  usageHistory,
  onPurchaseCredits,
  deleteConfirmText,
  setDeleteConfirmText,
  onSignOut,
  onDeleteAccount,
  loading
}: MobileDashboardProps) => {
  const [activeTab, setActiveTab] = useState("profile");
  const [useVideoBackground, setUseVideoBackground] = useState(false);
  const { session } = useAuth();
  const navigate = useNavigate();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const handleToggleBackground = () => {
    setUseVideoBackground(prev => !prev);
  };

  const handleSignOut = async () => {
    try {
      await onSignOut();
      toast.success("با موفقیت خارج شدید");
      navigate("/");
    } catch (error) {
      console.error("خطا در خروج از حساب:", error);
      toast.error("خطا در خروج از حساب");
    }
  };

  return (
    <div className="flex flex-col h-screen relative">
      {/* Background Image with Overlay */}
      <div 
        className="fixed inset-0 -z-10"
        style={{
          backgroundImage: `url("/lovable-uploads/2aad1c51-d510-4ebe-a0f3-09a8f55910de.png")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div className="fixed inset-0 -z-10 bg-black/50 backdrop-blur-sm" />
      
      <MobileTopBar 
        useVideoBackground={useVideoBackground} 
        onToggleBackground={handleToggleBackground} 
      />
      
      <div className="flex-1 overflow-auto pt-16 pb-20">
        <Tabs value={activeTab} onValueChange={handleTabChange} className="h-full">
          <TabsContent value="profile" className="mt-0 px-4 py-4 pb-24">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <ProfileTab 
                email={session?.user?.email || ''} 
                createdAt={new Date(session?.user?.created_at || Date.now()).toLocaleDateString('fa-IR')}
                userId={session?.user?.id || ''}
              />
            </motion.div>
          </TabsContent>
          
          <TabsContent value="credits" className="mt-0 px-4 py-4 pb-24">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <CreditsTab 
                credits={credits} 
                onPurchaseCredits={onPurchaseCredits}
              />
            </motion.div>
          </TabsContent>
          
          <TabsContent value="history" className="mt-0 px-4 py-4 pb-24">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <HistoryTab 
                usageHistory={usageHistory}
              />
            </motion.div>
          </TabsContent>
          
          <TabsContent value="tickets" className="mt-0 px-4 py-4 pb-24">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <TicketsTab />
            </motion.div>
          </TabsContent>
          
          <TabsContent value="settings" className="mt-0 px-4 py-4 pb-24">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <SettingsTab 
                deleteConfirmText={deleteConfirmText}
                setDeleteConfirmText={setDeleteConfirmText}
                onSignOut={handleSignOut}
                onDeleteAccount={onDeleteAccount}
                loading={loading}
              />
            </motion.div>
          </TabsContent>
        </Tabs>
      </div>
      
      {/* Bottom Navigation - Glassmorphism Style */}
      <div className="fixed bottom-0 left-0 right-0 h-16 bg-black/40 backdrop-blur-md border-t border-white/10 z-50">
        <div className="flex items-center justify-around h-full">
          <button
            onClick={() => handleTabChange("profile")}
            className={`flex flex-col items-center justify-center w-1/5 h-full ${activeTab === "profile" ? "text-indigo-400" : "text-white/70"}`}
          >
            <User className="w-5 h-5" />
            <span className="text-xs mt-1">پروفایل</span>
          </button>
          
          <button
            onClick={() => handleTabChange("credits")}
            className={`flex flex-col items-center justify-center w-1/5 h-full ${activeTab === "credits" ? "text-indigo-400" : "text-white/70"}`}
          >
            <CreditCard className="w-5 h-5" />
            <span className="text-xs mt-1">اعتبار</span>
          </button>
          
          <button
            onClick={() => navigate("/app")}
            className="flex flex-col items-center justify-center w-1/5 h-full text-white"
          >
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gradient-to-br from-indigo-600 to-purple-600 shadow-lg shadow-indigo-600/20">
              <Home className="w-6 h-6" />
            </div>
          </button>
          
          <button
            onClick={() => handleTabChange("history")}
            className={`flex flex-col items-center justify-center w-1/5 h-full ${activeTab === "history" ? "text-indigo-400" : "text-white/70"}`}
          >
            <Clock className="w-5 h-5" />
            <span className="text-xs mt-1">تاریخچه</span>
          </button>
          
          <button
            onClick={() => handleTabChange("tickets")}
            className={`flex flex-col items-center justify-center w-1/5 h-full ${activeTab === "tickets" ? "text-indigo-400" : "text-white/70"}`}
          >
            <MessageSquare className="w-5 h-5" />
            <span className="text-xs mt-1">پشتیبانی</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileDashboard;
