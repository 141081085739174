
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  BookOpen, 
  HelpCircle,
  ArrowRight,
  LockIcon,
  AlertCircle,
  ShieldCheck,
  MessageCircle,
  CreditCard,
  BarChart3,
  User,
  Calendar,
  Lightbulb,
  Zap,
  FileText,
  Instagram,
  Hash,
  Settings,
  Clock, // Adding missing import
  Video // Adding missing import
} from "lucide-react";
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger 
} from "@/components/ui/tooltip";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";

const Help = () => {
  const navigate = useNavigate();
  
  const guides = [
    {
      title: "راهنمای شروع کار با اینستایار",
      description: "آشنایی با محیط کاربری و نحوه استفاده از ابزارهای اصلی",
      icon: <Zap className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/getting-started"
    },
    {
      title: "استفاده از ابزار تولید کپشن",
      description: "راهنمای گام به گام برای تولید کپشن‌های جذاب",
      icon: <FileText className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/caption-generator"
    },
    {
      title: "بهینه‌سازی بیو اینستاگرام",
      description: "نحوه استفاده از ابزار تحلیل و بهینه‌سازی بیو",
      icon: <User className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/bio-optimization"
    },
    {
      title: "ایجاد محتوای کروسل",
      description: "راهنمای استفاده از ابزار طراحی کروسل محتوا",
      icon: <Instagram className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/carousel-content"
    },
    {
      title: "استفاده از مشاور هوشمند رشد",
      description: "نحوه دریافت راهکارهای رشد از هوش مصنوعی",
      icon: <BarChart3 className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/growth-advisor"
    },
    {
      title: "ایجاد کمپین‌های مناسبتی",
      description: "راهنمای استفاده از ابزار ایده‌پرداز رویدادها",
      icon: <Calendar className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/holiday-campaigns"
    },
    {
      title: "تولید و مدیریت هشتگ‌ها",
      description: "راهنمای کامل ابزار پیشنهاد و مدیریت هشتگ‌ها",
      icon: <Hash className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/hashtag-generator"
    },
    {
      title: "ایجاد تقویم محتوا",
      description: "نحوه برنامه‌ریزی و مدیریت تقویم محتوای اینستاگرام",
      icon: <Calendar className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/content-calendar"
    },
    {
      title: "مدیریت اعتبار و حساب کاربری",
      description: "راهنمای استفاده از اعتبارها و مدیریت حساب",
      icon: <CreditCard className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/account-management"
    },
    {
      title: "تنظیمات امنیتی حساب کاربری",
      description: "آشنایی با تنظیمات امنیتی و حفاظت از اطلاعات",
      icon: <ShieldCheck className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/security-settings"
    },
    {
      title: "استفاده از ابزار بازنویسی و ویرایش کپشن",
      description: "نحوه استفاده از ابزار پیشرفته ویرایش محتوا",
      icon: <FileText className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/caption-editor"
    },
    {
      title: "ابزار تولید ایده‌های استوری",
      description: "نحوه استفاده از ابزار ایده‌پرداز استوری",
      icon: <Lightbulb className="h-8 w-8 text-indigo-500" />,
      available: true,
      url: "/help/story-ideas"
    }
  ];

  const faqs = [
    {
      question: "اینستایار چیست؟",
      answer: "اینستایار یک دستیار هوشمند مبتنی بر هوش مصنوعی است که به کاربران کمک می‌کند تا محتوای جذاب و تعاملی برای اینستاگرام خود تولید کنند. با استفاده از ابزارهای متنوع اینستایار، می‌توانید کپشن، هشتگ، ایده‌های کروسل و محتوای استوری تولید کنید و استراتژی رشد پیج خود را بهبود بخشید. اینستایار با استفاده از الگوریتم‌های پیشرفته هوش مصنوعی، محتوایی متناسب با سلیقه و نیاز مخاطبان هدف شما تولید می‌کند."
    },
    {
      question: "آیا استفاده از اینستایار رایگان است؟",
      answer: "اینستایار یک دوره آزمایشی رایگان با ۲۰ اعتبار برای کاربران جدید ارائه می‌دهد. با این اعتبارها می‌توانید به صورت محدود از ابزارهای مختلف استفاده کنید و با امکانات سیستم آشنا شوید. پس از آن، می‌توانید با خرید اعتبار، به استفاده از اینستایار ادامه دهید. ما بسته‌های اعتباری متنوعی برای نیازهای مختلف ارائه می‌دهیم، از کاربران انفرادی گرفته تا آژانس‌های بازاریابی و تیم‌های محتوا."
    },
    {
      question: "هزینه استفاده از اینستایار چقدر است؟",
      answer: "اینستایار بر اساس اعتبار کار می‌کند. شما می‌توانید بسته‌های اعتباری مختلف را خریداری کنید و از آنها برای استفاده از ابزارهای مختلف استفاده کنید. هر ابزار، بسته به پیچیدگی، تعدادی اعتبار مصرف می‌کند. برای مثال، تولید یک کپشن ۱ اعتبار، تولید پیشنهاد هشتگ ۱ اعتبار، و طراحی یک برنامه کروسل محتوا ۳ اعتبار مصرف می‌کند. برای مشاهده لیست کامل قیمت‌ها و بسته‌های اعتباری، به صفحه تعرفه‌ها مراجعه کنید."
    },
    {
      question: "اعتبارها چگونه کار می‌کنند؟",
      answer: "هر بار که از یکی از ابزارهای اینستایار استفاده می‌کنید، تعدادی اعتبار از حساب شما کم می‌شود. تعداد اعتبار مصرفی بسته به نوع ابزار و پیچیدگی درخواست متفاوت است. به عنوان مثال، تولید یک کپشن ۱ اعتبار و طراحی یک کروسل ۳ اعتبار مصرف می‌کند. شما می‌توانید در هر زمان اعتبار اضافه خریداری کنید. همچنین برای مشتریان دائمی ما، بسته‌های اشتراک ماهانه با تخفیف ویژه در نظر گرفته شده است که اعتبار بیشتری به ازای هزینه کمتر ارائه می‌دهد."
    },
    {
      question: "روش‌های پرداخت شما چیست؟",
      answer: "در حال حاضر پرداخت از طریق درگاه‌های شتاب (کلیه کارت‌های بانکی عضو شبکه شتاب)، کیف پول‌های الکترونیکی، و پرداخت‌های کریپتو امکان‌پذیر است. برای سازمان‌ها و شرکت‌ها نیز امکان پرداخت از طریق صدور فاکتور رسمی و پرداخت به حساب شرکت فراهم شده است. در صورت نیاز به راهنمایی بیشتر در مورد روش‌های پرداخت، با پشتیبانی ما تماس بگیرید."
    },
    {
      question: "آیا اعتبارها منقضی می‌شوند؟",
      answer: "خیر، اعتبارهای خریداری شده هیچ محدودیت زمانی ندارند و تا زمانی که استفاده نشوند در حساب شما باقی می‌مانند. با این حال، اعتبارهای رایگان که در برنامه‌های تشویقی یا دوره آزمایشی به شما ارائه می‌شوند، ممکن است تاریخ انقضا داشته باشند که در زمان اعطای آنها اطلاع‌رسانی خواهد شد."
    },
    {
      question: "آیا اینستایار اطلاعات پیج اینستاگرام من را ذخیره می‌کند؟",
      answer: "اینستایار فقط اطلاعاتی را که شما به طور مستقیم وارد می‌کنید ذخیره می‌کند و هیچ دسترسی مستقیمی به حساب اینستاگرام شما ندارد. ما اطلاعات شما را کاملاً محرمانه نگه می‌داریم و از آنها فقط برای بهبود خدمات و تولید محتوای شخصی‌سازی شده استفاده می‌کنیم. برای اطلاعات بیشتر، می‌توانید به سیاست‌های حریم خصوصی ما مراجعه کنید."
    },
    {
      question: "آیا محتوای تولید شده در اینستایار منحصر به فرد است؟",
      answer: "بله، تمام محتوایی که توسط اینستایار تولید می‌شود کاملاً منحصر به فرد و براساس ورودی‌های شما ایجاد می‌شود. الگوریتم‌های پیشرفته هوش مصنوعی ما به گونه‌ای طراحی شده‌اند که محتوای اصیل و غیر تکراری تولید کنند. با این حال، کنترل نهایی محتوا با شماست و می‌توانید پیش از انتشار، آن را بررسی و در صورت نیاز ویرایش کنید."
    },
    {
      question: "چگونه می‌توانم با تیم پشتیبانی تماس بگیرم؟",
      answer: "شما می‌توانید از طریق صفحه 'تماس با ما' در وب‌سایت، ایمیل info@instayar.com، یا چت آنلاین موجود در سایت با تیم پشتیبانی ما تماس بگیرید. تیم پشتیبانی ما در روزهای کاری از ساعت ۹ صبح تا ۵ بعدازظهر آماده پاسخگویی به سوالات شماست. همچنین، می‌توانید سوالات متداول را در مرکز راهنما مطالعه کنید که ممکن است پاسخ سوال شما را در آنجا بیابید."
    },
    {
      question: "آیا اینستایار تخفیف‌های ویژه برای آژانس‌ها یا استفاده تیمی ارائه می‌دهد؟",
      answer: "بله، ما بسته‌های ویژه‌ای برای آژانس‌های بازاریابی و تیم‌های محتوا با تخفیف‌های قابل توجه ارائه می‌دهیم. این بسته‌ها شامل اعتبار بیشتر، دسترسی به ابزارهای پیشرفته و امکان مدیریت چندین حساب کاربری می‌شوند. برای اطلاعات بیشتر و دریافت پیشنهاد قیمت اختصاصی، با تیم فروش ما تماس بگیرید."
    }
  ];

  // تفکیک راهنماها بر اساس سطح کاربری
  const beginnerGuides = guides.filter((guide, index) => index < 4);
  const intermediateGuides = guides.filter((guide, index) => index >= 4 && index < 8);
  const advancedGuides = guides.filter((guide, index) => index >= 8);

  const renderContent = (item: any, type: string) => {
    if (item.available) {
      return (
        <a 
          href={item.url}
          className="block h-full transition-transform hover:scale-[1.02]"
        >
          {type === 'video' ? (
            <Card className="overflow-hidden h-full">
              <div className="h-48 overflow-hidden">
                <img src={item.thumbnail} alt={item.title} className="w-full h-full object-cover" />
                <div className="absolute top-2 right-2 bg-black/70 text-white px-2 py-1 rounded-md text-xs flex items-center">
                  <Clock className="w-3 h-3 ml-1" />
                  {item.duration}
                </div>
              </div>
              <CardHeader>
                <CardTitle className="text-lg">{item.title}</CardTitle>
                <CardDescription className="line-clamp-2">{item.description}</CardDescription>
              </CardHeader>
              <CardFooter>
                <div className="flex items-center text-indigo-600 font-medium">
                  <span>مشاهده ویدیو</span>
                  <ArrowRight className="w-4 h-4 mr-1" />
                </div>
              </CardFooter>
            </Card>
          ) : (
            <Card className="h-full">
              <CardHeader>
                <div className="flex justify-center mb-4">
                  {item.icon}
                </div>
                <CardTitle className="text-center text-lg">{item.title}</CardTitle>
                <CardDescription className="text-center">{item.description}</CardDescription>
              </CardHeader>
              <CardFooter className="justify-center">
                <div className="flex items-center text-indigo-600 font-medium">
                  <span>مشاهده راهنما</span>
                  <ArrowRight className="w-4 h-4 mr-1" />
                </div>
              </CardFooter>
            </Card>
          )}
        </a>
      );
    } else {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className={`bg-white rounded-xl ${type === 'video' ? '' : 'p-6'} shadow-sm border border-gray-100 h-full relative transition-all opacity-80`}>
                <div className="absolute inset-0 bg-gray-50/50 backdrop-blur-[1px] rounded-xl flex items-center justify-center z-10">
                  <div className="flex flex-col items-center gap-2">
                    <LockIcon className="w-8 h-8 text-gray-400" />
                    <span className="text-sm font-medium text-gray-500">به زودی</span>
                  </div>
                </div>
                
                {type === 'video' ? (
                  <>
                    <img src={item.thumbnail} alt={item.title} className="w-full h-48 object-cover" />
                    <div className="p-5">
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">{item.title}</h3>
                      <div className="flex items-center text-gray-500">
                        <Video className="w-4 h-4 ml-1" />
                        <span>{item.duration}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">{item.title}</h3>
                    <p className="text-gray-600 mb-4">{item.description}</p>
                  </>
                )}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex items-center gap-1">
                <AlertCircle className="w-4 h-4 text-yellow-500" />
                <span>به زودی در دسترس قرار خواهد گرفت</span>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
  };

  // کامپوننت برای نمایش بخش راهنما با عنوان
  const GuideSection = ({ title, description, guides }: { title: string, description: string, guides: any[] }) => (
    <div className="mb-16">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">{title}</h2>
        <p className="text-gray-600">{description}</p>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {guides.map((guide, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            viewport={{ once: true }}
          >
            {renderContent(guide, 'guide')}
          </motion.div>
        ))}
      </div>
    </div>
  );

  // بخش جدید برای شرح راهنمای ابزارها
  const toolGuides = [
    {
      icon: <FileText className="w-12 h-12 text-indigo-500 mb-4" />,
      title: "ابزار تولید کپشن",
      description: "الگوریتم پیشرفته هوش مصنوعی اینستایار کمک می‌کند تا کپشن‌های جذاب و گیرا برای پست‌های اینستاگرام خود تولید کنید. این ابزار با تحلیل محتوای تصویر، موضوع پست و سبک برند شما، متن‌هایی منحصر به فرد با قابلیت تعامل بالا ایجاد می‌کند.",
      features: [
        "تولید کپشن براساس موضوع، تصویر یا نوع محتوا",
        "تنظیم لحن کپشن (رسمی، دوستانه، آموزشی و...)",
        "پیشنهاد ایموجی‌های مناسب",
        "امکان تعیین طول کپشن",
        "بهینه‌سازی کپشن برای افزایش تعامل"
      ]
    },
    {
      icon: <Hash className="w-12 h-12 text-indigo-500 mb-4" />,
      title: "تولید و مدیریت هشتگ",
      description: "با استفاده از ابزار هوشمند هشتگ اینستایار، مجموعه‌ای از هشتگ‌های مرتبط و پرطرفدار برای افزایش دید پست‌های خود دریافت کنید. این ابزار با تحلیل ترندهای فعلی و محتوای پست شما، بهترین هشتگ‌ها را پیشنهاد می‌دهد.",
      features: [
        "پیشنهاد هشتگ‌های مرتبط با موضوع پست",
        "فیلتر هشتگ‌ها براساس میزان محبوبیت",
        "ترکیب هشتگ‌های عمومی و تخصصی",
        "ذخیره و مدیریت مجموعه‌های هشتگ",
        "آنالیز عملکرد هشتگ‌ها"
      ]
    },
    {
      icon: <Instagram className="w-12 h-12 text-indigo-500 mb-4" />,
      title: "تولید محتوای کروسل",
      description: "محتوای کروسل (اسلایدی) یکی از جذاب‌ترین و تعاملی‌ترین انواع پست در اینستاگرام است. با ابزار طراحی کروسل اینستایار، می‌توانید ایده‌ها و ساختار کروسل‌های جذاب را برای موضوعات مختلف دریافت کنید.",
      features: [
        "ایده‌پردازی موضوعات مناسب برای کروسل",
        "ساختاردهی محتوا در قالب چند اسلاید",
        "پیشنهاد عناوین جذاب برای هر اسلاید",
        "راهنمایی برای طراحی بصری هر بخش",
        "نکات افزایش تعامل مخاطب با محتوای کروسل"
      ]
    },
    {
      icon: <Lightbulb className="w-12 h-12 text-indigo-500 mb-4" />,
      title: "ایده‌پرداز استوری",
      description: "استوری‌ها بخش مهمی از استراتژی محتوای اینستاگرام هستند. ابزار ایده‌پرداز استوری اینستایار، ایده‌های خلاقانه و متنوعی برای تولید استوری‌های جذاب و تعاملی ارائه می‌دهد.",
      features: [
        "ایده‌های متنوع برای استوری روزانه",
        "پیشنهاد قالب‌های تعاملی (نظرسنجی، سؤال، کوئیز و...)",
        "ایده‌های پشت صحنه و نمایش فرآیند کار",
        "محتوای مناسبتی برای رویدادها و مناسبت‌ها",
        "استراتژی‌های افزایش مشارکت مخاطب در استوری"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-white" dir="rtl">
      {/* Header */}
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto py-6 px-6">
          <div className="flex justify-between items-center">
            <h1 
              className="text-2xl font-bold bg-gradient-to-l from-indigo-600 to-purple-600 text-transparent bg-clip-text cursor-pointer" 
              onClick={() => navigate("/")}
            >
              اینستایار
            </h1>
            <div className="flex items-center gap-4">
              <Button variant="ghost" onClick={() => navigate("/blog")}>آموزش اینستاگرام</Button>
              <Button variant="ghost" onClick={() => navigate("/pricing")}>تعرفه‌ها</Button>
              <Button variant="ghost" onClick={() => navigate("/about")}>درباره ما</Button>
              <Button className="bg-indigo-600 hover:bg-indigo-700 text-white" onClick={() => navigate("/auth")}>
                ورود / ثبت‌نام
              </Button>
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="py-16 px-6 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">مرکز راهنما</h1>
          <p className="text-xl text-gray-600 mb-8 leading-relaxed">
            از اینجا می‌توانید به راهنماها، ویدیوهای آموزشی و پاسخ سؤالات متداول دسترسی داشته باشید.
          </p>
        </div>
      </section>

      {/* Help Center Content */}
      <section className="py-12 px-6 mb-16">
        <div className="max-w-6xl mx-auto">
          <Tabs defaultValue="guides" className="w-full">
            <TabsList className="w-full flex justify-center mb-8">
              <TabsTrigger value="guides" className="px-8 py-2">
                <BookOpen className="w-5 h-5 ml-2" />
                راهنماها
              </TabsTrigger>
              <TabsTrigger value="tools" className="px-8 py-2">
                <Settings className="w-5 h-5 ml-2" />
                ابزارها
              </TabsTrigger>
              <TabsTrigger value="faq" className="px-8 py-2">
                <HelpCircle className="w-5 h-5 ml-2" />
                سؤالات متداول
              </TabsTrigger>
            </TabsList>
            
            <TabsContent value="guides">
              <GuideSection 
                title="راهنمای شروع کار" 
                description="آشنایی با اصول اولیه و شروع کار با اینستایار"
                guides={beginnerGuides}
              />
              
              <GuideSection 
                title="راهنمای کاربران متوسط" 
                description="بهره‌گیری بیشتر از قابلیت‌های اینستایار"
                guides={intermediateGuides}
              />
              
              <GuideSection 
                title="راهنمای کاربران پیشرفته" 
                description="استفاده حرفه‌ای و ترکیب ابزارها برای نتایج بهتر"
                guides={advancedGuides}
              />
            </TabsContent>

            <TabsContent value="tools">
              <div className="space-y-16">
                {toolGuides.map((tool, index) => (
                  <motion.div 
                    key={index}
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                    className="bg-white p-8 rounded-xl border border-gray-100 shadow-sm"
                  >
                    <div className="flex flex-col md:flex-row gap-8">
                      <div className="w-full md:w-2/5 flex flex-col items-center text-center">
                        {tool.icon}
                        <h3 className="text-2xl font-bold text-gray-900 mb-3">{tool.title}</h3>
                        <p className="text-gray-600">{tool.description}</p>
                      </div>
                      <div className="w-full md:w-3/5">
                        <div className="bg-indigo-50 p-6 rounded-xl">
                          <h4 className="text-lg font-semibold text-indigo-900 mb-4">ویژگی‌های کلیدی</h4>
                          <ul className="space-y-3">
                            {tool.features.map((feature, idx) => (
                              <li key={idx} className="flex items-start">
                                <div className="bg-indigo-100 p-1 rounded-full mt-1 ml-2">
                                  <Check className="h-3 w-3 text-indigo-600" />
                                </div>
                                <span>{feature}</span>
                              </li>
                            ))}
                          </ul>
                          <div className="mt-6 flex justify-center">
                            <Button className="bg-indigo-600 hover:bg-indigo-700" onClick={() => navigate("/auth")}>
                              استفاده از ابزار
                              <ArrowRight className="w-4 h-4 mr-2" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </TabsContent>
            
            <TabsContent value="faq">
              <div className="space-y-6 max-w-3xl mx-auto">
                {faqs.map((faq, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                    className="bg-white rounded-xl p-6 border border-gray-200"
                  >
                    <h3 className="text-lg font-semibold text-gray-900 mb-3">{faq.question}</h3>
                    <p className="text-gray-600">{faq.answer}</p>
                  </motion.div>
                ))}
                
                <div className="text-center mt-12">
                  <p className="text-gray-600 mb-4">برای مشاهده لیست کامل سؤالات متداول به صفحه زیر مراجعه کنید:</p>
                  <Button 
                    onClick={() => navigate("/faq")} 
                    className="bg-indigo-600 hover:bg-indigo-700 text-white"
                  >
                    مشاهده همه سؤالات متداول
                  </Button>
                </div>
              </div>
            </TabsContent>
          </Tabs>
          
          <div className="mt-16 text-center">
            <div className="bg-indigo-50 rounded-xl p-8 max-w-3xl mx-auto">
              <MessageCircle className="w-12 h-12 text-indigo-500 mx-auto mb-4" />
              <h3 className="text-xl font-bold mb-4">سؤال دیگری دارید؟</h3>
              <p className="text-gray-600 mb-6">
                تیم پشتیبانی ما آماده پاسخگویی به سؤالات شما است.
                می‌توانید از طریق فرم تماس با ما، ایمیل یا چت آنلاین با ما در ارتباط باشید.
              </p>
              <Button 
                onClick={() => navigate("/contact")} 
                className="bg-indigo-600 hover:bg-indigo-700 text-white"
              >
                تماس با ما
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-12 px-6">
        <div className="max-w-7xl mx-auto grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">لینک‌های مفید</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">خدمات</h3>
            <ul className="space-y-2">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">تماس با ما</h3>
            <ul className="space-y-2">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

// Element type is invalid: expected a string (for built-in components) or a class/function (for composite components) but got: object.
const Check = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

export default Help;
