import { useState, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { useAuth } from "@/lib/auth";
import { useNavigate } from "react-router-dom";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import SideNavigation from "@/components/dashboard/SideNavigation";
import ProfileTab from "@/components/dashboard/ProfileTab";
import CreditsTab from "@/components/dashboard/CreditsTab";
import HistoryTab from "@/components/dashboard/HistoryTab";
import SettingsTab from "@/components/dashboard/SettingsTab";
import MobileDashboard from "@/components/dashboard/MobileDashboard";
import { useMobile } from "@/hooks/use-mobile";
import SEO from "@/components/SEO";
import TicketsTab from "@/components/dashboard/TicketsTab";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { TopBar } from "@/components/TopBar";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { session, profile, signOut } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [credits, setCredits] = useState(0);
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [useVideoBackground, setUseVideoBackground] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");

  useEffect(() => {
    if (!session) {
      navigate("/auth");
      return;
    }
    
    // Fetch user credits and usage history
    const fetchUserData = async () => {
      try {
        // Fetch credits
        const { data: creditsData, error: creditsError } = await supabase
          .from('profiles')
          .select('credits')
          .eq('id', session.user.id)
          .single();
          
        if (creditsError) throw creditsError;
        if (creditsData) setCredits(creditsData.credits || 0);
        
        // Fetch usage history with generated_content
        const { data: historyData, error: historyError } = await supabase
          .from('tool_usage')
          .select('*')
          .eq('user_id', session.user.id)
          .order('created_at', { ascending: false });
          
        if (historyError) throw historyError;
        setUsageHistory(historyData || []);
        
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    
    fetchUserData();
  }, [session, navigate]);

  const handlePurchaseCredits = () => {
    navigate("/pricing");
  };
  
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("خطا در خروج از حساب کاربری");
    }
  };

  const handleToggleBackground = () => {
    setUseVideoBackground(prev => !prev);
  };

  // Empty handler for account deletion - won't be used as we've removed this feature
  const handleDeleteAccount = async () => {
    // This is now a placeholder function that isn't used
    return Promise.resolve();
  };

  if (!session || !profile) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-white">در حال بارگذاری...</div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <MobileDashboard 
        credits={credits}
        usageHistory={usageHistory}
        onPurchaseCredits={handlePurchaseCredits}
        onSignOut={handleSignOut}
        loading={loading}
        deleteConfirmText={deleteConfirmText}
        setDeleteConfirmText={setDeleteConfirmText}
        onDeleteAccount={handleDeleteAccount}
      />
    );
  }

  return (
    <div
      className="min-h-screen flex flex-col relative"
      dir="rtl"
    >
      <SEO
        title="داشبورد | اینستایار"
        description="مدیریت حساب کاربری و استفاده از ابزارهای اینستایار"
      />
      
      {/* Background Image with Overlay */}
      <div 
        className="fixed inset-0 -z-10"
        style={{
          backgroundImage: `url("/lovable-uploads/2aad1c51-d510-4ebe-a0f3-09a8f55910de.png")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div className="fixed inset-0 -z-10 bg-black/70" />
      
      <TopBar useVideoBackground={useVideoBackground} onToggleBackground={handleToggleBackground} />

      <main className="flex-1 flex pt-12">
        <aside className="w-64 border-l border-white/10 bg-black/30 backdrop-blur-md p-4">
          <SideNavigation currentTab={activeTab} onTabChange={setActiveTab} />
        </aside>

        <div className="flex-1 p-6">
          <Tabs value={activeTab}>
            <TabsContent value="profile" className="mt-0">
              <ProfileTab 
                email={session?.user?.email || ''} 
                createdAt={new Date(session?.user?.created_at || Date.now()).toLocaleDateString('fa-IR')}
                userId={session?.user?.id || ''}
              />
            </TabsContent>
            <TabsContent value="credits" className="mt-0">
              <CreditsTab 
                credits={credits} 
                onPurchaseCredits={handlePurchaseCredits}
              />
            </TabsContent>
            <TabsContent value="history" className="mt-0">
              <HistoryTab 
                usageHistory={usageHistory}
              />
            </TabsContent>
            <TabsContent value="tickets" className="mt-0">
              <TicketsTab />
            </TabsContent>
            <TabsContent value="settings" className="mt-0">
              <SettingsTab 
                onSignOut={handleSignOut}
                loading={loading}
                deleteConfirmText=""
                setDeleteConfirmText={() => {}}
                onDeleteAccount={async () => {}}
              />
            </TabsContent>
          </Tabs>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
