
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2, Lightbulb, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

interface FormData {
  holiday: string;
  niche: string;
}

export const HolidayCampaignGenerator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const form = useForm<FormData>();
  const { profile, refreshProfile } = useAuth();

  const onSubmit = async (data: FormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "holiday", creditCost: 4 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      // Call the function first to ensure it works before deducting credits
      const response = await supabase.functions.invoke('generate-holiday-campaign', {
        body: data
      });

      if (response.error) throw response.error;

      const result = response.data;
      
      // Check if result contains error property
      if (result && result.error) {
        throw new Error(result.error);
      }
      
      // Now deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setSuggestions(result.suggestions);
      setExplanation(result.explanation || "");

      try {
        await supabase.from('holiday_campaigns').insert({
          holiday: data.holiday,
          niche: data.niche,
          suggestions: result.suggestions,
          explanation: result.explanation
        });
      } catch (saveException) {
        console.error("Exception saving holiday campaign:", saveException);
        // Continue execution even if saving fails
      }

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "پیشنهادات آماده",
        description: "ایده‌های محتوایی با موفقیت تولید شد",
      });

    } catch (error: any) {
      toast({
        title: "خطا",
        description: error.message || "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6" dir="rtl">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="holiday"
            render={({ field }) => (
              <FormItem>
                <FormLabel>مناسبت یا رویداد</FormLabel>
                <FormControl>
                  <Input placeholder="مثال: شب یلدا" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="niche"
            render={({ field }) => (
              <FormItem>
                <FormLabel>حوزه فعالیت شما</FormLabel>
                <FormControl>
                  <Input placeholder="مثال: فروشگاه لباس" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            دریافت ایده‌های محتوایی
          </Button>
        </form>
      </Form>

      {suggestions.length > 0 ? (
        <div className="space-y-4">
          {suggestions.map((suggestion, index) => (
            <Card key={index} className="p-4 space-y-2">
              <h3 className="text-lg font-bold">{suggestion.title}</h3>
              <p className="text-sm text-gray-600">{suggestion.description}</p>
              <div className="text-sm">
                <span className="font-semibold">نوع محتوا: </span>
                {suggestion.content_type}
              </div>
              <div className="text-sm">
                <span className="font-semibold">نکات اجرایی: </span>
                {suggestion.tips}
              </div>
            </Card>
          ))}
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی رویداد: تحلیل ایده‌های مناسبتی</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>ایده‌ساز محتوای مناسبتی</strong> به شما کمک می‌کند برای مناسبت‌ها و رویدادهای مختلف، محتوای جذاب و مرتبط با کسب و کار خود تولید کنید. 
            این ابزار با ارائه ایده‌های خلاقانه باعث افزایش تعامل مخاطبان و فروش شما در دوره‌های مناسبتی می‌شود. 
            کافیست مناسبت مورد نظر و نوع کسب و کار خود را وارد کنید!
          </p>
        </InfoBox>
      )}
    </div>
  );
};
