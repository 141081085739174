
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Pagination, PaginationContent, PaginationItem, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { supabase } from "@/integrations/supabase/client";
import { Eye, Search, RefreshCw } from "lucide-react";
import { toast } from "sonner";

interface ToolUsage {
  id: string;
  user_id: string;
  tool_id: string;
  credit_cost: number;
  created_at: string;
  email?: string;
  full_name?: string;
  toolName?: string;
}

interface ToolResult {
  id: string;
  caption?: string;
  hashtags?: string[] | any;
  content?: string;
  analysis?: string;
  suggestions?: any;
  recommendations?: any;
  translated_text?: string;
  edited_text?: string;
  bio_text?: string;
  original_text?: string;
  target_language?: string;
  description?: string;
  [key: string]: any;
}

const ITEMS_PER_PAGE = 10;

const ToolsManagement = () => {
  const [toolUsage, setToolUsage] = useState<ToolUsage[]>([]);
  const [filteredUsage, setFilteredUsage] = useState<ToolUsage[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [toolFilter, setToolFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isResultDialogOpen, setIsResultDialogOpen] = useState(false);
  const [selectedTool, setSelectedTool] = useState<ToolUsage | null>(null);
  const [toolResult, setToolResult] = useState<ToolResult | null>(null);
  const [loadingResult, setLoadingResult] = useState(false);

  useEffect(() => {
    fetchToolUsage();
  }, []);

  useEffect(() => {
    filterToolUsage();
  }, [searchTerm, toolFilter, toolUsage]);

  const fetchToolUsage = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from("tool_usage")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;

      if (!data || data.length === 0) {
        setToolUsage([]);
        setFilteredUsage([]);
        setLoading(false);
        return;
      }

      const userIds = data.map(usage => usage.user_id);

      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("id, email")
        .in("id", userIds);

      if (userError) throw userError;

      const { data: profileData, error: profileError } = await supabase
        .from("profiles")
        .select("id, full_name")
        .in("id", userIds);

      if (profileError) throw profileError;

      const emailMap: { [key: string]: string } = {};
      const nameMap: { [key: string]: string } = {};

      userData?.forEach(user => {
        emailMap[user.id] = user.email;
      });

      profileData?.forEach(profile => {
        nameMap[profile.id] = profile.full_name || "";
      });

      const formattedUsage = data.map(usage => ({
        ...usage,
        email: emailMap[usage.user_id] || "ایمیل نامشخص",
        full_name: nameMap[usage.user_id] || "",
        toolName: getToolName(usage.tool_id)
      }));

      setToolUsage(formattedUsage);
      setFilteredUsage(formattedUsage);
      setTotalPages(Math.ceil(formattedUsage.length / ITEMS_PER_PAGE));
    } catch (error) {
      console.error("خطا در بارگذاری استفاده از ابزارها:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterToolUsage = () => {
    let result = [...toolUsage];

    if (searchTerm) {
      result = result.filter(
        usage =>
          usage.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          usage.full_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (toolFilter && toolFilter !== "all") {
      result = result.filter(usage => usage.tool_id === toolFilter);
    }

    setFilteredUsage(result);
    setTotalPages(Math.ceil(result.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
  };

  const getToolName = (toolId: string) => {
    const toolMap: { [key: string]: string } = {
      "caption-generator": "ایجاد کپشن",
      "hashtag-generator": "ایجاد هشتگ",
      "bio-analysis": "تحلیل بیو",
      "carousel-planner": "برنامه‌ریزی کاروسل",
      "translate-text": "ترجمه متن",
      "story-idea-generator": "ایده‌های استوری",
      "growth-mentor": "مشاور رشد",
      "content-calendar": "تقویم محتوا",
      "dm-consultant": "مشاوره پیام خصوصی",
      "repurpose-content": "بازآفرینی محتوا",
      "promotion-advisor": "مشاور تبلیغات",
      "aesthetic-advisor": "مشاور زیبایی",
      "outreach-generator": "ایجاد پیام‌های ارتباطی",
      "holiday-campaign-generator": "کمپین روزهای خاص",
      "caption-editor": "ویرایشگر کپشن",
      "quiz-generator": "ایجاد کوئیز"
    };

    return toolMap[toolId] || toolId;
  };

  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredUsage.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("fa-IR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    }).format(date);
  };

  const viewToolResult = async (tool: ToolUsage) => {
    setSelectedTool(tool);
    setIsResultDialogOpen(true);
    setLoadingResult(true);
    
    try {
      // Get the tool usage ID to query the corresponding result
      const toolId = tool.id;
      const toolType = tool.tool_id;
      
      let tableName = '';
      
      // Determine which table to query based on tool type
      switch(toolType) {
        case "caption-generator":
          tableName = "generated_captions";
          break;
        case "hashtag-generator":
          tableName = "hashtag_generations";
          break;
        case "bio-analysis":
          tableName = "bio_analyses";
          break;
        case "translate-text":
          tableName = "translations";
          break;
        case "content-calendar":
          tableName = "content_calendars";
          break;
        case "story-idea-generator":
          tableName = "story_ideas";
          break;
        case "growth-mentor":
          tableName = "growth_consultations";
          break;
        case "carousel-planner":
          tableName = "carousel_plans";
          break;
        case "dm-consultant":
          tableName = "dm_consultations";
          break;
        case "repurpose-content":
          tableName = "repurposed_content";
          break;
        case "promotion-advisor":
          tableName = "promotion_suggestions";
          break;
        case "aesthetic-advisor":
          tableName = "aesthetic_recommendations";
          break;
        case "outreach-generator":
          tableName = "outreach_messages";
          break;
        case "holiday-campaign-generator":
          tableName = "holiday_campaigns";
          break;
        case "caption-editor":
          tableName = "edited_captions";
          break;
        case "quiz-generator":
          tableName = "generated_quizzes";
          break;
        default:
          throw new Error("نوع ابزار نامعتبر است");
      }
      
      // Execute SQL query to find the corresponding result
      // We'll query by created_at close to the tool usage timestamp
      const { data: sqlResult, error: sqlError } = await supabase.rpc('execute_sql', {
        sql_query: `
          SELECT * FROM ${tableName} 
          WHERE created_at >= (
            SELECT created_at FROM tool_usage WHERE id = '${toolId}'
          )
          ORDER BY created_at ASC
          LIMIT 1
        `
      });
      
      if (sqlError) throw sqlError;
      
      if (sqlResult && Array.isArray(sqlResult) && sqlResult.length > 0) {
        // Safely cast the result to ToolResult type
        const result = sqlResult[0] as ToolResult;
        setToolResult(result);
      } else {
        throw new Error("نتیجه‌ای برای این ابزار یافت نشد");
      }
    } catch (error) {
      console.error("خطا در بارگذاری نتیجه ابزار:", error);
      toast.error("خطا در بارگذاری نتیجه");
      setToolResult(null);
    } finally {
      setLoadingResult(false);
    }
  };

  const renderToolResult = () => {
    if (!selectedTool || !toolResult) return null;
    
    const formatJSON = (jsonData: any) => {
      try {
        if (typeof jsonData === 'string') {
          return JSON.stringify(JSON.parse(jsonData), null, 2);
        }
        return JSON.stringify(jsonData, null, 2);
      } catch (e) {
        return jsonData;
      }
    };
    
    switch(selectedTool.tool_id) {
      case "caption-generator":
        return (
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-white mb-2">کپشن تولید شده:</h3>
              <p className="bg-gray-700 p-3 rounded">{toolResult.caption}</p>
            </div>
            {toolResult.hashtags && Array.isArray(toolResult.hashtags) && (
              <div>
                <h3 className="font-medium text-white mb-2">هشتگ‌ها:</h3>
                <p className="bg-gray-700 p-3 rounded">{toolResult.hashtags.join(" ")}</p>
              </div>
            )}
          </div>
        );
      
      case "hashtag-generator":
        return (
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-white mb-2">توضیحات:</h3>
              <p className="bg-gray-700 p-3 rounded">{toolResult.description}</p>
            </div>
            <div>
              <h3 className="font-medium text-white mb-2">هشتگ‌ها:</h3>
              <p className="bg-gray-700 p-3 rounded whitespace-pre-wrap">{formatJSON(toolResult.hashtags)}</p>
            </div>
          </div>
        );
      
      case "bio-analysis":
        return (
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-white mb-2">متن بیو:</h3>
              <p className="bg-gray-700 p-3 rounded">{toolResult.bio_text}</p>
            </div>
            <div>
              <h3 className="font-medium text-white mb-2">تحلیل:</h3>
              <p className="bg-gray-700 p-3 rounded whitespace-pre-wrap">{toolResult.analysis}</p>
            </div>
          </div>
        );
      
      case "translate-text":
        return (
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-white mb-2">متن اصلی:</h3>
              <p className="bg-gray-700 p-3 rounded">{toolResult.original_text}</p>
            </div>
            <div>
              <h3 className="font-medium text-white mb-2">ترجمه شده:</h3>
              <p className="bg-gray-700 p-3 rounded">{toolResult.translated_text}</p>
            </div>
            <div>
              <h3 className="font-medium text-white mb-2">زبان مقصد:</h3>
              <p className="bg-gray-700 p-3 rounded">{toolResult.target_language}</p>
            </div>
          </div>
        );
      
      default:
        return (
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-white mb-2">اطلاعات خام:</h3>
              <pre className="bg-gray-700 p-3 rounded overflow-auto whitespace-pre-wrap max-h-96 text-sm">
                {JSON.stringify(toolResult, null, 2)}
              </pre>
            </div>
          </div>
        );
    }
  };

  return (
    <div dir="rtl" className="space-y-6">
      <Card className="bg-gray-800 border-gray-700">
        <CardHeader>
          <CardTitle className="text-white">مدیریت استفاده از ابزارها</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col sm:flex-row gap-4 mb-6">
            <div className="relative flex-1">
              <Search className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="جستجو بر اساس ایمیل یا نام کاربر..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-4 pr-10"
              />
            </div>
            <Select value={toolFilter} onValueChange={setToolFilter}>
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue placeholder="همه ابزارها" />
              </SelectTrigger>
              <SelectContent className="bg-gray-800 border-gray-700 text-white">
                <SelectItem value="all">همه ابزارها</SelectItem>
                <SelectItem value="caption-generator">ایجاد کپشن</SelectItem>
                <SelectItem value="hashtag-generator">ایجاد هشتگ</SelectItem>
                <SelectItem value="bio-analysis">تحلیل بیو</SelectItem>
                <SelectItem value="carousel-planner">برنامه‌ریزی کاروسل</SelectItem>
                <SelectItem value="translate-text">ترجمه متن</SelectItem>
                <SelectItem value="story-idea-generator">ایده‌های استوری</SelectItem>
                <SelectItem value="growth-mentor">مشاور رشد</SelectItem>
                <SelectItem value="content-calendar">تقویم محتوا</SelectItem>
                <SelectItem value="dm-consultant">مشاوره پیام خصوصی</SelectItem>
                <SelectItem value="repurpose-content">بازآفرینی محتوا</SelectItem>
                <SelectItem value="promotion-advisor">مشاور تبلیغات</SelectItem>
                <SelectItem value="aesthetic-advisor">مشاور زیبایی</SelectItem>
                <SelectItem value="outreach-generator">ایجاد پیام‌های ارتباطی</SelectItem>
                <SelectItem value="holiday-campaign-generator">کمپین روزهای خاص</SelectItem>
                <SelectItem value="caption-editor">ویرایشگر کپشن</SelectItem>
                <SelectItem value="quiz-generator">ایجاد کوئیز</SelectItem>
              </SelectContent>
            </Select>
            <Button
              onClick={fetchToolUsage}
              className="flex items-center gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              <span>بارگذاری مجدد</span>
            </Button>
          </div>

          <div className="rounded-md border border-gray-700 overflow-hidden">
            <Table>
              <TableHeader className="bg-gray-900">
                <TableRow>
                  <TableHead className="text-white">کاربر</TableHead>
                  <TableHead className="text-white text-left">ابزار</TableHead>
                  <TableHead className="text-white text-left">هزینه اعتبار</TableHead>
                  <TableHead className="text-white text-left">تاریخ</TableHead>
                  <TableHead className="text-white text-left">عملیات</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  Array(5).fill(0).map((_, i) => (
                    <TableRow key={i} className="border-gray-700">
                      <TableCell>
                        <div className="space-y-1">
                          <Skeleton className="h-4 w-32 bg-gray-700" />
                          <Skeleton className="h-3 w-24 bg-gray-700" />
                        </div>
                      </TableCell>
                      <TableCell><Skeleton className="h-4 w-16 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-20 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-24 bg-gray-700" /></TableCell>
                      <TableCell><Skeleton className="h-8 w-8 bg-gray-700" /></TableCell>
                    </TableRow>
                  ))
                ) : filteredUsage.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-6 text-gray-400">
                      هیچ استفاده‌ای از ابزارها یافت نشد.
                    </TableCell>
                  </TableRow>
                ) : (
                  getCurrentPageItems().map((usage) => (
                    <TableRow key={usage.id} className="border-gray-700">
                      <TableCell>
                        <div>
                          <div className="font-medium text-white">{usage.email}</div>
                          {usage.full_name && (
                            <div className="text-sm text-gray-400">{usage.full_name}</div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="font-medium text-white">
                        {usage.toolName}
                      </TableCell>
                      <TableCell className="text-gray-300">
                        {usage.credit_cost}
                      </TableCell>
                      <TableCell className="text-gray-400">
                        {formatDate(usage.created_at)}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => viewToolResult(usage)}
                          title="مشاهده نتیجه"
                          className="text-blue-400 hover:text-blue-300 hover:bg-blue-900/20"
                        >
                          <Eye className="h-5 w-5" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>

          {filteredUsage.length > 0 && totalPages > 1 && (
            <div className="mt-4 flex justify-center">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={handlePrevPage}
                      className={currentPage === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
                    />
                  </PaginationItem>
                  <PaginationItem className="text-white">
                    صفحه {currentPage} از {totalPages}
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationNext
                      onClick={handleNextPage}
                      className={currentPage === totalPages ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={isResultDialogOpen} onOpenChange={setIsResultDialogOpen}>
        <DialogContent className="bg-gray-800 text-white border-gray-700 max-w-2xl" dir="rtl">
          <DialogHeader>
            <DialogTitle className="text-xl">
              {selectedTool && (
                <>نتیجه ابزار: {getToolName(selectedTool.tool_id)}</>
              )}
            </DialogTitle>
          </DialogHeader>

          {loadingResult ? (
            <div className="py-8 flex justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
            </div>
          ) : !toolResult ? (
            <div className="py-8 text-center text-gray-400">
              نتیجه‌ای برای این ابزار یافت نشد.
            </div>
          ) : (
            <div className="max-h-[60vh] overflow-y-auto">
              {renderToolResult()}
            </div>
          )}

          <DialogFooter>
            <Button onClick={() => setIsResultDialogOpen(false)}>
              بستن
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ToolsManagement;
