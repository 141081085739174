
import { Link } from "react-router-dom";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";
import { useMobile } from "@/hooks/use-mobile";
import { ExternalLink } from "lucide-react";

const Sitemap = () => {
  const isMobile = useMobile();

  // Blog post slugs organized by category
  const blogPosts = {
    "رشد فالوور": [
      { slug: "10-hidden-tricks-instagram-growth", title: "۱۰ ترفند مخفی برای افزایش فالوور اینستاگرام" },
      { slug: "attracting-real-active-instagram-followers", title: "ترفندهای جذب مخاطب فعال و واقعی" }
    ],
    "محتوا": [
      { slug: "complete-guide-instagram-reels", title: "راهنمای کامل تولید محتوا برای ریلز" },
      { slug: "instagram-reels-tutorial-complete-2024", title: "راهنمای کامل اینستاگرام ریلز: از صفر تا صد" },
      { slug: "instagram-reels-editing-tools", title: "بهترین ابزارهای ویرایش ریلز اینستاگرام" },
      { slug: "designing-attractive-carousel-posts", title: "نحوه طراحی پست‌های اسلایدی جذاب" },
      { slug: "create-instagram-content-calendar", title: "چگونه تقویم محتوایی اینستاگرام ایجاد کنیم" },
      { slug: "instagram-collab-content-creators", title: "همکاری با سازندگان محتوا در اینستاگرام" },
      { slug: "instagram-content-pillars", title: "ستون‌های محتوایی برای اینستاگرام موفق" },
      { slug: "instagram-content-batching", title: "تولید محتوای دسته‌ای برای صرفه‌جویی در زمان" },
      { slug: "instagram-content-repurposing", title: "بازآفرینی محتوا برای افزایش بهره‌وری" }
    ],
    "الگوریتم": [
      { slug: "leverage-new-instagram-algorithm", title: "چگونه از الگوریتم جدید اینستاگرام استفاده کنیم" },
      { slug: "hashtag-strategy-content-visibility", title: "نحوه استفاده از هشتگ‌ها برای افزایش دیده شدن" }
    ],
    "فروش": [
      { slug: "instagram-story-strategies-for-sales", title: "استراتژی‌های استوری برای فروش بیشتر" },
      { slug: "how-to-sell-products-on-instagram", title: "چگونه با اینستاگرام محصولات خود را بفروشیم" },
      { slug: "setup-instagram-shop-with-new-features", title: "نحوه راه‌اندازی فروشگاه در اینستاگرام" }
    ],
    "بازاریابی": [
      { slug: "instagram-niche-marketing", title: "بازاریابی تخصصی در اینستاگرام" },
      { slug: "instagram-audience-segmentation", title: "بخش‌بندی مخاطبان اینستاگرام" }
    ],
    "زمان‌بندی": [
      { slug: "best-posting-times-instagram-iran", title: "بهترین ساعات انتشار پست در اینستاگرام" }
    ],
    "عکاسی": [
      { slug: "professional-photography-instagram", title: "اصول عکاسی حرفه‌ای برای اینستاگرام" },
      { slug: "best-photo-editing-apps-instagram-1403", title: "بهترین نرم‌افزارهای ویرایش عکس" }
    ],
    "پروفایل": [
      { slug: "successful-instagram-bio", title: "ویژگی‌های یک بیوی موفق در اینستاگرام" }
    ],
    "هوش مصنوعی": [
      { slug: "ai-instagram-content-creation", title: "استفاده از هوش مصنوعی برای تولید محتوا" }
    ],
    "تحلیل": [
      { slug: "instagram-analytics-key-performance-indicators", title: "آنالیز عملکرد پیج اینستاگرام" },
      { slug: "instagram-key-statistics-1403", title: "آمار و ارقام کلیدی اینستاگرام" },
      { slug: "instagram-analytics-tools", title: "ابزارهای آنالیز اینستاگرام" }
    ],
    "همکاری": [
      { slug: "instagram-influencer-collaboration-guide", title: "راهنمای همکاری با اینفلوئنسرها" }
    ],
    "برندسازی": [
      { slug: "instagram-personal-branding", title: "استفاده از اینستاگرام برای برندسازی شخصی" }
    ],
    "تعامل": [
      { slug: "psychology-tips-instagram-engagement", title: "نکات روانشناسی برای افزایش تعامل" },
      { slug: "comprehensive-guide-instagram-engagement", title: "راهنمای جامع افزایش نرخ تعامل" },
      { slug: "instagram-community-building", title: "ایجاد جامعه قدرتمند در اینستاگرام" }
    ],
    "ابزارها": [
      { slug: "essential-tools-professional-instagram-management", title: "ابزارهای ضروری برای مدیریت حرفه‌ای" }
    ],
    "درآمدزایی": [
      { slug: "instagram-monetization-methods-1403", title: "روش‌های کسب درآمد از اینستاگرام" }
    ],
    "مقایسه": [
      { slug: "instagram-vs-other-social-media-platforms", title: "مقایسه اینستاگرام با سایر پلتفرم‌ها" },
      { slug: "all-about-threads-meta-new-platform", title: "همه چیز درباره تردز: پلتفرم جدید متا" }
    ],
    "تبلیغات": [
      { slug: "complete-instagram-ads-guide-iranian-businesses", title: "راهنمای کامل تبلیغات در اینستاگرام" }
    ],
    "لایو": [
      { slug: "instagram-live-comprehensive-guide", title: "راهنمای جامع لایو اینستاگرام" }
    ],
    "راهنماها": [
      { slug: "how-to-use-instagram-guides", title: "نحوه استفاده از راهنماها در اینستاگرام" }
    ],
    "موسیقی": [
      { slug: "how-to-use-music-instagram-content", title: "نحوه استفاده از موسیقی در محتوا" }
    ],
    "استراتژی": [
      { slug: "instagram-content-strategy-b2b", title: "استراتژی محتوای اینستاگرام برای B2B" }
    ],
    "امنیت": [
      { slug: "instagram-account-security", title: "امنیت حساب کاربری اینستاگرام" }
    ],
    "مدیریت": [
      { slug: "instagram-crisis-management", title: "مدیریت بحران در اینستاگرام" }
    ]
  };

  return (
    <div className={`bg-white ${!isMobile ? 'min-h-screen' : 'mobile-page-container'}`} dir="rtl">
      <SEO 
        title="نقشه سایت اینستایار | دسترسی آسان به تمام صفحات"
        description="نقشه کامل سایت اینستایار برای دسترسی سریع به تمام صفحات و بخش‌های سایت"
        keywords="نقشه سایت اینستایار، سایت‌مپ، دسترسی سریع، صفحات اینستایار"
        type="website"
      />

      <MarketingHeader currentPage="sitemap" />

      <div className="max-w-4xl mx-auto py-12 px-4 md:px-6">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">نقشه سایت</h1>
        <p className="text-gray-600 mb-10">برای دسترسی سریع به تمام بخش‌های سایت اینستایار می‌توانید از لینک‌های زیر استفاده کنید.</p>

        <div className="grid md:grid-cols-2 gap-12">
          {/* صفحات اصلی */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">صفحات اصلی</h2>
            <ul className="space-y-3">
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/" className="flex items-center">
                  <span className="ml-2">•</span>
                  صفحه اصلی
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/app" className="flex items-center">
                  <span className="ml-2">•</span>
                  اپلیکیشن
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/dashboard" className="flex items-center">
                  <span className="ml-2">•</span>
                  داشبورد
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/features" className="flex items-center">
                  <span className="ml-2">•</span>
                  امکانات
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/pricing" className="flex items-center">
                  <span className="ml-2">•</span>
                  تعرفه‌ها
                </Link>
              </li>
            </ul>
          </div>

          {/* اطلاعات و پشتیبانی */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">اطلاعات و پشتیبانی</h2>
            <ul className="space-y-3">
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/about" className="flex items-center">
                  <span className="ml-2">•</span>
                  درباره ما
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/contact" className="flex items-center">
                  <span className="ml-2">•</span>
                  تماس با ما
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/faq" className="flex items-center">
                  <span className="ml-2">•</span>
                  سؤالات متداول
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/help" className="flex items-center">
                  <span className="ml-2">•</span>
                  راهنما
                </Link>
              </li>
            </ul>
          </div>

          {/* مقالات وبلاگ */}
          <div className="md:col-span-2">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">مقالات وبلاگ</h2>
            <p className="text-gray-600 mb-4">بخش‌بندی مقالات بر اساس موضوع:</p>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
              {Object.entries(blogPosts).map(([category, posts]) => (
                <div key={category} className="mb-6">
                  <h3 className="font-semibold text-indigo-700 mb-2">{category}</h3>
                  <ul className="space-y-2">
                    {posts.map(post => (
                      <li key={post.slug} className="hover:text-indigo-600 transition-colors text-sm">
                        <Link to={`/blog/${post.slug}`} className="flex items-start">
                          <span className="ml-1 mt-1">•</span>
                          <span>{post.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            
            <div className="mt-4">
              <Link to="/blog" className="text-indigo-600 hover:text-indigo-800 font-medium">
                مشاهده تمام مقالات وبلاگ →
              </Link>
            </div>
          </div>

          {/* آموزش و محتوا */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">آموزش و محتوا</h2>
            <ul className="space-y-3">
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/blog" className="flex items-center">
                  <span className="ml-2">•</span>
                  بلاگ
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/roadmap" className="flex items-center">
                  <span className="ml-2">•</span>
                  نقشه راه
                </Link>
              </li>
            </ul>
          </div>

          {/* صفحات قانونی */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">اطلاعات قانونی</h2>
            <ul className="space-y-3">
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/terms" className="flex items-center">
                  <span className="ml-2">•</span>
                  قوانین و مقررات
                </Link>
              </li>
              <li className="hover:text-indigo-600 transition-colors">
                <Link to="/privacy" className="flex items-center">
                  <span className="ml-2">•</span>
                  حریم خصوصی
                </Link>
              </li>
            </ul>
          </div>

          {/* ابزارها و امکانات */}
          <div className="md:col-span-2">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">ابزارها و امکانات</h2>
            <p className="text-gray-600 mb-4">برای دسترسی به ابزارهای هوشمند اینستایار، به <Link to="/app" className="text-indigo-600 hover:underline">صفحه اپلیکیشن</Link> مراجعه کنید.</p>
          </div>
          
          {/* دسترسی سریع */}
          <div className="md:col-span-2">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">دسترسی‌های سریع</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              <Link to="/auth" className="bg-gray-50 hover:bg-gray-100 transition-colors p-4 rounded-md text-center">
                ورود / ثبت‌نام
              </Link>
              <Link to="/dashboard" className="bg-gray-50 hover:bg-gray-100 transition-colors p-4 rounded-md text-center">
                داشبورد کاربری
              </Link>
              <Link to="/features" className="bg-gray-50 hover:bg-gray-100 transition-colors p-4 rounded-md text-center">
                امکانات
              </Link>
              <Link to="/pricing" className="bg-gray-50 hover:bg-gray-100 transition-colors p-4 rounded-md text-center">
                تعرفه‌ها و قیمت‌ها
              </Link>
              <Link to="/faq" className="bg-gray-50 hover:bg-gray-100 transition-colors p-4 rounded-md text-center">
                سؤالات متداول
              </Link>
              <Link to="/careers" className="bg-gray-50 hover:bg-gray-100 transition-colors p-4 rounded-md text-center">
                فرصت‌های شغلی
              </Link>
            </div>
          </div>
          
          {/* لینک‌های خارجی */}
          <div className="md:col-span-2">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 border-b pb-2">لینک‌های خارجی</h2>
            <ul className="space-y-3">
              <li className="hover:text-indigo-600 transition-colors">
                <a href="https://instagram.com/instayar.com" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <span className="ml-2">•</span>
                  صفحه اینستاگرام اینستایار
                  <ExternalLink size={14} className="mr-2" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-8 px-4 md:px-6 mt-12">
        <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">اینستایار</h3>
            <p className="mb-3 md:mb-4 text-sm md:text-base">دستیار هوشمند اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">لینک‌های مفید</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><Link to="/" className="hover:text-white">صفحه اصلی</Link></li>
              <li><Link to="/blog" className="hover:text-white">آموزش اینستاگرام</Link></li>
              <li><Link to="/pricing" className="hover:text-white">تعرفه‌ها</Link></li>
              <li><Link to="/contact" className="hover:text-white">تماس با ما</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">خدمات</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><Link to="/features" className="hover:text-white">ویژگی‌ها</Link></li>
              <li><Link to="/pricing" className="hover:text-white">تعرفه‌ها</Link></li>
              <li><Link to="/faq" className="hover:text-white">سؤالات متداول</Link></li>
              <li><Link to="/help" className="hover:text-white">مرکز راهنما</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">تماس با ما</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-8 pt-6 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Sitemap;
