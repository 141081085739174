import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { RocketIcon, SparklesIcon, CheckCircle2, ShieldCheck, MessageCircle } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import SEO from "@/components/SEO";

const Pricing = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  // SEO structured data for pricing
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "اینستایار - تعرفه‌ها و قیمت‌ها",
    "applicationCategory": "BusinessApplication",
    "offers": [{
      "@type": "Offer",
      "name": "پکیج رایگان",
      "price": "0",
      "priceCurrency": "IRR"
    }, {
      "@type": "Offer",
      "name": "پکیج پایه",
      "price": "500000",
      "priceCurrency": "IRR"
    }, {
      "@type": "Offer",
      "name": "پکیج مدیریتی",
      "price": "1500000",
      "priceCurrency": "IRR"
    }, {
      "@type": "Offer",
      "name": "پکیج حرفه‌ای",
      "price": "2500000",
      "priceCurrency": "IRR"
    }],
    "operatingSystem": "Web",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "254"
    }
  };

  // تعریف پلن‌های قیمت‌گذاری - با حذف حروف اول و امکانات مشخص شده
  const tiers = [{
    name: "رایگان",
    id: "free",
    href: "#",
    priceMonthly: "رایگان",
    description: "برای شروع و آشنایی با امکانات اینستایار",
    features: ["۲۰ اعتبار رایگان", "دسترسی به همه ابزارها", "تولید محتوای پایه", "پشتیبانی از طریق ایمیل"],
    mostPopular: false,
    color: "gray"
  }, {
    name: "پایه",
    id: "basic",
    href: "#",
    priceMonthly: "۵۰,۰۰۰",
    description: "مناسب برای تولیدکنندگان محتوای مبتدی",
    features: ["۵۰ اعتبار", "دسترسی به همه ابزارها", "تولید محتوای باکیفیت", "پشتیبانی اولویت‌دار"],
    mostPopular: false,
    color: "blue"
  }, {
    name: "مدیریتی",
    id: "admin",
    href: "#",
    priceMonthly: "۱۵۰,۰۰۰",
    description: "مناسب برای تولیدکنندگان محتوا و کسب‌وکارهای کوچک",
    features: ["۲۰۰ اعتبار", "دسترسی به همه ابزارها", "تولید محتوای حرفه‌ای", "پشتیبانی ویژه", "اولویت در پشتیبانی"],
    mostPopular: true,
    color: "indigo"
  }, {
    name: "حرفه‌ای",
    id: "pro",
    href: "#",
    priceMonthly: "۲۵۰,۰۰۰",
    specialLabel: "بیشترین تخفیف",
    description: "برای آژانس‌های بازاریابی و تیم‌های بزرگ",
    features: ["۵۰۰ اعتبار", "دسترسی به همه ابزارها", "تولید محتوای پیشرفته", "پشتیبانی اختصاصی", "اولویت در ویژگی‌های جدید"],
    mostPopular: false,
    color: "amber"
  }];

  // تعیین رنگ دکمه برای هر پلن
  const getButtonClass = (tier) => {
    switch(tier.id) {
      case 'free':
        return 'bg-gray-600 hover:bg-gray-700';
      case 'basic':
        return 'bg-blue-500 hover:bg-blue-600';
      case 'admin':
        return 'bg-indigo-600 hover:bg-indigo-700';
      case 'pro':
        return 'bg-amber-500 hover:bg-amber-600';
      default:
        return 'bg-indigo-600 hover:bg-indigo-700';
    }
  };

  return (
    <div className={`bg-white ${!isMobile ? 'min-h-screen' : 'mobile-page-container'}`} dir="rtl">
      <SEO title="تعرفه‌ها و قیمت‌ها | اینستایار - دستیار هوشمند اینستاگرام" description="قیمت‌گذاری ساده و مقرون به صرفه برای استفاده از اینستایار. با خرید اعتبار، به تمامی ابزارهای هوشمند اینستایار دسترسی خواهید داشت و می‌توانید محتوای اینستاگرام خود را به سطح بالاتری ببرید." keywords="قیمت اینستایار، تعرفه اینستایار، خرید اعتبار اینستایار، هزینه تولید محتوای اینستاگرام، قیمت دستیار هوشمند اینستاگرام، پلن رایگان اینستایار، پکیج‌های اینستایار" canonicalUrl="https://instayar.com/pricing" type="product" structuredData={structuredData} />
      
      {/* Header - Removed 'mobile-header-fixed' class to ensure it's not sticky */}
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-4xl mx-auto py-2 md:py-6 px-4 md:px-6">
          <div className={`${isMobile ? 'flex flex-col gap-2' : 'flex justify-between items-center'}`}>
            <h1 className="text-2xl font-bold bg-gradient-to-l from-indigo-600 to-purple-600 text-transparent bg-clip-text cursor-pointer" onClick={() => navigate("/")}>
              اینستایار
            </h1>
            <div className={`flex ${isMobile ? 'w-full justify-center flex-wrap' : ''} items-center gap-2 md:gap-4`}>
              <Button variant="ghost" size={isMobile ? "sm" : "default"} onClick={() => navigate("/blog")} className="text-slate-800">آموزش اینستاگرام</Button>
              <Button variant="ghost" size={isMobile ? "sm" : "default"} onClick={() => navigate("/pricing")} className="text-slate-950 font-semibold">تعرفه‌ها</Button>
              <Button variant="ghost" size={isMobile ? "sm" : "default"} onClick={() => navigate("/about")} className="text-slate-950">درباره ما</Button>
              <Button className="bg-indigo-600 hover:bg-indigo-700 text-white" size={isMobile ? "sm" : "default"} onClick={() => navigate("/auth")}>
                ورود / ثبت‌نام
              </Button>
            </div>
          </div>
        </div>
      </header>

      {/* Pricing Section - Modernized Design */}
      <div className="py-10 md:py-16 px-4 md:px-6 lg:px-8 bg-gradient-to-b from-white to-slate-50">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            تعرفه‌های اینستایار
          </h2>
          <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
            با انتخاب پلن مناسب، تولید محتوای اینستاگرام خود را به سطح بالاتری ببرید.
          </p>
        </div>

        <div className="mt-10 md:mt-16 max-w-6xl mx-auto">
          <div className={`grid grid-cols-1 ${isMobile ? '' : 'md:grid-cols-4'} gap-6 md:gap-8`}>
            {tiers.map(tier => (
              <div 
                key={tier.id} 
                className={`bg-white rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1 overflow-hidden relative ${tier.mostPopular ? 'ring-2 ring-indigo-500' : ''}`}
              >
                {tier.mostPopular && (
                  <div className="absolute top-0 right-0 left-0">
                    <div className="bg-indigo-600 text-white text-center py-1.5 font-medium text-sm">محبوب‌ترین</div>
                  </div>
                )}
                {tier.specialLabel && (
                  <div className="absolute top-0 right-0 left-0">
                    <div className="bg-green-600 text-white text-center py-1.5 font-medium text-sm">{tier.specialLabel}</div>
                  </div>
                )}
                
                <div className="p-6 md:p-8">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{tier.name}</h3>
                  <p className="text-gray-500 mb-6 h-12">{tier.description}</p>
                  
                  <div className="mb-6">
                    <span className="text-4xl font-extrabold tracking-tight text-gray-900 font-sans">
                      {tier.priceMonthly}
                    </span>
                    {tier.id !== "free" && (
                      <span className="text-lg text-gray-500 mr-2">تومان</span>
                    )}
                    <p className="text-sm text-gray-500 mt-1">
                      {tier.id !== "free" && "بدون تاریخ انقضا"}
                    </p>
                  </div>

                  <Button 
                    className={`w-full ${getButtonClass(tier)} text-white py-2.5 rounded-xl transition-colors`} 
                    onClick={() => navigate("/auth")}
                  >
                    شروع کنید
                  </Button>
                </div>

                <div className="p-6 md:p-8 bg-gray-50 border-t border-gray-100">
                  <div className="text-sm font-medium text-gray-900 mb-4">امکانات شامل:</div>
                  <ul className="space-y-3">
                    {tier.features.map(feature => (
                      <li key={feature} className="flex items-start">
                        <CheckCircle2 className={`h-5 w-5 flex-shrink-0 text-${tier.color}-500`} />
                        <span className="mr-2 text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Why Choose Us Section - Updated Style */}
        <div className="mt-20 max-w-5xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 text-center mb-10">چرا اینستایار را انتخاب کنید؟</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
            {/* Feature 1 */}
            <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all">
              <div className="w-14 h-14 rounded-full bg-indigo-100 flex items-center justify-center mb-4">
                <RocketIcon className="w-7 h-7 text-indigo-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">افزایش سرعت تولید محتوا</h3>
              <p className="text-gray-600">با ابزارهای هوشمند ما، محتوای جذاب را در کمترین زمان تولید کنید.</p>
            </div>

            {/* Feature 2 */}
            <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all">
              <div className="w-14 h-14 rounded-full bg-purple-100 flex items-center justify-center mb-4">
                <SparklesIcon className="w-7 h-7 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">محتوای خلاقانه و جذاب</h3>
              <p className="text-gray-600">ایده‌های جدید و محتوای منحصربه‌فرد برای جذب مخاطبان بیشتر.</p>
            </div>

            {/* Feature 3 */}
            <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all">
              <div className="w-14 h-14 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <ShieldCheck className="w-7 h-7 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">امنیت و حریم خصوصی</h3>
              <p className="text-gray-600">اطلاعات شما نزد ما محفوظ است. با خیال راحت از خدمات ما استفاده کنید.</p>
            </div>
          </div>
        </div>

        {/* FAQ and Contact Section - Updated Style */}
        <div className="mt-16 md:mt-20 text-center max-w-4xl mx-auto">
          <div className="bg-gradient-to-r from-indigo-50 to-blue-50 rounded-2xl p-8 md:p-10">
            <div className="w-16 h-16 rounded-full bg-indigo-100 flex items-center justify-center mx-auto mb-6">
              <MessageCircle className="w-8 h-8 text-indigo-600" />
            </div>
            <h3 className="text-2xl font-bold mb-4">سؤالی دارید؟</h3>
            <p className="text-gray-600 mb-6 max-w-lg mx-auto">
              برای کسب اطلاعات بیشتر و دریافت مشاوره رایگان، با ما تماس بگیرید.
            </p>
            <Button className="bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-2.5 rounded-xl" onClick={() => navigate("/contact")}>
              تماس با ما
            </Button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-8 md:py-12 px-4 md:px-6">
        <div className="max-w-4xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">اینستایار</h3>
            <p className="mb-3 md:mb-4 text-sm md:text-base">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">لینک‌های مفید</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">خدمات</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-3 md:mb-4">تماس با ما</h3>
            <ul className="space-y-1 md:space-y-2 text-sm md:text-base">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-4xl mx-auto mt-8 md:mt-12 pt-6 md:pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Pricing;
