import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ShieldCheck } from "lucide-react";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white" dir="rtl">
      <SEO 
        title="سیاست حفظ حریم خصوصی | اینستایار"
        description="آشنایی با سیاست‌های حفظ حریم خصوصی و محافظت از داده‌های کاربران در اینستایار، دستیار هوشمند تولید محتوای اینستاگرام"
        keywords="حریم خصوصی اینستایار, سیاست‌های حفظ حریم خصوصی, امنیت داده‌ها, دستیار هوشمند اینستاگرام"
      />
      
      {/* Use the standardized marketing header */}
      <MarketingHeader currentPage="privacy" />

      {/* Hero Section */}
      <section className="py-16 px-6 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <ShieldCheck className="w-16 h-16 text-indigo-600 mx-auto mb-6" />
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">سیاست حفظ حریم خصوصی</h1>
          <p className="text-xl text-gray-600 mb-8 leading-relaxed">
            ما به حفظ حریم خصوصی کاربران خود متعهد هستیم. در این صفحه می‌توانید اطلاعات کاملی درباره نحوه استفاده و محافظت از داده‌های خود پیدا کنید.
          </p>
        </div>
      </section>

      {/* Privacy Policy Content */}
      <section className="py-12 px-6 mb-16">
        <div className="max-w-4xl mx-auto">
          <div className="space-y-12">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">مقدمه</h2>
              <p className="text-gray-600 mb-4">
                این سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه ما اطلاعات شخصی شما را جمع‌آوری، استفاده و محافظت می‌کنیم. ما متعهد به حفظ حریم خصوصی کاربران خود هستیم و از اطلاعات شما طبق قوانین حفظ حریم خصوصی مراقبت می‌کنیم.
              </p>
              <p className="text-gray-600">
                با استفاده از وب‌سایت و خدمات ما، شما با شرایط این سیاست موافقت می‌کنید. اگر با شرایط این سیاست موافق نیستید، لطفاً از خدمات ما استفاده نکنید.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">اطلاعاتی که ما جمع‌آوری می‌کنیم</h2>
              <p className="text-gray-600 mb-4">
                ما ممکن است ا��واع مختلفی از اطلاعات را از کاربران خود جمع‌آوری کنیم، از جمله:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>اطلاعات شخصی مانند نام، آدرس ایمیل و اطلاعات تماس</li>
                <li>اطلاعات حساب کاربری مانند نام کاربری و رمز عبور</li>
                <li>اطلاعات پرداخت برای خرید خدمات ما</li>
                <li>اطلاعات استفاده از خدمات ما، مانند تعامل با ابزارهای ما</li>
                <li>داده‌های فنی مانند آدرس IP، نوع مرورگر و سیستم عامل</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">نحوه استفاده از اطلاعات</h2>
              <p className="text-gray-600 mb-4">
                ما از اطلاعات جمع‌آوری شده برای اهداف زیر استفاده می‌کنیم:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>ارائه، نگهداری و بهبود خدمات خود</li>
                <li>پردازش پرداخت‌ها و تراکنش‌ها</li>
                <li>ارسال اطلاعات و به‌روزرسانی‌ها در مورد خدمات ما</li>
                <li>پاسخگویی به درخواست‌ها و پشتیبانی کاربران</li>
                <li>تحلیل استفاده از خدمات و بهبود تجربه کاربری</li>
                <li>شناسایی و جلوگیری از تقلب و موارد امنیتی</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">محافظت از اطلاعات</h2>
              <p className="text-gray-600 mb-4">
                ما از اقدامات امنیتی فنی و سازمانی مناسب برای محافظت از اطلاعات شخصی شما در برابر از دست دادن، سوء استفاده، دسترسی غیرمجاز، افشا، تغییر و تخریب استفاده می‌کنیم.
              </p>
              <p className="text-gray-600">
                با این حال، هیچ روش انتقال داده از طریق اینترنت یا روش ذخیره‌سازی الکترونیکی 100% امن نیست. در حالی که ما برای محافظت از اطلاعات شخصی شما تلاش می‌کنیم، نمی‌توانیم امنیت مطلق آن را تضمین کنیم.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">به اشتراک‌گذاری اطلاعات</h2>
              <p className="text-gray-600 mb-4">
                ما ممکن است اطلاعات شخصی شما را با اشخاص ثالث در موارد زیر به اشتراک بگذاریم:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>با ارائه‌دهندگان خدمات که به ما در ارائه خدمات کمک می‌کنند</li>
                <li>برای پیروی از قوانین، مقررات یا درخواست‌های قانونی</li>
                <li>برای محافظت از حقوق، اموال یا امنیت ما، کاربران ما یا دیگران</li>
                <li>در صورت ادغام، فروش دارایی‌ها یا انتقال کسب‌وکار (با اطلاع قبلی به شما)</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">حقوق شما</h2>
              <p className="text-gray-600 mb-4">
                شما حقوق خاصی در رابطه با اطلاعات شخصی خود دارید، از جمله:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>دسترسی به اطلاعات شخصی خود</li>
                <li>تصحیح اطلاعات نادرست</li>
                <li>حذف اطلاعات شخصی در شرایط خاص</li>
                <li>محدود کردن پردازش اطلاعات شخصی</li>
                <li>دریافت یک نسخه از اطلاعات شخصی خود</li>
                <li>اعتراض به پردازش اطلاعات شخصی در شرایط خاص</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">تغییرات در سیاست حفظ حریم خصوصی</h2>
              <p className="text-gray-600">
                ما ممکن است این سیاست حفظ حریم خصوصی را به‌روزرسانی کنیم. هر گونه تغییر در این سیاست در این صفحه اعمال خواهد شد و در صورت تغییرات اساسی، ما شما را از طریق ایمیل یا اعلان در وب‌سایت مطلع خواهیم کرد.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">تماس با ما</h2>
              <p className="text-gray-600 mb-4">
                اگر سؤالی درباره سیاست حفظ حریم خصوصی ما دارید، لطفاً با ما تماس بگیرید:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 mr-6">
                <li>ایمیل: info@instayar.com</li>
                <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
                <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
              </ul>
            </div>
          </div>

          <div className="mt-16 text-center">
            <p className="text-gray-600 mb-6">آخرین به‌روزرسانی: ۱۵ تیر ۱۴۰۳</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-12 px-6">
        <div className="max-w-7xl mx-auto grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">لینک‌های مفید</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">خدمات</h3>
            <ul className="space-y-2">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">تماس با ما</h3>
            <ul className="space-y-2">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Privacy;
