
import { useRef, useEffect } from 'react';
import HCaptchaLib from '@hcaptcha/react-hcaptcha';

// Define window.hcaptcha for TypeScript
declare global {
  interface Window {
    hcaptcha?: {
      reset: () => void;
    };
  }
}

interface HCaptchaProps {
  sitekey: string;
  onVerify: (token: string) => void;
  onError?: (error: any) => void;
  onExpire?: () => void;
}

const HCaptcha = ({ sitekey, onVerify, onError, onExpire }: HCaptchaProps) => {
  const captchaRef = useRef<HCaptchaLib>(null);

  useEffect(() => {
    // Reset captcha on component unmount
    return () => {
      if (captchaRef.current) {
        try {
          captchaRef.current.resetCaptcha();
        } catch (e) {
          console.error("خطا در بازنشانی کپچا:", e);
        }
      }
    };
  }, []);

  const handleVerify = (token: string) => {
    console.log(`هکپچا تأیید شد! طول توکن: ${token.length}`);
    // Make sure we have a valid token before passing to parent
    if (token && token.length > 0) {
      onVerify(token);
    } else {
      console.error("توکن کپچا نامعتبر است");
      if (onError) onError("invalid-token");
    }
  };

  const handleError = (err: any) => {
    console.error("خطای هکپچا:", err);
    if (onError) onError(err);
  };

  const handleExpire = () => {
    console.log("توکن هکپچا منقضی شد");
    if (onExpire) onExpire();
  };

  return (
    <div className="flex justify-center py-2" dir="rtl">
      <HCaptchaLib
        ref={captchaRef}
        sitekey={sitekey}
        onVerify={handleVerify}
        onError={handleError}
        onExpire={handleExpire}
        theme="dark"
        size="normal"
        languageOverride="fa"
      />
    </div>
  );
};

export default HCaptcha;
