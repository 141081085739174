
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader2, Lightbulb, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

interface FormData {
  text: string;
  editPrompt: string;
}

export const CaptionEditor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editedText, setEditedText] = useState<string>("");
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const form = useForm<FormData>();
  const { profile, refreshProfile } = useAuth();

  const onSubmit = async (data: FormData) => {
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "editor", creditCost: 2 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      console.log("Calling edit-caption function with data:", data);
      
      const response = await supabase.functions.invoke("edit-caption", {
        body: { 
          text: data.text,
          editPrompt: data.editPrompt
        },
      });

      if (response.error) throw response.error;
      
      const responseData = response.data;
      
      if (responseData && responseData.error) {
        throw new Error(responseData.error);
      }
      
      if (!responseData || !responseData.edited_text) {
        throw new Error('پاسخ نامعتبر از سرور');
      }
      
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setEditedText(responseData.edited_text);
      setExplanation(responseData.explanation || "");

      try {
        const { error: saveError } = await supabase.from('edited_captions').insert({
          original_text: data.text,
          edit_prompt: data.editPrompt,
          edited_text: responseData.edited_text,
          explanation: responseData.explanation || ""
        });
        
        if (saveError) {
          console.error("Error saving edited caption:", saveError);
        }
      } catch (saveException) {
        console.error("Exception saving edited caption:", saveException);
      }

      await refreshProfile();

      toast({
        title: "ویرایش انجام شد",
        description: "متن شما با موفقیت ویرایش شد",
      });

    } catch (error) {
      console.error("Error in CaptionEditor:", error);
      toast({
        title: "خطا",
        description: error instanceof Error ? error.message : "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6" dir="rtl">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="text"
            render={({ field }) => (
              <FormItem>
                <FormLabel>متن اصلی</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="متن مورد نظر خود را وارد کنید..."
                    className="h-32"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="editPrompt"
            render={({ field }) => (
              <FormItem>
                <FormLabel>دستور ویرایش</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="مثال: رسمی‌تر شود، کوتاه‌تر شود، لحن صمیمی‌تر شود و..."
                    className="h-24"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button type="submit" disabled={isLoading} className="w-full">
            {isLoading ? <Loader2 className="ml-2 h-4 w-4 animate-spin" /> : null}
            ویرایش متن
          </Button>
        </form>
      </Form>

      {editedText ? (
        <div className="space-y-4">
          <Card className="p-4">
            <h3 className="font-bold mb-2">متن ویرایش شده</h3>
            <p className="whitespace-pre-wrap">{editedText}</p>
          </Card>
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">توضیحات ویرایش: تحلیل تغییرات</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>ویرایشگر کپشن</strong> به شما کمک می‌کند متن‌های خود را به سرعت و با کیفیت بالا ویرایش کنید. 
            فقط کافیست متن اصلی و دستورات ویرایش را وارد کنید تا متنی حرفه‌ای و متناسب با نیاز خود دریافت کنید. 
            با این ابزار، دیگر نگران نگرانش کپشن‌های جذاب و تأثیرگذار نباشید!
          </p>
        </InfoBox>
      )}
    </div>
  );
};
