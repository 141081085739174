
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2, Lightbulb, Sparkles } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

export const ContentCalendar = () => {
  const [niche, setNiche] = useState("");
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState<any>(null);
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const { profile, refreshProfile } = useAuth();

  const generateCalendar = async () => {
    if (!niche.trim()) {
      toast({
        title: "خطا",
        description: "لطفاً حوزه فعالیت خود را وارد کنید",
        variant: "destructive",
      });
      return;
    }

    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "calendar", creditCost: 6 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setLoading(true);
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;

      // Call the function first to ensure it works before deducting credits
      const { data, error } = await supabase.functions.invoke("generate-content-calendar", {
        body: { niche, year, month: month },
      });

      if (error) throw error;
      
      // Now deduct credits
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Log tool usage
      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;
      
      setCalendar(data.calendar);
      setExplanation(data.explanation || "");

      // Refresh profile to update credit display
      await refreshProfile();

      toast({
        title: "موفقیت",
        description: "تقویم محتوا با موفقیت تولید شد",
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "خطا",
        description: "مشکلی در تولید تقویم پیش آمد",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6" dir="rtl">
      <div className="space-y-4">
        <Textarea
          placeholder="حوزه فعالیت خود را وارد کنید..."
          value={niche}
          onChange={(e) => setNiche(e.target.value)}
          className="min-h-[100px]"
        />

        <Button
          onClick={generateCalendar}
          disabled={loading}
          className="w-full"
        >
          {loading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              در حال تولید تقویم...
            </>
          ) : (
            "دریافت تقویم محتوا"
          )}
        </Button>
      </div>

      {calendar ? (
        <div className="space-y-6">
          {calendar.weekly_themes.map((week: any, weekIndex: number) => (
            <Card key={weekIndex}>
              <CardHeader>
                <CardTitle>هفته {week.week}</CardTitle>
                <CardDescription>{week.theme}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {week.posts.map((post: any, postIndex: number) => (
                    <div key={postIndex} className="border-b pb-4 last:border-0 last:pb-0">
                      <p className="font-semibold mb-2">روز {post.day}: {post.content_type}</p>
                      <p className="text-sm text-gray-600 mb-2">{post.description}</p>
                      <div className="flex flex-wrap gap-2">
                        {post.hashtags.map((tag: string, tagIndex: number) => (
                          <span
                            key={tagIndex}
                            className="bg-primary/10 text-primary text-xs px-2 py-1 rounded-full"
                          >
                            #{tag.replace(/^#/, '')}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          ))}
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی تقویم: تحلیل برنامه محتوایی</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>تقویم هوشمند محتوا</strong> به شما کمک می‌کند برای یک ماه آینده، برنامه منظم و حرفه‌ای تولید محتوا داشته باشید. 
            با استفاده از این ابزار، پست‌های منسجم و هدفمند تولید کنید و از سردرگمی در تولید محتوا رها شوید. 
            تمام هشتگ‌ها، ایده‌ها و موضوعات هفتگی برای شما آماده می‌شود تا فقط روی خلق محتوای با کیفیت تمرکز کنید.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
