import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Mail, Check, Building, Users, Clock, Gift } from "lucide-react";
import { useMobile } from "@/hooks/use-mobile";
import { MarketingHeader } from "@/components/MarketingHeader";
import SEO from "@/components/SEO";

const Careers = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const jobs = [
    {
      title: "متخصص هوش مصنوعی",
      type: "تمام وقت",
      location: "تهران / دورکاری",
      description: "به دنبال متخصص هوش مصنوعی با تجربه در NLP و تکنیک‌های پردازش زبان طبیعی هستیم که بتواند مدل‌های هوش مصنوعی ما را برای تولید محتوای اینستاگرام بهبود دهد."
    },
    {
      title: "توسعه‌دهنده Frontend",
      type: "تمام وقت",
      location: "تهران / دورکاری",
      description: "به دنبال توسعه‌دهنده فرانت‌اند با مهارت در React.js و TypeScript هستیم تا به تیم ما در توسعه رابط کاربری اینستایار کمک کند."
    },
    {
      title: "متخصص دیجیتال مارکتینگ",
      type: "تمام وقت",
      location: "تهران",
      description: "به دنبال متخصص دیجیتال مارکتینگ با تمرکز بر بازاریابی محتوا و استراتژی رشد در شبکه‌های اجتماعی هستیم."
    },
    {
      title: "کارشناس پشتیبانی مشتریان",
      type: "پاره وقت",
      location: "تهران / دورکاری",
      description: "به دنبال کارشناس پشتیبانی مشتریان با مهارت‌های ارتباطی عالی و آشنایی با اینستاگرام هستیم."
    }
  ];

  const benefits = [
    {
      icon: Building,
      title: "محیط کار مدرن",
      description: "دفتر مرکزی ما در تهران با امکانات مدرن و فضای کار خلاقانه"
    },
    {
      icon: Clock,
      title: "ساعات کاری منعطف",
      description: "امکان تنظیم ساعات کاری به صورت منعطف و امکان دورکاری"
    },
    {
      icon: Users,
      title: "تیم پویا و خلاق",
      description: "همکاری با تیمی از متخصصان خلاق و پرانرژی در حوزه تکنولوژی"
    },
    {
      icon: Gift,
      title: "مزایای رفاهی",
      description: "بیمه، پاداش عملکرد، و فرصت‌های رشد و یادگیری مداوم"
    }
  ];

  const values = [
    {
      title: "نوآوری",
      description: "ما به دنبال راه‌های جدید و خلاقانه برای حل مشکلات هستیم."
    },
    {
      title: "تعالی",
      description: "ما همیشه به دنبال بهترین نتایج ممکن هستیم و به استانداردهای بالا متعهدیم."
    },
    {
      title: "همکاری",
      description: "ما معتقدیم که بهترین نتایج از همکاری و اشتراک ایده‌ها حاصل می‌شود."
    },
    {
      title: "رشد",
      description: "ما به رشد و توسعه مداوم تیم و محصولات خود متعهدیم."
    }
  ];

  return (
    <div className="bg-white w-full max-w-full overflow-x-hidden" dir="rtl">
      <SEO 
        title="فرصت‌های شغلی | اینستایار"
        description="به تیم اینستایار بپیوندید. فرصت‌های شغلی در زمینه هوش مصنوعی، توسعه وب و دیجیتال مارکتینگ"
        keywords="استخدام اینستایار, فرصت‌های شغلی اینستایار, کار در استارتاپ"
      />
      
      <MarketingHeader currentPage="careers" />

      <section className="py-16 px-4 bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">پیوستن به تیم اینستایار</h1>
          <p className="text-xl text-gray-600 mb-8 leading-relaxed">
            به تیم در حال رشد ما بپیوندید و در ساخت آینده تولید محتوا با هوش مصنوعی همکاری کنید.
          </p>
        </div>
      </section>

      <section className="py-16 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">ارزش‌های ما</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              اینها ارزش‌هایی هستند که در اینستایار به آنها پایبندیم و در کار روزانه ما منعکس می‌شوند.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-xl p-6 border border-gray-200"
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 px-4 bg-indigo-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">مزایای کار با ما</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              ما به ایجاد محیط کاری مثبت و پرانرژی متعهدیم و مزایای رقابتی برای تیم خود فراهم می‌کنیم.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-xl p-6 shadow-sm text-center"
              >
                <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <benefit.icon className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 px-4 bg-white">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">فرصت‌های شغلی</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              به دنبال افراد خلاق، باانگیزه و مشتاق برای پیوستن به تیم خود هستیم.
            </p>
          </div>

          <div className="space-y-6">
            {jobs.map((job, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-xl p-6 border border-gray-200"
              >
                <div className="flex flex-wrap md:flex-nowrap items-start justify-between gap-4">
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">{job.title}</h3>
                    <div className="flex flex-wrap items-center gap-3 mb-4 text-sm">
                      <span className="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full">{job.type}</span>
                      <span className="text-gray-500">{job.location}</span>
                    </div>
                    <p className="text-gray-600">{job.description}</p>
                    <div className="mt-4 flex flex-wrap gap-3">
                      {['مهارت‌های ارتباطی', 'کار تیمی', 'خلاقیت'].map((skill, i) => (
                        <div key={i} className="flex items-center text-green-700 text-sm">
                          <Check className="w-4 h-4 ml-1" />
                          <span>{skill}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="shrink-0 mt-4 md:mt-0">
                    <Button 
                      onClick={() => window.location.href = "mailto:info@instayar.com?subject=درخواست شغلی: " + job.title} 
                      className="bg-indigo-600 hover:bg-indigo-700 text-white"
                    >
                      ارسال رزومه
                    </Button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 px-4 bg-indigo-50">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">مراحل استخدام</h2>
            <p className="text-lg text-gray-600 mb-8">
              فرآیند استخدام ما ساده و شفاف است. ما به دنبال افراد مستعد و مشتاق برای پیوستن به تیم خود هستیم.
            </p>
            <div className="grid md:grid-cols-3 gap-8 text-center">
              <div>
                <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center text-white mx-auto mb-4">1</div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">ارسال رزومه</h3>
                <p className="text-gray-600">رزومه و انگیزه‌نامه خود را برای ما ارسال کنید.</p>
              </div>
              <div>
                <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center text-white mx-auto mb-4">2</div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">مصاحبه اولیه</h3>
                <p className="text-gray-600">با تیم استخدام ما صحبت کنید تا بیشتر با یکدیگر آشنا شوید.</p>
              </div>
              <div>
                <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center text-white mx-auto mb-4">3</div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">پیشنهاد همکاری</h3>
                <p className="text-gray-600">در صورت تأیید، پیشنهاد همکاری برای شما ارسال خواهد شد.</p>
              </div>
            </div>
            <div className="mt-12">
              <Button 
                onClick={() => window.location.href = "mailto:info@instayar.com"} 
                className="bg-indigo-600 hover:bg-indigo-700 text-white flex items-center mx-auto"
              >
                <Mail className="ml-2" />
                ارسال ایمیل به ما
              </Button>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-gray-900 text-gray-400 py-12 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">اینستایار</h3>
            <p className="mb-4">دستیار هوشمند تولید محتوای اینستاگرام با هوش مصنوعی</p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">لینک‌های مفید</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">صفحه اصلی</a></li>
              <li><a href="/blog" className="hover:text-white">آموزش اینستاگرام</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/contact" className="hover:text-white">تماس با ما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">خدمات</h3>
            <ul className="space-y-2">
              <li><a href="/features" className="hover:text-white">ویژگی‌ها</a></li>
              <li><a href="/pricing" className="hover:text-white">تعرفه‌ها</a></li>
              <li><a href="/faq" className="hover:text-white">سؤالات متداول</a></li>
              <li><a href="/help" className="hover:text-white">مرکز راهنما</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">تماس با ما</h3>
            <ul className="space-y-2">
              <li>ایمیل: info@instayar.com</li>
              <li>تلفن: ۰۲۱۷۷۹۴۲۲۸۱</li>
              <li>آدرس: تهران، تهرانپارس، فلکه اول، خ ماهانی پلاک 117</li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-800 text-center">
          <p>© {new Date().getFullYear()} اینستایار. تمامی حقوق محفوظ است.</p>
        </div>
      </footer>
    </div>
  );
};

export default Careers;
