import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Copy, Loader2, Lightbulb, Sparkles } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

export const DMConsultant = () => {
  const [situation, setSituation] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [replies, setReplies] = useState<any[] | null>(null);
  const [explanation, setExplanation] = useState<string>("");
  const { toast } = useToast();
  const { profile, refreshProfile } = useAuth();

  const generateReplies = async () => {
    if (!situation.trim() || !message.trim()) {
      toast({
        title: "خطا",
        description: "لطفاً تمام فیلدها را پر کنید",
        variant: "destructive",
      });
      return;
    }

    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive",
      });
      return;
    }

    const toolInfo = { id: "dm", creditCost: 2 };

    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }

    setIsLoading(true);
    try {
      console.log("Calling generate-dm-reply with:", { situation, message });
      
      const response = await supabase.functions.invoke("generate-dm-reply", {
        body: { situation, message },
      });

      if (response.error) {
        console.error("Function error:", response.error);
        throw new Error(response.error.message || 'خطا در تولید پاسخ');
      }

      const result = response.data;
      console.log("DM Reply result:", result);
      
      if (!result || !result.replies) {
        throw new Error('پاسخ نامعتبر از سرور');
      }
      
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ credits: profile.credits - toolInfo.creditCost })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      const { error: insertError } = await supabase
        .from('tool_usage')
        .insert({
          user_id: profile.id,
          tool_id: toolInfo.id,
          credit_cost: toolInfo.creditCost
        });

      if (insertError) throw insertError;

      setReplies(result.replies);
      setExplanation(result.explanation || "");

      await supabase.from("dm_consultations").insert({
        situation: situation,
        original_message: message,
        suggested_replies: result.replies
      });

      await refreshProfile();

      toast({
        title: "موفقیت",
        description: "پاسخ‌های پیشنهادی تولید شدند",
      });
    } catch (error) {
      console.error("Error in DMConsultant:", error);
      toast({
        title: "خطا",
        description: error instanceof Error ? error.message : "متأسفانه مشکلی پیش آمده. لطفاً دوباره امتحان کنید.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast({
      title: "کپی شد",
      description: "متن در کلیپ‌بورد کپی شد",
    });
  };

  return (
    <div className="space-y-4" dir="rtl">
      <div className="space-y-2">
        <Textarea
          placeholder="شرایط و جزییات و حاشیه های مربوط به پیام را درین بخش وارد کنید..."
          value={situation}
          onChange={(e) => setSituation(e.target.value)}
          className="min-h-[100px]"
        />
      </div>
      <div className="space-y-2">
        <Textarea
          placeholder="متن پیام یا کامنت دریافتی را اینجا وارد کنید..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="min-h-[100px]"
        />
      </div>
      <Button
        onClick={generateReplies}
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            در حال تولید پاسخ...
          </>
        ) : (
          "تولید پاسخ‌های پیشنهادی"
        )}
      </Button>

      {replies ? (
        <>
          <Tabs defaultValue="professional">
            <TabsList className="grid grid-cols-3 mb-4">
              <TabsTrigger value="professional">رسمی</TabsTrigger>
              <TabsTrigger value="friendly">دوستانه</TabsTrigger>
              <TabsTrigger value="casual">غیر رسمی</TabsTrigger>
            </TabsList>
            {replies.map((reply, index) => (
              <TabsContent key={index} value={reply.type}>
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-right w-full">
                      {reply.type === "professional"
                        ? "پاسخ رسمی"
                        : reply.type === "friendly"
                        ? "پاسخ دوستانه"
                        : "پاسخ غیر رسمی"}
                    </CardTitle>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => copyToClipboard(reply.text)}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                  </CardHeader>
                  <CardContent>
                    <p className="whitespace-pre-wrap text-right">{reply.text}</p>
                  </CardContent>
                </Card>
              </TabsContent>
            ))}
          </Tabs>

          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }} 
              className="mt-6"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{ rotate: [0, 15, 0, 15, 0], scale: [1, 1.2, 1, 1.2, 1] }}
                    transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }} 
                      animate={{ scale: 1 }} 
                      transition={{ delay: 0.5, type: "spring" }} 
                      className="flex items-center justify-center ml-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی پاسخگویی</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }} 
                    animate={{ opacity: 1 }} 
                    transition={{ delay: 0.7, duration: 0.5 }} 
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed text-right">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>مشاور پیام‌ها</strong> به شما کمک می‌کند به سرعت به پیام‌های دریافتی خود در اینستاگرام پاسخ دهید. سه نوع پاسخ متفاوت (رسمی، دوستانه و غیررسمی) برای هر موقعیت دریافت کنید. 
            برای شروع، موقعیت و پیامی که می‌خواهید به آن پاسخ دهید را وارد کنید.
          </p>
        </InfoBox>
      )}
    </div>
  );
};
