
import { X, GripVertical } from "lucide-react";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";

interface MacWindowProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  zIndex?: number;
  onClick?: () => void;
  onHelpClick?: () => void;
  showHelpButton?: boolean;
}

export const MacWindow = ({
  title,
  isOpen,
  onClose,
  children,
  className,
  zIndex = 10,
  onClick,
  onHelpClick,
  showHelpButton = false
}: MacWindowProps) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 600, height: 500 });
  const [isResizing, setIsResizing] = useState(false);
  const [isHelpBlinking, setIsHelpBlinking] = useState(false);
  const constraintsRef = useRef(null);

  // افکت چشمک‌زن تصادفی برای دکمه راهنما
  useEffect(() => {
    if (showHelpButton) {
      // چشمک زدن تصادفی هر 10 تا 30 ثانیه
      const intervalId = setInterval(() => {
        setIsHelpBlinking(true);
        setTimeout(() => setIsHelpBlinking(false), 500); // چشمک زدن برای نیم ثانیه
      }, Math.random() * 20000 + 10000); // زمان تصادفی بین 10 تا 30 ثانیه
      
      return () => clearInterval(intervalId);
    }
  }, [showHelpButton]);

  if (!isOpen) return null;

  return (
    <motion.div
      ref={constraintsRef}
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.95, opacity: 0 }}
      onClick={onClick}
      drag={!isResizing}
      dragMomentum={false}
      dragConstraints={{ left: -800, right: 800, top: -400, bottom: 400 }}
      onDragEnd={(_, info) => {
        setPosition(prev => ({
          x: prev.x + info.offset.x,
          y: prev.y + info.offset.y
        }));
      }}
      style={{ 
        width: size.width,
        height: size.height,
        zIndex,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%) translate(${position.x}px, ${position.y}px)`,
      }}
      className={cn(
        "backdrop-blur-xl bg-white/20 rounded-lg shadow-2xl border border-white/20 min-w-[300px] min-h-[200px] max-w-[90vw] max-h-[90vh] overflow-hidden",
        className
      )}
    >
      <div className="h-9 bg-gray-100/80 border-b border-gray-200/50 rounded-t-lg flex items-center px-4 gap-2 cursor-move">
        <div className="flex items-center gap-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="w-3 h-3 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
          />
          <div className="w-3 h-3 bg-yellow-500 rounded-full" />
          {showHelpButton ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (onHelpClick) onHelpClick();
              }}
              className={`w-3 h-3 rounded-full hover:bg-green-600 transition-colors ${
                isHelpBlinking ? 'bg-green-300' : 'bg-green-500'
              }`}
              aria-label="راهنما"
            />
          ) : (
            <div className="w-3 h-3 bg-green-500 rounded-full" />
          )}
        </div>
        
        <span className="text-sm text-gray-600 font-medium absolute left-1/2 -translate-x-1/2">
          {title}
        </span>
      </div>
      <div className="p-4 overflow-auto h-[calc(100%-2.25rem)] text-right rtl">
        {children}
      </div>

      <div
        className="absolute bottom-0 right-0 w-6 h-6 cursor-se-resize"
        onMouseDown={(e) => {
          e.stopPropagation();
          setIsResizing(true);
          const startX = e.pageX;
          const startY = e.pageY;
          const startWidth = size.width;
          const startHeight = size.height;

          const onMouseMove = (e: MouseEvent) => {
            const deltaX = e.pageX - startX;
            const deltaY = e.pageY - startY;
            setSize({
              width: Math.max(300, Math.min(window.innerWidth * 0.9, startWidth + deltaX)),
              height: Math.max(200, Math.min(window.innerHeight * 0.9, startHeight + deltaY))
            });
          };

          const onMouseUp = () => {
            setIsResizing(false);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
          };

          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
        }}
      >
        <GripVertical className="w-4 h-4 text-gray-400 transform rotate-45 absolute bottom-1 right-1" />
      </div>
    </motion.div>
  );
};
