
import { useEffect, useState } from "react";
import { CreditCard } from "lucide-react";
import { useAuth } from "@/lib/auth";

export const CreditDisplay = () => {
  const { profile } = useAuth();
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevCredits, setPrevCredits] = useState(profile?.credits || 0);

  useEffect(() => {
    if (profile?.credits !== undefined && prevCredits !== profile.credits) {
      // Trigger animation when credits change
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000);
      setPrevCredits(profile.credits);
    }
  }, [profile?.credits, prevCredits]);

  if (!profile) return null;

  // Convert the credits number to Farsi numerals
  const farsiCredits = profile.credits?.toLocaleString('fa-IR');

  return (
    <div 
      className={`flex items-center gap-1.5 bg-black/30 px-2 py-1 rounded-md font-medium
                 ${isAnimating ? "animate-pulse text-amber-400" : "text-white"}`}
    >
      <CreditCard className="w-4 h-4" />
      <span dir="ltr">{farsiCredits}</span>
    </div>
  );
};
