
import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "sonner";
import { useAuth } from "@/lib/auth";
import { motion } from "framer-motion";
import { Send, Loader2, AlertCircle, XCircle } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

const TicketsTab = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [priority, setPriority] = useState("medium");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const { session } = useAuth();

  const resetForm = () => {
    setTitle("");
    setMessage("");
    setPriority("medium");
    setError("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    
    if (!session?.user) {
      toast.error("لطفا ابتدا وارد حساب کاربری خود شوید");
      return;
    }
    
    if (!title.trim()) {
      toast.error("لطفا عنوان را وارد کنید");
      return;
    }
    
    if (!message.trim()) {
      toast.error("لطفا توضیحات را وارد کنید");
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      console.log("Sending support request...");
      
      const { data, error: fnError } = await supabase.functions.invoke("send-support-email", {
        body: {
          title: title.trim(),
          message: message.trim(),
          priority,
          email: session.user.email,
          userId: session.user.id
        }
      });
      
      console.log("Support request response:", data, fnError);
      
      if (fnError) {
        throw new Error(fnError.message || "خطا در ارسال درخواست پشتیبانی");
      }
      
      if (!data?.success) {
        throw new Error(data?.message || data?.error || "خطا در ارسال درخواست پشتیبانی");
      }
      
      toast.success("درخواست پشتیبانی شما با موفقیت ارسال شد");
      resetForm();
    } catch (error: any) {
      console.error("خطا در ارسال درخواست پشتیبانی:", error);
      setError(error.message || "خطا در ارسال فرم. لطفا دوباره تلاش کنید");
      toast.error(error.message || "خطا در ارسال فرم. لطفا دوباره تلاش کنید");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="space-y-4" dir="rtl">
      <Card className="bg-black/30 backdrop-blur-xl border border-white/10 shadow-xl text-white">
        <CardHeader>
          <CardTitle className="text-xl font-semibold text-white">پشتیبانی</CardTitle>
        </CardHeader>
        <CardContent>
          {!session ? (
            <div className="text-center py-20">
              <p className="text-gray-300">برای استفاده از سیستم پشتیبانی، لطفا وارد حساب کاربری خود شوید.</p>
            </div>
          ) : (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {error && (
                <div className="bg-red-500/20 border border-red-500/50 rounded-md p-4 mb-4 flex items-start text-white">
                  <XCircle className="h-5 w-5 ml-2 mt-0.5 flex-shrink-0" />
                  <div className="flex-1">
                    <p className="font-semibold">خطا در ارسال درخواست</p>
                    <p className="text-sm opacity-90">{error}</p>
                  </div>
                </div>
              )}
              
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="title" className="text-white">
                    عنوان
                  </Label>
                  <Input
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="bg-gray-700 border-gray-600 text-white"
                    placeholder="عنوان پیام پشتیبانی"
                  />
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="priority" className="text-white">
                    اولویت
                  </Label>
                  <Select value={priority} onValueChange={setPriority}>
                    <SelectTrigger className="bg-gray-700 border-gray-600 text-white">
                      <SelectValue placeholder="انتخاب اولویت" />
                    </SelectTrigger>
                    <SelectContent className="bg-gray-800 border-gray-700 text-white">
                      <SelectItem value="low">کم</SelectItem>
                      <SelectItem value="medium">متوسط</SelectItem>
                      <SelectItem value="high">زیاد</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="message" className="text-white">
                    پیام
                  </Label>
                  <Textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="min-h-32 bg-gray-700 border-gray-600 text-white"
                    placeholder="توضیحات مشکل یا درخواست خود را اینجا بنویسید"
                  />
                </div>
                
                <Button 
                  type="submit" 
                  className="w-full" 
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="ml-2 h-4 w-4 animate-spin" />
                      در حال ارسال...
                    </>
                  ) : (
                    <>
                      <Send className="ml-2 h-4 w-4" />
                      ارسال به پشتیبانی
                    </>
                  )}
                </Button>
                
                <p className="text-gray-400 text-sm text-center mt-4">
                  تیم پشتیبانی در اسرع وقت با شما در ارتباط خواهد بود.
                </p>
              </form>
            </motion.div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default TicketsTab;
