
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2, Shield, AlertTriangle } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from '@/integrations/supabase/client';
import HCaptcha from '@/components/auth/HCaptcha';
import { HCAPTCHA_SITE_KEY, CAPTCHA_ERROR_MESSAGES } from '@/components/auth/captchaConfig';

// Key for tracking login attempts in local storage
const ADMIN_LOGIN_ATTEMPTS_KEY = 'admin_login_attempts';
const ADMIN_LOCKED_UNTIL_KEY = 'admin_locked_until';
const MAX_ATTEMPTS_BEFORE_CAPTCHA = 2; // Lower threshold for admin login
const MAX_ATTEMPTS_BEFORE_LOCKOUT = 5; // Threshold for temporary lockout
const LOCKOUT_DURATION_MINUTES = 15; // Lockout duration in minutes

// Function to sanitize input values
const sanitizeInput = (input: string): string => {
  return input.trim().replace(/[<>"'&]/g, (char) => {
    switch (char) {
      case '<': return '&lt;';
      case '>': return '&gt;';
      case '"': return '&quot;';
      case "'": return '&#39;';
      case '&': return '&amp;';
      default: return char;
    }
  });
};

const AdminAuth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string | null>(null);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [captchaRequired, setCaptchaRequired] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [lockoutRemaining, setLockoutRemaining] = useState<number | null>(null);
  const [securityInfo, setSecurityInfo] = useState({
    browserInfo: '',
    ipInfo: 'فراهم نشده',
    timeInfo: ''
  });
  const timerRef = useRef<number | null>(null);
  const loginAttemptTimestamps = useRef<number[]>([]);
  const navigate = useNavigate();
  const { toast } = useToast();

  // Load login attempts and lockout status from localStorage on component mount
  useEffect(() => {
    // Collect security info
    const browserInfo = navigator.userAgent;
    const timeInfo = new Date().toLocaleString('fa-IR');
    setSecurityInfo({ 
      browserInfo, 
      ipInfo: 'فراهم نشده', 
      timeInfo
    });
    
    const attempts = parseInt(localStorage.getItem(ADMIN_LOGIN_ATTEMPTS_KEY) || '0', 10);
    setLoginAttempts(attempts);
    setCaptchaRequired(attempts >= MAX_ATTEMPTS_BEFORE_CAPTCHA);
    
    const lockedUntil = localStorage.getItem(ADMIN_LOCKED_UNTIL_KEY);
    if (lockedUntil) {
      const lockTime = parseInt(lockedUntil, 10);
      const now = Date.now();
      if (now < lockTime) {
        setIsLocked(true);
        updateLockoutTimer(lockTime);
      } else {
        // Lockout period expired
        localStorage.removeItem(ADMIN_LOCKED_UNTIL_KEY);
      }
    }
    
    // Load timestamps for rate limiting
    const timestamps = JSON.parse(localStorage.getItem('login_timestamps') || '[]');
    loginAttemptTimestamps.current = timestamps;
  }, []);

  useEffect(() => {
    // Cleanup timer on component unmount
    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, []);

  const updateLockoutTimer = (lockTime: number) => {
    // Clear any existing timer
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
    }
    
    // Calculate and display remaining time
    const updateRemaining = () => {
      const now = Date.now();
      if (now >= lockTime) {
        setIsLocked(false);
        setLockoutRemaining(null);
        localStorage.removeItem(ADMIN_LOCKED_UNTIL_KEY);
        window.clearInterval(timerRef.current!);
        timerRef.current = null;
      } else {
        const secondsRemaining = Math.ceil((lockTime - now) / 1000);
        setLockoutRemaining(secondsRemaining);
      }
    };
    
    updateRemaining(); // Initial update
    timerRef.current = window.setInterval(updateRemaining, 1000);
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session) {
          try {
            const { data, error } = await supabase.rpc('is_admin', {
              user_uuid: session.user.id
            });
            
            if (data && !error) {
              // User is already logged in as admin
              navigate('/admin/dashboard');
            }
          } catch (error) {
            console.error('خطا در بررسی وضعیت ادمین:', error);
          }
        }
      } catch (error) {
        console.error('خطا در بررسی جلسه:', error);
      }
    };
    
    checkAdminStatus();
  }, [navigate]);

  const handleCaptchaVerify = (token: string) => {
    console.log("کپچا تأیید شد، طول توکن:", token.length);
    setCaptchaToken(token);
    setCaptchaError(null);
  };

  const handleCaptchaError = (error: any) => {
    console.error("هکپچا خطا داد:", error);
    
    let errorMessage = CAPTCHA_ERROR_MESSAGES.loadingError;
    if (error === "network-error") {
      errorMessage = CAPTCHA_ERROR_MESSAGES.networkError;
    }
    
    setCaptchaError(errorMessage);
    setCaptchaToken(null);
  };

  const handleCaptchaExpire = () => {
    console.log("کپچا منقضی شد");
    setCaptchaToken(null);
    setCaptchaError(CAPTCHA_ERROR_MESSAGES.expiredError);
  };

  const verifyCaptchaToken = async (token: string): Promise<boolean> => {
    try {
      console.log("ارسال درخواست تأیید کپچا با توکن:", token.substring(0, 15) + "...");
      
      // Get client info for security tracking
      const clientInfo = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screenSize: `${window.screen.width}x${window.screen.height}`,
      };
      
      // Use Supabase client to call the edge function (this will add the authorization header automatically)
      const { data, error: functionError } = await supabase.functions.invoke("verify-hcaptcha", {
        body: { 
          token,
          clientInfo
        },
      });
      
      console.log("نتیجه تأیید کپچا:", data);
      
      if (functionError) {
        console.error("خطا در تابع edge:", functionError);
        return false;
      }
      
      if (data && data.success === true) {
        console.log("کپچا با موفقیت تأیید شد");
        return true;
      } else {
        console.error("خطا در تأیید کپچا:", data?.error || "خطای نامشخص");
        if (data?.errorCodes) {
          console.error("کدهای خطای هکپچا:", data.errorCodes);
        }
        return false;
      }
    } catch (error) {
      console.error("خطا در ارسال درخواست تأیید کپچا:", error);
      return false;
    }
  };
  
  // Check rate limiting (防止暴力破解)
  const checkRateLimit = (): boolean => {
    const now = Date.now();
    // Remove attempts older than 10 minutes
    loginAttemptTimestamps.current = loginAttemptTimestamps.current.filter(
      timestamp => now - timestamp < 10 * 60 * 1000
    );
    
    // Check if there are more than 10 attempts in the last 10 minutes
    if (loginAttemptTimestamps.current.length >= 10) {
      return false; // Rate limit exceeded
    }
    
    // Add current attempt
    loginAttemptTimestamps.current.push(now);
    localStorage.setItem('login_timestamps', JSON.stringify(loginAttemptTimestamps.current));
    return true; // Rate limit not exceeded
  };

  const incrementLoginAttempts = () => {
    const newAttempts = loginAttempts + 1;
    setLoginAttempts(newAttempts);
    localStorage.setItem(ADMIN_LOGIN_ATTEMPTS_KEY, newAttempts.toString());
    
    if (newAttempts >= MAX_ATTEMPTS_BEFORE_CAPTCHA) {
      setCaptchaRequired(true);
    }
    
    // Check if account should be locked
    if (newAttempts >= MAX_ATTEMPTS_BEFORE_LOCKOUT) {
      const lockUntil = Date.now() + (LOCKOUT_DURATION_MINUTES * 60 * 1000);
      localStorage.setItem(ADMIN_LOCKED_UNTIL_KEY, lockUntil.toString());
      setIsLocked(true);
      updateLockoutTimer(lockUntil);
      
      // Record security event using log function - no user_id needed
      try {
        console.log("Recording admin login lockout event");
        // Recording failed login information, but without making a direct database call
        // This will be logged in localStorage for now
        const lockoutInfo = {
          email: email,
          attempts: newAttempts,
          timestamp: new Date().toISOString(),
          lockout_minutes: LOCKOUT_DURATION_MINUTES
        };
        
        console.warn("Account locked due to multiple failed attempts:", lockoutInfo);
        
        // Instead of using await here (which causes the error), we'll just log the info
        console.log("Lockout info will be recorded via RPC call during next login attempt");
      } catch (e) {
        console.error('خطا در ثبت رویداد قفل شدن حساب:', e);
      }
    }
  };

  const resetLoginAttempts = () => {
    setLoginAttempts(0);
    setCaptchaRequired(false);
    localStorage.setItem(ADMIN_LOGIN_ATTEMPTS_KEY, '0');
    localStorage.removeItem(ADMIN_LOCKED_UNTIL_KEY);
    setIsLocked(false);
    setLockoutRemaining(null);
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const logSecurityEvent = async (success: boolean, userId?: string, error?: string) => {
    try {
      const eventData = {
        email: email,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
        error: error
      };
      
      if (userId) {
        await supabase.from('user_events').insert({
          user_id: userId,
          event_type: success ? 'admin_login_success' : 'admin_login_failed',
          event_data: eventData
        });
      } else {
        console.log("Security event logged locally (no user ID):", 
          success ? 'admin_login_success' : 'admin_login_failed',
          eventData
        );
      }
    } catch (e) {
      console.error('خطا در ثبت رویداد امنیتی:', e);
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);

    try {
      // Check rate limiting
      if (!checkRateLimit()) {
        throw new Error("تعداد تلاش‌های ورود بیش از حد مجاز است. لطفاً بعداً دوباره تلاش کنید.");
      }
      
      // Check if account is locked
      if (isLocked) {
        throw new Error(`حساب شما به دلیل تلاش‌های ناموفق متعدد موقتاً قفل شده است. لطفاً ${Math.ceil(lockoutRemaining! / 60)} دقیقه دیگر تلاش کنید.`);
      }
      
      // Sanitize and validate inputs
      const sanitizedEmail = sanitizeInput(email);
      if (sanitizedEmail !== email) {
        throw new Error("ایمیل حاوی کاراکترهای غیرمجاز است");
      }
      
      // Basic input validation
      if (!email.trim() || !password.trim()) {
        throw new Error("لطفاً ایمیل و رمز عبور را وارد کنید");
      }
      
      if (!email.includes('@') || email.length < 5) {
        throw new Error("ایمیل وارد شده نامعتبر است");
      }
      
      if (password.length < 6) {
        throw new Error("رمز عبور باید حداقل ۶ کاراکتر باشد");
      }

      // Check if captcha is required and provided
      if (captchaRequired && !captchaToken) {
        throw new Error("لطفاً ابتدا کپچا را تکمیل کنید");
      }

      // Verify captcha if provided
      if (captchaToken) {
        console.log("در حال تأیید کپچا...");
        const isValidCaptcha = await verifyCaptchaToken(captchaToken);
        
        if (!isValidCaptcha) {
          incrementLoginAttempts();
          throw new Error(CAPTCHA_ERROR_MESSAGES.verificationError);
        }
        
        console.log("کپچا تأیید شد، تلاش برای ورود به سیستم...");
      }

      // ورود به حساب کاربری با Supabase
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      
      if (error) {
        console.error("خطای ورود:", error);
        incrementLoginAttempts();
        await logSecurityEvent(false, undefined, error.message);
        throw error;
      }

      if (!data.user) {
        incrementLoginAttempts();
        await logSecurityEvent(false, undefined, 'No user returned');
        throw new Error('ورود ناموفق بود');
      }
      
      // بررسی اینکه آیا کاربر ادمین است با استفاده از تابع is_admin
      const { data: isAdmin, error: isAdminError } = await supabase.rpc('is_admin', {
        user_uuid: data.user.id
      });
      
      if (isAdminError) {
        console.error('خطا در بررسی وضعیت ادمین:', isAdminError);
        await supabase.auth.signOut();
        await logSecurityEvent(false, undefined, 'Admin check error: ' + isAdminError.message);
        throw new Error('خطا در بررسی وضعیت ادمین. لطفاً با پشتیبانی تماس بگیرید');
      }
      
      // اگر کاربر ادمین نیست
      if (!isAdmin) {
        await supabase.auth.signOut();
        incrementLoginAttempts();
        await logSecurityEvent(false, undefined, 'Not an admin user');
        throw new Error('شما مجوز دسترسی به پنل مدیریت را ندارید');
      }
      
      // Reset login attempts after successful login
      resetLoginAttempts();
      await logSecurityEvent(true, data.user.id);
      
      // Cache admin status
      sessionStorage.setItem('admin_role', 'true');
      sessionStorage.setItem('admin_role_timestamp', Date.now().toString());
      
      toast({
        title: "ورود موفقیت‌آمیز",
        description: "شما با موفقیت وارد پنل مدیریت شدید",
      });
      
      // Record successful login - instead of using RPC, log to console
      try {
        console.log('ثبت ورود موفق:', {
          email: email,
          userAgent: navigator.userAgent,
          success: true
        });
      } catch (e) {
        console.error('خطا در ثبت ورود موفق:', e);
      }
      
      // هدایت به داشبورد مدیریت
      navigate('/admin/dashboard');
    } catch (error: any) {
      console.error('خطا در ورود به سیستم:', error);
      setErrorMessage(error.message || 'خطا در ورود به سیستم. لطفاً دوباره تلاش کنید');
      toast({
        variant: "destructive",
        title: "خطا در ورود به سیستم",
        description: error.message || 'خطا در ورود به سیستم. لطفاً دوباره تلاش کنید',
      });
      
      // Record failed login - instead of using RPC, log to console
      try {
        console.log('ثبت ورود ناموفق:', {
          email: email,
          userAgent: navigator.userAgent,
          success: false
        });
      } catch (e) {
        console.error('خطا در ثبت ورود ناموفق:', e);
      }
      
      if (error.message && error.message.includes("کپچا")) {
        setCaptchaToken(null);
        try {
          if (window.hcaptcha) {
            window.hcaptcha.reset();
          }
        } catch (e) {
          console.error("خطا در بازنشانی کپچا:", e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const formatRemainingTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4" dir="rtl">
      <div 
        className="fixed inset-0 -z-10 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("/lovable-uploads/9b847d69-0e42-4191-b92a-492215e504bb.png")`,
        }}
      />
      <div className="fixed inset-0 -z-10 bg-black/60" />
      
      <Card className="w-full max-w-md">
        <CardHeader className="text-center">
          <CardTitle className="text-xl font-bold">ورود به پنل مدیریت</CardTitle>
          <CardDescription>
            لطفاً اطلاعات ورود خود را وارد کنید
          </CardDescription>
        </CardHeader>
        <form onSubmit={handleLogin}>
          <CardContent className="space-y-4">
            {isLocked && (
              <div className="bg-amber-500/20 p-4 rounded-md flex items-start space-x-2 space-x-reverse">
                <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 ml-2" />
                <div className="text-sm text-amber-200">
                  <p className="font-semibold">حساب موقتاً قفل شده است</p>
                  <p>به دلیل تلاش‌های ناموفق متعدد، حساب شما به مدت {LOCKOUT_DURATION_MINUTES} دقیقه قفل شده است.</p>
                  {lockoutRemaining && (
                    <p className="mt-1">زمان باقی‌مانده: {formatRemainingTime(lockoutRemaining)}</p>
                  )}
                </div>
              </div>
            )}
            
            <div className="space-y-2">
              <Label htmlFor="email">ایمیل</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
                placeholder="ایمیل خود را وارد کنید"
                disabled={isLocked || loading}
                className="bg-gray-100 text-gray-900"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">رمز عبور</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
                placeholder="رمز عبور خود را وارد کنید"
                disabled={isLocked || loading}
                className="bg-gray-100 text-gray-900"
              />
            </div>
            
            {captchaRequired && (
              <div className="space-y-2">
                <Label htmlFor="captcha" className="flex items-center gap-1">
                  <Shield className="h-4 w-4 opacity-70" />
                  تأیید امنیتی
                </Label>
                <HCaptcha
                  sitekey={HCAPTCHA_SITE_KEY}
                  onVerify={handleCaptchaVerify}
                  onError={handleCaptchaError}
                  onExpire={handleCaptchaExpire}
                />
                {captchaError && (
                  <p className="text-xs text-red-400">{captchaError}</p>
                )}
                {loginAttempts >= MAX_ATTEMPTS_BEFORE_CAPTCHA && (
                  <p className="text-xs text-yellow-400">
                    بیش از {MAX_ATTEMPTS_BEFORE_CAPTCHA} بار تلاش ناموفق برای ورود انجام شده است. لطفاً کپچا را تکمیل کنید.
                  </p>
                )}
              </div>
            )}

            {/* Security Information Panel */}
            <div className="bg-gray-800/50 p-3 rounded-md border border-gray-700/50 text-xs">
              <div className="flex items-center gap-1 mb-1 text-gray-300">
                <Shield className="h-3 w-3" />
                <p className="font-medium">اطلاعات امنیتی:</p>
              </div>
              <p className="text-gray-400">مرورگر: {securityInfo.browserInfo.substring(0, 50)}...</p>
              <p className="text-gray-400">زمان: {securityInfo.timeInfo}</p>
            </div>
            
            {errorMessage && (
              <div className="bg-red-500/20 text-red-200 p-3 rounded-md text-sm border border-red-500/20">
                {errorMessage}
              </div>
            )}
          </CardContent>
          <CardFooter>
            <Button 
              type="submit" 
              className="w-full"
              disabled={isLocked || loading || (captchaRequired && !captchaToken)}
            >
              {loading ? (
                <>
                  <Loader2 className="ml-2 h-4 w-4 animate-spin" />
                  در حال ورود...
                </>
              ) : (
                "ورود به پنل مدیریت"
              )}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default AdminAuth;
