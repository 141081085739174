
import { CreditCard, History, LogOut, Settings, User, MessageSquare } from "lucide-react";
import { cn } from "@/lib/utils";
import { useAuth } from "@/lib/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

interface SideNavigationProps {
  currentTab: string;
  onTabChange: (tab: string) => void;
}

const SideNavigation = ({ currentTab, onTabChange }: SideNavigationProps) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  
  const items = [
    {
      id: "profile",
      label: "پروفایل",
      icon: User,
    },
    {
      id: "credits",
      label: "اعتبار",
      icon: CreditCard,
    },
    {
      id: "history",
      label: "تاریخچه",
      icon: History,
    },
    {
      id: "tickets",
      label: "پشتیبانی",
      icon: MessageSquare,
    },
    {
      id: "settings",
      label: "تنظیمات",
      icon: Settings,
    },
  ];

  const handleSignOut = async () => {
    try {
      await signOut();
      toast.success("با موفقیت خارج شدید");
      navigate("/");
    } catch (error) {
      console.error("خطا در خروج از حساب:", error);
      toast.error("خطا در خروج از حساب");
    }
  };

  return (
    <div className="h-full flex flex-col">
      <nav className="space-y-2">
        {items.map((item) => (
          <button
            key={item.id}
            onClick={() => onTabChange(item.id)}
            className={cn(
              "w-full flex items-center gap-3 p-3 text-right rounded-lg transition-colors duration-200",
              currentTab === item.id
                ? "bg-white/10 text-white"
                : "text-gray-300 hover:bg-white/5 hover:text-white"
            )}
          >
            <item.icon className="h-5 w-5" />
            <span>{item.label}</span>
          </button>
        ))}
      </nav>

      <div className="mt-auto pt-6 border-t border-white/10 mt-6">
        <button
          onClick={handleSignOut}
          className="w-full flex items-center gap-3 p-3 text-right rounded-lg text-gray-300 hover:bg-white/5 hover:text-white transition-colors duration-200"
        >
          <LogOut className="h-5 w-5" />
          <span>خروج از حساب</span>
        </button>
      </div>
    </div>
  );
};

export default SideNavigation;
