
import { Award, Check, Star } from "lucide-react";
import type { QuizQuestion as QuizQuestionType, QuizAnswer } from "@/types/quiz";

interface QuizQuestionProps {
  question: QuizQuestionType;
  answer?: QuizAnswer;
  index: number;
}

export const QuizQuestion = ({ question, answer, index }: QuizQuestionProps) => {
  return (
    <div className="space-y-3">
      <p className="text-lg font-semibold">
        {index + 1}. {question.question}
      </p>
      <div className="mr-6 space-y-2">
        {question.options.map((option: string, optionIndex: number) => {
          const isCorrectAnswer = answer && answer.correct_option === optionIndex;
          return (
            <div 
              key={optionIndex} 
              className={`flex items-start gap-2 p-2 rounded-md ${isCorrectAnswer ? "bg-green-100 border-2 border-green-500" : ""}`}
            >
              <div className={`w-6 h-6 flex items-center justify-center ${
                isCorrectAnswer
                ? "bg-green-500 text-white" : "bg-primary/20"
              } rounded-full`}>
                {isCorrectAnswer ? (
                  <Award className="text-white h-4 w-4" />
                ) : (
                  <span>
                    {String.fromCharCode(65 + optionIndex)}
                  </span>
                )}
              </div>
              <p className={isCorrectAnswer ? "font-bold text-green-700" : ""}>
                {option}
                {isCorrectAnswer && (
                  <span className="text-green-600 mr-2 font-bold text-base"> ✓ پاسخ صحیح</span>
                )}
              </p>
            </div>
          );
        })}
      </div>
      {/* حذف بخش نکته مهم - زیرا قبلاً پاسخ صحیح را نشان می‌دهیم */}
    </div>
  );
};
