import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Loader2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import HCaptcha from "./HCaptcha";
import { HCAPTCHA_SITE_KEY, CAPTCHA_ERROR_MESSAGES } from "./captchaConfig";

// Define the global interface for hCaptcha
declare global {
  interface Window {
    hcaptcha?: {
      reset: () => void;
    };
  }
}

const forgotPasswordSchema = z.object({
  email: z.string().email("لطفاً یک ایمیل معتبر وارد کنید"),
});

type ForgotPasswordFormValues = z.infer<typeof forgotPasswordSchema>;

interface ForgotPasswordFormProps {
  onModeChange: (mode: "login") => void;
}

const ForgotPasswordForm = ({ onModeChange }: ForgotPasswordFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string | null>(null);
  const { toast } = useToast();

  const form = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const handleCaptchaVerify = (token: string) => {
    console.log("کپچا تأیید شد، طول توکن:", token.length);
    setCaptchaToken(token);
    setCaptchaError(null);
  };

  const handleCaptchaError = (error: any) => {
    console.error("هکپچا خطا داد:", error);
    
    let errorMessage = CAPTCHA_ERROR_MESSAGES.loadingError;
    if (error === "network-error") {
      errorMessage = CAPTCHA_ERROR_MESSAGES.networkError;
    }
    
    setCaptchaError(errorMessage);
    setCaptchaToken(null);
  };

  const handleCaptchaExpire = () => {
    console.log("کپچا منقضی شد");
    setCaptchaToken(null);
    setCaptchaError(CAPTCHA_ERROR_MESSAGES.expiredError);
  };

  const verifyCaptchaToken = async (token: string): Promise<boolean> => {
    try {
      console.log("ارسال درخواست تأیید کپچا با توکن:", token.substring(0, 15) + "...");
      
      // Use Supabase client to call the edge function (this will add the authorization header automatically)
      const { data, error: functionError } = await supabase.functions.invoke("verify-hcaptcha", {
        body: { token },
      });
      
      console.log("نتیجه تأیید کپچا:", data);
      
      if (functionError) {
        console.error("خطا در تابع edge:", functionError);
        return false;
      }
      
      if (data && data.success === true) {
        console.log("کپچا با موفقیت تأیید شد");
        return true;
      } else {
        console.error("خطا در تأیید کپچا:", data?.error || "خطای نامشخص");
        if (data?.errorCodes) {
          console.error("کدهای خطای هکپچا:", data.errorCodes);
        }
        return false;
      }
    } catch (error) {
      console.error("خطا در ارسال درخواست تأیید کپچا:", error);
      return false;
    }
  };

  const handleForgotPassword = async (data: ForgotPasswordFormValues) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      if (!captchaToken) {
        throw new Error("لطفاً ابتدا کپچا را تکمیل کنید");
      }

      console.log("در حال تأیید کپچا...");
      const isValidCaptcha = await verifyCaptchaToken(captchaToken);
      
      if (!isValidCaptcha) {
        throw new Error(CAPTCHA_ERROR_MESSAGES.verificationError);
      }
      
      console.log("کپچا تأیید شد، تلاش برای بازیابی رمز عبور...");

      // Now send reset password without captchaToken
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(
        data.email,
        { redirectTo: `${window.location.origin}/auth?reset=true` }
      );

      if (resetError) {
        console.error("خطای بازنشانی رمز عبور:", resetError);
        throw resetError;
      }
      
      setSuccess("ایمیل بازیابی رمز عبور به آدرس شما ارسال شد. لطفاً صندوق ورودی خود را بررسی کنید.");
      toast({
        title: "ارسال ایمیل بازیابی",
        description: "ایمیل بازیابی رمز عبور به آدرس شما ارسال شد. لطفاً صندوق ورودی خود را بررسی کنید.",
      });
      
      onModeChange("login");
    } catch (error: any) {
      console.error("خطا در بازیابی رمز عبور:", error);
      setError(error.message || "مشکلی در فرآیند بازیابی رمز عبور پیش آمد");
      toast({
        title: "خطا",
        description: error.message || "مشکلی در فرآیند بازیابی رمز عبور پیش آمد",
        variant: "destructive",
      });
      
      if (error.message && error.message.includes("کپچا")) {
        setCaptchaToken(null);
        try {
          if (window.hcaptcha) {
            window.hcaptcha.reset();
          }
        } catch (e) {
          console.error("خطا در بازنشانی کپچا:", e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleForgotPassword)} className="space-y-3">
      <div className="space-y-1">
        <Label htmlFor="reset-email" className="text-white text-sm">ایمیل</Label>
        <Input
          id="reset-email"
          type="email"
          placeholder="name@example.com"
          className="bg-white/10 border-white/20 text-white placeholder:text-gray-400 text-sm"
          {...form.register("email")}
        />
        {form.formState.errors.email && (
          <p className="text-xs text-red-400">{form.formState.errors.email.message}</p>
        )}
      </div>
      
      <HCaptcha
        sitekey={HCAPTCHA_SITE_KEY}
        onVerify={handleCaptchaVerify}
        onError={handleCaptchaError}
        onExpire={handleCaptchaExpire}
      />
      
      {captchaError && (
        <p className="text-xs text-red-400">{captchaError}</p>
      )}
      
      <Button 
        type="submit" 
        variant="gradient"
        className="w-full text-sm h-9 mt-3"
        disabled={loading || !captchaToken}
      >
        {loading ? <Loader2 className="h-4 w-4 animate-spin ml-2" /> : null}
        ارسال لینک بازیابی
      </Button>
      
      <div className="text-center text-xs">
        <button 
          type="button"
          onClick={() => onModeChange("login")}
          className="text-indigo-300 hover:text-indigo-200"
        >
          بازگشت به صفحه ورود
        </button>
      </div>

      {error && (
        <div className="bg-red-500/20 text-red-200 p-2 rounded-md text-xs border border-red-500/20">
          {error}
        </div>
      )}
      
      {success && (
        <div className="bg-green-500/20 text-green-200 p-2 rounded-md text-xs border border-green-500/20">
          {success}
        </div>
      )}
    </form>
  );
};

export default ForgotPasswordForm;
