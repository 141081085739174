
import React from 'react';

const MaintenancePage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4" dir="rtl">
      <div className="max-w-md w-full text-center space-y-6">
        <div className="space-y-2">
          <h1 className="text-3xl font-bold text-white">سایت در حال به‌روزرسانی است</h1>
          <p className="text-gray-400">
            ما در حال انجام تغییرات و به‌روزرسانی هستیم. به زودی برمی‌گردیم!
          </p>
        </div>
        
        <div className="relative">
          <div className="h-1 w-full bg-gray-700 rounded-full overflow-hidden">
            <div className="h-full bg-gradient-to-r from-blue-500 to-violet-500 animate-pulse" style={{ width: '75%' }}></div>
          </div>
        </div>
        
        <p className="text-sm text-gray-500">
          لطفاً کمی صبر کنید و بعداً مجدداً تلاش کنید.
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
