import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { Loader2, Copy, Lightbulb, Sparkles } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { HelpOverlay } from "@/components/HelpOverlay";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

interface HashtagGeneratorProps {
  initialShowHelp?: boolean;
}

export const HashtagGenerator = ({
  initialShowHelp = false
}: HashtagGeneratorProps) => {
  const [showHelp, setShowHelp] = useState(initialShowHelp);
  const [description, setDescription] = useState("");
  const [hashtagCount, setHashtagCount] = useState(15);
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [explanation, setExplanation] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    toast
  } = useToast();
  const {
    profile,
    refreshProfile
  } = useAuth();

  useEffect(() => {
    setShowHelp(initialShowHelp);
  }, [initialShowHelp]);

  const generateHashtags = async () => {
    if (!description.trim()) {
      toast({
        title: "خطا",
        description: "لطفاً توضیحات پست را وارد کنید",
        variant: "destructive"
      });
      return;
    }
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }
    const toolInfo = {
      id: "hashtags",
      creditCost: 2
    };
    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }
    setIsLoading(true);
    try {
      const {
        data,
        error
      } = await supabase.functions.invoke("generate-hashtags", {
        body: {
          description,
          hashtagCount
        }
      });
      if (error) throw error;
      if (!data || !data.hashtags || !Array.isArray(data.hashtags)) {
        throw new Error("خطا در دریافت هشتگ‌ها");
      }

      const {
        error: updateError
      } = await supabase.from('profiles').update({
        credits: profile.credits - toolInfo.creditCost
      }).eq('id', profile.id);
      if (updateError) throw updateError;

      const {
        error: logError
      } = await supabase.from('tool_usage').insert({
        user_id: profile.id,
        tool_id: toolInfo.id,
        credit_cost: toolInfo.creditCost
      });
      if (logError) console.error("خطا در ثبت استفاده از ابزار:", logError);
      setHashtags(data.hashtags);
      setExplanation(data.explanation || "");

      await refreshProfile();
      toast({
        title: "انجام شد",
        description: `${data.hashtags.length} هشتگ ایجاد شد`
      });
    } catch (error: any) {
      console.error("Error generating hashtags:", error);
      toast({
        title: "خطا",
        description: error.message || "مشکلی در ایجاد هشتگ‌ها پیش آمد",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast({
      title: "کپی شد",
      description: "هشتگ‌ها در کلیپ‌بورد کپی شدند"
    });
  };

  const copyAllHashtags = () => {
    const formattedHashtags = hashtags.map(tag => `#${tag}`).join(' ');
    copyToClipboard(formattedHashtags);
  };

  return <div className="space-y-4 relative" dir="rtl">
      <HelpOverlay isVisible={showHelp} onClose={() => setShowHelp(false)} title="راهنمای تولید هشتگ" description="با این ابزار می‌توانید هشتگ‌های مرتبط با محتوای خود را به صورت هوشمند تولید کنید." steps={["توضیحات پست خود را در کادر مربوطه وارد کنید.", "تعداد هشتگ مورد نیاز را تنظیم کنید.", "دکمه «تولید هشتگ» را کلیک کنید.", "برای استفاده از این ابزار، ۲ اعتبار کسر می‌شود."]} tips={["برای بهترین نتیجه، توضیحات دقیق و مرتبط با محتوای پست خود وارد کنید.", "ترکیبی از هشتگ‌های پرطرفدار و هشتگ‌های اختصاصی استفاده کنید.", "از هشتگ‌های بسیار عمومی مانند #عکس یا #اینستاگرام به تنهایی استفاده نکنید.", "برای هر پست بین ۱۰ تا ۲۰ هشتگ استفاده کنید.", "می‌توانید هشتگ‌ها را در کامنت اول پست قرار دهید تا کپشن شما تمیز بماند."]} />

      <div className="mb-4">
        <Label htmlFor="description" className="mb-2 block">توضیحات پست</Label>
        <Textarea id="description" placeholder="محتوای پست خود را توضیح دهید..." value={description} onChange={e => setDescription(e.target.value)} className="resize-none h-32" />
      </div>
      
      <div className="mb-4">
        <Label htmlFor="count" className="mb-2 block">تعداد هشتگ</Label>
        <Input id="count" type="number" min={5} max={30} value={hashtagCount} onChange={e => setHashtagCount(parseInt(e.target.value) || 15)} />
      </div>
      
      <Button onClick={generateHashtags} disabled={isLoading} className="w-full">
        {isLoading ? <>
            <Loader2 className="ml-2 h-4 w-4 animate-spin" />
            در حال تولید هشتگ...
          </> : "تولید هشتگ"}
      </Button>
      
      {hashtags.length > 0 ? <>
          <Card className="mt-4">
            <CardContent className="p-4">
              <div className="flex flex-wrap gap-2 mb-4">
                {hashtags.map((tag, i) => <div key={i} className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm">
                    #{tag}
                  </div>)}
              </div>
              <Button variant="outline" onClick={copyAllHashtags} className="w-full bg-indigo-800 hover:bg-indigo-700">
                <Copy className="h-4 w-4 ml-2" />
                کپی تمام هشتگ‌ها
              </Button>
            </CardContent>
          </Card>
          
          {explanation && <motion.div initial={{
        opacity: 0,
        y: 20
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        delay: 0.3,
        duration: 0.5
      }} className="mt-6">
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div className="absolute -right-6 -top-6 text-primary/10 z-0" animate={{
              rotate: [0, 15, 0, 15, 0],
              scale: [1, 1.2, 1, 1.2, 1]
            }} transition={{
              duration: 5,
              repeat: Infinity,
              repeatType: "reverse"
            }}>
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div initial={{
                scale: 0
              }} animate={{
                scale: 1
              }} transition={{
                delay: 0.5,
                type: "spring"
              }} className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10">
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">هوش هشتگ: روش انتخاب هشتگ‌ها</h3>
                  </div>
                  
                  <motion.div initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} transition={{
              delay: 0.7,
              duration: 0.5
            }} className="relative z-10">
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>}
        </> : <InfoBox>
          <p className="text-sm">
            <strong>هشتگ ساز هوشمند</strong> به شما کمک می‌کند هشتگ‌های مرتبط، پرطرفدار و هدفمند برای افزایش دیده شدن پست‌های خود پیدا کنید. 
            این ابزار با تحلیل محتوای پست شما، هشتگ‌هایی را پیشنهاد می‌دهد که الگوریتم اینستاگرام را جذب کرده و به اکسپلور شدن پست‌های شما کمک می‌کند. 
            فقط توضیحات پست خود را وارد کنید و بهترین هشتگ‌ها را دریافت کنید!
          </p>
        </InfoBox>}
    </div>;
};
