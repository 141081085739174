
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import DOMPurify from "dompurify"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * ابزارهای امنیتی برای پاکسازی و اعتبارسنجی داده‌های ورودی
 */
export const securityUtils = {
  /**
   * پاکسازی عمومی متن ورودی برای جلوگیری از حملات XSS
   */
  sanitizeString(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    // پاکسازی عمومی با استفاده از DOMPurify
    let sanitized = DOMPurify.sanitize(input, {
      ALLOWED_TAGS: [], // اجازه هیچ تگی داده نمی‌شود
      ALLOWED_ATTR: [], // اجازه هیچ ویژگی تگی داده نمی‌شود
    });
    
    // حذف کاراکترهای بالقوه خطرناک
    sanitized = sanitized
      .replace(/[^\u0600-\u06FFa-zA-Z0-9\s.,?!@:;()\[\]{}\/\-_=+%$#]/g, '')
      .trim();
    
    return sanitized;
  },

  /**
   * پاکسازی متن‌های HTML محدود (مثل توضیحات ریچ تکست)
   * فقط تگ‌های ساده و پایه را نگه می‌دارد
   */
  sanitizeHTML(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    return DOMPurify.sanitize(input, {
      ALLOWED_TAGS: ['b', 'i', 'u', 'p', 'span', 'br', 'ul', 'ol', 'li'],
      ALLOWED_ATTR: ['style', 'class'],
    });
  },

  /**
   * پاکسازی داده‌های ایمیل
   */
  sanitizeEmail(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    const sanitized = DOMPurify.sanitize(input);
    // پترن اعتبارسنجی ساده ایمیل
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    return emailPattern.test(sanitized) ? sanitized : '';
  },

  /**
   * پاکسازی اعداد
   */
  sanitizeNumber(input: string | number | undefined | null): number {
    if (input === undefined || input === null) return 0;
    
    const sanitized = typeof input === 'string' 
      ? DOMPurify.sanitize(input)
      : String(input);
      
    // حذف همه کاراکترها بجز ارقام، نقطه و منفی
    const numStr = sanitized.replace(/[^0-9.-]/g, '');
    const num = parseFloat(numStr);
    
    return isNaN(num) ? 0 : num;
  },

  /**
   * پاکسازی مسیرها و آدرس‌های URL
   */
  sanitizeUrl(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    // پاکسازی عمومی با DOMPurify
    const sanitized = DOMPurify.sanitize(input);
    
    // اطمینان از اینکه URL دارای پروتکل معتبر است
    const urlPattern = /^(https?:\/\/|\/|\.\/|\.\.\/)/i;
    if (!urlPattern.test(sanitized)) {
      return '';
    }
    
    // حذف اسکریپت‌های مخرب احتمالی
    if (/javascript:/i.test(sanitized)) {
      return '';
    }
    
    return sanitized;
  },

  /**
   * پاکسازی نام کاربری اینستاگرام
   */
  sanitizeInstagramUsername(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    const sanitized = DOMPurify.sanitize(input);
    // حذف @ اولیه اگر وجود داشته باشد
    let username = sanitized.startsWith('@') ? sanitized.substring(1) : sanitized;
    
    // فقط حروف، اعداد، نقطه و زیرخط مجاز هستند
    username = username.replace(/[^a-zA-Z0-9._]/g, '');
    
    return username;
  },

  /**
   * پاکسازی شماره تلفن
   */
  sanitizePhoneNumber(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    const sanitized = DOMPurify.sanitize(input);
    // حذف همه کاراکترها بجز ارقام، + (برای کد کشور)
    return sanitized.replace(/[^0-9+]/g, '');
  },

  /**
   * اعتبارسنجی و پاکسازی کد ملی
   */
  sanitizeNationalCode(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    const sanitized = DOMPurify.sanitize(input);
    // فقط ارقام مجاز هستند
    const digitsOnly = sanitized.replace(/[^0-9]/g, '');
    
    // کد ملی ایرانی باید 10 رقم باشد
    if (digitsOnly.length !== 10) {
      return '';
    }
    
    return digitsOnly;
  },

  /**
   * پاکسازی و اعتبارسنجی تاریخ
   */
  sanitizeDate(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    const sanitized = DOMPurify.sanitize(input);
    
    // بررسی اینکه آیا مقدار یک تاریخ معتبر است
    const date = new Date(sanitized);
    if (isNaN(date.getTime())) {
      return '';
    }
    
    return sanitized;
  },
  
  /**
   * پاکسازی و خروجی به HTML با رعایت اصول امنیتی
   * برای نمایش متن در UI
   */
  escapeHtml(input: string | undefined | null): string {
    if (input === undefined || input === null) return '';
    
    return input
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
};
