
// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://shzeflsnvtkgewbtjqgx.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNoemVmbHNudnRrZ2V3YnRqcWd4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg4NjM4NDgsImV4cCI6MjA1NDQzOTg0OH0.efd6wQ44yyDRNLx6za86D0nWl-qaVXP7Ngani1_p6NQ";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);

// تابع کمکی برای بررسی وضعیت ادمین با استفاده از تابع RPC
export const checkIsAdmin = async (userId: string): Promise<boolean> => {
  if (!userId) return false;
  
  try {
    // استفاده از تابع is_admin که به‌صورت امن طراحی شده است
    const { data, error } = await supabase
      .rpc('is_admin', { user_uuid: userId });
      
    if (error) {
      console.error('خطای بررسی وضعیت ادمین:', error);
      return false;
    }
    
    return !!data;
  } catch (error) {
    console.error('خطا در بررسی وضعیت ادمین:', error);
    return false;
  }
};

// تابع برای دریافت تصاویر اینستاگرام
export const getInstagramImages = (): string[] => {
  // استفاده از آدرس‌های مستقیم تصاویر به جای دریافت از باکت
  const instagramImages = [
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(1).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(2).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(3).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(4).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(5).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(6).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(7).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(8).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(9).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(10).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(11).png",
    "https://shzeflsnvtkgewbtjqgx.supabase.co/storage/v1/object/public/instagram//instastock%20(12).png"
  ];
  
  console.log('تصاویر اینستاگرام آماده شدند:', instagramImages.length);
  return instagramImages;
};
