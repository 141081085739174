
import { motion } from "framer-motion";
import type { Tool } from "@/types/tool";

interface ToolBarProps {
  tools: Tool[];
  onToolClick: (toolId: string) => void;
}

export const ToolBar = ({ tools, onToolClick }: ToolBarProps) => {
  return (
    <div className="fixed inset-x-0 bottom-0 flex justify-center items-center p-4">
      <motion.div 
        className="px-6 py-4 rounded-2xl backdrop-blur-xl bg-white/15 border border-white/20 shadow-lg"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
      >
        <div className="grid grid-cols-8 gap-4 max-w-[calc(100vw-2rem)]">
          {tools.map((tool) => (
            <motion.button
              key={tool.id}
              onClick={() => onToolClick(tool.id)}
              className="w-16 h-20 flex flex-col items-center justify-center transition-all hover:scale-110 group relative hover:bg-white/10 rounded-xl"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <tool.icon className="w-8 h-8 text-white mb-2" />
              <span className="text-white text-xs">{tool.shortTitle}</span>
              <div className="absolute -top-8 scale-0 group-hover:scale-100 transition-transform bg-black/80 text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                {tool.title} ({tool.creditCost} اعتبار)
              </div>
            </motion.button>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
