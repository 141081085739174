import { useState, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { SecureInput } from "@/components/ui/secure-input";
import { SecureTextarea } from "@/components/ui/secure-textarea";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import { Search, RefreshCw, Edit, User, CreditCard, Key, ArrowLeft, ArrowRight, Lock } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useForm } from "react-hook-form";
import { securityUtils } from "@/lib/utils";

interface User {
  id: string;
  email: string;
  created_at: string;
  profile?: {
    credits: number;
    full_name: string | null;
    instagram_page: string | null;
    phone_number: string | null;
    city: string | null;
  };
}

interface Payment {
  id: string;
  amount: number;
  package_name: string;
  status: string;
  payment_date: string;
  track_id?: string;
  created_at: string;
}

const USERS_PER_PAGE = 50;
const PAYMENTS_PER_PAGE = 10;

const UserManagement = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userPayments, setUserPayments] = useState<Payment[]>([]);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [paymentsPage, setPaymentsPage] = useState(1);
  const [totalPaymentsPages, setTotalPaymentsPages] = useState(1);

  const form = useForm({
    defaultValues: {
      credits: 0,
      full_name: "",
      instagram_page: "",
      phone_number: "",
      city: "",
      notes: ""
    }
  });

  const passwordForm = useForm({
    defaultValues: {
      newPassword: ""
    }
  });

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const { count } = await supabase
        .from("users")
        .select("*", { count: "exact", head: true });
      
      if (count !== null) {
        setTotalPages(Math.ceil(count / USERS_PER_PAGE));
      }

      const from = (currentPage - 1) * USERS_PER_PAGE;
      const to = from + USERS_PER_PAGE - 1;
      
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("id, email, created_at")
        .range(from, to)
        .order("created_at", { ascending: false });

      if (userError) throw userError;

      const userIds = userData.map((user: any) => user.id);
      const { data: profileData, error: profileError } = await supabase
        .from("profiles")
        .select("id, credits, full_name, instagram_page, phone_number, city")
        .in("id", userIds);

      if (profileError) throw profileError;

      const formattedUsers = userData.map((user: any) => {
        const profile = profileData?.find((p: any) => p.id === user.id);
        return {
          id: user.id,
          email: user.email || "ایمیل نامشخص",
          created_at: user.created_at,
          profile: profile ? {
            credits: profile.credits || 0,
            full_name: profile.full_name,
            instagram_page: profile.instagram_page,
            phone_number: profile.phone_number,
            city: profile.city
          } : undefined
        };
      });

      setUsers(formattedUsers);
    } catch (error) {
      console.error("خطا در دریافت کاربران:", error);
      toast.error("خطا در بارگذاری لیست کاربران");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserPayments = async (userId: string) => {
    setLoadingPayments(true);
    try {
      const { count } = await supabase
        .from("payments")
        .select("*", { count: "exact", head: true })
        .eq("user_id", userId);
        
      if (count !== null) {
        setTotalPaymentsPages(Math.ceil(count / PAYMENTS_PER_PAGE));
      } else {
        setTotalPaymentsPages(1);
      }
      
      const from = (paymentsPage - 1) * PAYMENTS_PER_PAGE;
      const to = from + PAYMENTS_PER_PAGE - 1;
      
      const { data, error } = await supabase
        .from("payments")
        .select("id, amount, package_name, status, payment_date, track_id, created_at")
        .eq("user_id", userId)
        .order("payment_date", { ascending: false })
        .range(from, to);

      if (error) throw error;
      setUserPayments(data || []);
    } catch (error) {
      console.error("خطا در دریافت پرداخت‌ها:", error);
      toast.error("خطا در بارگذاری پرداخت‌ها");
    } finally {
      setLoadingPayments(false);
    }
  };

  const sendPasswordResetEmail = async (email: string) => {
    setSendingPasswordReset(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;
      toast.success("ایمیل بازیابی رمز عبور با موفقیت ارسال شد");
    } catch (error: any) {
      console.error("خطا در ارسال ایمیل بازیابی:", error);
      toast.error(error.message || "خطا در ارسال ایمیل بازیابی رمز عبور");
    } finally {
      setSendingPasswordReset(false);
    }
  };

  const setUserPassword = async (values: { newPassword: string }) => {
    if (!selectedUser || !values.newPassword) return;
    
    const sanitizedPassword = securityUtils.sanitizeString(values.newPassword);
    
    if (!sanitizedPassword || sanitizedPassword.length < 8) {
      toast.error("رمز عبور باید حداقل 8 کاراکتر باشد");
      return;
    }
    
    try {
      const { error } = await supabase.auth.admin.updateUserById(
        selectedUser.id,
        { password: sanitizedPassword }
      );
      
      if (error) throw error;
      
      toast.success("رمز عبور کاربر با موفقیت تغییر کرد");
      setIsPasswordDialogOpen(false);
      passwordForm.reset({ newPassword: "" });
    } catch (error: any) {
      console.error("خطا در تغییر رمز عبور:", error);
      toast.error(error.message || "خطا در تغییر رمز عبور");
    }
  };

  const updateUserProfile = async (values: any) => {
    if (!selectedUser) return;
    
    const sanitizedValues = {
      credits: securityUtils.sanitizeNumber(values.credits),
      full_name: securityUtils.sanitizeString(values.full_name),
      instagram_page: securityUtils.sanitizeInstagramUsername(values.instagram_page),
      phone_number: securityUtils.sanitizePhoneNumber(values.phone_number),
      city: securityUtils.sanitizeString(values.city)
    };
    
    try {
      const { error } = await supabase
        .from("profiles")
        .update(sanitizedValues)
        .eq("id", selectedUser.id);
      
      if (error) throw error;
      
      toast.success("اطلاعات کاربر با موفقیت به‌روز شد");
      setIsEditDialogOpen(false);
      
      setUsers(users.map(user => 
        user.id === selectedUser.id 
          ? {
              ...user,
              profile: {
                ...user.profile,
                ...sanitizedValues
              }
            }
          : user
      ));
      
    } catch (error: any) {
      console.error("خطا در به‌روزرسانی پروفایل:", error);
      toast.error(error.message || "خطا در به‌روزرسانی اطلاعات کاربر");
    }
  };

  const selectUser = (user: User) => {
    setSelectedUser(user);
    setPaymentsPage(1);
    fetchUserPayments(user.id);
    
    form.reset({
      credits: user.profile?.credits || 0,
      full_name: user.profile?.full_name || "",
      instagram_page: user.profile?.instagram_page || "",
      phone_number: user.profile?.phone_number || "",
      city: user.profile?.city || "",
      notes: ""
    });
  };

  const backToUserList = () => {
    setSelectedUser(null);
    setUserPayments([]);
  };

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const openPasswordDialog = () => {
    setIsPasswordDialogOpen(true);
    passwordForm.reset({ newPassword: "" });
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  useEffect(() => {
    if (selectedUser) {
      fetchUserPayments(selectedUser.id);
    }
  }, [paymentsPage]);

  const filteredUsers = users.filter(
    (user) =>
      (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.profile?.full_name &&
        user.profile.full_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.profile?.instagram_page &&
        user.profile.instagram_page.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.profile?.phone_number &&
        user.profile.phone_number.includes(searchTerm))
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("fa-IR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat("fa-IR").format(amount);
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case "paid":
        return "پرداخت شده";
      case "pending":
        return "در انتظار پرداخت";
      case "failed":
        return "ناموفق";
      default:
        return status;
    }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "paid":
        return "bg-green-900 text-green-300";
      case "pending":
        return "bg-yellow-900 text-yellow-300";
      case "failed":
        return "bg-red-900 text-red-300";
      default:
        return "bg-gray-900 text-gray-300";
    }
  };

  const renderPaginationNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }
    
    return pageNumbers;
  };

  if (selectedUser) {
    return (
      <div className="space-y-4" dir="rtl">
        <div className="flex items-center justify-between mb-4">
          <Button
            variant="outline"
            size="sm"
            onClick={backToUserList}
            className="flex items-center gap-2 admin-button"
          >
            <ArrowRight className="h-4 w-4" />
            بازگشت به لیست کاربران
          </Button>
          
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={openEditDialog}
              className="flex items-center gap-2 admin-button"
            >
              <Edit className="h-4 w-4" />
              ویرایش کاربر
            </Button>
            
            <Button
              variant="outline"
              size="sm"
              onClick={openPasswordDialog}
              className="flex items-center gap-2 admin-button"
            >
              <Lock className="h-4 w-4" />
              تغییر رمز عبور
            </Button>
            
            <Button
              variant="outline"
              size="sm"
              onClick={() => sendPasswordResetEmail(selectedUser.email)}
              disabled={sendingPasswordReset}
              className="flex items-center gap-2 admin-button"
            >
              <Key className="h-4 w-4" />
              {sendingPasswordReset ? "در حال ارسال..." : "ارسال ایمیل بازیابی رمز"}
            </Button>
          </div>
        </div>
        
        <Card className="bg-gray-800 border-gray-700">
          <CardContent className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="text-lg font-medium text-white mb-4">اطلاعات پروفایل</h3>
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="text-gray-400">ایمیل:</span>
                    <span className="text-white">{securityUtils.escapeHtml(selectedUser.email)}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">نام:</span>
                    <span className="text-white">{securityUtils.escapeHtml(selectedUser.profile?.full_name || "—")}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">اعتبار:</span>
                    <span className="text-white">{selectedUser.profile?.credits}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">صفحه اینستاگرام:</span>
                    <span className="text-white">{securityUtils.escapeHtml(selectedUser.profile?.instagram_page || "—")}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">شماره تلفن:</span>
                    <span className="text-white">{securityUtils.escapeHtml(selectedUser.profile?.phone_number || "—")}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">شهر:</span>
                    <span className="text-white">{securityUtils.escapeHtml(selectedUser.profile?.city || "—")}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">تاریخ ثبت‌نام:</span>
                    <span className="text-white">{formatDate(selectedUser.created_at)}</span>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-lg font-medium text-white mb-4">تاریخچه پرداخت‌ها</h3>
                {loadingPayments ? (
                  <div className="space-y-2">
                    {Array(3).fill(0).map((_, i) => (
                      <Skeleton key={i} className="h-10 w-full bg-gray-700" />
                    ))}
                  </div>
                ) : userPayments.length === 0 ? (
                  <p className="text-gray-400">این کاربر تاکنون پرداختی نداشته است.</p>
                ) : (
                  <div className="space-y-3">
                    {userPayments.map((payment) => (
                      <div key={payment.id} className="bg-gray-700 p-3 rounded-md">
                        <div className="flex justify-between">
                          <span className="text-gray-300">
                            {payment.package_name}
                            {payment.track_id && 
                              <span className="text-xs text-gray-400 block mt-1">
                                شماره پیگیری: {payment.track_id}
                              </span>
                            }
                          </span>
                          <span className={`font-medium ${payment.status === 'paid' ? 'text-green-400' : payment.status === 'pending' ? 'text-yellow-400' : 'text-red-400'}`}>
                            {formatAmount(payment.amount)} ریال
                          </span>
                        </div>
                        <div className="flex justify-between mt-1">
                          <span className="text-gray-400">{formatDate(payment.payment_date || payment.created_at)}</span>
                          <span className={`text-sm px-2 py-0.5 rounded ${getStatusBadgeClass(payment.status)}`}>
                            {getStatusText(payment.status)}
                          </span>
                        </div>
                      </div>
                    ))}
                    
                    {totalPaymentsPages > 1 && (
                      <div className="flex justify-center items-center gap-2 mt-4">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setPaymentsPage(p => Math.max(p - 1, 1))}
                          disabled={paymentsPage === 1}
                          className="admin-button"
                        >
                          <ArrowRight className="h-4 w-4" />
                        </Button>
                        
                        <span className="text-gray-400 mx-2">
                          صفحه {paymentsPage} از {totalPaymentsPages}
                        </span>
                        
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setPaymentsPage(p => Math.min(p + 1, totalPaymentsPages))}
                          disabled={paymentsPage === totalPaymentsPages}
                          className="admin-button"
                        >
                          <ArrowLeft className="h-4 w-4" />
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        
        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
          <DialogContent className="bg-gray-800 text-white border-gray-700 sm:max-w-md" dir="rtl">
            <DialogHeader>
              <DialogTitle>ویرایش اطلاعات کاربر</DialogTitle>
            </DialogHeader>
            
            <Form {...form}>
              <form onSubmit={form.handleSubmit(updateUserProfile)} className="space-y-4">
                <FormField
                  control={form.control}
                  name="credits"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>اعتبار</FormLabel>
                      <FormControl>
                        <SecureInput 
                          type="number" 
                          sanitizeAs="number"
                          onSanitizedChange={(value) => field.onChange(parseInt(value) || 0)}
                          className="text-gray-900"
                          placeholder="اعتبار"
                          defaultValue={field.value.toString()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="full_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>نام کامل</FormLabel>
                      <FormControl>
                        <SecureInput 
                          sanitizeAs="text"
                          onSanitizedChange={field.onChange}
                          className="text-gray-900"
                          placeholder="نام کامل" 
                          defaultValue={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="instagram_page"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>صفحه اینستاگرام</FormLabel>
                      <FormControl>
                        <SecureInput 
                          sanitizeAs="instagram"
                          onSanitizedChange={field.onChange}
                          className="text-gray-900"
                          placeholder="نام کاربری اینستاگرام" 
                          defaultValue={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="phone_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>شماره تلفن</FormLabel>
                      <FormControl>
                        <SecureInput 
                          sanitizeAs="phone"
                          onSanitizedChange={field.onChange}
                          className="text-gray-900"
                          placeholder="شماره تلفن" 
                          defaultValue={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>شهر</FormLabel>
                      <FormControl>
                        <SecureInput 
                          sanitizeAs="text"
                          onSanitizedChange={field.onChange}
                          className="text-gray-900"
                          placeholder="شهر" 
                          defaultValue={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="notes"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>یادداشت‌ها (فقط برای مرجع ادمین)</FormLabel>
                      <FormControl>
                        <SecureTextarea 
                          onSanitizedChange={field.onChange}
                          className="text-gray-900"
                          placeholder="یادداشت‌ها" 
                          defaultValue={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <div className="flex justify-end gap-2 pt-2">
                  <Button type="button" variant="outline" onClick={() => setIsEditDialogOpen(false)}>
                    انصراف
                  </Button>
                  <Button type="submit">
                    ذخیره تغییرات
                  </Button>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
        
        <Dialog open={isPasswordDialogOpen} onOpenChange={setIsPasswordDialogOpen}>
          <DialogContent className="bg-gray-800 text-white border-gray-700 sm:max-w-md" dir="rtl">
            <DialogHeader>
              <DialogTitle>تغییر رمز عبور کاربر</DialogTitle>
            </DialogHeader>
            
            <Form {...passwordForm}>
              <form onSubmit={passwordForm.handleSubmit(setUserPassword)} className="space-y-4">
                <FormField
                  control={passwordForm.control}
                  name="newPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>رمز عبور جدید</FormLabel>
                      <FormControl>
                        <SecureInput 
                          type="password"
                          sanitizeAs="text"
                          className="text-gray-900"
                          placeholder="رمز عبور جدید را وارد کنید"
                          onSanitizedChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <div className="flex justify-end gap-2 pt-2">
                  <Button 
                    type="button" 
                    variant="outline" 
                    onClick={() => setIsPasswordDialogOpen(false)}
                  >
                    انصراف
                  </Button>
                  <Button type="submit">
                    ذخیره تغییرات
                  </Button>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  return (
    <div className="space-y-4" dir="rtl">
      <div className="flex items-center space-x-2 space-x-reverse">
        <div className="relative flex-1">
          <Search className="absolute right-3 top-2.5 h-4 w-4 text-gray-400" />
          <SecureInput
            placeholder="جستجو بر اساس ایمیل، نام، صفحه اینستاگرام یا شماره تلفن..."
            defaultValue={searchTerm}
            sanitizeAs="text"
            onSanitizedChange={setSearchTerm}
            className="pl-4 pr-10 text-gray-900"
          />
        </div>
        <Button 
          variant="outline" 
          size="icon" 
          onClick={fetchUsers} 
          disabled={loading}
          className="admin-button"
        >
          <RefreshCw className={`h-4 w-4 ${loading ? 'animate-spin' : ''}`} />
        </Button>
      </div>

      <Card className="bg-gray-800 border-gray-700">
        <CardContent className="p-0">
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead className="bg-gray-700 text-gray-200">
                <tr>
                  <th className="text-right px-4 py-3">ایمیل</th>
                  <th className="text-right px-4 py-3">نام</th>
                  <th className="text-right px-4 py-3">صفحه اینستاگرام</th>
                  <th className="text-right px-4 py-3">شماره تلفن</th>
                  <th className="text-right px-4 py-3">اعتبار</th>
                  <th className="text-right px-4 py-3">تاریخ ثبت‌نام</th>
                  <th className="text-right px-4 py-3">عملیات</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {loading ? (
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <tr key={index}>
                        <td className="px-4 py-3">
                          <Skeleton className="h-4 w-32 bg-gray-700" />
                        </td>
                        <td className="px-4 py-3">
                          <Skeleton className="h-4 w-20 bg-gray-700" />
                        </td>
                        <td className="px-4 py-3">
                          <Skeleton className="h-4 w-24 bg-gray-700" />
                        </td>
                        <td className="px-4 py-3">
                          <Skeleton className="h-4 w-24 bg-gray-700" />
                        </td>
                        <td className="px-4 py-3">
                          <Skeleton className="h-4 w-12 bg-gray-700" />
                        </td>
                        <td className="px-4 py-3">
                          <Skeleton className="h-4 w-28 bg-gray-700" />
                        </td>
                        <td className="px-4 py-3">
                          <Skeleton className="h-8 w-10 bg-gray-700" />
                        </td>
                      </tr>
                    ))
                ) : filteredUsers.length === 0 ? (
                  <tr>
                    <td
                      colSpan={7}
                      className="px-4 py-8 text-center text-gray-400"
                    >
                      هیچ کاربری یافت نشد.
                    </td>
                  </tr>
                ) : (
                  filteredUsers.map((user) => (
                    <tr
                      key={user.id}
                      className="hover:bg-gray-700/50 transition-colors cursor-pointer text-gray-200"
                      onClick={() => selectUser(user)}
                    >
                      <td className="px-4 py-3">{user.email}</td>
                      <td className="px-4 py-3">{user.profile?.full_name || "—"}</td>
                      <td className="px-4 py-3">{user.profile?.instagram_page || "—"}</td>
                      <td className="px-4 py-3 text-left">{user.profile?.phone_number || "—"}</td>
                      <td className="px-4 py-3">{user.profile?.credits}</td>
                      <td className="px-4 py-3">{formatDate(user.created_at)}</td>
                      <td className="px-4 py-3">
                        <Button 
                          variant="ghost" 
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            selectUser(user);
                          }}
                          className="admin-button"
                        >
                          <User className="h-4 w-4 text-gray-300" />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
      
      {!loading && totalPages > 1 && (
        <div className="flex justify-center items-center gap-2 mt-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage(p => Math.max(p - 1, 1))}
            disabled={currentPage === 1}
            className="admin-button"
          >
            <ArrowRight className="h-4 w-4" />
          </Button>
          
          {renderPaginationNumbers().map((pageNum, index) => (
            pageNum === "..." ? (
              <span key={`ellipsis-${index}`} className="text-gray-400">...</span>
            ) : (
              <Button
                key={`page-${pageNum}`}
                variant={currentPage === pageNum ? "default" : "outline"}
                size="sm"
                onClick={() => typeof pageNum === 'number' && setCurrentPage(pageNum)}
                className={`min-w-[2.5rem] ${currentPage === pageNum ? '' : 'admin-button'}`}
              >
                {pageNum}
              </Button>
            )
          ))}
          
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage(p => Math.min(p + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="admin-button"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
