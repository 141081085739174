
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent } from "@/components/ui/card";
import { Copy, Loader2, Lightbulb, Sparkles } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { InfoBox } from "@/components/InfoBox";
import { motion } from "framer-motion";

interface ContentRepurposerProps {
  initialShowHelp?: boolean;
}

export const ContentRepurposer = ({
  initialShowHelp = false
}: ContentRepurposerProps) => {
  const [sourceUrl, setSourceUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [variations, setVariations] = useState<string[]>([]);
  const [explanation, setExplanation] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [showHelp, setShowHelp] = useState(initialShowHelp);
  const {
    toast
  } = useToast();
  const {
    profile,
    refreshProfile
  } = useAuth();

  useEffect(() => {
    setShowHelp(initialShowHelp);
  }, [initialShowHelp]);

  const handleRepurpose = async () => {
    if (!sourceUrl) {
      toast({
        title: "خطا",
        description: "لطفاً آدرس محتوا را وارد کنید",
        variant: "destructive"
      });
      return;
    }
    if (!profile) {
      toast({
        title: "خطا",
        description: "لطفاً وارد حساب کاربری خود شوید",
        variant: "destructive"
      });
      return;
    }
    const toolInfo = {
      id: "repurpose",
      creditCost: 4
    };
    if (profile.credits < toolInfo.creditCost) {
      toast({
        title: "اعتبار ناکافی",
        description: `برای استفاده از این ابزار به ${toolInfo.creditCost} اعتبار نیاز دارید`,
        variant: "destructive"
      });
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      console.log('Calling repurpose-content function with URL:', sourceUrl);
      const {
        data,
        error
      } = await supabase.functions.invoke("repurpose-content", {
        body: {
          sourceUrl
        }
      });
      if (error) {
        console.error('Error from edge function:', error);
        throw new Error(error.message);
      }
      if (!data || !data.variations || data.variations.length === 0) {
        console.error('No content was returned from the edge function');
        throw new Error('بازنویسی محتوا با خطا مواجه شد. لطفاً مجدداً تلاش کنید.');
      }

      const {
        error: updateError
      } = await supabase.from('profiles').update({
        credits: profile.credits - toolInfo.creditCost
      }).eq('id', profile.id);
      if (updateError) throw updateError;

      const {
        error: insertError
      } = await supabase.from('tool_usage').insert({
        user_id: profile.id,
        tool_id: toolInfo.id,
        credit_cost: toolInfo.creditCost
      });
      if (insertError) throw insertError;

      const formattedVariations = data.variations.map((content: string) => {
        return content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
          .replace(/\*(.*?)\*/g, '<em>$1</em>')
          .replace(/^# (.*?)$/gm, '<h1>$1</h1>')
          .replace(/^## (.*?)$/gm, '<h2>$1</h2>')
          .replace(/^### (.*?)$/gm, '<h3>$1</h3>');
      });
      
      setVariations(formattedVariations);
      setExplanation(data.explanation || "");
      
      const {
        error: saveError
      } = await supabase.from("repurposed_content").insert([{
        source_url: sourceUrl,
        content_variations: formattedVariations,
        explanation: data.explanation || "",
        user_id: profile.id
      }]).select().single();
      if (saveError) {
        console.error('Error saving repurposed content:', saveError);
      }

      await refreshProfile();
      toast({
        title: "محتوا آماده شد",
        description: "محتوای شما با موفقیت بازنویسی شد"
      });
    } catch (error: any) {
      console.error("Error in content repurposing:", error);
      setError(error.message);
      toast({
        title: "خطا",
        description: error.message || "مشکلی در بازنویسی محتوا پیش آمد",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    const plainText = text.replace(/<[^>]*>/g, '');
    await navigator.clipboard.writeText(plainText);
    toast({
      title: "کپی شد",
      description: "محتوا در کلیپ‌بورد کپی شد"
    });
  };

  return <div className="space-y-4 relative" dir="rtl">
      {showHelp && <div className="absolute inset-0 z-40 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 rounded-md animate-in fade-in-0 duration-200" onClick={() => setShowHelp(false)}>
          <div className="bg-white/90 backdrop-blur-md p-6 rounded-lg shadow-xl max-w-xl w-full text-right space-y-4 animate-in slide-in-from-bottom-3 duration-300 max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-300" onClick={e => e.stopPropagation()} style={{
        scrollbarWidth: 'thin',
        scrollbarColor: '#D1D5DB transparent'
      }}>
            <h3 className="text-xl font-bold text-gray-900 mb-2">راهنمای استفاده از ابزار بازنویسی محتوا</h3>
            <p className="text-gray-700 mb-4">با این ابزار می‌توانید محتوای وب‌سایت‌ها را به پست‌های جذاب اینستاگرامی تبدیل کنید.</p>
            <div className="space-y-3">
              <h4 className="font-semibold text-gray-800">مراحل استفاده:</h4>
              <ol className="list-decimal list-inside space-y-2 pr-4">
                <li>آدرس مقاله یا صفحه وب مورد نظر خود را در کادر وارد کنید.</li>
                <li>دکمه «تولید محتوای جدید» را کلیک کنید.</li>
                <li>محتوای استخراج شده پردازش شده و به فرمت مناسب اینستاگرام تبدیل می‌شود.</li>
                <li>برای استفاده از این ابزار، ۱۰ اعتبار کسر می‌شود.</li>
              </ol>
            </div>
            <div className="space-y-3 mt-4">
              <h4 className="font-semibold text-gray-800">بهترین روش‌های استفاده:</h4>
              <ul className="list-disc list-inside space-y-2 pr-4">
                <li>از آدرس‌های معتبر استفاده کنید که محتوای متنی کافی داشته باشند.</li>
                <li>برای مقالات و محتوای بلاگی، بهترین نتیجه را خواهید گرفت.</li>
                <li>محتوای تولید شده را بررسی کرده و در صورت نیاز ویرایش کنید.</li>
                <li>می‌توانید از این محتوا برای ایجاد پست‌های آموزشی و اطلاع‌رسانی استفاده کنید.</li>
              </ul>
            </div>
            <div className="mt-6 text-center">
              <Button onClick={() => setShowHelp(false)}>بستن راهنما</Button>
            </div>
          </div>
        </div>}

      <div className="mb-4">
        <p className="text-sm mb-2 text-right text-zinc-50">
          آدرس مقاله یا وبسایت مورد نظر خود را وارد کنید تا آن را به محتوای مناسب اینستاگرام تبدیل کنیم.
        </p>
      </div>
      
      <Input placeholder="آدرس مقاله یا محتوای اصلی را وارد کنید..." value={sourceUrl} onChange={e => setSourceUrl(e.target.value)} />
      
      <Button onClick={handleRepurpose} disabled={isLoading} className="w-full">
        {isLoading ? <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            در حال تولید محتوا...
          </> : "تولید محتوای جدید"}
      </Button>

      {error && <div className="p-3 bg-red-50 text-red-700 rounded-md text-sm">
          {error}
        </div>}

      {variations.length > 0 ? (
        <div className="space-y-6">
          {variations.map((variation, index) => (
            <Card key={index} className="overflow-hidden">
              <CardContent className="pt-6">
                <div className="flex justify-between items-start gap-4">
                  <div className="flex-1">
                    <h3 className="font-semibold mb-4">نسخه {index + 1}</h3>
                    <div 
                      className="text-gray-700 text-right leading-relaxed" 
                      dangerouslySetInnerHTML={{
                        __html: variation
                      }}
                    />
                  </div>
                  <Button variant="ghost" size="icon" onClick={() => copyToClipboard(variation)}>
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
          
          {explanation && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mt-8"
            >
              <Card className="border border-primary/20 bg-gradient-to-br from-purple-50/10 to-blue-50/10 backdrop-blur-sm overflow-hidden">
                <CardContent className="p-5 relative">
                  <motion.div 
                    className="absolute -right-6 -top-6 text-primary/10 z-0" 
                    animate={{
                      rotate: [0, 15, 0, 15, 0],
                      scale: [1, 1.2, 1, 1.2, 1]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <Lightbulb size={120} />
                  </motion.div>
                  
                  <div className="flex items-center mb-4 relative z-10">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.5, type: "spring" }}
                      className="flex items-center justify-center mr-3 w-8 h-8 rounded-full bg-primary/10"
                    >
                      <Sparkles className="h-4 w-4 text-primary" />
                    </motion.div>
                    <h3 className="text-lg font-semibold text-gray-800">استراتژی محتوا: نحوه بازنویسی محتوا</h3>
                  </div>
                  
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="relative z-10"
                  >
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">{explanation}</p>
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      ) : (
        <InfoBox>
          <p className="text-sm">
            <strong>ابزار بازنویسی محتوا</strong> به شما کمک می‌کند تا محتوای موجود در وب‌سایت‌ها و مقالات را به پست‌های جذاب اینستاگرامی تبدیل کنید. 
            فقط کافیست آدرس صفحه یا مقاله مورد نظر خود را وارد کنید و دکمه تولید را بزنید. بدون نیاز به بازنویسی یا ویرایش دستی، محتوای شما به فرمت مناسب اینستاگرام و با ساختار حرفه‌ای تبدیل می‌شود.
          </p>
        </InfoBox>
      )}
    </div>;
};
