
import React from "react";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import type { Tool } from "@/types/tool";

interface MobileToolWindowProps {
  tool: Tool | null;
  onClose: () => void;
}

export const MobileToolWindow = ({ tool, onClose }: MobileToolWindowProps) => {
  if (!tool) return null;

  return (
    <motion.div
      className="mobile-tool-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      dir="rtl"
    >
      <button className="mobile-back-button" onClick={onClose}>
        <ArrowLeft className="h-5 w-5" />
      </button>
      <div className="pt-16">
        <h2 className="text-lg font-semibold text-white mb-4">{tool.title}</h2>
        <div className="bg-white/10 backdrop-blur-md p-4 rounded-lg border border-white/20 text-white">
          <tool.component />
        </div>
      </div>
    </motion.div>
  );
};
